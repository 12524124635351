<div class="profile-header">
  <app-profile-header [org]="org" [user]="user" [editable]="editable" ></app-profile-header>
</div>

<div class="container">
<div class="row">
		<div class="col col-xl-9 order-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title" translate>messages.header_title</h6>
					<!-- <a href="#" class="more"><svg class="olymp-three-dots-icon"><use xlink:href="#olymp-three-dots-icon"></use></svg></a> -->
				</div>

				<div class="row">
                    <!-- empty messages -->
                    <div *ngIf="(!messages || messages.length == 0) && !currentThread" class="col-12 no-messages" translate>messages.no_messages_info</div>

					<div class="col col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 padding-r-0 chat-item">

						<!-- List Chat Messages -->

                        <perfect-scrollbar  [autoPropagation]="true" [config]="{ }" class="msg-container">
                            <ul class="notification-list chat-message">

                                <ng-container *ngFor="let message of messages" >
                                    <li *ngIf="message.valid" (click)="selectMessageThread(message)">
                                        <div class="author-thumb">
                                            <img loading="lazy" class="img-author" src="{{message.from.avatar}}" alt="author">
                                        </div>
                                        <div class="notification-event">
                                            <a [routerLink]="[message.from.profileUrl]" (click)="$event.stopPropagation();" class="h6 notification-friend">{{message.from.name}}</a>
                                            <span class="chat-message-item two-rows unselectable">{{message.last?.message}}</span>
                                            <span class="notification-date"><time class="entry-date updated unselectable" >{{message.last?.sentTime}}</time></span>
                                        </div>
                                        <span class="notification-icon">
                                            <svg class="olymp-chat---messages-icon"><use xlink:href="#olymp-chat---messages-icon"></use></svg>
                                        </span>

                                        <div class="unread-flag bg-purple" *ngIf="message.unread > 0" >{{message.unread}}</div>

                                        <!-- <div class="more">
                                            <svg class="olymp-three-dots-icon"><use xlink:href="#olymp-three-dots-icon"></use></svg>
                                        </div> -->
                                    </li>
                                </ng-container>

                                <!-- <li>
                                    <div class="author-thumb">
                                        <img loading="lazy" src="img/avatar8-sm.jpg" alt="author">
                                    </div>
                                    <div class="notification-event">
                                        <a href="#" class="h6 notification-friend">Diana Jameson</a>
                                        <span class="chat-message-item">Hi James! It’s Diana, I just wanted to let you know that we have to reschedule...</span>
                                        <span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">4 hours ago</time></span>
                                    </div>
                                    <span class="notification-icon">
                                                                    <svg class="olymp-chat---messages-icon"><use xlink:href="#olymp-chat---messages-icon"></use></svg>
                                                                </span>

                                    <div class="more">
                                        <svg class="olymp-three-dots-icon"><use xlink:href="#olymp-three-dots-icon"></use></svg>
                                    </div>

                                </li> -->
                            </ul>
                        </perfect-scrollbar>

						<!-- ... end List Chat Messages -->


					</div>

					<div class="col col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 padding-l-0 thread-container">


						<!-- Chat Field -->

						<div class="chat-field" *ngIf="currentThread">
							<div class="ui-block-title">
                                <div class="author-thumb">
                                    <img loading="lazy" class="img-author" src="{{currentThread.from.avatar}}" alt="author">
                                </div>
                                <h6 class="title" style="white-space: nowrap; padding-left: 12px;">{{currentThread.from.name}}</h6>
                                <span style="width: 100%;">&nbsp;</span>
								<!-- <a href="#" class="more"><svg class="olymp-three-dots-icon"><use xlink:href="#olymp-three-dots-icon"></use></svg></a> -->
                            </div>

                            <perfect-scrollbar #messageThreadScroll [position]="end" [autoPropagation]="true" [config]="{ }" class="msg-container-thread">
								<ul class="notification-list chat-message chat-message-field">

                                    <li *ngFor="let item of currentThread.chat">
										<div class="author-thumb">
											<img loading="lazy" class="img-author" src="{{currentThread.users[item.from].avatar}}" alt="author">
										</div>
										<div class="notification-event" style="width:100%;">
											<div class="event-info-wrap">
												<span class="h6 notification-friend">{{currentThread.users[item.from].name}}</span>
												<span class="notification-date" ><time class="entry-date updated" datetime="{{item.sent}}">{{item.sentTime}}</time></span>
											</div>
											<span class="chat-message-item">{{item.message}}</span>
										</div>
									</li>

									<!-- <li>
										<div class="author-thumb">
											<img loading="lazy" src="img/avatar10-sm.jpg" alt="author">
										</div>
										<div class="notification-event">
											<div class="event-info-wrap">
												<a href="#" class="h6 notification-friend">Elaine Dreyfuss</a>
												<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
											</div>
											<span class="chat-message-item">Hi James, How are your doing? Please remember that next week we are going to Gotham Bar to celebrate Marina’s Birthday.</span>
										</div>
									</li>

									<li>
										<div class="author-thumb">
											<img loading="lazy" src="img/author-page.jpg" alt="author">
										</div>
										<div class="notification-event">
											<div class="event-info-wrap">
												<a href="#" class="h6 notification-friend">James Spiegel</a>
												<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 8:30pm</time></span>
											</div>
											<span class="chat-message-item">Hi Elaine! I have a question, do you think that tomorrow we could talk to
																	the client to iron out some details before the presentation? I already finished the first screen but
																	I need to ask him something before moving on. Anyway, here’s a preview!
																</span>
											<div class="added-photos">
												<img loading="lazy" src="img/photo-message1.jpg" alt="photo">
												<img loading="lazy" src="img/photo-message2.jpg" alt="photo">
												<span class="photos-name">icons.jpeg; bunny.jpeg</span>
											</div>
										</div>
									</li>

									<li>
										<div class="author-thumb">
											<img loading="lazy" src="img/avatar10-sm.jpg" alt="author">
										</div>
										<div class="notification-event">
											<div class="event-info-wrap">
												<a href="#" class="h6 notification-friend">Elaine Dreyfuss</a>
												<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 9:56pm</time></span>
											</div>
											<span class="chat-message-item">We’ll have to check that at the office and see if the client is on board with it. I think That looks really good!</span>
										</div>
									</li> -->
                                </ul>
                            </perfect-scrollbar>

							<form>

                                <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <!-- <mat-label translate>Reply</mat-label> -->
                                        <textarea matInput name="newMessage" rows="3" [(ngModel)]="newMessage"></textarea>
                                    </mat-form-field>
                                </div>

								<div class="add-options-message">
                                    <span class="options-message" [matMenuTriggerFor]="emojisMenu" #matMenuTrigger="matMenuTrigger">
                                        <svg class="olymp-happy-face-icon"><use xlink:href="#olymp-happy-face-icon"></use></svg>
                                    </span>

                                    <mat-menu #emojisMenu="matMenu" >
                                        <emoji-mart (click)="$event.stopPropagation();" class="emoji-mart" [showSingleCategory]="true" (emojiSelect)="addEmoji($event); matMenuTrigger.closeMenu();" title="{{ 'choose_emoji' | translate }}"></emoji-mart>
                                    </mat-menu>

									<button class="btn btn-primary btn-sm" (click)="sendMessage()" [disabled]="!newMessage" translate>messages.post_message</button>
								</div>

							</form>

						</div>

						<!-- ... end Chat Field -->

					</div>
				</div>

			</div>

        </div>

        <!-- left side components -->
        <aside class="col col-xl-3 order-xl-1 sidebar">
            <!-- TODO: add here widget to select organization to send messages -->
            <app-profile-members-widget *ngIf="org" [org]="org" ></app-profile-members-widget>
            <app-profile-badges-widget *ngIf="user" [user]="user" ></app-profile-badges-widget>
            <app-profile-info-widget [user]="user" [org]="org" [showFullInfo]="false" ></app-profile-info-widget>
		</aside>


	</div>
</div>



<div *ngIf="currentThread" class="container popup-chat">
	<div class="row">
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">


			<!-- Popup Chat -->

			<div class="ui-block popup-chat">
				<div class="ui-block-title">
                    <div class="author-thumb">
                        <img loading="lazy" class="img-author" src="{{currentThread.from.avatar}}" alt="author">
                    </div>
					<h6 class="title" style="white-space: nowrap; padding-left: 12px;">{{currentThread.from.name}}</h6>
					<div class="more">
                        <a [routerLink]="" (click)="closeThread()">
                            <svg class="olymp-close-icon left-menu-icon"><use xlink:href="#olymp-close-icon"></use></svg>
                        </a>
						<!-- <svg class="olymp-three-dots-icon"><use xlink:href="#olymp-three-dots-icon"></use></svg>
						<svg class="olymp-little-delete"><use xlink:href="#olymp-little-delete"></use></svg> -->
					</div>
                </div>
                <perfect-scrollbar #messageThreadScrollPopup [autoPropagation]="false" [config]="{ }" class="msg-container-popup">
					<ul class="notification-list chat-message chat-message-field">

                        <ng-container *ngFor="let item of currentThread.chat; let i = index;">

                            <li [className]="item.from == 0 ? 'chat-item-left' : 'chat-item-right'">
                                <!-- <div *ngIf="i == 0 || currentThread.chat[i - 1].from != item.from" class="author-thumb"> -->
                                <div class="author-thumb">
                                    <img loading="lazy" class="img-author" src="{{currentThread.users[item.from].avatar}}" alt="author">
                                </div>
                                <div class="notification-event">
                                    <span class="chat-message-item">{{item.message}}</span>
                                    <span class="notification-date date-to-right"><time class="entry-date updated" datetime="{{item.sent}}">{{item.sentTime}}</time></span>
                                </div>
                            </li>

                        </ng-container>

					</ul>
				</perfect-scrollbar>

				<form class="popup-form">

					<div class="form-group label-floating is-empty">

                        <a class="emojis-options" [matMenuTriggerFor]="emojisMenu" #matMenuTrigger="matMenuTrigger">
                            <svg class="olymp-happy-face-icon"><use xlink:href="#olymp-happy-face-icon"></use></svg>
                        </a>

                        <mat-menu #emojisMenu="matMenu" >
                            <emoji-mart (click)="$event.stopPropagation();" class="emoji-mart" [showSingleCategory]="true" (emojiSelect)="addEmoji($event); matMenuTrigger.closeMenu();" title="{{ 'choose_emoji' | translate }}"></emoji-mart>
                        </mat-menu>

						<textarea class="form-control" name="newMessage" [(ngModel)]="newMessage" placeholder="{{ 'messages.enter_text' | translate }}"></textarea>
						<div class="add-options-message">

                            <button class="btn btn-primary btn-sm" (click)="sendMessage()" [disabled]="!newMessage" translate>messages.post_message</button>
						</div>
					</div>

				</form>


			</div>

			<!-- ... end Popup Chat -->


		</div>
	</div>
</div>

<!-- ... end Popup Chat -->
