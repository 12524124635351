import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { EventService } from '../services/event.service';
import { BaseEvent, ErrorEventData, EventType } from '../shared/types/base';
import { Subscription } from 'rxjs';

@Component({
    selector: 'base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit, OnDestroy {

    constructor(protected eventService: EventService) {
        this.subscriptions.add(
          this.eventService.getObservable().subscribe((data) => {
              this.eventReceived(data);
          })
        );
    }

    protected subscriptions = new Subscription();

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    protected eventReceived(event: BaseEvent): void {
        // override
    };

    protected sendEvent(event: BaseEvent): void {
        this.eventService?.publishData(event);
    }

    protected handleApiError(error) {
      console.log(error);
        const errorMsg = ApiService.getErrorMessage(error);
        const data: ErrorEventData = {
            error: error,
            alert: { type: 'error', message: errorMsg, dismissible: true },
            unauthorized: error?.status === 401
        };

        this.sendEvent({
            name: EventType.error,
            data
        });
    }
}
