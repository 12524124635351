import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import {EventType} from '../../shared/types/base';
import { BaseComponent } from 'src/app/base/base.component';
import {EventService} from '../../services/event.service';

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent extends BaseComponent implements OnInit {

    @Input() feeds = [];
    @Input() singleFeed = null;
    @Input() openIndex = 0;

    currentUser = null;
    isSuperAdmin: boolean = false;

    openReplyComment: string = "";
    allowComment: boolean = false;
    newComment: string = "";

    constructor(
        eventService: EventService,
        public activeModal: NgbActiveModal,
        private httpClient: HttpClient,
        private apiService: ApiService) {
            super(eventService);
            this.isSuperAdmin = apiService.getCurrentUser()?.superAdmin ?? false;
            this.currentUser = this.apiService.getCurrentUser();
            this.allowComment = this.currentUser != null;
        }

    ngOnInit(): void {
      if (this.singleFeed) {
        this.getFullFeed();
      }
    }

    getPhotoUrl(photo) {
        return ApiService.getPhotoUrl(photo?.url);
    }

    static open(modalService, params: any) {
        let modalRef = modalService.open(ImageViewerComponent, { centered: true, windowClass : "modal-class" });
        if (params?.photos) {
            modalRef.componentInstance.photos = params.photos;
        }
        if (params?.feeds) {

            params.feeds.forEach(f => {
                if (params.user && !f.user) {
                    f.user = params.user;
                }
                if (params.org && !f.org) {
                    f.org = params.org;
                }
            });
            modalRef.componentInstance.feeds = params.feeds;
        }
        if (params?.singleFeed) {
            modalRef.componentInstance.singleFeed = params.singleFeed;
        }
        if (params?.openIndex) {
            modalRef.componentInstance.openIndex = params.openIndex;
        }
    }

    downloadImage(photo) {
        if (!this.isSuperAdmin) {
            return;
        }
        if (!photo && this.singleFeed) photo = this.singleFeed.photo;
        else return;

        const imgUrl = ApiService.getPhotoUrl(photo?.url);
        const imgName = imgUrl.substr(imgUrl.lastIndexOf('/') + 1);
        this.httpClient.get(imgUrl, {responseType: 'blob' as 'json'})
          .subscribe((res: any) => {
            const file = new Blob([res], {type: res.type});

            // IE
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(file);
              return;
            }

            const blob = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = blob;
            link.download = imgName;

            // Version link.click() to work at firefox
            link.dispatchEvent(new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            }));

            setTimeout(() => { // firefox
              window.URL.revokeObjectURL(blob);
              link.remove();
            }, 100);
          });
    }

  isMyFav() {
    return this.currentUser?.favs?.some(f => f.feedId == this.singleFeed._id) ?? false;
  }

  hasMyLike(feed) {
    return feed?.likes?.some(l => l == this.currentUser?._id) ?? false;
  }


  likeFeed(feed) {
    if (!feed?._id) {
      return;
    }

    let currentUser = this.apiService.getCurrentUser();
    if (!currentUser) {
      this.sendEvent( {
        name: EventType.alert,
        data: { type: 'warning', message: 'favs.login_to_like', dismissible: true }
      });
      return;
    }

    let hasMyLike = feed.likes?.some(l => l == currentUser?._id) ?? false;
    if (!hasMyLike) {
      this.apiService.likeFeed(feed._id).subscribe(
        (result) => {
          if (!feed.likes) {
            feed.likes = [];
          }
          feed.likes.push(currentUser._id);
        },
        (error) => this.handleApiError(error) );
    }
    else {
      this.apiService.removeFeedLike(feed._id).subscribe(
        (result) => {
          let idx = feed.likes?.findIndex(l => l == currentUser?._id) ?? -1;
          if (idx > -1) {
            feed.likes.splice(idx, 1);
          }
        },
        (error) => this.handleApiError(error) );
    }
  }

  addFav() {
    if (!this.singleFeed?._id) {
      return;
    }

    if (!this.currentUser) {
      this.sendEvent( { name: EventType.alert, data: { type: 'warning', message: 'favs.login_to_fav', dismissible: true } });
      return;
    }

    let fav = { "feedId": this.singleFeed._id }

    if (!this.isMyFav()) {
      this.apiService.addFavorite(fav).subscribe(
        () => {},
        (error) => this.handleApiError(error) );
    }
    else {
      this.apiService.removeFavorite(fav).subscribe(
        () => {},
        (error) => this.handleApiError(error) );
    }
  }

  getFullFeed() {
    if (!this.singleFeed?._id) {
      return;
    }

    this.apiService.getFullFeed( this.singleFeed._id ).subscribe(
      (result) => {
        this.singleFeed = result.body["feed"];
      },
      (error) => this.handleApiError(error) );
  }

  replyComment($event) {
    if (!$event?.responseTo || !$event?.text) {
      return;
    }

    this.apiService.commentFeedComment(this.singleFeed._id, $event?.responseTo, $event?.text).subscribe(
      () => {
        this.getFullFeed();
        this.openReplyComment = $event?.responseTo;
      },
      (error) => this.handleApiError(error) );
  }


  addEmoji($event) {
    this.newComment = `${this.newComment}${$event.emoji.native}`;
  }

  addComment() {
    if (!this.newComment || !this.singleFeed) {
      return;
    }

    this.apiService.commentFeed(this.singleFeed._id, this.newComment).subscribe(
      (result) => {
        this.newComment = "";
        this.getFullFeed();
      },
      (error) => this.handleApiError(error) );
  }
}
