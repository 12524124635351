<div *ngIf="allowEdit" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

            <div class="align-right">
                <a [routerLink]="" (click)="collapse.toggle()" [attr.aria-expanded]="!addPhotosCollapsed"
                    class="btn btn-primary btn-md-2">{{'profile.add_photos' | translate}} +</a>
            </div>

            <div #collapse="ngbCollapse" [(ngbCollapse)]="addPhotosCollapsed">
                <div class="ui-block responsive-flex">
                    <div class="h5 title" style="margin:16px; text-align: center;" translate>profile.add_photos</div>
                    <div style="margin:16px; text-align: center;">
                        <app-photo-picker #photo_picker [multiple]="true" [working]="working"
                            (photoPicked)="addPhotos($event)">

                            <div class="row justify-content-md-center">
                                <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>profile.photo_description</mat-label>
                                        <textarea [maxLength]="1000" [(ngModel)]="newPhotoDesc" style="min-height: 32px;"
                                            matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                        </app-photo-picker>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



<div *ngIf="feeds" class="container">
  <div class="row">
    <div class="col">
      <div class="photo-album-wrapper">
        <ng-container *ngFor="let feed of feeds">
          <app-home-photo-item
            [feed]="feed" [editable]="allowEdit"
            (onOpenFeed)="onOpenFeed($event)"
            (onRemoveFeed)="removePhotoFeed($event)"
          ></app-home-photo-item>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-back-to-top></app-back-to-top>

<app-load-page-button *ngIf="hasMorePages" (loadMore)="loadMore()" ></app-load-page-button>
