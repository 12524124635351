import { Utils } from "../../../utils";
import { environment } from "../../../../environments/environment";
import { User } from "../../../shared/types/profile";

export class ReferralsTableData {
  public userId: string;
  public userName: string;
  public avatarUrl: string;
  public userUrl?: string;
  constructor(
    user: Partial<User>,
    public totalScore: number,
    public weeklyScore: number,
  ) {
    this.userId = user._id;
    this.userName = `${ user.profile.firstname } ${ user.profile.lastname }`
    this.userUrl = `/profile/${user._id}`;
    this.avatarUrl = Utils.getUserAvatarUrl(user, 'medium') || Utils.getAvatarMedium();
  }

  static fromReferralsWeekly(data: Record<string, any>): ReferralsTableData {
    return new ReferralsTableData(
      data,
      data.totalScore || 0,
      data.weeklyScore || 0,
    )
  }
}
