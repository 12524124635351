import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { SuperAdminService } from "../../services/super-admin.service";
import { User } from "../../shared/types/profile";

@Component({
    selector: 'app-super-admin-users',
    templateUrl: './super-admin-users.component.html',
    styleUrls: ['./super-admin-users.component.css']
})
export class SuperAdminUsersComponent extends BaseComponent implements OnInit {

    displayedColumns: string[] = [
      'email',
      'profile.name',
      'profile.country',
      'created',
      'age',
      'active',
      'verified',
      'blocked',
      'allowMassMessages',
      'star'
    ];

    _filter = { countries: [], sports: [], tags: [], user: null, superAdminFilter: null };
    @Input() set filter(value) {

        let reloadData =
            this._filter.countries != value.countries
            || this._filter.sports != value.sports
            || this._filter.tags != value.tags
            || this._filter.user != value.user
            || this._filter.superAdminFilter != value.superAdminFilter;

        this._filter = value;
        if (reloadData && this.initialized) {
            this.filterChanged.emit();
        }
    }
    get filter() { return this._filter; }

    filterChanged = new EventEmitter();
    working: boolean = false;
    users: any[] = [];
    currentPage = 0;
    hasMorePages: boolean = false;
    resultsLength: number = 0;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        private superAdminService: SuperAdminService
    ) {
        super(eventService);
    }

    initialized: boolean = false;
    ngOnInit(): void {
        //this.getUsers();
        //this.initialized = true;
    }

    ngAfterViewInit() {
        this.initialized = true;
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.filterChanged.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page, this.filterChanged)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.working = true;
                    return this.getUsers(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex)
                        .pipe(catchError(() => observableOf(null)));
                }),
                map(data => {
                    this.working = false;

                    let users = data.body["users"] ?? [];
                    if (users === null) {
                        return [];
                    }

                    this.resultsLength = data.body["usersCount"] ?? 0;
                    return users;
                })
            ).subscribe(data => this.users = data);
    }


    getUsers(sort: string, order: SortDirection, page: number) {

        let params = {
            start: (page) * this.paginator.pageSize,
            len: this.paginator.pageSize,
            orgs: false,
            feeds: false
        };

        params["sort"] = sort;
        params["sortDir"] = order.toString();

        params["sa_all"] = true;
        if (this.filter.superAdminFilter) {
            if (this.filter.superAdminFilter["name"]) {
                params["search"] = this.filter.superAdminFilter["name"];
            }
            let active = this.filter.superAdminFilter["active"];
            if (active) {
                params["blocked"] = active == 'false';
            }
        }

        if (this.filter?.countries) {
            params["country"] = this.filter.countries.join(",");
        }
        if (this.filter?.sports) {
            params["sport"] = this.filter.sports.join(",");
        }
        if (this.filter?.tags && this.filter.tags.length > 0) {
            params["tags"] = this.filter.tags.join(",");
        }
        if (this.filter?.user) {
            if (this.filter.user.gender) {
                params["gender"] = this.filter.user.gender;
            }
            if (this.filter.user.minHeight) {
                params["minHeight"] = this.filter.user.minHeight;
            }
            if (this.filter.user.maxHeight) {
                params["maxHeight"] = this.filter.user.maxHeight;
            }
            if (this.filter.user.minWeight) {
                params["minWeight"] = this.filter.user.minWeight;
            }
            if (this.filter.user.maxWeight) {
                params["maxWeight"] = this.filter.user.maxWeight;
            }
            if (this.filter.user.minAge > 0) {
                params["minAge"] = this.filter.user.minAge;
            }
            if (this.filter.user.maxAge < 99) {
                params["maxAge"] = this.filter.user.maxAge;
            }
            if (this.filter.user.isMember) {
                params["isMemberOfClub"] = this.filter.user.isMember == 'isMember';
            }
        }

        return this.apiService.search(params);
    }

    blockUser(user: any, block: boolean = true) {
        this.working = true;
        this.apiService.blockUser(user._id, block).subscribe(
            (result) => {
                this.working = false;
                user.blocked = block;
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    allowMassMessages(user: any, allow: boolean) {
        this.working = true;
        this.apiService.allowUserMassMessages(user._id, allow).subscribe(
            (result) => {
                this.working = false;
                user.allowMassMessages = allow;
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

  onVerifiedChange(event: MatCheckboxChange, user: User): void {
    if (!event) return;
    event.checked
      ? this.superAdminService.updateUserToVerified(user._id)
      : this.superAdminService.updateUserToNotVerified(user._id);
  }
}
