export class PageableResponse<T> {
  result: Array<T> = [];
  count: number;
}

export class Page {
  skip: number = 0;
  limit: number = 10;
  count: number = 0;
  index: number = 0;
  sortKey?: string;
  sortDirection?: 'asc' | 'desc' | '';

  next(): Page {
    const page = new Page();
    Object.assign(page, this);
    page.setIndex(page.index + 1);
    return page;
  }

  previous(): Page {
    const page = new Page();
    Object.assign(page, this);
    page.setIndex(page.index - 1);
    return page;
  }

  last(): Page {
    const page = new Page();
    Object.assign(page, this);
    page.setIndex(this.getLastPageIndex());
    return page;
  }

  first(): Page {
    const page = new Page();
    Object.assign(page, this);
    page.setIndex(0);
    return page;
  }

  getLastPageIndex() {
    const index = Math.floor(this.count / this.limit - 1);
    return index < 0 ? 0 : index;
  }

  setIndex(i: number): void {
    this.index = i;
    this.skip = this.index * this.limit;
  }
}
