import { Component, Input, OnInit } from '@angular/core';
import { User } from "../../shared/types/profile";
import { ApiService } from "../../services/api.service";
import { Utils } from "../../utils";
import { Member } from "../../shared/types/member";

@Component({
  selector: 'app-user-widget-slim',
  templateUrl: './user-widget-slim.component.html',
  styleUrls: ['./user-widget-slim.component.css']
})
export class UserWidgetSlimComponent implements OnInit {
  @Input() user: User
  @Input() member: Member;
  @Input() showInvitationOptions: boolean = false;
  @Input() showAdminActions = false;
  @Input() showRegularActions = true;
  @Input() isSuperAdmin = false;
  avatarUrl: string;
  collapseActions: boolean = true;
  isMe = false;
  _isVerified = false;

  constructor(
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.isMe = this.apiService.getCurrentUser()?._id === this.user?._id;
    this._isVerified = this.user?.verified || false;
    this.initAvatarUrl();
  }

  private initAvatarUrl(): void {
    this.avatarUrl = ApiService.getPhotoUrl(this.user?.avatar?.avatar?.medium, Utils.getAvatarMedium());
  }
}
