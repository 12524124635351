export enum NotificationType {
  ORG_ACTIVATED = 'org-activated',
  ORG_DEACTIVATED = 'org-deactivated',
  MEMBER_REQUEST = 'member-request',
  MEMBER_REQUEST_ORG = 'org-member-request',
  USER_BLOCKED = 'user-blocked',
  FEED_BLOCKED = 'feed-blocked',
  FAV_USER_NEW_POST = 'fav-user-new-post',
  FAV_ORG_NEW_POST = 'fav-org-new-post',
}

export class NotificationOption {
  constructor(
    public label: string,
    public key: keyof NotificationSettings,
    public hidden: Array<keyof NotificationSettingsItem> = []
  ) {}
}

export class NotificationsConfig {
  enabled = true;
  settings = new NotificationSettings();

  set(config: NotificationsConfig): void {
    this.enabled = config.enabled;
    Object.keys(this.settings).forEach(key => {
      if (config.settings.hasOwnProperty(key)) {
        this.settings[key] = config.settings[key];
      }
    })
  }
}

export class NotificationSettings {
  favouriteOrgPost = new NotificationSettingsItem();
  favouriteUserPost = new NotificationSettingsItem();
  memberRequest = new NotificationSettingsItem();
  memberRequestOrg = new NotificationSettingsItem();
  orgActivated = new NotificationSettingsItem();
  orgDeactivated = new NotificationSettingsItem();
  feedBlocked = new NotificationSettingsItem();
  feedBlockedOrg = new NotificationSettingsItem();
}

export class NotificationSettingsItem {
  inApp: boolean = true;
  email: boolean = true;
}
