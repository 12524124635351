import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';

const USERS_CHUNK_LEN = 25;

@Component({
    selector: 'app-home-users',
    templateUrl: './home-users.component.html',
    styleUrls: ['./home-users.component.css']
})
export class HomeUsersComponent extends BaseComponent implements OnInit {

    currentPage = 0;
    hasMorePages: boolean = false;
    users = [];
    hasOrgs: boolean = false;

    working: boolean = false;

    mapCenter = {
        latitude: 48.1486,
        longitude: 17.1077
    }

    mapFilter = {
        center: this.mapCenter,
        radius: 200000
    }
    newMapBound: boolean = false;

    @Input() showMap: boolean = false;
    @Input() superAdmin: boolean = false;

    _filter = { countries: [], sports: [], tags: [], user: null, superAdminFilter: null };
    @Input() set filter(value) {

        let reloadData =
            this._filter.countries != value.countries
            || this._filter.sports != value.sports
            || this._filter.tags != value.tags
            || this._filter.user != value.user
            || this._filter.superAdminFilter != value.superAdminFilter;

        this._filter = value;
        if (reloadData && this.initialized)
            this.getUsers();
    }
    get filter() { return this._filter; }

    constructor(
        eventService: EventService,
        private apiService: ApiService) {
        super(eventService);
    }

    initialized: boolean = false;
    ngOnInit(): void {
        this.getUsers();
        this.hasOrgs = this.apiService.getCurrentUser()?.adminOrgs?.some(o => o.active);
        this.initialized = true;
    }

    getUsers(fromStart = true) {

        if (fromStart) {
            this.currentPage = 0;
        }

        let params = {
            start: this.currentPage * USERS_CHUNK_LEN,
            len: USERS_CHUNK_LEN,
            orgs: false,
            feeds: false
        };

        if (this.superAdmin) {
            params["sa_all"] = true;
            if (this.filter.superAdminFilter) {
                if (this.filter.superAdminFilter["name"]) {
                    params["search"] = this.filter.superAdminFilter["name"];
                }
                let active = this.filter.superAdminFilter["active"];
                if (active) {
                    params["blocked"] = active == 'false';
                }
            }
        }
        else {
            params["active"] = true;
            params["blocked"] = false;
        }

        if (this.filter?.countries) {
            params["country"] = this.filter.countries.join(",");
        }
        if (this.filter?.sports) {
            params["sport"] = this.filter.sports.join(",");
        }
        if (this.filter?.tags && this.filter.tags.length > 0) {
            params["tags"] = this.filter.tags.join(",");
        }
        if (this.filter?.user) {
            if (this.filter.user.gender) {
                params["gender"] = this.filter.user.gender;
            }
            if (this.filter.user.minHeight) {
                params["minHeight"] = this.filter.user.minHeight;
            }
            if (this.filter.user.maxHeight) {
                params["maxHeight"] = this.filter.user.maxHeight;
            }
            if (this.filter.user.minWeight) {
                params["minWeight"] = this.filter.user.minWeight;
            }
            if (this.filter.user.maxWeight) {
                params["maxWeight"] = this.filter.user.maxWeight;
            }
            if (this.filter.user.minAge > 0) {
                params["minAge"] = this.filter.user.minAge;
            }
            if (this.filter.user.maxAge < 99) {
                params["maxAge"] = this.filter.user.maxAge;
            }
            if (this.filter.user.isMember) {
                params["isMemberOfClub"] = this.filter.user.isMember == 'isMember';
            }
        }

        if (this.showMap && this.mapFilter) {
            params["latLng"] = `${this.mapFilter.center.latitude},${this.mapFilter.center.longitude}`;
            params["radius"] = Math.trunc(this.mapFilter.radius);
        }

        this.newMapBound = false;
        this.working = true;
        this.apiService.search(params).subscribe(
            (result) => {
                this.working = false;

                let users = result.body["users"] ?? [];
                this.hasMorePages = (users.length == USERS_CHUNK_LEN);

                if (this.showMap) {
                    users.forEach(u => {
                        u["mapIcon"] = this.getAvatarMapIcon(u);
                    });
                }

                if (fromStart) {
                    this.users = users;
                } else {
                    this.users = this.users.concat(users);
                }
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    loadMore() {
        this.currentPage++;
        this.getUsers(false);
    }

    blockUser(user, block: boolean = true) {
        this.working = true;
        this.apiService.blockUser(user._id, block).subscribe(
            (result) => {
                this.working = false;
                user.blocked = block;
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    getAvatarMapIcon(user) {
        let url = user?.avatar?.avatar?.small;
        url = ApiService.getPhotoUrl(url, Utils.getAvatarSmall());

        return {
            url: url,
            scaledSize: { width: 28, height: 28 }
        };
    }

    mapBoundsChanged(bounds: google.maps.LatLngBounds) {
        this.mapFilter.center = { longitude: bounds.getCenter().lng(), latitude: bounds.getCenter().lat() };
        this.mapFilter.radius = google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), bounds.getSouthWest());

        this.newMapBound = true;
    }
}
