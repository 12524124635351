import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { EventService } from './event.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Utils } from '../utils';
import { EventType } from '../shared/types/base';
import { first } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    intervalSubs: Subscription = null;
    lastNotification = null;
    subscriptions: Subscription = new Subscription();

    constructor(
        private apiService: ApiService,
        private eventService: EventService,
        private angularFireMessaging: AngularFireMessaging) { }

    ngOnDestroy() {
        if (this.intervalSubs) {
            this.intervalSubs.unsubscribe();
        }

        this.subscriptions?.unsubscribe();
    }

    init(): void {
        this.angularFireMessaging.messages.subscribe(
          (notification: any) => {
            this.eventService.publishData({
              name: EventType.data,
              data: { notification: notification, fromBackground: false }
            });
          });

        this.subscriptions.add(
          this.angularFireMessaging.tokenChanges.subscribe(token => {
            this._sendTokenToServer(token);
          })
        );
    }

    initFirebaseToken(): void {
      if (this.apiService.getFirebaseToken()) return;
      this.angularFireMessaging.requestToken
        .pipe(first())
        .subscribe(
        (token) => {

          if (token) {
            this._sendTokenToServer(token);
          }
          else {
            this.startInterval();
          }
        },
        () => {
          this.startInterval();
        }
      );
    }

    revokeFirebaseToken(): void {
      const token = this.apiService.getFirebaseToken();
      if (!token) return;
      this.angularFireMessaging.deleteToken(token).pipe(first()).subscribe((res) => {});
    }

    _sendTokenToServer(token: string) {
        if (!this.apiService.getCurrentUser()) return;

        this.apiService.updateFirebaseToken(token).subscribe(
            () => {
                console.log("Firebase token updated on server");
            },
            (error) => { console.log("error update Firebase token: ", error); } );
    }

    // ------ disabled Firebase Push service functions ------ //

    startInterval() {
        // start interval to check new notifications (in case of disabled push service)
        console.log("start notification update loop");
        const source = interval(environment.NOTIFICATIONS_CHECK_INTERVAL);
        this.intervalSubs = source.subscribe(val => this.getNotifications());
    }

    getNotifications() {
        if (!this.apiService.hasUserToken()) {
            return;
        }

        this.apiService.getNotifications().subscribe(
            (result) => {
                this.handleNotification(result.body);
            },
            (error) => {
                console.log("Notifications receiver error.");
            });
    }

    handleNotification(data) {
        if (!this.lastNotification || !Utils.deepCompareObjects(this.lastNotification, data)) {
            console.log("Propagate manual notification event");
            this.lastNotification = JSON.parse(JSON.stringify(data));
            this.eventService.publishData({ name: EventType.data, data: { notification: { body: data }, fromBackground: false } });
        }
    }
}
