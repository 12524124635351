import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-photo-picker-from-album',
    templateUrl: './photo-picker-from-album.component.html',
    styleUrls: ['./photo-picker-from-album.component.css']
})
export class PhotoPickerFromAlbumComponent implements OnInit {

    @Input() photos = null;
    @Output() photoSelected = new EventEmitter();
    
    selectedPhoto: any = null;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    getPhotoUrl(photo) {
        return ApiService.getPhotoUrl(photo?.previewUrl, photo?.url);
    }

    onPhotoChecked(photo) {
        this.selectedPhoto = photo;
    }

    cancel() {
        this.activeModal.dismiss();
    }

    selectPhoto() {
        this.photoSelected.emit({ "photo": this.selectedPhoto });
        this.cancel();
    }
}
