import { Component } from '@angular/core';
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { User } from "../../shared/types/profile";
import { UserNamePipe } from "../../pipes/user-name.pipe";

@Component({
  selector: 'app-admin-edit-user-warning',
  templateUrl: './admin-edit-user-warning.component.html',
  styleUrls: ['./admin-edit-user-warning.component.css'],
  providers: [UserNamePipe],
})
export class AdminEditUserWarningComponent {
  _ngbRef: NgbModalRef;
  _user: User;
  _org: Record<string, any>;

  constructor() { }

  onConfirm(): void {
    this._ngbRef.close(true);
  }

  onCancel(): void {
    this._ngbRef.close(false);
  }
}
