<ng-container *ngIf="loggedUser?._id === _user?._id || loggedUser?.superAdmin || !_user?.profile?.hidden">
  <div *ngIf="user || org" class="container">
    <div class="row">


      <!-- left side components -->
      <aside class="col col-xl-3 order-xl-1 col-lg-6 order-lg-1 col-md-6 col-sm-6 col-12 responsive-display-none">
        <app-profile-donation-widget [org]="org" [user]="user"></app-profile-donation-widget>
        <app-profile-members-widget *ngIf="org" [org]="org" ></app-profile-members-widget>
        <app-profile-badges-widget *ngIf="user" [user]="user" ></app-profile-badges-widget>
        <app-favs-widget *ngIf="user && loggedUser?._id === _user?._id" [user]="user"></app-favs-widget>
        <app-profile-info-widget [user]="user" [org]="org" [showFullInfo]="false" ></app-profile-info-widget>
      </aside>

      <!-- ... end left side components -->


      <!-- Right side components -->

      <aside class="col col-xl-3 order-xl-3 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12 responsive-display-none">
        <app-org-recommendations *ngIf="org" [org]="org"></app-org-recommendations>
        <app-profile-photos-widget [user]="user" [org]="org" ></app-profile-photos-widget>
      </aside>


      <!-- Main Content -->

      <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-3 col-md-12 col-sm-12 col-12">

        <div *ngIf="editable && !superAdminEdit" class="ui-block">
          <app-new-feed [user]="user" [org]="org" (feedAdded)="getFeeds(true)"></app-new-feed>
        </div>

        <div id="newsfeed-items-grid">
          <app-feed
            *ngFor="let feed of feeds"
            [feed]="feed" [user]="user"
            [org]="org"
            [allowEdit]="editable && !superAdminEdit"
            (remove)="removeFeed($event)"
          ></app-feed>
        </div>
      </main>
      <!-- ... end Right side components -->


      </div>
  </div>

  <app-load-page-button *ngIf="hasMorePages" (loadMore)="loadMore()"></app-load-page-button>
</ng-container>

<ng-container *ngIf="_user?.profile?.hidden && loggedUser?._id !== _user?._id">
  <div class="col col-12 d-flex gap-1 justify-content-center">
    <i class="h3 d-flex m-0 align-self-center fa fa fa-eye-slash"></i>
    <span class="h3 d-flex m-0">{{ 'profile.profile_hidden' | translate }}</span>
  </div>
</ng-container>

<app-back-to-top></app-back-to-top>
