<mat-table
  #table
  [dataSource]="data"
  matSort
  matSortDisableClear
  (matSortChange)="onSortChange($event)"
  [matSortActive]="page.sortKey"
  [matSortDirection]="page.sortDirection"
>
  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef>{{ 'table.position' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index"><span>{{ (i + 1) * (page.index + 1) }}.</span></mat-cell>
  </ng-container>

  <ng-container matColumnDef="user">
    <mat-header-cell *matHeaderCellDef>{{ 'person' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let data">
      <a [href]="data.userUrl" class="d-flex align-items-center gap-05">
        <div *ngIf="data.avatarUrl" class="avatar">
          <img [src]="data.avatarUrl" alt="user-icon" class="avatar-icon">
        </div>
        {{ data.userName }}
      </a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="weeklyScore">
    <mat-header-cell *matHeaderCellDef [mat-sort-header]="'weeklyScore'">{{ 'profile.referrals_this_week' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.weeklyScore }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="totalScore">
    <mat-header-cell *matHeaderCellDef [mat-sort-header]="'totalScore'">{{ 'profile.referrals_total' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.totalScore }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['position', 'user', 'weeklyScore', 'totalScore']"></mat-header-row>
  <mat-header-row *matRowDef="let row; columns: ['position', 'user', 'weeklyScore', 'totalScore']"
                  [ngClass]="{'current-user-highlight': shouldHighlight(row)}">
  </mat-header-row>
</mat-table>
<mat-paginator
  [pageIndex]="page.index"
  [pageSize]="page.limit"
  [length]="page.count"
  [showFirstLastButtons]="true"
  [pageSizeOptions]="[10]"
  (page)="onPageChange($event)"
></mat-paginator>

<div class="loading-mask" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
