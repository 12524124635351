import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { EventService } from "../../services/event.service";
import { Subscription } from "rxjs";
import { EventType } from "./base";

export class AppPaginatorIntl extends MatPaginatorIntl {

  private eventSub?: Subscription;

  constructor(
    private translateService: TranslateService,
    private eventService: EventService
  ) {
    super();
    this.translateLabels();
    this.eventSub = this.eventService.getObservable().subscribe(event => {
      if (event.name === EventType.command && event.data.command === 'changeLang') {
        this.translateLabels();
      }
    })
  }

  private translateLabels(): void {
    this.itemsPerPageLabel = this.translateService.instant('paginator.items_per_page');
    this.nextPageLabel = this.translateService.instant('paginator.next_page_label');
    this.previousPageLabel = this.translateService.instant('paginator.previous_page_label');
    this.firstPageLabel = this.translateService.instant('paginator.first_page_label');
    this.lastPageLabel = this.translateService.instant('paginator.last_page_label');
    this.getRangeLabel = (page, pageSize, length): string => {
      return `${ page } - ${ pageSize } ${ this.translateService.instant('paginator.of') } ${ length }`;
    }
  }
}
