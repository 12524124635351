import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '../../shared/types/options';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent {
  @Input() set allTags(tags: Array<Tag>) {
    this._allTags = tags;
    this._allTags.forEach(tag => this.tags[tag.id] = this.tags[tag.id] ?? false);
  }
  @Input() labelKey: 'name' | 'filterName' = 'name';
  @Input() tags: Record<string, boolean> = {};
  @Output() onTagsChanged = new EventEmitter<Record<string, any>>();

  _allTags: Array<Tag> = [];

  constructor() { }

  onTagClick(selectedTag: Tag): void {
    if (!selectedTag) return;
    this.tags[selectedTag.id] = !this.tags[selectedTag.id];
    const emitObject = {};
    Object.keys(this.tags).forEach((key, index) => this.tags[key] && (emitObject[index] = key))
    this.onTagsChanged.emit(emitObject);
  }

  isSelected(tag: Tag): boolean {
    return this.tags[tag.id];
  }
}
