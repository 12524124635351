<div class="container">
    <div class="row">

        <div class="col-12" style="padding: 0px;">
            <div class="ui-block">
                <div class="ui-block-content position-relative" #mapWrapper [appStretchToBottom]="mapWrapper" style="padding: 4px; height: 450px">
                    <div class="map-controls">
                      <div class="btn-group">
                        <button class="btn" [ngClass]="usersVisible ? 'btn-primary' : 'btn-secondary'" (click)="toggleUsers()">
                          <i class="fa fa-user"></i>
                        </button>
                        <button class="btn" [ngClass]="orgsVisible ? 'btn-primary' : 'btn-secondary'" (click)="toggleOrganizations()">
                          <i class="fa fa-users"></i>
                        </button>
                      </div>
                    </div>

                    <agm-map
                        #agmMap
                        style="width: 100%; height: 100%;"
                        [latitude]="mapCenter?.latitude"
                        [longitude]="mapCenter?.longitude"
                        [fitBounds]="mapBounds"
                        [fullscreenControl]="true"
                        (boundsChange)="mapBounds$.next($event)"
                        (zoomChange)="onZoomChange($event)"
                    >

                        <ng-container *ngFor="let user of users">
                          <ng-container *ngIf="user.profile?.location?.latitude && user.profile?.location?.longitude">
                            <agm-marker
                                [latitude]="user.profile.location.latitude"
                                [longitude]="user.profile.location.longitude"
                                [iconUrl]="user.mapIcon"
                                [visible]="usersVisible"
                            >
                              <agm-info-window [disableAutoPan]="true">
                                <div style="text-align: center;">
                                  {{user.profile.firstname}} {{user.profile.lastname}}
                                  <br /><a [routerLink]="['/profile', user?._id, 'view-profile']" translate>map.view_profile</a>
                                </div>
                              </agm-info-window>
                            </agm-marker>
                          </ng-container>
                        </ng-container>

                        <agm-marker *ngFor="let org of orgs"
                                    [latitude]="org.profile?.location.latitude"
                                    [longitude]="org.profile?.location.longitude"
                                    [iconUrl]="org.mapIcon"
                                    [visible]="orgsVisible"
                        >
                            <agm-info-window [disableAutoPan]="true">
                                <div style="text-align: center;">
                                    {{org.profile.name}}
                                    <br /><a [routerLink]="['/org-profile/' + org._id]" translate>map.view_profile</a>
                                </div>
                            </agm-info-window>
                        </agm-marker>

                        <agm-circle
                            [visible]="circleVisible()"
                            [radius]="getRadius()"
                            [latitude]="getRadiusLat()"
                            [longitude]="getRadiusLng()"
                            [circleDraggable]="false"
                            [fillColor]="'#d5b2ba'"
                            [strokeColor]="'#db1d45'"
                            [strokeWeight]="2"
                        ></agm-circle>
                    </agm-map>
                </div>
            </div>
        </div>
    </div>
</div>
