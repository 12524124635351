import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/base/base.component';
import { EventService } from 'src/app/services/event.service';
import { AlertType, BaseEvent, EventType } from '../../shared/types/base';

export interface Alert {
    type: AlertType;
    message: string;
    dismissible?: boolean;
    class?: string;
    header?: string;
    delay?: number;
}

const MAX_GLOBAL_ALERTS = 5;

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent extends BaseComponent implements OnInit {

    @Input() alert: Alert = null;
    @Input() listenEvents = false;

    globalAlerts: any[] = [];

    constructor(
        eventService: EventService,
        private translateService: TranslateService,
    ) {
        super(eventService);
    }

    ngOnInit(): void {
    }

  eventReceived(event: BaseEvent): void {
        if (this.listenEvents && event.name === EventType.alert) {

            const alert: Alert = {
                type: event.data.type,
                message: this.translateService.instant(event.data.message),
                delay: 20000,
                class: '',
                header: '',
            };

            switch (alert.type) {
              case 'info': {
                alert.class = '';
                alert.header = this.translateService.instant('alert.header_info');
              }            break;
              case 'success': {
                alert.class = 'bg-success text-light';
                alert.header = this.translateService.instant('alert.header_success');
              }               break;
              case 'warning': {
                alert.class = 'bg-warning text-light';
                alert.header = this.translateService.instant('alert.header_warning');
              }               break;
              case 'error': {
                alert.class = 'bg-danger text-light';
                alert.header = this.translateService.instant('alert.header_error');
              }             break;
            }

            this.globalAlerts.push(alert);

            if (this.globalAlerts.length > MAX_GLOBAL_ALERTS) {
                this.globalAlerts = this.globalAlerts.slice(this.globalAlerts.length - MAX_GLOBAL_ALERTS);
            }
        }
    }

    clearAlert(index = -1): void {

        if (index > -1) {
            this.globalAlerts.splice(index, 1);
        }
        else {
            this.alert = null;
        }
    }
}
