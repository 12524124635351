import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '../shared/types/profile';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  transform(user: UserProfile, nameFormat: 'firstName' | 'lastName' | 'full' = 'full'): string {
    if (nameFormat === 'firstName') return user.firstname;
    if (nameFormat === 'lastName') return user.lastname;
    if (nameFormat === 'full') return `${user.firstname} ${user.lastname}`;
    return '';
  }
}
