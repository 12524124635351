import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';
import { OrganizationType } from '../../shared/types/profile';

const ORGS_CHUNK_LEN = 25;

@Component({
    selector: 'app-home-orgs',
    templateUrl: './home-orgs.component.html',
    styleUrls: ['./home-orgs.component.css']
})
export class HomeOrgsComponent extends BaseComponent implements OnInit {

    currentPage = 0;
    hasMorePages: boolean = false;
    orgs = [];

    mapCenter = {
        latitude: 48.1486,
        longitude: 17.1077
    }

    mapFilter = {
        center: this.mapCenter,
        radius: 200000
    }
    newMapBound: boolean = false;

    working: boolean = false;

    @Input() type: Array<OrganizationType | keyof typeof OrganizationType> = [];
    @Input() showMap: boolean = false;
    @Input() superAdmin: boolean = false;

    _filter = { countries: [], sports: [], tags: [], superAdminFilter: null };
    @Input() set filter(value) {

        let reloadData =
            this._filter.countries != value.countries
            || this._filter.sports != value.sports
            || this._filter.tags != value.tags
            || this._filter.superAdminFilter != value.superAdminFilter;

        this._filter = value;
        if (reloadData && this.initialized)
            this.getOrgs();
    }
    get filter() { return this._filter; }

    constructor(
        eventService: EventService,
        private apiService: ApiService) {
        super(eventService);
    }

    initialized: boolean = false;
    ngOnInit(): void {
        this.getOrgs();
        this.initialized = true;
    }

    getOrgs(fromStart = true) {

        if (fromStart) {
            this.currentPage = 0;
        }

        let params = {
            start: this.currentPage * ORGS_CHUNK_LEN,
            len: ORGS_CHUNK_LEN,
            users: false,
            feeds: false
        };

        if (this.superAdmin) {
            params["sa_all"] = true;
            if (this.filter.superAdminFilter) {
                if (this.filter.superAdminFilter["name"]) {
                    params["search"] = this.filter.superAdminFilter["name"];
                }
                let active = this.filter.superAdminFilter["active"];
                if (active) {
                    params["active"] = active;
                }
            }
        }
        else {
            params["active"] = true;
        }

        if (this.type && this.type.length > 0) {
            params["orgType"] = this.type.join(',');
        }
        if (this.filter?.countries) {
            params["country"] = this.filter.countries.join(",");
        }
        if (this.filter?.sports) {
            params["sport"] = this.filter.sports.join(",");
        }
        if (this.filter?.tags && this.filter.tags.length > 0) {
            params["tags"] = this.filter.tags.join(",");
        }

        if (this.showMap && this.mapFilter) {
            params["latLng"] = `${this.mapFilter.center.latitude},${this.mapFilter.center.longitude}`;
            params["radius"] = Math.trunc(this.mapFilter.radius);
        }

        this.newMapBound = false;
        this.working = true;
        this.apiService.search(params).subscribe(
            (result) => {
                this.working = false;

                let orgs = result.body["organizations"] ?? [];
                this.hasMorePages = (orgs.length == ORGS_CHUNK_LEN);

                if (this.showMap) {
                    orgs.forEach(o => {
                        o["mapIcon"] = this.getAvatarMapIcon(o);
                    });
                }

                if (fromStart) {
                    this.orgs = orgs;
                } else {
                    this.orgs = this.orgs.concat(orgs);
                }
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    loadMore() {
        this.currentPage++;
        this.getOrgs(false);
    }

    activateProfile(org, activate: boolean = true) {
        if (!this.apiService.getCurrentUser()?.superAdmin) {
            return;
        }

        let data = { "active": activate };
        this.apiService.activateOrg(org._id, data).subscribe(
            (result) => {
                org.active = activate;
            },
            (error) => this.handleApiError(error) );
    }

    getAvatarMapIcon(org) {
        let url = ApiService.getPhotoUrl(org?.avatar?.avatar?.small, Utils.getLogoSmall());

        return {
            url: url,
            scaledSize: { width: 28, height: 28 }
        };
    }

    mapBoundsChanged(bounds: google.maps.LatLngBounds) {
        this.mapFilter.center = { longitude: bounds.getCenter().lng(), latitude: bounds.getCenter().lat() };
        this.mapFilter.radius = google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), bounds.getSouthWest());
        
        this.newMapBound = true;
    }
}
