import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  constructor(private apiService: ApiService, private router: Router) {
  }
  canActivate(): boolean | UrlTree {
    const user = this.apiService.getCurrentUser();
    return Boolean(user?.superAdmin) ? true : this.router.createUrlTree(['home']);
  }
}
