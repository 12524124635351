import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, tap} from 'rxjs/operators';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';

@Component({
    selector: 'app-quick-search-result',
    templateUrl: './quick-search-result.component.html',
    styleUrls: ['./quick-search-result.component.css']
})
export class QuickSearchResultComponent extends BaseComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  hasOrgs: boolean = false;
  working: boolean = false;
  searchTerm: string = "";
  feedId: string = "";
  onlyFeeds: boolean = false;
  users: any[] = [];
  orgs: any[] = [];
  feeds: any[] = [];
  searchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    eventService: EventService,
    private apiService: ApiService,
    protected route: ActivatedRoute
  ) {
    super(eventService);
  }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params=>{
          this.searchTerm = params['term'];
          this.feedId = params['feedId'];
          this.onlyFeeds = params["onlyFeeds"] === 'true';
          this.search();
        });

        this.route.params.subscribe(params => {
          this.searchTerm = params['searchTerm'];
          this.search();
        });

        this.hasOrgs = this.apiService.getCurrentUser()?.adminOrgs?.some(o => o.active);

        this.searchForm = this.formBuilder.group({
          searchInput: null
        });

        this.searchForm.get('searchInput')
          .valueChanges.pipe(debounceTime(300),
          tap(() => this.mobileSearch()),
        ).subscribe();
    }

    searchFromMobile($event: any): void {
        this.searchTerm = $event?.term ?? "";
        this.onlyFeeds = ($event?.onlyFeeds === true);
        this.search();
    }

    search() {
        if (!this.searchTerm && !this.feedId) {
            this.users = [];
            this.orgs = [];
            this.feeds = [];
            return;
        }

        const query = this.feedId
          ? { "feedId": this.feedId }
          : { "search": this.searchTerm };

        if (this.onlyFeeds) {
            query['users'] = false;
            query['orgs'] = false;
        }

        this.working = true;
        this.apiService.search(query).subscribe(
            (result) => {
                this.working = false;
                this.users = result.body["users"];
                this.orgs = result.body["organizations"];
                this.feeds = result.body["feeds"];
            },
            (error) => { this.handleApiError(error); this.working = false; } );
    }

    mobileSearch() {
      this.autocomplete?.closePanel();
      this.searchFromMobile({ "term": this.searchForm.get('searchInput').value });
    }
}
