<div class="" >
    <div class="modal-content ">
        <a [routerLink]="" (click)="activeModal.dismiss('cancel')" class="close icon-close"  aria-label="Close">
            <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
        </a>

        <div class="modal-header">
            <h6 class="title" translate>profile.set_default_user_org</h6>
        </div>

        <div class="modal-body">
            <mat-radio-group class="radio-group" [(ngModel)]="defOrgId">
                <mat-radio-button *ngFor="let org of orgs" [value]="org._id">
                    <div class="radio-content">
                        <img src="{{getAvatar(org)}}" />
                        <div class="radio-content-label">{{org.profile?.name}}</div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
            <div class="btn-save-container">
                <button type="button" class="btn btn-primary" (click)="setDefOrg()" translate>save</button>
            </div>
        </div>
	</div>
</div>