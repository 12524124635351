import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { EventType } from '../../shared/types/base';
import { User } from "../../shared/types/profile";
import { Member } from "../../shared/types/member";
import { first } from "rxjs/operators";

const MEMBERS_CHUNK_LEN = 25;

@Component({
    selector: 'app-org-profile-members',
    templateUrl: './org-profile-members.component.html',
    styleUrls: ['./org-profile-members.component.css']
})
export class OrgProfileMembersComponent extends BaseComponent implements OnInit {

    _org: any = null;
    get org(): any {
        return this._org;
    }
    @Input() set org(value: any) {
        this._org = value;

        let user = this.apiService.getCurrentUser();
        this.allowEdit = this.apiService.isCurrentUserAdmin(this._org?._id);
        this.userLoggedIn = Boolean(user);
        this.getMembers(true);
    }

    currentPage = 0;
    hasMorePages: boolean = false;
    working: boolean = false;
    allowEdit: boolean = false;
    userLoggedIn: boolean = false;
    members: Member[] = [];
    memberRequests: Member[] = [];
    userAlreadyMember: boolean = false;
    currentUser?: User;
    showMemberRequestSentAlert = false;

    constructor(
        eventService: EventService,
        private translateService: TranslateService,
        private apiService: ApiService) {
            super(eventService);
        }

    ngOnInit(): void {
        this.currentUser = this.apiService.getCurrentUser();
        this.getMembers(true);
    }

    loadMore() {
        this.currentPage++;
        this.getMembers();
    }

    getMembers(fromStart: boolean = false) {
        if (!this.org)
            return;

        if (fromStart)
            this.currentPage = 0;

        let params = {
            start: this.currentPage * MEMBERS_CHUNK_LEN,
            len: MEMBERS_CHUNK_LEN
        };

        this.working = true;
        this.apiService.getOrgMembers(this.org._id, params)
          .pipe(first())
          .subscribe(
            (result) => {
                this.working = false;
                let responseMembers: Member[] = result?.body["members"] ?? [];
                const members: Member[] = [];
                const memberRequest: Member[] = [];
                let owner: Member;

                this.hasMorePages = (members.length == MEMBERS_CHUNK_LEN);

                let currentUser = this.apiService.getCurrentUser();
                if (currentUser){
                    let me = this._org?.members.find(m=> m.userId == currentUser._id);
                    if (me) {
                        this.showMemberRequestSentAlert = me.acceptedByUser && !me.acceptedByOrg;
                        me.show = true;
                        this.userAlreadyMember = true;
                    }
                }

                responseMembers?.forEach(m => {
                    m.role = m.user?.adminOrgs?.find(o => o.id == this._org?._id)?.role;
                    if(!this.isOwner(m)) {
                      if ((!m.acceptedByOrg && m.acceptedByUser) || (m.acceptedByOrg && !m.acceptedByUser)) memberRequest.push(m);
                      if (m.acceptedByOrg && m.acceptedByUser) members.push(m);
                    } else {
                      owner = m;
                    }
                });

                if (fromStart) {
                    this.members = owner ? [owner, ...members] : members;
                    this.memberRequests = memberRequest;
                }
                else {
                    this.members = this.members.concat(members);
                    this.memberRequests = this.members.concat(memberRequest);
                }

              this.members.sort((a, b) => {
                const aLastname = a.user.profile.lastname.toLowerCase() ?? '';
                const bLastname = b.user.profile.lastname.toLowerCase() ?? '';

                return aLastname.localeCompare(bLastname);
              })

              this.memberRequests.sort((a, b) => {
                const aLastname = a.user.profile.lastname.toLowerCase() ?? '';
                const bLastname = b.user.profile.lastname.toLowerCase() ?? '';

                return aLastname.localeCompare(bLastname);
              })
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    requestMembership() {
        if (!this.org)
            return;

        this.working = true;
        this.apiService.requestOrgMembership(this.org._id)
          .pipe(first())
          .subscribe(
            (result) => {
                this.sendEvent({name: EventType.update, data: {update: 'profile'}});
                this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'profile.membership_requested_success', dismissible: true } });
                this.working = false;
                this.getMembers(true);
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    acceptMembership(userId) {
        if (!this.org)
            return;

        this.working = true;
        this.apiService.acceptOrgMembership(this.org._id, userId).subscribe(
            (result) => {
                this.working = false;
                this.getMembers(true);
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    changeMemberPosition(member) {
        if (!this.org || !member)
            return;

        let data = { position: member.position };

        this.working = true;
        this.apiService.changeOrgMemberPosition(this.org._id, member.userId, data).subscribe(
            (result) => {
                this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'profile.member_position_saved', dismissible: true } });
                this.working = false;
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    removeMembership(userId) {
        if (!this.org)
            return;

        this.working = true;
        this.apiService.removeOrgMembership(this.org._id, userId).subscribe(
            (result) => {
                this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'profile.membership_removed_success', dismissible: true } });
                this.sendEvent( { name: EventType.update, data: { update: 'profile' } });
                this.working = false;
                this.getMembers(true);
            },
            (error) => this.handleApiError(error) );
    }

    setAdmin(member) {
        if (!this.org || !member?.userId)
            return;

        this.working = true;
        this.apiService.addOrgAdmin(this.org._id, member.userId).subscribe(
            (result) => {
                this.working = false;
                member.role = 'admin';
            },
            (error) => this.handleApiError(error) );
    }

    unsetAdmin(member) {
        if (!this.org || !member?.userId)
            return;

        this.working = true;
        this.apiService.removeOrgAdmin(this.org._id, member.userId).subscribe(
            (result) => {
                this.working = false;
                member.role = '';
            },
            (error) => this.handleApiError(error) );
    }

    showAdminActions(member: Member): boolean {
      if (!this.userLoggedIn) return false;
      if (!member.acceptedByUser || !member.acceptedByOrg) return false;
      if (!this.isCurrentUserOwner() && !this.isCurrentUserAdmin()) return false;
      if (this.isCurrentUserOwner() && !this.isMe(member)) return true;
      if (this.isCurrentUserOwner() && this.isMe(member)) return false;
      if (this.isMe(member)) return true;
      return !(this.isAdmin(member) || this.isOwner(member));
    }

    showSetAdminAction(member: Member): boolean {
      if (this.isCurrentUserOwner() || this.isCurrentUserAdmin()) {
        return !this.isMe(member) && !this.isAdmin(member) && !this.isOwner(member);
      }
      return false;
    }

    showUnsetAdminAction(member: Member): boolean {
      return this.isCurrentUserOwner() && this.isAdmin(member);
    }

    showRemoveUserAction(member: Member): boolean {
      if (this.isMe(member)) return false;
      return (!this.isAdmin(member) || this.isCurrentUserOwner()) && !this.isOwner(member);
    }

    showLeaveOrgAction(member: Member): boolean {
      return this.isMe(member) && !this.isCurrentUserOwner();
    }

    showRequestActions(member: Member): boolean {
      return this.userLoggedIn && (this.isCurrentUserOwner() || this.isCurrentUserAdmin()) && !member.acceptedByOrg;
    }

    showChangePosition(member: Member): boolean {
      if (!this.userLoggedIn) {
        return false;
      }

      if (this.isCurrentUserOwner()) {
        return !this.isMe(member);
      }

      if (this.isCurrentUserAdmin()) {
        return !(this.isOwner(member) || this.isAdmin(member) || this.isMe(member));
      } else {
        return false;
      }
    }

    private isMe(member: Member): boolean {
      return member.userId === this.currentUser?._id;
    }

    private isOwner(member: Member): boolean {
      return member.role === 'owner';
    }

    private isAdmin(member: Member): boolean {
      return member.role === 'admin';
    }

    private isCurrentUserOwner(): boolean {
      return this.currentUser?._id === this._org.owner ?? false;
    }

    private isCurrentUserAdmin(): boolean {
      return this.currentUser?.adminOrgs.some(admin => admin._id === this._org._id) ?? false;
    }
}
