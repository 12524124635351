<div class="container" *ngIf="org" >

	<div class="row">

    <div class="menu-settings-mobile col col-lg-9 order-lg-2 col-md-12 order-md-1 col-12">
      <app-tab-menu [items]="orgTabItems" (onChange)="onTabChange($event)"></app-tab-menu>
    </div>

		<div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
        <app-org-profile-edit *ngIf="setting == 'edit-profile'" [org]="org" ></app-org-profile-edit>
        <app-profile-edit-career *ngIf="setting == 'edit-profile-career'" [org]="org" ></app-profile-edit-career>
      </div>
      <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
    <div class="ui-block">

				<!-- Your Profile  -->
				
				<div class="your-profile">
					<div class="ui-block-title ui-block-title-small ui-block-header">
						<h6 class="title" translate>profile.settings</h6>
					</div>
				
					<div class="ui-block-title setting-link">
						<a [routerLink]="" (click)="setting = 'edit-profile'" class="h6 title" translate>profile.org_base_profile</a>
                    </div>
                    <div class="ui-block-title setting-link">
						<a [routerLink]="" (click)="setting = 'edit-profile-career'" class="h6 title" translate>profile.career</a>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<app-back-to-top></app-back-to-top>
