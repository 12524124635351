import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { UiService } from 'src/app/services/ui.service';
import { Utils } from 'src/app/utils';

@Component({
    selector: 'app-header-messages',
    templateUrl: './header-messages.component.html',
    styleUrls: ['./header-messages.component.css']
})
export class HeaderMessagesComponent implements OnInit {

    _messages = [];
    @Input() set messages(value) {

        value?.forEach(v => {
            this.setMsgProps(v);
        } );

        this._messages = value.filter(v => v.valid);
    }

    showMessages: boolean = false;

    get messages() {
        return this._messages;
    }

    constructor(
        private apiService: ApiService,
        private uiService: UiService,
        private translateService: TranslateService,
        private datePipe: DatePipe,
        private router: Router) { }

    ngOnInit(): void {
    }

    setMsgProps(msg) {
        let me = this.apiService.getCurrentUser();
        if (!me || !msg || !msg.chat || msg.chat.length == 0) {
            return;
        }

        if ( me.adminOrgs && msg.participantUserObjects?.some(p => p._id == me._id)
            && msg.participantOrgObjects.some(p=> me.adminOrgs.some(o => o._id == p._id)) ) {
                return; // message sent from/to me from/to my org
            }

        msg["display"] = {};

        let senderUser = msg.participantUserObjects?.find(p => p._id != me._id);

        if (senderUser) { // message from user
            msg["display"]["name"] = `${senderUser.profile.firstname} ${senderUser.profile.lastname}`;
            msg["display"]["avatar"] = ApiService.getPhotoUrl(senderUser.avatar?.avatar?.small, Utils.getAvatarSmall());
            msg["profileUrl"] = `/profile/${senderUser._id}`;
        }
        else { // message from org

            let senderOrg = null;
            if (me.adminOrgs) {
                senderOrg = msg.participantOrgObjects.find(p=> !me.adminOrgs.some(o => o._id == p._id))
            }
            else if (msg.participantOrgObjects.length > 0) {
                senderOrg = msg.participantOrgObjects[0];
            }

            if (!senderOrg) {
                return;
            }

            msg["display"]["name"] = senderOrg.profile?.name;
            msg["display"]["avatar"] = ApiService.getPhotoUrl(senderOrg.avatar?.avatar?.small, Utils.getLogo());
            msg["profileUrl"] = `/org-profile/${senderOrg._id}`;
        }

        let receiverUser = msg.participantUserObjects?.find(p => p._id == me._id);
        if (receiverUser) { // message to me
            msg["url"] = `/messages/${msg._id}`;
        }
        else { // message to one of my org
            if (msg.participantOrgObjects.length != 1) {
                return;
            }

            msg["url"] = `/org-messages/${msg.participantOrgObjects[0]._id}/${msg._id}`;
        }

        msg["display"]["message"] = msg.chat[msg.chat.length - 1].message;
        msg["display"]["date"] = Utils.getHumanDate(msg?.updated, this.datePipe, this.translateService);
        msg["valid"] = true;
    }

    openMessages(open: boolean = true, wait = 0) {

        if (wait > 0) {
            setTimeout(() => {
                if (this.showMessages != open)
                    this.showMessages = open;
            }, wait);
        }
        else {
            if (this.showMessages != open)
                this.showMessages = open;
        }
    }
}
