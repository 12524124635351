import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { Utils } from 'src/app/utils';

@Component({
    selector: 'app-select-def-org',
    templateUrl: './select-def-org.component.html',
    styleUrls: ['./select-def-org.component.css']
})
export class SelectDefOrgComponent implements OnInit {

    @Input() orgs: any[] = [];
    @Input() defOrgId: string = "";
    @Output() defOrgSelected = new EventEmitter();

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    setDefOrg() {
        this.defOrgSelected.emit({ "orgId": this.defOrgId });
        this.activeModal.dismiss();
    }

    getAvatar(org) {
        return ApiService.getPhotoUrl(org?.avatar?.avatar?.small, Utils.getLogoMedium());
    }
}
