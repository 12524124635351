<div class="ui-block">
  <div class="ui-block-content">
    <h6>{{ 'profile.id_code' | translate }}</h6>
    <span class="h2">{{ user.referenceId }}</span>
  </div>
</div>
<div class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>profile.personal_info</h6>
    </div>
    <div class="ui-block-content">

        <form *ngIf="data">
            <fieldset [disabled]="working">
                <div class="row">
                  <div class="col col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" >
                      <mat-label translate>profile.first_name</mat-label>
                      <input matInput type="text" name="firstname" [(ngModel)]="data.firstname" minlength="2" required>
                    </mat-form-field>
                  </div>

                  <div class="col col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" >
                      <mat-label translate>profile.last_name</mat-label>
                      <input matInput type="text" name="lastname" [(ngModel)]="data.lastname" minlength="2" required>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-lg-6 col-md-6 col-sm-12 order-sm-1">
                    <mat-form-field appearance="outline">
                      <mat-label translate>profile.gender</mat-label>
                      <mat-select name="gender" [(ngModel)]="data.gender">
                        <mat-option value="male" translate>{{ 'profile.male' | translate }}</mat-option>
                        <mat-option value="female" translate>{{ 'profile.female' | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-6 col-md-6 col-sm-12 order-sm-0">
                    <mat-label>{{ 'profile.birthday' | translate }}</mat-label>
                    <app-date-picker-simple [date]="data.birthdate" (onChange)="onBirthdayChange($event)"></app-date-picker-simple>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label translate>profile.occupation</mat-label>
                      <mat-select name="occupation" [(ngModel)]="data.occupation">
                        <mat-option *ngFor="let o of occupations" [value]="o.id" [ngStyle]="{'display': o.visible ? 'block' : 'none'}">{{ o.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label translate>profile.height</mat-label>
                      <input matInput type="number" name="height" [(ngModel)]="data.height" >
                    </mat-form-field>
                  </div>
                </div>

              <div class="row">
                <div class="col col-lg-6 col-md-6 offset-md-6 col-sm-12">
                  <mat-form-field appearance="outline" >
                    <mat-label translate>profile.weight</mat-label>
                    <input matInput type="number" name="weight" [(ngModel)]="data.weight" >
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col col-lg-6 col-md-6 col-sm-12">
                  <mat-form-field appearance="outline" >
                    <mat-label translate>profile.work</mat-label>
                    <input matInput type="text" name="work" [(ngModel)]="data.work" minlength="2">
                  </mat-form-field>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12">
                  <mat-form-field appearance="outline" >
                    <mat-label translate>profile.place</mat-label>
                    <input matInput #place type="text" name="address" value="{{data.location?.formatted_address}}">
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col col-12">
                  <mat-form-field appearance="outline">
                    <mat-label translate>profile.info</mat-label>
                    <textarea matInput name="info" [(ngModel)]="data.info"></textarea>
                  </mat-form-field>
                </div>
              </div>

                <div class="row">

                    <div class="col col-12 row-divider" ></div>

                    <div class="col col-md-12">
                      <mat-label>{{ 'profile.tags_search_filter' | translate }}:</mat-label>
                      <app-tags [allTags]="profileTags" [tags]="data.tags"></app-tags>
                    </div>

                    <div class="col col-12 row-divider" ></div>
                    <div class="col col-md-12">
                      <app-tags [allTags]="searchTags" [tags]="data.tags"></app-tags>
                    </div>


<!--                    <div *ngFor="let tag of tags" class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" >-->

<!--                        <mat-checkbox [(ngModel)]="data.tags[tag.id]" name="{{tag.id}}" >-->
<!--                            {{tag.name}}-->
<!--                        </mat-checkbox>-->

<!--                    </div>-->

                    <div class="col col-12 row-divider" ></div>

                    <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <mat-form-field appearance="outline">
                          <mat-label translate>profile.main_sport</mat-label>
                          <mat-select #mainSportSelect name="mainSport"
                                      [(ngModel)]="data.defSport"
                                      [placeholder]="'profile.select_main_sport' | translate"
                                      (selectionChange)="onMainSportSelect($event.value)">
                            <mat-select-filter [matSelect]="mainSportSelect"
                                               [items]="allSports"
                                               [filterKey]="'display'"
                                               [placeholder]="'profile.search_sport' | translate"
                                               (onFiltered)="filteredSports = $event"
                            ></mat-select-filter>
                            <mat-option *ngFor="let o of filteredSports" [value]="o.value">{{ o.display }}</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <tag-input [(ngModel)]="data.formatted_sports" name="sports"
                                   [onlyFromAutocomplete]="true" [onTextChangeDebounce]="0"
                                   [animationDuration]="{enter: '500ms', leave: '250ms'}"
                                   secondaryPlaceholder="{{ 'profile.sports' | translate }}"
                                   placeholder="{{ 'profile.add_sport' | translate }}"
                        >
                            <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="allSports" [displayBy]="'display'"></tag-input-dropdown>
                        </tag-input>

                        <mat-label>{{ 'profile.languages_label' | translate }}</mat-label>
                        <tag-input [(ngModel)]="data.formatted_langs"
                                   name="langs" [animationDuration]="{enter: '500ms', leave: '250ms'}"
                                   [onlyFromAutocomplete]="true" [onTextChangeDebounce]="0"
                                   secondaryPlaceholder="{{ 'profile.languages' | translate }}"
                                   placeholder="{{ 'profile.add_lang' | translate }}"
                        >
                            <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="allLangs"></tag-input-dropdown>
                        </tag-input>

                    </div>

                    <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <mat-label>{{ 'profile.contact' | translate }}</mat-label>

                        <div class="d-flex align-items-center">
                          <mat-form-field appearance="outline" class="mr-2">
                            <mat-label translate>profile.email</mat-label>
                            <input matInput type="text" name="email" [(ngModel)]="user.email" disabled>
                          </mat-form-field>
                          <div class="d-flex">
                            <mat-checkbox class="mr-2 align-items-center" labelPosition="after" name="emailPublic" [(ngModel)]="data.emailPublic">
                              {{ 'makePublic' | translate }}
                            </mat-checkbox>
                          </div>
                        </div>


                        <div class="d-flex align-items-center">
                          <input-phone #phoneNumberInput class="mb-1 mr-2" style="display: inline-block; width: 100%" [(input)]="data.phoneNumber"></input-phone>
                          <div class="d-flex">
                            <mat-checkbox class="mr-2 align-items-center" labelPosition="after" name="phoneNumberPublic" [(ngModel)]="data.phoneNumberPublic">
                              {{ 'makePublic' | translate }}
                            </mat-checkbox>
                          </div>
                        </div>

                        <mat-form-field appearance="outline">
                            <mat-label translate>profile.web</mat-label>
                            <span matPrefix><i class="far fa-globe fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;</span>
                            <input matInput type="text" name="web" [(ngModel)]="data.web">
                        </mat-form-field>

                        <mat-form-field appearance="outline" >
                            <mat-label translate>profile.transparent_account</mat-label>
                            <span matPrefix><i class="fa fa-donate fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;</span>
                            <input matInput type="text" name="donationsUrl" [(ngModel)]="data.donationsUrl">
                        </mat-form-field>

                        <mat-form-field appearance="outline" >
                            <mat-label translate>profile.facebook_account</mat-label>
                            <span matPrefix><i class="fab fa-facebook-f c-facebook fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;</span>
                            <input matInput type="text" name="facebook" [(ngModel)]="data.facebook"
                                placeholder="{{ 'profile.facebook_account_hint' | translate }}" [pattern]="fbUrlPattern">
                        </mat-form-field>

                        <mat-form-field appearance="outline" >
                            <mat-label translate>profile.instagram_account</mat-label>
                            <span matPrefix><i class="fab fa-instagram c-instagram fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;</span>
                            <input matInput type="text" name="instagram" [(ngModel)]="data.instagram"
                                placeholder="{{ 'profile.instagram_account_hint' | translate }}" [pattern]="instagramRegex">
                        </mat-form-field>

                        <mat-form-field appearance="outline" >
                            <mat-label translate>profile.twitter_account</mat-label>
                            <span matPrefix><i class="fab fa-twitter c-twitter fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;</span>
                            <input matInput type="text" name="twitter" [(ngModel)]="data.twitter"
                                placeholder="{{ 'profile.twitter_account_hint' | translate }}" [pattern]="twitterUrlPattern">
                        </mat-form-field>

                        <mat-form-field appearance="outline" >
                          <mat-label translate>profile.youtube_account</mat-label>
                          <span matPrefix><i class="fab fa-youtube c-youtube fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;</span>
                          <input matInput type="text" name="youtube" [(ngModel)]="data.youtube"
                                 placeholder="{{ 'profile.youtube_account_hint' | translate }}" [pattern]="youtubeRegex">
                        </mat-form-field>

                        <mat-form-field appearance="outline" >
                          <mat-label translate>profile.linkedin_account</mat-label>
                          <span matPrefix><i class="fab fa-linkedin c-linkedin fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;</span>
                          <input matInput type="text" name="linkedin" [(ngModel)]="data.linkedin"
                                 placeholder="{{ 'profile.linkedin_account_hint' | translate }}" [pattern]="linkedInRegex">
                        </mat-form-field>

                        <mat-form-field appearance="outline" >
                          <mat-label translate>profile.tiktok_account</mat-label>
                          <span matPrefix class="icon-prefix">
                                <i class="c-tiktok fa-lg" aria-hidden="true">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
                                  </svg>
                                </i>
                                &nbsp;&nbsp;
                              </span>
                          <input matInput type="text" name="tiktok" [(ngModel)]="data.tiktok"
                                 placeholder="{{ 'profile.tiktok_account_hint' | translate }}" [pattern]="tiktokRegex">
                        </mat-form-field>

                        <div style="margin-top: 2px; margin-bottom: 12px;">
                            <app-insta-follow></app-insta-follow>
                        </div>

                    </div>

                    <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                        <button class="btn btn-secondary btn-lg full-width"  (click)="restoreProfile()" >
                            <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ 'profile.btn_restore_values' | translate }}
                        </button>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                        <button class="btn btn-primary btn-lg full-width"  (click)="saveProfile()" >
                            <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ 'btn_save_all' | translate }}
                        </button>
                    </div>

                </div>
            </fieldset>
        </form>

    </div>
</div>
