import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Utils } from '../utils';
import { environment } from "../../environments/environment";

@Pipe({
    name: 'avatarUrl'
})
export class AvatarUrlPipe implements PipeTransform {
  constructor(private apiService: ApiService) {
  }

    transform(value: any, type: 'small' | 'medium' | 'path' = 'small'): string {

        let url = '';

        if (type === 'path') {
          return environment.API_URL + value;
        }

        if (type == 'medium') {
            url = ApiService.getPhotoUrl(value?.avatar?.avatar?.medium, Utils.getLogoMedium());
        }
        else {
            url = ApiService.getPhotoUrl(value?.avatar?.avatar?.small, Utils.getLogoSmall());
        }

        return url
    }
}
