<div class="" >
    <a [routerLink]="" (click)="activeModal.dismiss()" class="close icon-close" aria-label="Close">
        <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
    </a>

    <div class="modal-header">
        <h6 class="title" translate>restore_password.header</h6>
    </div>

    <div class="modal-body">
            <p translate>restore_password.body</p>

            <mat-form-field appearance="outline" >
                <mat-label translate>restore_password.email</mat-label>
                <input matInput type="email" [(ngModel)]="email">
            </mat-form-field>

            <app-alert [alert]="alertCode"></app-alert>
            <button
            class="btn btn-purple btn-lg full-width"
                (click)="requestPasswordChangeCode()">
                    <span *ngIf="workingCode" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{ 'restore_password.send_code' | translate }}
            </button>

            <mat-form-field appearance="outline" >
                <mat-label translate>restore_password.enter_code</mat-label>
                <input matInput type="text" [(ngModel)]="code">
            </mat-form-field>
            <mat-form-field appearance="outline" >
                <mat-label translate>restore_password.new_psw</mat-label>
                <input matInput type="password" [(ngModel)]="password">
            </mat-form-field>
            
            <app-alert [alert]="alert"></app-alert>
            <button
                class="btn btn-primary btn-lg full-width"
                (click)="requestPasswordChange()">
                    <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{ 'restore_password.btn' | translate }}
            </button>

    </div>
</div>