<ng-container *ngIf="comment">

    <div class="post__author author vcard inline-items">
        <img loading="lazy" src="{{getAvatarUrl()}}" alt="author">

        <div class="author-date">
            <a class="h6 post__author-name fn" [routerLink]="['/profile/' + comment.user?._id]">{{getName()}}</a>
            <div class="post__date">
                <time class="published" datetime="comment.added">
                    {{getDate()}}
                </time>
            </div>
        </div>
    </div>

    <p>{{comment.text}}</p>

    <ng-container *ngIf="allowChildren">
        <div style="width: 100%; text-align: right;">

            <a *ngIf="allowComment && (!comment.comments || comment.comments.length == 0)" [routerLink]="" (click)="collapseComments.toggle()" class="reply" translate>comments.reply</a>

            <a *ngIf="comment.comments && comment.comments.length > 0" [routerLink]="" (click)="collapseComments.toggle()" class="post-add-icon inline-items" style="margin: 0px;">
                <svg class="olymp-speech-balloon-icon"><use xlink:href="#olymp-speech-balloon-icon"></use></svg>
                <span class="comments-text">{{ comment.comments.length }} <span translate>comments.replies</span></span>
            </a>


        </div>

        <div class="" #collapseComments="ngbCollapse" [ngbCollapse]="!openReplies">

          <div class="row" style="margin-top: 12px; margin-left: 20px;">

            <div class="col col-12" *ngIf="comment.comments && comment.comments.length > 0" style="margin-bottom: 25px;" >
              <ul class="comments-list">

                <li *ngFor="let comment of comment.comments" class="comment-item" style="padding-left: 0;">
                  <app-comment [comment]="comment" [allowChildren]="false" ></app-comment>
                </li>

              </ul>
            </div>

          </div>


          <ng-container *ngIf="allowComment">
            <div class="col col-12" style="padding: 0">

              <mat-form-field appearance="outline">
                <mat-label translate>comments.comment_comment</mat-label>
                <textarea matInput name="newComment" [(ngModel)]="newComment" ></textarea>
              </mat-form-field>

            </div>

            <div class="col col-12" style="padding: 0" >

              <div class="row " style="height: 40px; vertical-align: middle;">

                <div class="col" style="text-align: left; margin-top: 10px">

                  <a [routerLink]="" class="emoji-control" [matMenuTriggerFor]="emojisMenu" #matMenuTrigger="matMenuTrigger">
                    <svg class="olymp-happy-face-icon"><use xlink:href="#olymp-happy-face-icon"></use></svg>
                  </a>

                  <mat-menu #emojisMenu="matMenu" >
                    <emoji-mart (click)="$event.stopPropagation();" class="emoji-mart" [showSingleCategory]="true" (emojiSelect)="addEmoji($event); matMenuTrigger.closeMenu();" title="{{ 'choose_emoji' | translate }}"></emoji-mart>
                  </mat-menu>

                </div>

                <div class="col" style="text-align: right">
                  <button class="btn btn-primary btn-md-2" style="margin-bottom: 0;" (click)="addComment()" [disabled]="!newComment" translate>comments.add_reply</button>
                </div>

              </div>
            </div>
          </ng-container>
        </div>
    </ng-container>

</ng-container>
