import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

const FEEDS_CHUNK_LEN = 25;

@Component({
    selector: 'app-home-photos',
    templateUrl: './home-photos.component.html',
    styleUrls: ['./home-photos.component.css']
})
export class HomePhotosComponent extends BaseComponent implements OnInit {

    currentPage = 0;
    hasMorePages: boolean = false;
    feeds = [];
    working: boolean = false;
    _filter = { countries: [], sports: [] };

    @Input() set filter(value) {
        let reloadData =
            this._filter.countries != value.countries
            || this._filter.sports != value.sports;

        this._filter = value;
        if (reloadData)
            this.getFeeds();
    }
    get filter() { return this._filter; }

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        private modalService: NgbModal,
    ) {
        super(eventService);
    }

    ngOnInit(): void {}

    onOpenFeed(feed: any): void {
      const index = this.feeds.indexOf(feed)
      ImageViewerComponent.open(this.modalService, { "feeds": this.feeds, "openIndex": index });
    }

    getFeeds(fromStart = true) {

        if (fromStart) {
            this.currentPage = 0;
        }

        let params = {
            types: "photo",
            start: this.currentPage * FEEDS_CHUNK_LEN,
            len: FEEDS_CHUNK_LEN
        };

        if (this.filter?.countries) {
            params["country"] = this.filter.countries.join(",");
        }
        if (this.filter?.sports) {
            params["sport"] = this.filter.sports.join(",");
        }

        this.working = true;
        this.apiService.searchFeeds(params).subscribe(
            (result) => {
                this.working = false;

                let feeds = result.body["feeds"] ?? [];
                this.hasMorePages = (feeds.length == FEEDS_CHUNK_LEN);

                if (fromStart) {
                    this.feeds = feeds;
                } else {
                    this.feeds = this.feeds.concat(feeds);
                }
            },
            (error) => { this.handleApiError(error); this.working = false; });
    }

    loadMore() {
        this.currentPage++;
        this.getFeeds(false);
    }
}
