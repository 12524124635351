<div class="ui-block">
    <div class="ui-block-title d-flex justify-content-between">
        <h6 class="title d-flex">{{ 'profile.notifications' | translate }}</h6>
        <mat-slide-toggle [(ngModel)]="user.notificationsConfig.enabled" (change)="onToggleNotifications($event)"></mat-slide-toggle>
    </div>

    <div class="ui-block-content">
        <fieldset [disabled]="!user.notificationsConfig.enabled" #fieldset>
            <div class="row mb-3">
              <div class="col col-12 notifications-grid">
                <div></div>
                <div class="d-flex gap-05 align-items-center justify-content-center">
                  <i class="far fa-envelope fa-lg"></i>
                  <span class="h6 m-0">Email</span>
                </div>
                <div class="d-flex gap-05 align-items-center justify-content-center">
                  <i class="far fa-window-maximize fa-lg"></i>
                  <span class="h6 m-0">{{ 'profile.notifications_inApp' | translate }}</span>
                </div>

                <ng-container *ngFor="let option of notificationOptions">
                  <span class="h6">{{ option.label | translate }}</span>
                  <div class="d-flex justify-content-center">
                    <mat-slide-toggle *ngIf="!(option.hidden?.includes('email'))" [disabled]="fieldset.disabled" [(ngModel)]="user.notificationsConfig.settings[option.key].email"></mat-slide-toggle>
                  </div>
                  <div class="d-flex justify-content-center">
                    <mat-slide-toggle *ngIf="!(option.hidden?.includes('inApp'))" [disabled]="fieldset.disabled" [(ngModel)]="user.notificationsConfig.settings[option.key].inApp"></mat-slide-toggle>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col col-12 text-right">
                <button class="btn btn-primary" (click)="onSaveNotifications()">{{ 'save' | translate }}</button>
              </div>
            </div>
        </fieldset>
    </div>
</div>

<div class="ui-block" #passwordManagement>
    <div class="ui-block-title">
        <h6 class="title" translate>account_settings.change_password</h6>
    </div>

    <div class="ui-block-content">

        <form>
            <fieldset [disabled]="working">
                <div class="row">

                    <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <mat-form-field appearance="outline" >
                            <mat-label translate>account_settings.current_password</mat-label>
                            <input matInput name="oldPassword" [(ngModel)]="oldPassword" type="password" >
                        </mat-form-field>
                    </div>

                    <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                        <mat-form-field appearance="outline" >
                            <mat-label translate>account_settings.new_password</mat-label>
                            <input matInput name="newPassword" [(ngModel)]="newPassword" type="password" >
                        </mat-form-field>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                        <mat-form-field appearance="outline" >
                            <mat-label translate>account_settings.confirm_password</mat-label>
                            <input matInput name="confirmPassword" [(ngModel)]="confirmPassword" type="password" >
                        </mat-form-field>
                    </div>

                    <div class="col col-lg-12 col-sm-12 col-sm-12 col-12">
                        <div class="remember">
                            <a [routerLink]="" (click)="restorePassword()" class="forgot" translate>login.forgot_psw</a>
                        </div>
                    </div>

                    <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <button class="btn btn-primary btn-lg full-width" [disabled]="!newPassword" (click)="changePassword()" >
                            <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ 'account_settings.btn' | translate }}
                        </button>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>

<div class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>account_settings.manage_personal_data</h6>
    </div>
    <div class="ui-block-content">
        <div class="row">
            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <button class="btn-secondary btn btn-lg full-width" (click)="openDeletionDialog()" >
                    <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{ 'account_settings.delete_account' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
