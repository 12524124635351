<div class="" >
    <div class="modal-content ">
        <a [routerLink]="" (click)="activeModal.dismiss('cancel')" class="close icon-close"  aria-label="Close">
            <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
        </a>
        <div class="modal-header">
            <h6 class="title" translate>choose_photo.from_photos</h6>
        </div>

        <div class="modal-body">

            <div class="tab-content">
                <h5 *ngIf="!photos || photos.length == 0" class="no-photo" id="home" aria-expanded="true" translate>
                    choose_photo.no_photos
                </h5>

                <div *ngIf="photos && photos.length > 0" class="tab-pane active img-list-wrapper" role="tabpanel" aria-expanded="true">

                    <div *ngFor="let photo of photos" class="choose-photo-item">
                        <div class="radio">
                            <label class="custom-radio">
                                <img loading="lazy" src="{{getPhotoUrl(photo)}}" alt="photo">
                                <input type="radio" name="optionsRadios" (change)="onPhotoChecked(photo)" >
                                <span class="circle"></span>
                                <span class="check"></span>
                            </label>
                        </div>
                    </div>

                </div>

                <div class="buttons" >
                    <button class="btn btn-secondary btn-lg btn--half-width" style="margin: auto;" (click)="cancel()" translate>btn_cancel</button>
                    &nbsp;
                    <button class="btn btn-primary btn-lg btn--half-width" style="margin: auto;" [disabled]="!selectedPhoto" (click)="selectPhoto()" translate>choose_photo.btn_confirm</button>
                </div>

            </div>
        </div>
    </div>
</div>
