<div>
    <input #photo_file_input type="file" accept="image/*" [multiple]="multiple" style="display:none" (change)="handleFile($event)">
    <a role="button" (click)="openPicker()">

        <div *ngIf="!showPreview && !_loadingPreview">
            <svg class="olymp-computer-icon"><use xlink:href="#olymp-computer-icon"></use></svg>

            <h6 translate>photo_picker.header</h6>
            <span translate>photo_picker.browse</span>
        </div>

        <div *ngIf="_loadingPreview" class="preview-loading">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>

        <div [hidden]="!showPreview || croppingMode !== 'none'" class="photos-window">
          <div class="img-preview-item" *ngFor="let url of fileDataUrls; let i = index">
            <a role="button" class="btn btn-primary" (click)="removeFile(i, $event)">
              <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
            </a>
            <img [src]="url" class="preview-img">
          </div>
        </div>
    </a>

    <ng-container *ngIf="croppingMode === 'profile-picture'">
      <div class="mb-3">
        <image-cropper
          *ngIf="cropImage"
          [imageFile]="cropImage"
          [aspectRatio]="1"
          (imageCropped)="onImageCropped($event)"
        ></image-cropper>
      </div>

      <div class="crop-preview-wrapper" *ngIf="cropEvent">
        <p class="h4">{{ 'preview' | translate }}</p>
        <div id="crop-preview-profile-picture" class="crop-preview">
          <img [src]="cropEvent.base64" class="_small" alt="preview-img-32">
          <img [src]="cropEvent.base64" class="_medium" alt="preview-img-64">
          <img [src]="cropEvent.base64" class="_large" alt="preview-img-128">
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="croppingMode === 'title-photo'">
      <div class="mb-3">
        <image-cropper
          *ngIf="cropImage"
          [maintainAspectRatio]="false"
          [imageFile]="cropImage"
          (imageCropped)="onImageCropped($event)"
        ></image-cropper>
      </div>

      <div class="crop-preview-wrapper" *ngIf="cropEvent">
        <p class="h4">{{ 'preview' | translate }}</p>
        <div class="crop-preview">
          <img [src]="cropEvent.base64" class="_title" alt="preview-img-64">
        </div>
      </div>
    </ng-container>

    <ng-content ></ng-content>

    <div *ngIf="showPreview && showButtons" >

        <button *ngIf="showButtons" class="btn btn-primary btn--half-width m-0" (click)="ok()" [disabled]="working">
            <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{ 'btn_ok' | translate }}
        </button>
        &nbsp;
        <button *ngIf="showButtons" class="btn btn-secondary btn--half-width cancel-button m-0" (click)="cancel()" [disabled]="working">
            {{ 'btn_cancel' | translate }}
        </button>
    </div>

</div>
