import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Utils } from 'src/app/utils';

@Component({
    selector: 'app-org-widget',
    templateUrl: './org-widget.component.html',
    styleUrls: ['./org-widget.component.css']
})
export class OrgWidgetComponent implements OnInit {

    @Input() org;
    _isVerified = false;

    constructor() { }

    ngOnInit(): void {
      this._isVerified = this.org.verified;
    }

    getLogoUrl() {
        return ApiService.getPhotoUrl(this.org?.avatar?.avatar?.small, Utils.getLogoMedium());
    }

    getCoverUrl() {
        return ApiService.getPhotoUrl(this.org?.avatar?.cover?.previewUrl, Utils.getCover());
    }
}
