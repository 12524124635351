import {AfterViewInit, Component, ElementRef, Output, OnDestroy, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-load-page-button',
  templateUrl: './load-page-button.component.html',
  styleUrls: ['./load-page-button.component.css']
})
export class LoadPageButtonComponent implements AfterViewInit, OnDestroy {
  @Output() loadMore = new EventEmitter<void>();

  private observer: IntersectionObserver;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadMore.emit();
        }
      });
    });

    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy() {
    if (this.observer) this.observer.disconnect();
  }
}
