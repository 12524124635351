<ng-container *ngIf="mode === 'admin' || mode === 'create'">
  <form [formGroup]="_form" *ngIf="_form !== undefined" (submit)="onSubmit()">
    <div class="row" *ngIf="showErrorMessage">
      <div class="col col-12">
        <div class="alert alert-danger">
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 col-md-4 no-gutters d-flex justify-content-center">
        <app-photo-picker [multiple]="false" [showButtons]="false" (photoPreviewChanged)="onPhotoPicked($event)"></app-photo-picker>
      </div>

      <div class="col col-12 col-md-8 no-gutters">
        <div class="col col-12 no-gutters mb-2" *ngIf="_linkedUser">
          <mat-label class="mr-2">{{ 'legend.linked_with' | translate }}</mat-label>
          <a class="mr-2" [href]="'/profile/' + _linkedUser._id + '/view-profile'" target="_blank">
            {{ _linkedUser.profile.firstname }} {{ _linkedUser.profile.lastname }}
          </a>
          <button class="btn btn-primary m-0" (click)="onChangeLinkedUser()">{{ 'btn_change' | translate }}</button>
        </div>
        <div class="col col-12 no-gutters" *ngIf="!_linkedUser">
          <mat-label>{{ 'legend.link_with' | translate }}</mat-label>
          <app-search-generic
            [searchConfig]="_searchConfig"
            (onSelect)="onUserSelect($event)"
            [itemTemplate]="item"
            [displayKey]="'name'"
            [inputDisplayFn]="printName"
          >
            <ng-template #item let-result>
              <div class="author-thumb">
                <img loading="lazy" class="img-author" [src]="result.avatar | avatarUrl: 'path'" alt="result">
              </div>
              <span class="result-text" *ngIf="result">
                <span>
                  <span>{{ result.name }}</span>
                  <span class="small c-primary ml-2">{{ result.referenceId }}</span>
                </span>
                <div *ngIf="result.country" class="result-text-small">{{ 'countries.' + result.country | translate }}</div>
              </span>
            </ng-template>
          </app-search-generic>
        </div>
        <div class="row">
          <div class="col col-12 col-lg-6 no-gutters">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'register_user.first_name' | translate }}</mat-label>
              <input matInput type="text" [formControlName]="'firstname'">
            </mat-form-field>
          </div>
          <div class="col col-12 col-lg-6 no-gutters">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'register_user.last_name' | translate }}</mat-label>
              <input matInput type="text" [formControlName]="'lastname'">
            </mat-form-field>
          </div>
        </div>

        <div class="col-12 no-gutters">
          <mat-label>{{ 'legend.birthdate' | translate }}</mat-label>
          <app-date-picker-simple [date]="_birthdate" [required]="true" (onChange)="onBirthdateChange($event)"></app-date-picker-simple>
        </div>
        <!-- Temporarily hidden -->
        <div class="col-12 no-gutters" *ngIf="false">
          <mat-label>{{ 'legend.death_date' | translate }}</mat-label>
          <app-date-picker-simple [date]="_deathDate" (onChange)="onDeathDateChange($event)"></app-date-picker-simple>
          <ng-container *ngIf="hasError('deathDate', 'deathDate')">
            <mat-error>{{ (_form.get('deathDate').errors.deathDate) | translate }}</mat-error>
          </ng-container>
        </div>
        <div class="col-12 no-gutters">
          <div class="row">
            <div class="col col-12 col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'home.filter_country' | translate }}</mat-label>
                <mat-select #countrySelect [formControlName]="'country'" [placeholder]="'profile.country' | translate">
                  <mat-select-filter [matSelect]="countrySelect"
                                     [items]="_countries"
                                     [filterKey]="'name'"
                                     [placeholder]="'home.search' | translate"
                                     (onFiltered)="_filteredCountries = $event"
                  ></mat-select-filter>
                  <mat-option *ngFor="let o of _filteredCountries" [value]="o.code">{{ o.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-12 col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'legend.sport' | translate }}</mat-label>
                <mat-select #sportSelect [formControlName]="'defSport'" [placeholder]="'legend.sport' | translate">
                  <mat-select-filter [matSelect]="sportSelect"
                                     [items]="_sports"
                                     [filterKey]="'name'"
                                     [placeholder]="'profile.search_sport' | translate"
                                     (onFiltered)="_filteredSports = $event"
                  ></mat-select-filter>
                  <mat-option *ngFor="let o of _filteredSports" [value]="o.id">{{ o.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'legend.info' | translate }}</mat-label>
          <textarea matInput type="text" [formControlName]="'info'"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col col-12 col-md-3 text-right">
        <button *ngIf="mode === 'create'" class="btn btn-primary m-0 full-width" type="submit" [disabled]="_form.invalid">{{ 'legend.create_profile' | translate }}</button>
        <button *ngIf="mode === 'admin'" class="btn btn-primary m-0 full-width" type="button" (click)="onSaveAccept()" [disabled]="_form.invalid">{{ (_legend.active ? 'save' : 'legend.save_and_accept') | translate }}</button>
      </div>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="mode === 'readonly'">
  <div class="row legend-grid">
    <div class="d-none d-md-block img-wrapper col col-12 col-md-4 no-gutters">
      <img [src]="'/v1' + _avatar.url" class="readonly-img" alt="legend-profile-picture">
    </div>
    <div class="col col-12 col-md-8 no-gutters">
      <div class="flex-column legend-header">
        <h2 class="font-weight-normal m-0">{{ _form.value.firstname }} {{ _form.value.lastname }}</h2>
        <h5 class="font-weight-light">{{ _form.value.country | countryName }}, {{ _form.value.defSport | sportName }}</h5>
        <h6 class="font-weight-light">
          <label class="mr-2">{{ 'age' | translate }}:</label>
          <span class="mr-2">{{ {startDate: _birthdate, endDate: _deathDate} | age }}</span>
          <span>({{ printDates() }})</span>
        </h6>
      </div>

      <div class="d-flex">
        <p class="legend-info">{{ _form.value.info }}</p>
      </div>
    </div>
  </div>
</ng-container>
