import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Tag } from '../shared/types/options';

@Injectable({
    providedIn: 'root'
})
export class EnumService {

    enums: any = null;

    constructor(private translateService: TranslateService) {
    }

    setEnums(enums) {
        this.enums = enums;
    }

    _getLangId() {
        return this.translateService.currentLang ?? (this.translateService.defaultLang ?? "en");
    }

    _getLocalizedEnums<Entity = any>(enumId): Array<Entity> {
        if (!this.enums || !this.enums[enumId]) {
            return null;
        }

        let langId = this._getLangId();
        return this.enums[enumId][langId] ?? null;
    }

    getEnums() {
        return this.enums;
    }

    getLanguages() {
        return this.enums?.languages;
    }

    getCountries() {
        return this.enums?.countries;
    }

    getUserOccupations() {
        return this._getLocalizedEnums("userOccupations");
    }

    getOrgTypes() {
        return this._getLocalizedEnums("orgTypes");
    }

    getSports() {
        return this._getLocalizedEnums("sports");
    }

    getFeedTags() {
        return this._getLocalizedEnums("feedTags");
    }

    getClubTags() {
        return this._getLocalizedEnums("clubTags");
    }

    getFirmTags() {
        return this._getLocalizedEnums("firmTags");
    }

    getFacilityTags() {
        return this._getLocalizedEnums("facilityTags");
    }

    getUserTags() {
        return this._getLocalizedEnums<Tag>("userTags");
    }
}
