import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
    providedIn: 'root'
})
export class UiService {

    private menuDrawer: MatDrawer;
    private userDrawer: MatDrawer;
    private loginButton: HTMLButtonElement;
    private customData: any = null;

    constructor() { }

    setMenuDrawer(menuDrawer: MatDrawer): void {
        this.menuDrawer = menuDrawer;
    }

    setRigthDrawer(userDrawer: MatDrawer): void {
        this.userDrawer = userDrawer;
    }

    toggleMenuDrawer(): void {
        this.menuDrawer?.toggle();
    }

    toggleUserDrawer(): void {
        this.userDrawer?.toggle();
    }

    openLoginDialog(): void {
        this.loginButton?.click();
    }

    setCustomData(data): void {
        this.customData = data;
    }

    getCustomData(): void {
        return this.customData;
    }

    setLoginButton(button: HTMLButtonElement): void {
      this.loginButton = button;
    }
}
