import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-profile-view',
    templateUrl: './profile-view.component.html',
    styleUrls: ['./profile-view.component.css']
})
export class ProfileViewComponent implements OnInit {

    _user: any = null;
    get user(): any {
        return this._user;
    }

    @Input() set user(value: any) {
        this._user = value;
        
        this.profile = this._user?.profile;
    }

    _org: any = null;
    get org(): any {
        return this._org;
    }

    @Input() set org(value: any) {
        this._org = value;
        
        this.profile = this._org?.profile;
    }

    profile: any = null;
    curentUser: any;
    
    constructor(private apiService: ApiService) { }

    ngOnInit(): void {
        this.curentUser = this.apiService.getCurrentUser();
    }
}
