import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Legend, Mode} from '../../types/org-profile-legends.types';
import {ApiService} from '../../../../services/api.service';
import {Utils} from '../../../../utils';
import {first} from 'rxjs/operators';
import {EventService} from '../../../../services/event.service';
import {EventType} from '../../../../shared/types/base';
import {LegendsService} from '../../services/legends.service';
import {User} from '../../../../shared/types/profile';
import {noop} from 'rxjs';

@Component({
  selector: 'app-legend-widget',
  templateUrl: './legend-widget.component.html',
  styleUrls: ['./legend-widget.component.css']
})
export class LegendWidgetComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private eventService: EventService,
    private legendsService: LegendsService
  ) {}

  @Input() legend: Legend;
  @Input() showButtons: boolean;
  @Input() admin = false;
  @Output('showProfile') showProfileEmitter = new EventEmitter<Legend>();
  @Output() onAcceptSuccess = new EventEmitter<Legend>();
  @Output() onDenySuccess = new EventEmitter<Legend>();
  isLiked = false;
  _isSuperAdmin = false;
  _currentUser?: User;


  ngOnInit(): void {
    this._currentUser = this.apiService.getCurrentUser();
    this._isSuperAdmin = this._currentUser?.superAdmin || false;
    this.isLiked = this._currentUser?.likedLegends?.includes(this.legend._id) || false;
  }

  getAvatarUrl(legend: Legend): any {
    return ApiService.getPhotoUrl(legend.avatar?.previewUrl, Utils.getAvatarMedium());
  }

  onLikeLegend(event: Event): void {
    event.stopPropagation();
    this.isLiked ? this.removeLike() : this.likeLegend();
  }

  getFlag(legend: Legend): string {
    return Utils.getFlag(legend.profile.country);
  }

  onShowProfile(event?: Event): void {
    event?.stopPropagation();
    this.showProfileEmitter.emit(this.legend);
    this.legendsService.showProfileDialog(this.legend, this._isSuperAdmin ? Mode.ADMIN : Mode.READONLY);
  }

  onAccept(event: MouseEvent): void {
    event.stopPropagation();
    this.acceptProfile();
  }

  onDecline(event: MouseEvent): void {
    event.stopPropagation();
    this.declineProfile();
  }

  private likeLegend(): void {
    if (!this._currentUser) return;
    this.legend.likeCount += 1;
    this.isLiked = true;
    this.apiService.legendLike(this.legend._id)
      .pipe(first())
      .subscribe(noop, () => {
        this.eventService.publishData({name: EventType.alert, data: {type: 'error',header: 'alert.header_error', message: '', dismissible: true}})
        this.legend.likeCount -= 1;
        this.isLiked = false;
      });
  }

  private removeLike(): void {
    this.legend.likeCount -= 1;
    this.isLiked = false;
    this.apiService.legendRemoveLike(this.legend._id)
      .pipe(first())
      .subscribe(noop, () => {
        this.eventService.publishData({name: EventType.alert, data: {type: 'error',header: 'alert.header_error', message: '', dismissible: true}})
        this.legend.likeCount += 1;
        this.isLiked = true;
      });
  }

  private acceptProfile(): void {
    this.apiService.updateLegend(this.legend._id)
      .pipe(first())
      .subscribe(() => {
        this.onAcceptSuccess.emit(this.legend)
      });
  }

  private declineProfile(): void {
    this.apiService.deleteLegend(this.legend._id)
      .pipe(first())
      .subscribe(() => {this.onAcceptSuccess.emit(this.legend)});
  }
}
