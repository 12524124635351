import { Component } from '@angular/core';
import { UiService } from "../services/ui.service";

@Component({
  selector: 'app-help-children-page',
  templateUrl: './help-children-page.component.html',
  styleUrls: ['./help-children-page.component.css']
})
export class HelpChildrenPageComponent {

  constructor(
    private uiService: UiService,
  ) { }

  onRegisterLogin(): void {
    this.uiService.openLoginDialog();
  }
}
