<div class="ui-block" *ngIf="visible">
  <div class="ui-block-title">
    <h6 class="title">
      <span *ngIf="_user" class="mr-2">{{ 'profile.donations_title' | translate }}</span>
      <span *ngIf="_org" class="mr-2">{{ 'profile.donations_title_org' | translate }}</span>
      <i class="fa fa-question-circle" tooltipClass="donations-tooltip" [ngbTooltip]="'profile.donations_tooltip' | translate"></i>
    </h6>
  </div>
  <div class="ui-block-content">
    <a role="button" class="btn btn-purple full-width m-0"
       [href]="_user?.profile?.donationsUrl || _org?.profile?.donationsUrl" target="_blank"
    >
      <i class="fa fa-donate mr-2"></i>
      <span>{{ 'btn_donate' | translate }}</span>
    </a>
  </div>
</div>

