<ng-container *ngIf="!showMap">
    <div class="container">
        <div class="row">

            <ng-container *ngIf="!orgs || orgs.length == 0">
                <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
                <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
            </ng-container>

            <div *ngFor="let org of orgs" class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" >
                <app-org-widget [org]="org">

                    <ng-container *ngIf="superAdmin">
                        <ng-container *ngIf="org.active">
                            <h4 style="color: green;" translate>admin.active</h4>
                            <a [routerLink]="" (click)="activateProfile(org, false)" translate>admin.deactivate_profile</a>
                        </ng-container>
                        <ng-container *ngIf="!org.active">
                            <h4 style="color: red;" translate>admin.inactive</h4>
                            <a [routerLink]="" (click)="activateProfile(org)" translate>admin.activate_profile</a>
                        </ng-container>
                    </ng-container>

                </app-org-widget>
            </div>

        </div>
    </div>

    <app-load-page-button *ngIf="hasMorePages" (loadMore)="loadMore()" ></app-load-page-button>

</ng-container>

<ng-container *ngIf="showMap">

    <div class="container">
        <div class="row">
            <div class="col-12" style="padding: 0px;">
                <div class="ui-block">
                    <div class="ui-block-content" style="padding: 4px;" [appStretchToBottom]="mapContainer" [stretchToBottomOffset]="20" #mapContainer>
                        <agm-map style="width: 100%; height: 100%;"
                            [latitude]="mapCenter?.latitude" [longitude]="mapCenter?.longitude"
                            (boundsChange)="mapBoundsChanged($event)" >
                            <agm-marker *ngFor="let org of orgs"
                                [latitude]="org.profile?.location.latitude"
                                [longitude]="org.profile?.location.longitude"
                                [iconUrl]="org.mapIcon">
                                <agm-info-window [disableAutoPan]="true">
                                    <div style="text-align: center;">
                                        {{org.profile.name}}
                                        <br/><a [routerLink]="['/org-profile/' + org._id]" translate>map.view_profile</a>
                                    </div>
                                  </agm-info-window>
                                </agm-marker>
                        </agm-map>

                        <div *ngIf="newMapBound" class="search-area">
                            <a [routerLink]="" (click)="getOrgs()" class="btn btn-primary btn-sm" translate>map.search_this_area</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <a [routerLink]="['../']" class="back-to-top map-btn" >
        <i class="fa fa-list" aria-hidden="true"></i>
    </a>
</ng-container>
