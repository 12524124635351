import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { EventService } from '../services/event.service';
import { FilterType } from '../components/home-filter/home-filter.component';
import { ApiService } from '../services/api.service';
import { EventType } from '../shared/types/base';

@Component({
    selector: 'app-mass-messages',
    templateUrl: './mass-messages.component.html',
    styleUrls: ['./mass-messages.component.css']
})
export class MassMessagesComponent extends BaseComponent implements OnInit {

    FilterType = FilterType;
    filter: any = {};
    working: boolean = false;
    currentTab: string = "new";
    usersCount: number = -1;
    messageText: string = "";

    fromSubjects: any[] =  [];
    from: string = "";

    constructor(
        eventService: EventService,
        private apiService: ApiService) {
        super(eventService);
    }

    ngOnInit(): void {
        // TODO: set from subject
        const currentUser = this.apiService.getCurrentUser();

        if (currentUser?.allowMassMessages) {
            let name = "me";
            if (currentUser.profile) {
                name = `${currentUser.profile.firstname} ${currentUser.profile.lastname}`;
            }

            this.fromSubjects.push({ id: "me", "name": name });
        }

        currentUser?.adminOrgs?.forEach(o => {
            if (o.allowMassMessages) {
                this.fromSubjects.push({ id: o._id, "name": o.profile?.name ?? o._id });
            }
        });

        if (this.fromSubjects.length > 0) {
            this.from = this.fromSubjects[0].id;
        }
    }

    filterChanged($event) {
        this.filter = Object.assign({}, $event?.filter);
        this.getUsers();
    }

    addEmoji($event) {
        this.messageText = `${this.messageText}${$event.emoji.native}`;
    }

    getSearchParams() {
        let params = { orgs: false, feeds: false };

        if (this.filter?.countries) {
            params["country"] = this.filter.countries.join(",");
        }
        if (this.filter?.sports) {
            params["sport"] = this.filter.sports.join(",");
        }
        if (this.filter?.tags && this.filter.tags.length > 0) {
            params["tags"] = this.filter.tags.join(",");
        }
        if (this.filter?.user) {
            if (this.filter.user.gender) {
                params["gender"] = this.filter.user.gender;
            }
            if (this.filter.user.minHeight) {
                params["minHeight"] = this.filter.user.minHeight;
            }
            if (this.filter.user.maxHeight) {
                params["maxHeight"] = this.filter.user.maxHeight;
            }
            if (this.filter.user.minWeight) {
                params["minWeight"] = this.filter.user.minWeight;
            }
            if (this.filter.user.maxWeight) {
                params["maxWeight"] = this.filter.user.maxWeight;
            }
            if (this.filter.user.minAge > 0) {
                params["minAge"] = this.filter.user.minAge;
            }
            if (this.filter.user.maxAge < 99) {
                params["maxAge"] = this.filter.user.maxAge;
            }
            if (this.filter.user.isMember) {
                params["isMemberOfClub"] = this.filter.user.isMember == 'isMember';
            }
        }

        return params;
    }

    getUsers() {

        const params = this.getSearchParams();
        params["start"] = 0;
        params["len"] = 1;

        this.working = true;
        this.apiService.search(params).subscribe(
            (result) => {
                this.working = false;

                this.usersCount = result.body["usersCount"] ?? [];
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    sendMessage() {
        if (!this.from) {
            return;
        }

        const params = this.getSearchParams();
        params['message'] = this.messageText;

        if (this.from == "me") {
            this.working = true;
            this.apiService.sendMassMessage(params).subscribe(
                (result) => {
                    this.working = false;
                    this.usersCount = -1;
                    this.sendMassMessageSuccessEvent();
                },
                (error) => {this.handleApiError(error); this.working = false;} );
        }
        else {
            this.working = true;
            this.apiService.sendOrgMassMessage(this.from, params).subscribe(
                (result) => {
                    this.working = false;
                    this.usersCount = -1;
                    this.sendMassMessageSuccessEvent();
                },
                (error) => {this.handleApiError(error); this.working = false;} );
        }
    }

    private sendMassMessageSuccessEvent(): void {
        this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'mass_messages.message_sent', dismissible: true } } );
    }
}
