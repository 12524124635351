import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';

@Component({
    selector: 'app-profile-badges-widget',
    templateUrl: './profile-badges-widget.component.html',
    styleUrls: ['./profile-badges-widget.component.css']
})
export class ProfileBadgesWidgetComponent extends BaseComponent implements OnInit {

    @Input() user: any = null;
    orgs: any[] = null;

    constructor(
        eventService: EventService,
        private apiService: ApiService
    ) {
        super(eventService);
     }

    ngOnInit(): void {
        this.getUserOrgs();
    }

    getOrgName(org) {
        return org?.name;
    }

    getLogoUrl(org) {
        return ApiService.getPhotoUrl(org?.avatar?.avatar?.small, Utils.getLogoSmall());
    }

    getUserOrgs() {
        if (!this.user)
            return;

        let params = { start: 0, len: 14 };

        this.apiService.getUserOrgs(this.user._id).subscribe(
            (result) => {
                this.orgs = result?.body["organizations"] ?? [];
            },
            (error) => this.handleApiError(error) );
    }
}
