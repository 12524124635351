import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-insta-follow',
    templateUrl: './insta-follow.component.html',
    styleUrls: ['./insta-follow.component.css']
})
export class InstaFollowComponent implements OnInit {

    instaUrl = environment.INSTA_PROFILE_URL;

    constructor() { }

    ngOnInit(): void {
    }

}
