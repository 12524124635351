<ng-container *ngIf="!feeds || feeds.length == 0">
    <div class="row">
        <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
        <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
    </div>
</ng-container>

<div class="photo-album-wrapper m-0">
    <ng-container *ngFor="let feed of feeds">
      <app-home-photo-item [feed]="feed" (onOpenFeed)="onOpenFeed($event)"></app-home-photo-item>
    </ng-container>
</div>

<app-load-page-button *ngIf="hasMorePages" (loadMore)="loadMore()" ></app-load-page-button>
