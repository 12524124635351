import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { first } from "rxjs/operators";
import { User } from "../../shared/types/profile";
import { BaseComponent } from "../../base/base.component";
import { EventService } from "../../services/event.service";

@Component({
  selector: 'app-org-recommendations',
  templateUrl: './org-recommendations.component.html',
  styleUrls: ['./org-recommendations.component.css']
})
export class OrgRecommendationsComponent extends BaseComponent implements OnInit {

  @Input() org: any;
  recommendedBy: Array<User> = [];
  othersCount: number = 0;

  constructor(private apiService: ApiService, eventService: EventService) {
    super(eventService)
  }

  ngOnInit(): void {
    this.loadUsers(this.org._id);
  }

  private loadUsers(orgId: string): void {
    this.apiService.getOrgRecommendations(orgId)
      .pipe(first())
      .subscribe(res => {
        this.recommendedBy = res.users;
        this.othersCount = Math.max(res.totalCount - 10, 0);
      },(e) => this.handleApiError(e))
  }
}
