<div class="legend-item ui-block relative" (click)="onShowProfile()">
  <div class="country-flag">
    <img [src]="getFlag(legend)" alt="country-flag">
  </div>
  <div class="d-flex flex-column ui-block-content">
    <div class="legend-image">
      <img [src]="getAvatarUrl(legend)" alt="legend-avatar-icon">
    </div>
    <div class="legend-body">
      <p class="h3 font-weight-normal">{{ legend.profile | userName: 'full' }}</p>
      <p class="h5 font-weight-light">
        <span class="mr-2">{{ legend.profile.defSport | sportName }}</span>
        <span>{{ legend.profile.country | countryName }}</span>
      </p>
      <ng-container *ngIf="showButtons">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div class="like-item d-flex gap-05 align-items-center">
            <i class="like-button p-2 fa-2x" [ngClass]="isLiked ? 'fa fa-heart c-primary' : 'far fa-heart'" role="button" (click)="onLikeLegend($event)"></i>
            <span class="font-weight-normal">{{ legend.likeCount || 0 }}</span>
          </div>
          <div *ngIf="legend.userId">
            <a [href]="'/profile/' + legend.userId + '/view-profile'" target="_blank" role="button" class="btn btn-sm btn-primary profile-button m-0" (click)="$event.stopPropagation()">
              <span class="mr-2"> {{ 'btn_profile' | translate }}</span>
              <i class="fa fa-external-link"></i>
            </a>
          </div>
          <div class="renown-item d-flex gap-05 align-items-center">
            <i class="fa fa-crown p-2 fa-2x"></i>
            <span class="font-weight-normal">{{ legend.renownCount || 0 }}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="admin">
        <div class="d-flex flex-column gap-05">
          <div class="d-flex flex-grow-1">
            <button class="btn btn-primary m-0 full-width" (click)="onShowProfile($event)">{{ 'legend.show_profile' | translate }}</button>
          </div>
          <div class="d-flex flex-grow-1 gap-05">
            <button class="btn btn-success m-0 full-width" (click)="onAccept($event)">{{ 'profile.accept_membership' | translate }}</button>
            <button class="btn btn-danger m-0 full-width" (click)="onDecline($event)">{{ 'profile.decline_membership' | translate }}</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
