import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';
import { BaseEvent, EventType } from '../../shared/types/base';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css']
})
export class MessagesComponent extends BaseComponent implements OnInit {

    currentThread: any = null;
    orgId: string = null;
    org: any = null;
    user: any = null;
    editable: boolean = false;

    myself: any = {};

    messages: any[] = [];
    newMessage: string = "";
    isActive: boolean = false;

    @ViewChild("messageThreadScroll") private messageThreadScroll: PerfectScrollbarComponent;
    @ViewChild("messageThreadScrollPopup") private messageThreadScrollPopup: PerfectScrollbarComponent;

    constructor(
        activatedRoute: ActivatedRoute,
        eventService: EventService,
        private apiService: ApiService,
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private breakpointObserver: BreakpointObserver,
        router: Router
    ) {
        super(eventService);

        activatedRoute.params.subscribe(params => {

            let currentUser = apiService.getCurrentUser();
            if (!currentUser) {
                router.navigateByUrl('/');
                return;
            }

            this.orgId = params["orgId"];
            
            if (this.orgId) {
                this.getOrg();
            }
            else {
                this.user = currentUser;
                this.editable = true;
                this.setMyself();
            }

            let options = {
                threadId: params["threadId"],
                toUser: params["toUser"],
                toOrg: params["toOrg"]
            };

            this.getMessages(options);
        });
    }

    ngOnInit(): void {
        this.isActive = true;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.isActive = false;
    }

    setMyself() {
        this.myself = {
            name: this.user ? `${this.user.profile?.firstname} ${this.user.profile?.lastname}` : this.org?.profile?.name,
            avatar: this.user ?
                ApiService.getPhotoUrl(this.user?.avatar?.avatar?.small, Utils.getAvatarSmall()) :
                ApiService.getPhotoUrl(this.org?.avatar?.avatar?.small, Utils.getLogo())
        };
    }

    protected eventReceived(event: BaseEvent): void {

        if (!this.isActive || event.name !== EventType.data) {
            return;
        }

        if (event.data.notification?.data?.body?.messages) {
            // all unread thread is in event data
            event.data.notification?.data?.body?.messages?.forEach(m => { // before replace msg threads compare chat len to update only new threads
                let msgIdx = this.messages?.findIndex(msg => msg._id == m._id);
                // compare msgs chat nums, ignore new threads
                if (msgIdx >= 0 && this.messages[msgIdx].chat && m.chat && this.messages[msgIdx].chat.length < m.chat.length) {
                    this.messages[msgIdx] = this.handleMessage(m);
                    if (this.currentThread?._id == m._id) {
                        this.selectMessageThread(this.messages[msgIdx]);
                    }
                }
            });
        }
        else if (event.data.notification?.data?.update == "messages") {
            let threadId = event.data.notification?.data?.threadId;
            if (threadId) {
                this.getMessageThread(threadId);
            }
        }
    }

    getOrg() {
        if (!this.orgId) {
            return;
        }

        this.apiService.getOrganization(this.orgId).subscribe(
            (result) => {
                this.org = result.body["organization"];
                this.editable = this.apiService.isCurrentUserAdmin(this.org._id);
                this.setMyself();
            },
            (error) => this.handleApiError(error) );
    }

    getMessages(options) {
        if (this.orgId) {
            //get org messages
            this.apiService.getOrgMessages( this.orgId, {} ).subscribe(
                (result) => {
                    this.handleMessagesResponse(result.body["messages"], options);
                },
                (error) => this.handleApiError(error) );
        }
        else {
            // get user messages
            this.apiService.getMessages( {} ).subscribe(
                (result) => {
                    this.handleMessagesResponse(result.body["messages"], options);
                },
                (error) => this.handleApiError(error) );
        }
    }

    getMessageThread(threadId) {
        if (!threadId) {
            return;
        }

        if (this.orgId) {
            //get org messages
            this.apiService.getOrgMessage( this.orgId, threadId ).subscribe(
                (result) => {
                    let message = result.body["message"];
                    if (message?._id) {                   
                        message = this.handleMessage(message);    
                        let idx = this.messages.findIndex(m => m._id == threadId);
                        if (idx >= 0) {
                            this.messages[idx] = message;
                            if (this.currentThread?._id == threadId) {
                                this.selectMessageThread(message);
                            }
                        }
                    }
                },
                (error) => this.handleApiError(error) );
        }
        else {
            // get user messages
            this.apiService.getMessage( threadId ).subscribe(
                (result) => {
                    let message = result.body["message"];
                    if (message?._id) {                   
                        message = this.handleMessage(message);    
                        let idx = this.messages.findIndex(m => m._id == threadId);
                        if (idx >= 0) {
                            this.messages[idx] = message;
                            if (this.currentThread?._id == threadId) {
                                this.selectMessageThread(message);
                            }
                        }
                    }
                },
                (error) => this.handleApiError(error) );
        }
    }

    handleMessagesResponse(result, options) {

        result?.forEach(m => this.handleMessage(m) );

        this.messages = result;

        let thread = null;
        if (options.threadId) {
            thread = this.messages?.find(m => m._id == options.threadId);
        }
        else if (options.toUser) {
            thread = this.messages?.find(m => m.participants.some(p => p.userId == options.toUser));
            if (!thread) {
                this.createNewThread(options);
                return;
            }
        }
        else if (options.toOrg) {
            thread = this.messages?.find(m => m.participants.some(p => p.orgId == options.toOrg));
            if (!thread) {
                this.createNewThread(options);
                return;
            }
        }

        if (!thread) {
            if (!this.breakpointObserver.isMatched('(max-width: 991px)')) { // do not set default thread on mobile
                thread = this.messages[this.messages.length - 1];
            }
        }

        this.selectMessageThread(thread);
    }

    handleMessage(m) {
        if (!m) {
            return m;

        }
        let fromIdx = -1;
        if (this.orgId) {
            fromIdx = m.participants?.findIndex(p => p.orgId != this.orgId );
        }
        else {
            fromIdx = m.participants?.findIndex(p => p.userId != this.user?._id );
        }

        if (fromIdx < 0 || fromIdx > 1) {
            return m;
        }

        let fromUser = m.participants[fromIdx].userId;
        let fromOrg = m.participants[fromIdx].orgId;
        let from = null;
        if (fromUser) {
            from = m.participantUserObjects?.find(p => p._id == fromUser);
        }
        else if (fromOrg) {
            from = m.participantOrgObjects?.find(p => p._id == fromOrg);
        }

        if (!from) {
            return m; 
        }

        m.from = {
            name: (fromUser) ? `${from.profile?.firstname} ${from.profile?.lastname}` : from.profile?.name,
            avatar: ApiService.getPhotoUrl(from.avatar?.avatar?.small, fromUser ? Utils.getAvatarSmall() : Utils.getLogo()),
            profileUrl: (fromUser) ? `/profile/${from._id}` : `/org-profile/${from._id}`
        };

        m.users = [{}, {}];
        m.users[fromIdx] = m.from;
        m.users[1 - fromIdx] = this.myself;
        m.unread = m.participants[1 - fromIdx].unread;

        this.handleChat(m);

        if (m.chat && m.chat.length > 0) {
            m.valid = true;
        }

        return m;
    }

    handleChat(msg) {
        const today = new Date();
        msg.chat?.forEach(ch => {
            ch.sentTime = Utils.getHumanDate(ch.sent, this.datePipe, this.translateService, today);
        });

        if (msg.chat && msg.chat.length > 0) {
            msg.last = msg.chat[msg.chat.length - 1];
        }
    }

    selectMessageThread(thread) {
        this.currentThread = thread;
        this.msgThreadScrollDown();

        if (this.currentThread && this.currentThread._id && this.currentThread.unread > 0) {
            this.setThreadRead(this.currentThread._id);
        }
    }

    msgThreadScrollDown() {
        
        window.setTimeout(() => {
            this.messageThreadScroll?.directiveRef?.scrollToBottom();
            this.messageThreadScrollPopup?.directiveRef?.scrollToBottom();
        }, 100);
    }

    createNewThread(options) {
        
        if (options.toUser) {
            this.apiService.getUser(options.toUser).subscribe(
                (result) => {
                    let user = result.body["user"];
                    let thread = { toUser: options.toUser, from: {} };
                    thread.from = {
                        name: `${user.profile?.firstname} ${user.profile?.lastname}`,
                        avatar: ApiService.getPhotoUrl(user.avatar?.avatar?.small, Utils.getAvatarSmall()),
                        profileUrl: `/profile/${user._id}`
                    };
                
                    this.selectMessageThread(thread);
                },
                (error) => this.handleApiError(error) );
        }
        else if (options.toOrg) {
            this.apiService.getOrganization(options.toOrg).subscribe(
                (result) => {
                    let org = result.body["organization"];
                    let thread = { toOrg: options.toOrg, from: {} };
                    thread.from = {
                        name: org.profile?.name,
                        avatar: ApiService.getPhotoUrl(org.avatar?.avatar?.small, Utils.getLogo()),
                        profileUrl: `/org-profile/${org._id}`
                    };

                    this.selectMessageThread(thread);
                },
                (error) => this.handleApiError(error) );
        }
    }

    addEmoji($event) {
        this.newMessage = `${this.newMessage}${$event.emoji.native}`;
    }

    sendMessage() {
        if (this.currentThread?._id) {
            this.responseMessage(this.currentThread?._id, this.newMessage);
        }
        else if (this.currentThread?.toUser || this.currentThread?.toOrg) {
            this.sendNewMessage(this.currentThread, this.newMessage);
        }
    }

    sendNewMessage(to: any, message: string) {
        let msg = {};
        if (to?.toUser) {
            msg["userId"] = to.toUser;
        } else if (to?.toOrg) {
            msg["orgId"] = to.toOrg;
        } else {
            return;
        }

        msg["message"] = message;

        if (this.orgId) {
            //send message as org
            this.apiService.sendOrgMessage(this.orgId, msg ).subscribe(
                (result) => {
                    this.getMessages({ threadId: result.body["message"]._id });
                    this.newMessage = "";
                },
                (error) => this.handleApiError(error) );
        }
        else {
            // send message as user
            this.apiService.sendMessage( msg ).subscribe(
                (result) => {
                    this.getMessages({ threadId: result.body["message"]._id });
                    this.newMessage = "";
                },
                (error) => this.handleApiError(error) );
        }
    }

    responseMessage(threadId: string, message: string) {
        if (!threadId || !this.newMessage) {
            return;
        }

        if (this.orgId) {
            //response as org
            this.apiService.responseOrgMessage( this.orgId, threadId, message ).subscribe(
                (result) => {
                    this.handleMessageSentResponse(result.body["message"]);
                    this.msgThreadScrollDown();
                },
                (error) => this.handleApiError(error) );
        }
        else {
            // response as user
            this.apiService.responseMessage( threadId, message ).subscribe(
                (result) => {
                    this.handleMessageSentResponse(result.body["message"]);
                    this.msgThreadScrollDown();
                },
                (error) => this.handleApiError(error) );
        }
    }

    handleMessageSentResponse(msgResponse: any) {
        this.newMessage = "";
        let msg = this.messages?.find(m=> m._id == msgResponse._id);
        if (msg) {
            this.handleChat(msgResponse);
            msg.updated = msgResponse.updated;
            msg.chat = msgResponse.chat;
            msg.last = msgResponse.last;
        }
    }

    setThreadRead(threadId) {
        if (!threadId) {
            return;
        }

        if (this.orgId) {
            this.apiService.messageOrgRead(this.orgId, threadId).subscribe(
                (result) => {
                    // send event to update header notifs
                    this.sendUpdateNotificationsEvent();
                    let thread = this.messages?.find(m=> m._id == threadId);
                    if (thread) { thread.unread = 0; }
                },
                (error) => this.handleApiError(error) );
        }
        else {
            this.apiService.messageRead(threadId).subscribe(
                (result) => {
                    // send event to update header notifs
                    this.sendUpdateNotificationsEvent();
                    let thread = this.messages?.find(m=> m._id == threadId);
                    if (thread) { thread.unread = 0; }
                },
                (error) => this.handleApiError(error) );
        }
    }

    closeThread() {
        this.currentThread = null;
    }

    private sendUpdateNotificationsEvent(): void {
        this.sendEvent({ name: EventType.update, data: { update: 'notifications' } });
    }
}
