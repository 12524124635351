<!-- News Feed Form  -->

    <div class="news-feed-form">

        <form>
            <fieldset [disabled]="working">
                <div class="author-thumb">
                    <img loading="lazy" class="img-author" src="{{getAvatarUrl()}}" alt="author">
                </div>

                <mat-form-field *ngIf="!newFeedTypePhoto" class="feed-text" appearance="">
                    <div class="feed-text-pencil-icon" [ngClass]="showTextFeedIcon ? 'd-block' : 'd-none'">
                      <i class="fa fa-edit"></i>
                    </div>
                    <mat-label translate>feeds.new_feed_label</mat-label>
                    <textarea #textFeedInput
                              matInput class="feed-text-input"
                              [maxLength]="1000" name="feedText"
                              [(ngModel)]="newFeedText"
                              (focus)="showTextFeedIcon = false"
                              (blur)="showTextFeedIcon = true"
                    ></textarea>
                </mat-form-field>

                <div [ngClass]="{'hidden': !newFeedTypePhoto}" style="margin-top:16px; text-align: center;" >
                    <app-photo-picker #photo_picker (photoPreviewChanged)="photoSelected($event)"
                        [showButtons]="false" [multiple]="true" >

                        <div class="row justify-content-md-center">
                            <div class="col col-12">
                                <mat-form-field appearance="" class="feed-text" >
                                    <mat-label style="margin-left: 8px;" translate>profile.photo_description</mat-label>
                                    <textarea #photoFeedInput name="photoFeedText" [maxLength]="1000" [(ngModel)]="newFeedText" style="min-height: 32px !important;" matInput ></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                    </app-photo-picker>
                </div>


              <div *ngIf="newVideoTypePhoto" class="col" style="max-height: 50px; margin-bottom: 1rem;">
                <mat-form-field class="youtube-field" appearance="outline">
                  <mat-label translate>feeds.link</mat-label>
                  <input matInput name="youtubeLink" [(ngModel)]="youtubeLink">
                </mat-form-field>
              </div>

              <div class="add-options-message" translate>feeds.new_feed_tags_header</div>

<!--                <div class="col" style="min-width: 140px;" *ngFor="let tag of feedTags" >-->
<!--                    <mat-checkbox [(ngModel)]="tag.selected" name="{{tag.id}}" >-->
<!--                        {{tag.name}}-->
<!--                    </mat-checkbox>-->
<!--                </div>-->

                <div class="d-flex col-12 mb-2">
                  <app-tags class="full-width" [allTags]="feedTags" [tags]="selectedFeedTags"></app-tags>
                </div>

                <div class="add-options-message">

                    <span class="options-message" (click)="openPhotoFeeds()" >
                        <svg class="olymp-camera-icon"><use xlink:href="#olymp-camera-icon"></use></svg>
                    </span>

<!--                    <span class="options-message" (click)="openPhotoFeeds(false)" ngbTooltip="{{ 'feeds.new_feed_text' | translate }}" triggers="click:blur" container="body" >-->
<!--                        <svg class="olymp-computer-icon"><use xlink:href="#olymp-computer-icon"></use></svg>-->
<!--                    </span>-->

                    <span class="options-message" (click)="openYoutubeField()" ngbTooltip="{{ 'feeds.new_feed_youtube' | translate }}" triggers="click:blur" container="body" >
                        <svg class="olymp-play-icon"><use xlink:href="#olymp-play-icon"></use></svg>
                    </span>

                    <span class="options-message" [matMenuTriggerFor]="emojisMenu" #matMenuTrigger="matMenuTrigger">
                        <svg class="olymp-happy-face-icon"><use xlink:href="#olymp-happy-face-icon"></use></svg>
                    </span>

                    <mat-menu #emojisMenu="matMenu" >
                        <emoji-mart (click)="$event.stopPropagation();" class="emoji-mart" [showSingleCategory]="true" (emojiSelect)="addEmoji($event); matMenuTrigger.closeMenu();" title="{{ 'choose_emoji' | translate }}"></emoji-mart>
                    </mat-menu>

                    <button class="btn btn-primary btn-md-2 button-post-feed" (click)="postFeed()" [disabled]="working || !newFeedText || (newFeedTypePhoto && (newFeedPhotos.length == 0))" translate>feeds.btn_add</button>

                </div>
            </fieldset>
        </form>

    </div>

<!-- ... end News Feed Form  -->
