
<div class="container">

  <div class="text-center mt-3 mb-1 cat-list-bg-style">
    <div class="cat-list__item">
      <a [routerLink]="['/help-children']" role="button" style="text-transform: none" class="d-flex align-items-center">
        <span class="inline-block">
          <p class="text-center m-0 fs-xl">{{ 'header_info.title' | translate }}</p>
          <p class="text-center m-0 fs-lg">{{ 'header_info.subtitle_2' | translate }}</p>
        </span>
        <span class="ml-3">
          <i class="fa fa-heart fa-3x c-primary icon"></i>
        </span>
      </a>
    </div>
  </div>

  <div class="alert alert-info fade show" role="alert" *ngIf="currentTab && currentTab !== 'feeds' && currentTab !== 'map'">
    <span [innerHtml]="('header_info.' + currentTab) | translate"></span>
  </div>

  <div class="row non-mobile-version">
    <div class="header-menu-red-dots">
      <ul class="cat-list-bg-style align-center sorting-menu display-navigation m-0">
        <li class="cat-list__item btn-pretty">
          <a [routerLink]="['/org-profile/hall-of-fame/legends']">
            <img *ngIf="_geoCountryCode | flag" [src]="_geoCountryCode | flag" alt="flag-icon">
            <span>{{ 'home.hall_of_fame_title' | translate }}</span>
          </a>
        </li>
        <li class="cat-list__item btn-pretty" [routerLinkActive]="'active'">
          <a [routerLink]="['/home/firms']">
            <img *ngIf="_geoCountryCode | flag" [src]="_geoCountryCode | flag" alt="flag-icon">
            <span>{{ 'home.nav_benefits' | translate }}</span>
          </a>
        </li>
      </ul>
      <ul class="cat-list-bg-style align-center sorting-menu display-navigation mx-4 mt-2 mb-4">
        <li class="cat-list__item" [routerLinkActive]="'active'"><a [routerLink]="['/home/users']" translate>home.users</a></li>
        <li class="cat-list__item" [routerLinkActive]="'active'"><a [routerLink]="['/home/events']" translate>home.events</a></li>
        <li class="cat-list__item" [routerLinkActive]="'active'"><a [routerLink]="['/home/clubs']" translate>home.clubs</a></li>
        <li class="cat-list__item" [routerLinkActive]="'active'"><a [routerLink]="['/home/facilities']" translate>home.facilities</a></li>
        <li class="cat-list__item" [routerLinkActive]="'active'"><a [routerLink]="['/home/feeds']" translate>home.feeds</a></li>
        <!-- <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'map' }"><a [routerLink]="['/home/map']" translate>home.map</a></li> -->
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col col-12">
      <app-home-filter
        #filterComponent
        [caption]="getHeaderText()" [type]="filterType"
        (filterChanged)="filterChanged($event)"
        (filterLoaded)="onFiltersLoaded()"
      >
        <div *ngIf="!currentTab" class="mobile-version">
          <ul class="home-mobile-nav-buttons-list mb-3 gap-05">
            <li class="mobile-nav-button btn-pretty" [routerLinkActive]="'active'">
              <a [routerLink]="['/org-profile/hall-of-fame/legends']">
                <img *ngIf="_geoCountryCode | flag" [src]="_geoCountryCode | flag" alt="flag-icon">
                <span>{{ 'home.hall_of_fame_title' | translate }}</span>
              </a>
            </li>
            <li class="mobile-nav-button btn-pretty" [routerLinkActive]="'active'">
              <a [routerLink]="['/home/firms']">
                <img *ngIf="_geoCountryCode | flag" [src]="_geoCountryCode | flag" alt="flag-icon">
                <span>{{ 'home.nav_benefits' | translate }}</span>
              </a>
            </li>
            <li class="mobile-nav-button" [routerLinkActive]="'active'"><a [routerLink]="['/home/events']" translate>home.events</a></li>
            <ul class="list-row gap-05">
              <li class="mobile-nav-button" [routerLinkActive]="'active'"><a [routerLink]="['/home/users']" translate>home.users</a></li>
              <li class="mobile-nav-button" [routerLinkActive]="'active'"><a [routerLink]="['/home/clubs']" translate>home.clubs</a></li>
            </ul>
            <li class="mobile-nav-button" [routerLinkActive]="'active'"><a [routerLink]="['/home/facilities']" translate>home.facilities</a></li>
            <li class="mobile-nav-button" [routerLinkActive]="'active'"><a [routerLink]="['/home/feeds']" translate>home.feeds</a></li>
          </ul>
        </div>
      </app-home-filter>
    </div>
  </div>

  <div class="row">
    <div class="col col-12">
      <div class="ui-block" *ngIf="currentTab == 'feeds'">
        <div class="ui-block-title" style="cursor: pointer;" (click)="collapse.toggle()" [attr.aria-expanded]="!collapseNewFeed">
          <h4>{{'home.add_feed' | translate}}</h4>
          <div style="text-align: right;">
            <svg *ngIf="collapseNewFeed" class="collapse-icon olymp-accordion-open-icon"><use xlink:href="#olymp-accordion-open-icon"></use></svg>
            <svg *ngIf="!collapseNewFeed" class="collapse-icon olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
          </div>
        </div>
        <div class="ui-block-content" #collapse="ngbCollapse" [(ngbCollapse)]="collapseNewFeed" (ngbCollapseChange)="onNewFeedCollapse($event)">
          <app-new-feed #newFeedComponent *ngIf="currentUser" [user]="currentUser" (feedAdded)="feedsComponent.getFeeds()"></app-new-feed>
          <div *ngIf="!currentUser">
            <h6> {{'home.add_feed_no_user' | translate}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col col-12" *ngIf="filtersLoaded">
      <app-home-photos [filter]="filter" *ngIf="!currentTab"></app-home-photos>
      <app-home-feeds [filter]="filter" *ngIf="currentTab == 'feeds' || currentTab === 'events'" #feedsComponent></app-home-feeds>
      <app-home-orgs [filter]="filter" [showMap]="showMap" [type]="['sport_club','fan_club']" *ngIf="currentTab == 'clubs'"></app-home-orgs>
      <app-home-users [filter]="filter" [showMap]="showMap" *ngIf="currentTab == 'users'"></app-home-users>
      <app-home-orgs [filter]="filter" [showMap]="showMap" [type]="['firm']" *ngIf="currentTab == 'firms'"></app-home-orgs>
      <app-home-orgs [filter]="filter" [showMap]="showMap" [type]="['sport_facility']" *ngIf="currentTab == 'facilities'"></app-home-orgs>
      <app-home-map #homeMapComponent [filter]="filter" *ngIf="currentTab == 'map'"></app-home-map>
    </div>
  </div>
</div>


<app-back-to-top></app-back-to-top>
