<ng-container *ngIf="feed">
<!--    <a [routerLink]="" (click)="onClick()" class="bgr-author-widget" ngbTooltip="{{tooltip}}" container="body"></a>-->
    <a class="avatar-widget" [routerLink]="" [ngStyle]="{'flex-flow': flow}" (click)="onClick()" ngbTooltip="{{tooltip}}" container="body" >
        <div class="img-wrapper">
            <img loading="lazy" class="img-feed-author" src="{{getAvatar()}}" alt="author">
            <app-verified-badge *ngIf="_isVerified"></app-verified-badge>
        </div>

        <ng-container *ngIf="showInfo">
            <div *ngIf="feed.org">
                <div class="one-row feed-author-name h6 author-name">{{feed.org.profile?.name}}</div>
                <div class="feed-author-country country" [ngStyle]="{'text-align': flow === 'row-reverse' ? 'end' : 'start'}" *ngIf="feed.org.profile?.country" translate>countries.{{feed.org.profile?.country}}</div>
            </div>

            <div *ngIf="feed.user">
                <div class="feed-author-name h6 author-name">{{feed.user.profile | userName: 'firstName'}}</div>
                <div class="feed-author-country country" [ngStyle]="{'text-align': flow === 'row-reverse' ? 'end' : 'start'}" *ngIf="feed.user.profile?.country" translate>countries.{{feed.user.profile?.country}}</div>
            </div>
        </ng-container>
    </a>
</ng-container>
