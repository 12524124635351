<ul class="ui-block"
    [ngClass]="navClass()"
>
  <ng-container *ngFor="let item of items">
    <li *ngIf="item.visible"
        (click)="onClick(item)"
        [routerLinkActive]="item.path ? 'active' : ''"
        [ngClass]="{'active': isActive(item)}"
    >
      <a [routerLink]="item.path">
        <span class="h6 title" style="margin: 0!important;">{{ item.label | translate }}</span>
      </a>
    </li>
  </ng-container>
</ul>
