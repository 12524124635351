import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { ApiService } from '../services/api.service';
import { EventService } from '../services/event.service';
import { EventType } from '../shared/types/base';

@Component({
    selector: 'app-activate-user',
    templateUrl: './activate-user.component.html',
    styleUrls: ['./activate-user.component.css']
})
export class ActivateUserComponent extends BaseComponent implements OnInit {

    working: boolean = false;
    user = null;
    resultMsg: string = "";

    constructor(
        eventService: EventService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: ApiService) {
            super(eventService);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            if ("code" in params && "id" in params) {
                this.user = {
                    code: params["code"],
                    id: params["id"],
                    email: "email" in params ? params["email"] : ""
                };

                this.activateUser();
            }
        });
    }

    activateUser() {
        this.working = true;
        this.apiService.activateUser(this.user).subscribe(
            (result) => {
                this.working = false;
                this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'activate_user.successed', dismissible: true } });
                this.router.navigateByUrl('/home');
            },
            (error) => {
                this.working = false;
                this.handleApiError(error);
                this.resultMsg = "activate_user.failed" + "info_email" + ".";
            });
    }
}
