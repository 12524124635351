import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventService } from './event.service';
import { ApiService } from "./api.service";
import { EventType } from "../shared/types/base";
import GeocoderRequest = google.maps.GeocoderRequest;
import Geocoder = google.maps.Geocoder;

@Injectable({
  providedIn: 'root'
})
export class GeocoderService {
  private geocoder: Geocoder;
  private allowed = false;
  private location: google.maps.GeocoderAddressComponent;

  country = new BehaviorSubject<string | null>(null);
  countryCode = new BehaviorSubject<string | null>(null);
  onLoad = new BehaviorSubject(false);
  coordinates = new BehaviorSubject<Coordinates | null>(null);

  constructor(
    private eventService: EventService,
    private apiService: ApiService,
  ) {
  }

  init(): void {
    this.onLoad.next(true);

    if (this.location) {
      this.processAddressComponent(this.location);
      return;
    }

    if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
    navigator.geolocation.getCurrentPosition((res) => {
      this.onGeolocationSuccess(res);
    }, (e) => this.onGeolocationError(e));

    this.eventService.getObservable().subscribe(event => {
      if (event.name === 'data' && event.data.currentUser) {
        const user = this.apiService.getCurrentUser();
        if (user.profile.location) this.onGeocodeSuccess([user.profile.location]);
      }

      if (event.name === EventType.command && event.data.command === 'logout') {
        this.country.next('');
        this.countryCode.next('');
        if (this.allowed) this.init();
      }
    })
  }

  private onGeolocationSuccess(res: Position): void {
    this.allowed = true;
    this.coordinates.next(res.coords);
    const request: GeocoderRequest = {
      location: {
        lat: res.coords.latitude,
        lng: res.coords.longitude,
        }
      }
    this.geocoder.geocode(request, (res) => this.onGeocodeSuccess(res));
  }

  private onGeocodeSuccess(res: google.maps.GeocoderResult[]): void {
    const countryComponent = res.reduce((component: google.maps.GeocoderAddressComponent, result) => {
      const countryComponent = result.address_components.find(component => component.types.includes('country'));
      if (countryComponent) {
        return countryComponent;
      }
    }, null);
    if (countryComponent) this.processAddressComponent(countryComponent);
  }

  private onGeolocationError(e: PositionError): void {
    if (e.code === 1) {
      const user = this.apiService.getCurrentUser();
      if (user?.profile?.location) {
        this.onGeocodeSuccess([user.profile.location]);
      }
    }
  }

  private processAddressComponent(component: google.maps.GeocoderAddressComponent): void {
    if (component.types.includes('country')) {
      this.location = component;
      this.country.next(component.long_name);
      this.countryCode.next(component.short_name);
    }
  }
}
