import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from "../services/api.service";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: 'localeDate'
})
export class LocaleDatePipe implements PipeTransform {
  constructor(private apiService: ApiService, private translateService: TranslateService) {
  }

  transform(date?: string | Date, locale?: string ): string {
    if (!date) return '';
    if (typeof date === 'string') date = new Date(date);
    const loggedUser = this.apiService.getCurrentUser();
    if (!locale) locale = this.translateService.currentLang || loggedUser.lang || navigator.language;
    return date.toLocaleDateString(locale);
  }

}
