<div>
    <a [routerLink]="[]" (click)="activeModal.dismiss();" class="close icon-close" aria-label="Close">
        <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
    </a>
    <div class="modal-body deletion-dialog " style="padding: 0px; margin: 0px;">
      <div class="title h6" translate>delete_account.delete_user</div>

      <div class="h5 mx-4 my-5 text-center" translate>delete_account.deletion_warning</div>

      <div class="row mx-auto">
        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
          <button class="btn btn-primary btn-lg full-width"  (click)="activeModal.dismiss()" >
            {{ 'delete_account.cancel' | translate }}
          </button>
        </div>
        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
          <button class="btn btn-secondary btn-lg full-width"  (click)="deleteUser()" >
            <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{ 'delete_account.confirm' | translate }}
          </button>
        </div>
      </div>
    </div>
</div>
