import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Legend } from "../types/org-profile-legends.types";
import { LegendFormDialogComponent } from "../components/legend-form-dialog/legend-form-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class LegendsService {
  constructor(private modalService: NgbModal) { }

  private dialogOptions: NgbModalOptions = {
    size: 'lg',
    windowClass: 'legend-dialog'
  }

  openCreateProfileDialog(): NgbModalRef {
    const ref = this.modalService.open(LegendFormDialogComponent, this.dialogOptions);
    ref.componentInstance.mode = 'create';
    return ref;
  }

  showProfileDialog(legend: Legend, mode: 'readonly' | 'create' | 'admin' = 'readonly'): NgbModalRef {
    const ref = this.modalService.open(LegendFormDialogComponent, this.dialogOptions);
    ref.componentInstance.legend = legend;
    ref.componentInstance.modal = ref;
    ref.componentInstance.mode = mode;
    return ref;
  }
}
