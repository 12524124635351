<article class="photo-item">

  <div class="background">
    <img [src]="_previewUrl"
         [hidden]="loadState === 'failed'"
         (load)="onImgLoad()"
         (error)="onImgError()"
         loading="lazy" alt="feed-photo" class="photo-background"
    >
    <div class="background-loading" [ngStyle]="{display: loadState === 'pending' ? 'block' : 'none'}">
      <i class="loading-inner"></i>
    </div>
  </div>

  <div *ngIf="loadState === 'failed'" class="img-fail-content">
    <i class="far fa-exclamation-triangle d-block"></i>
    <span class="h2">{{ 'feeds.failed_label' | translate }}</span>
  </div>

  <ng-container *ngIf="loadState === 'loaded'">
    <div class="overlay overlay-dark"></div>
    <a role="button" (click)="openPhoto()" class="full-block"></a>
  </ng-container>

  <div class="content" style="padding-right: 44px;">
    <p class="title two-rows">{{feed.text}}</p>
    <time class="published">{{_addedText}}</time>
  </div>

  <div *ngIf="editable" class="more">
    <svg class="olymp-three-dots-icon">
      <use xlink:href="#olymp-three-dots-icon"></use>
    </svg>
    <ul class="more-dropdown more-with-triangle">
      <!-- <li>
          <a [routerLink]="" (click)="changeDescription(photo); $event.stopPropagation();"
              translate>profile.change_photo_description</a>
      </li> -->
      <li>
        <a role="button" class="c-secondary" (click)="removePhoto(); $event.stopPropagation();"
           translate>profile.remove_photo
        </a>
      </li>
    </ul>
  </div>

  <div *ngIf="!editable" class="feed-item-header cursor-pointer" (click)="openPhoto()">
    <div class="d-flex flex-column align-items-start feed-author">
      <span [ngStyle]="{visiblity: !feed.org ? 'hidden' : 'visible'}" class="feed-author-caption text-shadow">
        {{ _orgType }}
      </span>
      <app-photo-feed-author-widget [showInfo]="true" [nameFormat]="'firstName'" [feed]="feed"></app-photo-feed-author-widget>
    </div>

    <ng-container *ngIf="_hasDefaultOrg">
      <div class="d-flex flex-column align-items-end">
        <span class="feed-author-caption text-shadow" translate>{{ _userDefOrgType }}</span>
        <app-photo-feed-author-widget [showInfo]="true" [nameFormat]="'firstName'" [flow]="'row-reverse'" [feed]="{'org': _userDefOrg}"></app-photo-feed-author-widget>
      </div>
    </ng-container>
  </div>

  <a *ngIf="loadState === 'loaded'" role="button" (click)="likeFeed(feed)" class="feed-like inline-items" ngbTooltip="{{ 'favs.like' | translate }}">
    <svg *ngIf="!hasMyLike(feed)" class="olymp-heart-icon"><use xlink:href="#olymp-heart-icon"></use></svg>
    <i *ngIf="hasMyLike(feed)" class="liked fas fa-heart"></i>
    <span *ngIf="feed.likes">{{feed.likes.length}}</span>
    <span *ngIf="!feed.likes">0</span>
  </a>

</article>
