import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EnumService } from 'src/app/services/enum.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';
import { User } from '../../shared/types/profile';
import { Feed } from "../../shared/types/feed";

const FEEDS_CHUNK_LEN = 25;

@Component({
    selector: 'app-profile-feeds',
    templateUrl: './profile-feeds.component.html',
    styleUrls: ['./profile-feeds.component.css']
})
export class ProfileFeedsComponent extends BaseComponent implements OnInit {

    loggedUser?: User;
    _user: Partial<User> = null;
    @Input() set user(value) {
        this._user = value;
        if (!this.feeds || this.feeds.length == 0) {
            this.getFeeds(true);
        }
        this.superAdminEdit = this.loggedUser?.superAdmin && this._user._id !== this.loggedUser?._id;
    }
    get user(): User | null | any {
        return this._user;
    }

    _org = null;
    @Input() set org(value) {
        this._org = value;
        if (!this.feeds || this.feeds.length == 0) {
            this.getFeeds(true);
        }
    }
    get org(): any {
        return this._org;
    }

    @Input() editable: boolean = false;

    feedTags: any[] = [];
    feeds = [];

    currentPage = 0;
    hasMorePages: boolean = false;
    superAdminEdit: boolean = false;

    constructor(
        eventService: EventService,
        private enumService: EnumService,
        private apiService: ApiService
        ) {
        super(eventService);
    }

    ngOnInit(): void {
        this.loggedUser = this.apiService.getCurrentUser();
        this.getFeeds(true);
        this.feedTags = this.enumService.getFeedTags();
    }

    getAvatarUrl() {
        if (this.user) {
            return ApiService.getPhotoUrl(this.user?.avatar?.avatar?.medium, Utils.getAvatarMedium());
        }

        if (this.org) {
            return ApiService.getPhotoUrl(this.org?.avatar?.avatar?.medium, Utils.getLogoMedium());
        }
    }

    getFeeds(fromStart: boolean = false) {
        if (fromStart)
            this.currentPage = 0;

        let params = {
            start: this.currentPage * FEEDS_CHUNK_LEN,
            len: FEEDS_CHUNK_LEN
        };

        if (this.user) {
            this.apiService.getUserFeeds(this.user._id, params).subscribe(
                (result) => this.feedsReceived(result.body["feeds"], fromStart),
                (error) => this.handleApiError(error) );
        }
        else if (this.org) {
            this.apiService.getOrgFeeds(this.org._id, params).subscribe(
                (result) => this.feedsReceived(result.body["feeds"], fromStart),
                (error) => this.handleApiError(error) );
        }
    }

    feedsReceived(feeds: Array<Feed>, fromStart) {
        this.hasMorePages = (feeds.length == FEEDS_CHUNK_LEN);
        feeds.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());

        if (fromStart) {
            this.feeds = feeds;
        }
        else {
            this.feeds = this.feeds.concat(feeds);
        }
    }

    loadMore() {
        this.currentPage++;
        this.getFeeds();
    }

    removeFeed($event) {
        if ($event?.feed?._id) {

            if (this.user) {
                this.apiService.removeUserFeed($event?.feed?._id).subscribe(
                    (result) => {
                        this.getFeeds(true);
                    },
                    (error) => this.handleApiError(error) );
            }
            else if (this.org) {
                this.apiService.removeOrgFeed(this.org._id, $event?.feed?._id).subscribe(
                    (result) => {
                        this.getFeeds(true);
                    },
                    (error) => this.handleApiError(error) );
            }
        }
    }
}
