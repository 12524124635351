import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '../../shared/types/menu';
import { Utils } from "../../utils";

@Component({
    selector: 'app-profile-settings',
    templateUrl: './profile-settings.component.html',
    styleUrls: ['./profile-settings.component.css']
})
export class ProfileSettingsComponent implements OnInit {

    @Input() set superAdminEdit(val: boolean) {
      this._superAdminEdit = Utils.isNullish(val) ? false : val;
      this.updateTabItems();
    };
    @Input() user: any = null;
    @Input() set setting(val: string) {
      this._setting = val;
      this.updateTabItems();
    };

    _setting: string = 'edit-profile';
    _superAdminEdit = false;
    tabItems: MenuItem[] = [];

    constructor() {}

    ngOnInit(): void {
      this.updateTabItems();
    }

    onTabChange(item: MenuItem): void {
        if (!item.props?.navName) return;
        this.setting = item.props.navName;
    }

    private updateTabItems(): void {
      this.tabItems = [
        {label: 'profile.personal_info', visible: true, active: () => this._setting === 'edit-profile', props: {navName: 'edit-profile'}},
        {label: 'profile.career', visible: true, active: () => this._setting === 'edit-profile-career', props: {navName: 'edit-profile-career'}},
        {label: 'profile.account_settings', visible: !this._superAdminEdit, active: () => this._setting === 'edit-profile-settings' || this._setting === 'edit-profile-password', props: {navName: 'edit-profile-settings'}},
      ];
    }

}
