import { NotificationsConfig } from "./notifications";

export enum OrganizationType {
	sport_club = 'sport_club',
	fan_club = 'fan_club',
	firm = 'firm',
	sport_facility = 'sport_facility',
  hall_of_fame = 'hall_of_fame',
  rescue_service = 'rescue_service'
}

export type User = {
  _id: string;
  activationCode: string;
  allowMassMessages: boolean;
  firebaseToken?: string; // Allow null for empty firebaseToken
	suggestedBy: User; // Reference to another User object
  email: string;
  active: boolean;
  created: string;
  profile: UserProfileType;
  superAdmin: boolean;
  firebaseTokens: string[];
  lang: string;
  favs: UserFavs[];
  adminOrgs: UserAdminOrgs[];
  photos: UserPhotos[];
  avatar: UserAvatar;
  deleted: boolean;
  organizations: UserOrganizations[];
  notificationsConfig: NotificationsConfig;
  likedLegends: Array<string>;
  verified: boolean;
  referenceId: string;
  donationsUrl?: string;
  [key: string]: any;
}

export type UserProfile = Partial<UserProfileType>;

type UserProfileType = {
	birthdate: string; // ISO 8601 date-time format
	defSport: string; // Likely represents an ID or code for the default sport
	facebook: string; // Empty string for no Facebook profile
	twitter: string; // Empty string for no Facebook profile
	firstname: string;
	gender: "male" | "female" | string; // Allows for additional gender identities
	instagram: string; // Enforces a valid URL format
	langs: string[]; // Empty array for no languages
	lastname: string;
	linkedin: string;
	occupation: string;
	sports: string[]; // Empty array for no sports listed
	tags: string[] | any;
	tiktok: string;// Empty array for no tags
	weight: string;
	height: string;
	youtube: string;
	phoneNumber: string;
	phoneNumberPublic: boolean;
	emailPublic: boolean;
	hidden: boolean;
  info: string,
  country: string,
	[key: string]: any;
}

export interface UserFavs {
  added: string;
  feedId: string;
  [key: string]: any;
}
export interface UserAdminOrgs {
  id: string;
  role: string;
  [key: string]: any;
}
export interface UserPhotos {
  id: string;
  name: string;
  url: string;
  previewUrl: string;
  mimeType: string;
  size: number;
  added: string;
  description: string;
  [key: string]: any;
}
export interface UserAvatarCover {
  id: string;
  name: string;
  url: string;
  previewUrl: string;
  mimeType: string;
  size: number;
  added: string;
  description: string;
  [key: string]: any;
}
export interface UserAvatarAvatar {
  id: string;
  name: string;
  url: string;
  previewUrl: string;
  mimeType: string;
  size: number;
  added: string;
  description: string;
  small: string;
  medium: string;
  [key: string]: any;
}
export interface UserAvatar {
  cover: UserAvatarCover;
  avatar: UserAvatarAvatar;
  [key: string]: any;
}
export interface UserOrganizations {
  orgId: string;
  active: boolean;
  [key: string]: any;
}
