import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { Utils } from 'src/app/utils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from 'src/app/services/event.service';
import { BaseComponent } from 'src/app/base/base.component';
import { BaseEvent, EventType } from '../../shared/types/base';
import { Feed } from "../../shared/types/feed";

@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.css']
})
export class FeedComponent extends BaseComponent implements OnInit {

    _feed = null;
    _photosLength = null;
    @Input() set feed(value: Feed) {
        this._feed = value;

        if (this._feed) {
            if (this.feed.type === 'video') {
                this.youtubeUri = this.dom.bypassSecurityTrustResourceUrl(Utils.getYoutubeUrl(this.feed.youtubeLink));
            }

            let currentUser = this.apiService.getCurrentUser();
            this.isMyFav = currentUser?.favs?.some(f => f.feedId == this.feed._id) ?? false;
            this.hasMyLike = this.feed.likes?.some(l => l == currentUser?._id) ?? false;
            this.commentsLen = this.feed.comments?.filter(c => !c.responseTo)?.length ?? 0;
        }
    }
    get feed(): Feed {
        return this._feed;
    }

    @Input() user = null;
    @Input() org = null;
    @Input() allowEdit: boolean = false;

    @Output() remove = new EventEmitter();

    youtubeUri: SafeResourceUrl = null;
    isMyFav: boolean = false;
    hasMyLike: boolean = false;
    commentsLen = 0;
    openReplyComment: string = "";
    allowComment: boolean = false;

    _commentsCollapsed: boolean = true;
    set commentsCollapsed(value: boolean) {
        this._commentsCollapsed = value;

        if (!value) {
            this.getFullFeed();
        }
    }
    get commentsCollapsed(): boolean { return this._commentsCollapsed; }

    newComment: string = "";

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        private translateService: TranslateService,
        private datePipe: DatePipe,
        private dom: DomSanitizer,
        private modalService: NgbModal) {
            super(eventService);
        }

    ngOnInit(): void {
        if (this.apiService.getCurrentUser()) {
          this.allowComment = true;
        }
        this._photosLength = (this._feed.additionalPhotos ?? []).length + 1;
    }

    // TODO CHECK IF EXISTS userLoggedIn
    eventReceived(event: BaseEvent): void {
        if (event.name === EventType.data && event.data.userLoggedIn) {
            this.allowComment = event["userLoggedIn"];
        }
    }

    getProfileUrl() {

        if (this.user) {
            return `/profile/${this.user?._id}`;
        }
        else {
            return `/org-profile/${this.org?._id}`;
        }
    }
    getAvatarUrl() {
        if (this.user) {
            return ApiService.getPhotoUrl(this.user?.avatar?.avatar?.medium, Utils.getAvatarMedium());
        }
        else {
            return ApiService.getPhotoUrl(this.org?.avatar?.avatar?.medium, Utils.getLogoMedium());
        }
    }

    getFeedPhotoUrl(photo) {
        return ApiService.getPhotoUrl(photo?.previewUrl, photo?.url);
    }

    getName() {
        if (this.user) {
            return `${this.user.profile?.firstname} ${this.user.profile?.lastname}`;
        }
        else {
            return this.org?.profile?.name;
        }
    }

    getDate() {
        return Utils.getHumanDate(this.feed?.updated, this.datePipe, this.translateService);
    }

    removeIt() {
        this.remove.emit( { "feed": this.feed, "user": this.user, "org": this.org } );
    }

    openPhoto(index = 0) {
        if (this.feed?.photo?.url) {
            ImageViewerComponent.open(this.modalService, { "singleFeed": this.feed, "openIndex": index });
        }
    }

    getFullFeed() {
        if (!this.feed?._id) {
            return;
        }

        this.apiService.getFullFeed( this.feed._id ).subscribe(
            (result) => {
                this.feed = result.body["feed"];
            },
            (error) => this.handleApiError(error) );
    }

    addComment() {
        if (!this.newComment || !this.feed) {
            return;
        }

        this.apiService.commentFeed(this.feed._id, this.newComment).subscribe(
            (result) => {
                this.newComment = "";
                this.getFullFeed();
            },
            (error) => this.handleApiError(error) );
    }

    addEmoji($event) {
        this.newComment = `${this.newComment}${$event.emoji.native}`;
    }

    replyComment($event) {
        if (!$event?.responseTo || !$event?.text) {
            return;
        }

        this.apiService.commentFeedComment(this.feed._id, $event?.responseTo, $event?.text).subscribe(
            (result) => {
                this.getFullFeed();
                this.openReplyComment = $event?.responseTo;
            },
            (error) => this.handleApiError(error) );
    }

    addFav() {
        if (!this.feed?._id) {
            return;
        }

        let currentUser = this.apiService.getCurrentUser();
        if (!currentUser) {
            this.sendEvent( { name: EventType.alert, data: { type: 'warning', message: 'favs.login_to_fav', dismissible: true } });
            return;
        }

        let fav = { "feedId": this.feed._id }

        if (!this.isMyFav) {
            this.apiService.addFavorite(fav).subscribe(
                (result) => { this.getFullFeed(); },
                (error) => this.handleApiError(error) );
        }
        else {
            this.apiService.removeFavorite(fav).subscribe(
                (result) => { this.getFullFeed(); },
                (error) => this.handleApiError(error) );
        }
    }

    likeFeed() {
        if (!this.feed?._id) {
            return;
        }

        let currentUser = this.apiService.getCurrentUser();
        if (!currentUser) {
            this.sendEvent( { name: EventType.alert, data: { type: 'warning', message: 'favs.login_to_like', dismissible: true } });
            return;
        }

        if (!this.hasMyLike) {
            this.apiService.likeFeed(this.feed._id).subscribe(
                (result) => { this.getFullFeed(); },
                (error) => this.handleApiError(error) );
        }
        else {
            this.apiService.removeFeedLike(this.feed._id).subscribe(
                (result) => { this.getFullFeed(); },
                (error) => this.handleApiError(error) );
        }
    }

    hasAttachment(): boolean {
      return this._feed.type == 'photo' || this._feed.type == 'video';
    }

    getFeedText(): string {
      if (this._feed.type == 'video') {
        const regExp = /^https?:\/\/(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)[^\s]+/;
        return this._feed.text.replace(regExp, '')
      } else {
        return this._feed.text;
      }
    }
}
