<ng-container *ngIf="user">
  <div class="ui-block">
    <div class="ui-block-content flex-column gap-05">
      <ng-content select="[before]"></ng-content>

      <div class="d-flex flex-grow-1 align-items-center gap-1">
        <a class="position-relative" [routerLink]="['/profile', user?._id, 'view-profile']">
          <img *ngIf="avatarUrl" [src]="avatarUrl" loading="lazy" class="avatar-img shadow-sm">
          <app-verified-badge *ngIf="_isVerified"></app-verified-badge>
        </a>
        <div class="d-flex flex-column">
          <a [routerLink]="['/profile', user?._id, 'view-profile']"  class="h5 m-0">{{ user.profile.firstname }} {{ user.profile.lastname }}</a>
          <span *ngIf="isSuperAdmin" class="h6 m-0">
          <span class="mr-2">{{ 'profile.org_owner' | translate }}</span>
          <i class="fa fa-crown"></i>
        </span>
          <span *ngIf="member && !isSuperAdmin" class="h6 m-0">{{ member.position }}</span>
        </div>
      </div>

      <div class="user-content">
        <div *ngIf="showRegularActions" class="d-flex justify-content-center gap-05">
          <app-message-options *ngIf="!isMe" [user]="user"></app-message-options>
          <app-user-invitation-options *ngIf="!isMe && showInvitationOptions" [user]="user"></app-user-invitation-options>
          <app-fav-button *ngIf="!isMe" [user]="user" ></app-fav-button>
        </div>

        <ng-content select="[content]"></ng-content>

        <div *ngIf="showAdminActions" [(ngbCollapse)]="collapseActions" id="actions-panel">
          <ng-content select="[actionsContent]"></ng-content>
        </div>

        <button *ngIf="showAdminActions" type="button" class="btn btn-link full-width m-0" (click)="collapseActions = !collapseActions">
          <i class="fa mr-2" [ngClass]="collapseActions ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
          <span>{{ 'profile.manage_member' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>

