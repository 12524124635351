import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';

const FEEDS_CHUNK_LEN = 25;

@Component({
    selector: 'app-home-feeds',
    templateUrl: './home-feeds.component.html',
    styleUrls: ['./home-feeds.component.css']
})
export class HomeFeedsComponent extends BaseComponent implements OnInit, OnDestroy {

    currentPage = 0;
    hasMorePages: boolean = false;
    feeds = [];

    working: boolean = false;

    _filter = { countries: [], sports: [], feedTypes: [], tags: [], superAdminFilter: null };
    @Input() set filter(value) {

        let reloadData =
            this._filter.countries != value.countries
            || this._filter.sports != value.sports
            || this._filter.tags != value.tags
            || this._filter.feedTypes != value.feedTypes
            || this._filter.superAdminFilter != value.superAdminFilter;

        this._filter = value;
        if (reloadData && this.initialized)
            this.getFeeds();
    }
    get filter() { return this._filter; }

    @Input() superAdmin: boolean = false;

    constructor(
        eventService: EventService,
        private apiService: ApiService) {
        super(eventService);
    }

    initialized: boolean = false;
    ngOnInit(): void {
        this.getFeeds();
        this.initialized = true;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this._filter.tags = [];
    }

    getFeeds(fromStart = true) {

        if (fromStart) {
            this.currentPage = 0;
        }

        let params = {
            start: this.currentPage * FEEDS_CHUNK_LEN,
            len: FEEDS_CHUNK_LEN,
            album: false
        };

        if (this.filter?.countries) {
            params["country"] = this.filter.countries.join(",");
        }
        if (this.filter?.sports) {
            params["sport"] = this.filter.sports.join(",");
        }

        // Show only text feeds
        //params["types"] = "text";
        // if (this.filter?.feedTypes && this.filter.feedTypes.length > 0) {
        //     params["types"] = this.filter.feedTypes.join(",");
        // }

        if (this.superAdmin) {
            params["sa_all"] = true;

            if (this.filter?.feedTypes && this.filter.feedTypes.length > 0) {
                params["types"] = this.filter.feedTypes.join(",");
            }
            else if (params["types"]) {
                delete params["types"];
            }

            if (this.filter.superAdminFilter) {
                let active = this.filter.superAdminFilter["active"];
                if (active) {
                    params["blocked"] = active == 'false';
                }
            }
        }
        else {
            params["blocked"] = false;
        }

        if (this.filter?.tags && this.filter.tags.length > 0) {
            params["tags"] = this.filter.tags.join(",");
        }

        this.working = true;
        this.apiService.searchFeeds(params).subscribe(
            (result) => {
                this.working = false;

                let feeds = result.body["feeds"] ?? [];
                this.hasMorePages = (feeds.length == FEEDS_CHUNK_LEN);

                if (fromStart) {
                    this.feeds = feeds;
                } else {
                    this.feeds = this.feeds.concat(feeds);
                }
            },
            (error) => { this.handleApiError(error); this.working = false; });
    }

    blockFeed(feed: any, block: boolean = true) {
        this.working = true;
        this.apiService.blockFeed(feed._id, block).subscribe(
            (result) => {
                this.working = false;
                feed.blocked = block;
            });
    }

    loadMore() {
        this.currentPage++;
        this.getFeeds(false);
    }
}
