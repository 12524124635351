<div class="flex">
  <div class="col col-12 col-md-6 offset-md-3">
    <app-register-user (registerSuccess)="onRegisterSuccess()" [showCloseIcon]="false" [referenceId]="referenceId">
      <div class="d-flex flex-column" endContent>
        <ng-container *ngIf="invitedByUser; else loading">
          <span class="h6">{{ 'register_user.invited_by' | translate }}</span>
          <app-user-widget-slim
            *ngIf="invitedByUser"
            [user]="invitedByUser"
            [showRegularActions]="false"
          ></app-user-widget-slim>
        </ng-container>
        <ng-template #loading>
          <div class="d-flex flex-grow-1 justify-content-center">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>
        </ng-template>
      </div>
    </app-register-user>
  </div>
</div>
