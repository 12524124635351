import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';
import { User } from "../../shared/types/profile";
import { BaseEvent, EventType } from "../../shared/types/base";

@Component({
    selector: 'app-quick-search',
    templateUrl: './quick-search.component.html',
    styleUrls: ['./quick-search.component.css']
})
export class QuickSearchComponent extends BaseComponent implements OnInit {

    searchForm: FormGroup;
    filteredResults: any[];
    isLoading: boolean = false;
    loggedUser?: User;

    constructor(
        private formBuilder: FormBuilder,
        eventService: EventService,
        private apiService: ApiService,
        private router: Router
    ) {
        super(eventService);
    }

  protected eventReceived(event: BaseEvent): void {
    if (event.name === EventType.data) {
      event.data.currentUser ? this.loggedUser = event.data.currentUser : this.clearSearch();
    }
  }

  ngOnInit(): void {
      this.loggedUser = this.apiService.getCurrentUser();
      this.searchForm = this.formBuilder.group({
          searchInput: null
      });

      this.searchForm.get('searchInput').valueChanges
        .pipe(
          debounceTime(300),
          tap(() => this.isLoading = true),
          switchMap(value => {
            const params = {
              "search": value
            }

            if (this.loggedUser?.superAdmin) {
              params['sa_all'] = true;
            }

            return this.apiService.quicksearch(params)
              .pipe(finalize(() => this.isLoading = false))
          })
        )
        .subscribe(result => this.filteredResults = result.body.result);
    }

    displayFn(result) {
          if (result) {
              return result.name;
          }
      }

    getAvatar(result) {
        let defLogo = result?.type == 'org' ? Utils.getLogo() : Utils.getAvatarSmall();
        return ApiService.getPhotoUrl(result?.avatar, defLogo);
    }

    selectResult(result) {
      this.clearSearch();
      if (result?.type == 'user') {
            this.router.navigateByUrl(`/profile/${result._id}/view-profile`);
        }
        else if (result?.type == 'org') {
            this.router.navigateByUrl(`/org-profile/${result._id}/view-profile`);
        }
        else if (result?.type == 'feed') {
            this.router.navigate(['/search'], {queryParams: { feedId: result._id, onlyFeeds: true }});
        }
    }

    search(): void {
        let searchTerm = this.searchForm.get('searchInput').value;
        const params = {term: searchTerm};
        this.searchForm.get('searchInput').setValue('');
        this.router.navigate(['/search'], {queryParams: params});
    }

    clearSearch(): void {
      this.searchForm.get('searchInput').setValue('');
    }
}
