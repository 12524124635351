import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from "ngx-image-cropper";
import { Utils } from "../../utils";

@Component({
    selector: 'app-photo-picker',
    templateUrl: './photo-picker.component.html',
    styleUrls: ['./photo-picker.component.css']
})
export class PhotoPickerComponent implements OnInit {

    @ViewChild("photo_file_input") private photoFileInput: ElementRef<HTMLInputElement>;

    @Input() multiple: boolean = false;
    @Input() working: boolean = false;
    @Input() showButtons: boolean = true;
    @Input() croppingMode: 'profile-picture' | 'title-photo' | 'none' = 'none';
    @Output() photoPreviewChanged = new EventEmitter<{files: File[]}>();
    @Output() photoPicked = new EventEmitter();
    @Output() cancelled = new EventEmitter();

    pickedFiles: File[] = [];
    fileDataUrls: string[] = []
    showPreview = false;
    _loadingPreview = false;
    cropImage: File;
    cropEvent?: ImageCroppedEvent;

    constructor() { }

    ngOnInit(): void {}

    openPicker(): void {
        if (this._loadingPreview) return;
        this.photoFileInput?.nativeElement?.click();
    }

    ok(): void {
        this.photoPicked.emit({ files: this.croppingMode !== 'none' ? [Utils.base64ToFile(this.cropEvent.base64, this.pickedFiles[0].name)] : this.pickedFiles });
    }

    async handleFile(e: Event): Promise<void> {
        if (!e) return;
        const inputFileEl = e.target as HTMLInputElement;
        const inputFiles = inputFileEl.files;

        if (inputFiles?.length === 0) {
            this.clearFiles();
            return;
        }

        this._loadingPreview = true;

        if (this.multiple) {
            const files = Array.from(inputFiles);
            const compressedFiles: File[] = [];
            const compressPromises = files.map(file => Utils.compressFile(file));
            await Promise.all(compressPromises).then(res => compressedFiles.push(...res));
            this.pickedFiles.push(...compressedFiles);
            await this.updatePhotoPreview(files);
        }
        else {
            this.fileDataUrls = [];
            const compressedFile = await Utils.compressFile(inputFileEl.files[0]);
            this.cropImage = compressedFile;
            this.pickedFiles = [compressedFile];
            await this.updatePhotoPreview(this.pickedFiles);
        }
        this.photoPreviewChanged.emit({ files: this.pickedFiles });
        inputFileEl.value = null;
        this._loadingPreview = false;
    }

    async updatePhotoPreview(files: File[]): Promise<void> {
        this.showPreview = false; // hide the preview when updating

        if (!files || files.length === 0) {
          return;
        }

        const promises: Promise<string>[] = [];
        files.forEach(file => {
          const promise = new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              if (typeof reader.result === 'string') resolve(reader.result);
            };
            reader.readAsDataURL(file);
          });
          promises.push(promise);
        });

        const dataUrls = await Promise.all(promises);
        this.fileDataUrls.push(...dataUrls);
        this.showPreview = true;
    }

    clearFiles(): void {
        this.fileDataUrls = [];
        this.pickedFiles = [];
        this.cropEvent = undefined;
        this.cropImage = undefined;
        this.photoPreviewChanged.emit({ files: this.pickedFiles })
    }

    cancel(): void {
        this.showPreview = false;
        this.photoFileInput.nativeElement.value = "";
        this.clearFiles();
        this.cancelled.emit();
    }

    removeFile(i: number, event: Event): void {
        if (event) {
            event.preventDefault()
            event.stopPropagation();
        }
        this.pickedFiles.splice(i, 1);
        this.fileDataUrls.splice(i, 1);

        if (this.pickedFiles.length === 0) this.showPreview = false;

        this.photoPreviewChanged.emit({ files: this.pickedFiles });
    }

  onImageCropped(event: ImageCroppedEvent): void {
      if (!event?.base64) return;

      this.cropEvent = event;
      this.photoPreviewChanged.emit({ files: [Utils.base64ToFile(event.base64, this.pickedFiles[0].name)] });
  }
}
