<div class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>favs.favourites</h6>
        <a *ngIf="user" style="text-align: right;" [routerLink]="['/profile/' + user._id + '/favs']" translate>view_all</a>
    </div>
    
    <ul class="widget w-friend-pages-added notification-list friend-requests">

        <li *ngFor="let fav of favs" class="inline-items">
            <div class="author-thumb">
                <img loading="lazy" class="img-author" src="{{fav.avatar}}" alt="author">
            </div>
            <div class="notification-event">
                <a [routerLink]="[fav.profileUrl]" class="h6 notification-friend">{{fav.name}}</a>
                <span *ngIf="fav.country" class="chat-message-item" translate>countries.{{fav.country}}</span>
            </div>    
        </li>

    </ul>

</div>