<div *ngIf="org" class="ui-block">

    <div class="friend-item fav-page">
        <div [routerLink]="['/org-profile/' + org._id]" class="friend-header-thumb img-cover-bgr cursor-pointer" [ngStyle]="{'background-image': 'url(' + getCoverUrl() + ')' }" ></div>

        <div class="friend-item-content">

            <div [routerLink]="['/org-profile/' + org._id]" class="friend-avatar cursor-pointer">
                <div class="author-thumb">
                    <a>
                        <img class="img-logo" loading="lazy" src="{{getLogoUrl(org)}}" alt="author">
                        <app-verified-badge *ngIf="_isVerified"></app-verified-badge>
                    </a>
                </div>
                <div class="author-content">
                    <a class="h5 author-name">{{org.profile?.name}}</a>
                    <div *ngIf="org.profile?.country" class="country" translate>countries.{{org.profile.country}}</div>
                    <div *ngIf="!org.profile?.country" class="country">&nbsp;</div>
                </div>

            </div>

            <div class="friend-count">
                <ng-content></ng-content>
            </div>

            <div class="control-block-button" data-swiper-parallax="-100">
                <app-message-options [org]="org"></app-message-options>
                <app-recommend-button [org]="org"></app-recommend-button>
                <app-fav-button [org]="org"></app-fav-button>
            </div>

            <!-- <div class="friend-count" data-swiper-parallax="-500">
                <a class="friend-count-item">
                    <div class="h6">{{!org.photos ? 0 : org.photos.length}}</div>
                    <div class="title" translate>admin.photos</div>
                </a>
                <a class="friend-count-item">
                    <div class="h6">{{!org.members ? 0 : org.members.length}}</div>
                    <div class="title" translate>admin.members</div>
                </a>

            </div> -->
        </div>

    </div>
</div>
