<div class="" >
    <a [routerLink]="" (click)="activeModal.dismiss()" class="close icon-close"  aria-label="Close">
        <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
    </a>
    <div class="modal-body registration-login-form" style="padding: 0px; margin: 0px;">
        <div class="title h6" translate>login.header</div>
        <form class="content" [formGroup]="loginForm" (ngSubmit)="loginUser(loginForm.value)">
            <fieldset [disabled]="working">
                <div class="row">
                    <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <mat-form-field appearance="outline" >
                            <mat-label translate>login.email</mat-label>
                            <input matInput type="email" formControlName="email">
                        </mat-form-field>

                        <mat-form-field appearance="outline" >
                            <mat-label translate>login.password</mat-label>
                            <input matInput [type]="passwordHide ? 'password' : 'text'" formControlName="password">
                            <mat-icon matSuffix style="cursor: pointer;" (click)="passwordHide = !passwordHide">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>

                        <div class="remember" style="padding-bottom: 16px;">
                            <a [routerLink]="" class="forgot" (click)="openRestoreDialog()" translate>login.forgot_psw</a>
                        </div>

                        <app-alert [alert]="alert"></app-alert>
                        <button type="submit" class="btn btn-lg btn-primary full-width" [disabled]="!loginForm.valid" >
                            <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ 'login.btn' | translate }}
                        </button>

                        <div class="register-user-link">
                            <span style="margin-right: 6px;" translate>login.register_user_info</span>
                            <a [routerLink]="" class="btn btn-sm btn-primary" style="margin: auto;" (click)="openRegisterDialog()" translate>login.register_user</a>
                        </div>

                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>