import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { PhotoPickerComponent } from '../photo-picker/photo-picker.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { Utils } from 'src/app/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventType } from '../../shared/types/base';

const PHOTOS_CHUNK_LEN = 25;

@Component({
    selector: 'app-dialog-change-photo-desc',
    templateUrl: './photo-desc-dialog.html',
    styleUrls: ['./profile-photos.component.css']
})
export class ChangePhotoDescDialog implements OnInit {

    @Input() desc;
    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    save() {
        this.activeModal.close({ "result": "ok", "desc": this.desc });
    }

    cancel() {
        this.activeModal.close({ "result": "cancel", "desc": this.desc });
    }
}

@Component({
    selector: 'app-profile-photos',
    templateUrl: './profile-photos.component.html',
    styleUrls: ['./profile-photos.component.css']
})
export class ProfilePhotosComponent extends BaseComponent implements OnInit {

    _user: any = null;
    get user(): any {
        return this._user;
    }
    @Input() set user(value: any) {
        this._user = value;

        this.allowEdit = (this._user && this._user?._id == this.apiService.getCurrentUser()?._id);
        this.getPhotoFeeds(true);
    }

    _org: any = null;
    get org(): any {
        return this._org;
    }
    @Input() set org(value: any) {
        this._org = value;

        this.allowEdit = this.apiService.isCurrentUserAdmin(this._org?._id);
        this.getPhotoFeeds(true);
    }

    working: boolean = false;
    allowEdit: boolean = false;
    feeds = null;

    addPhotosCollapsed = true;
    newPhotoDesc: string = "";

    currentPage = 0;
    hasMorePages: boolean = false;

    @ViewChild("photo_picker") private photoPicker: PhotoPickerComponent;

    constructor(
        eventService: EventService,
        private translateService: TranslateService,
        private apiService: ApiService,
        private modalService: NgbModal,
        private snackBar: MatSnackBar,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private _document) {
        super(eventService);
    }

    ngOnInit(): void {
    }

    getPhotoUrl(photo) {
        return ApiService.getPhotoUrl(photo?.url);
    }

    getPreviewUrl(photo) {
        return ApiService.getPhotoUrl(photo?.previewUrl, photo?.url);
    }

    getPreviewDivStyle(photo) {
        let url = this.getPreviewUrl(photo);
        return { "background-image": `url(${url})` };
    }

    getAddedText(date) {
        return Utils.getAddedText(date, this.translateService);
    }

    hasMyLike(feed) {
        let currentUser = this.apiService.getCurrentUser();
        return feed?.likes?.some(l => l == currentUser?._id) ?? false;
    }

    getPhotoFeeds(fromStart: boolean = false) {
        if (fromStart)
            this.currentPage = 0;

        let params = {
            start: this.currentPage * PHOTOS_CHUNK_LEN,
            len: PHOTOS_CHUNK_LEN,
            "type": "photo"
        };

        if (this.user) {
            this.apiService.getUserFeeds(this.user._id, params).subscribe(
                (result) => this.feedsReceived(result.body["feeds"], fromStart),
                (error) => this.handleApiError(error) );
        }
        else if (this.org) {
            this.apiService.getOrgFeeds(this.org._id, params).subscribe(
                (result) => this.feedsReceived(result.body["feeds"], fromStart),
                (error) => this.handleApiError(error) );
        }
    }

    feedsReceived(feeds, fromStart) {
        this.hasMorePages = (feeds.length == PHOTOS_CHUNK_LEN);

        if (fromStart) {
            this.feeds = feeds;
        }
        else {
            this.feeds = this.feeds.concat(feeds);
        }
    }

    loadMore() {
        this.currentPage++;
        this.getPhotoFeeds();
    }

    addPhotos($event) {
        if (!("files" in $event) || $event?.files?.length == 0) {
            return;
        }

        const formData = new FormData();
        $event.files.forEach(file => { formData.append(file.name, file, file.name); });
        formData.append("description", this.newPhotoDesc);
        formData.append('album', 'true');

        this.setWorking(true, "progress_uploading_photo");

        if (this.org?._id) {
            this.apiService.uploadOrgPhotos(this.org._id, formData).subscribe(
                () => {
                    this.setWorking(false);
                    this.photoPicker.cancel();
                    this.photoPicker.showPreview = false;
                    this.newPhotoDesc = "";
                    this.sendUpdateProfileEvent();
                },
                (error) => { this.setWorking(false); this.handleApiError(error); });
        } else {
            this.apiService.uploadUserPhotos(formData).subscribe(
                () => {
                    this.setWorking(false);
                    this.photoPicker.cancel();
                    this.photoPicker.showPreview = false;
                    this.sendUpdateProfileEvent();
                },
                (error) => { this.setWorking(false); this.handleApiError(error); });
        }
    }

    setWorking(working: boolean, message: string = "") {
        this.working = working;

        if (working) {
            let msg = this.translateService.instant(message);
            this.snackBar?.open(msg, null, { duration: 0 });
        }
        else {
            this.snackBar?.dismiss();
        }
    }

    likeFeed(feed) {
        if (!feed?._id) {
            return;
        }

        let currentUser = this.apiService.getCurrentUser();
        if (!currentUser) {
            this.sendEvent( { name: EventType.alert, data: { type: 'warning', message: 'favs.login_to_like', dismissible: true } });
            return;
        }

        let hasMyLike = feed.likes?.some(l => l == currentUser?._id) ?? false;
        if (!hasMyLike) {
            this.apiService.likeFeed(feed._id).subscribe(
                (result) => {
                    if (!feed.likes) {
                        feed.likes = [];
                    }
                    feed.likes.push(currentUser._id);
                 },
                (error) => this.handleApiError(error) );
        }
        else {
            this.apiService.removeFeedLike(feed._id).subscribe(
                (result) => {
                    let idx = feed.likes?.findIndex(l => l == currentUser?._id) ?? -1;
                    if (idx > -1) {
                        feed.likes.splice(idx, 1);
                    }
                },
                (error) => this.handleApiError(error) );
        }
    }

    onOpenFeed(feed: any): void {
      if (!feed) return;
      const index = this.feeds.indexOf(feed);
      ImageViewerComponent.open(this.modalService, { "feeds": this.feeds, "openIndex": index, "user": this.user, "org": this.org });
    }

    removePhotoFeed(feed) {
        if (!feed)
            return;

        if (this.org?._id) {
            this.apiService.removeOrgFeed(this.org._id, feed._id).subscribe(
                (result) => {
                    this.sendUpdateProfileEvent();
                },
                (error) => this.handleApiError(error));
        } else {
            this.apiService.removeUserFeed(feed._id).subscribe(
                (result) => {
                    this.sendUpdateProfileEvent();
                    this.getPhotoFeeds(true);
                },
                (error) => this.handleApiError(error));
        }
    }

    changeDescription(photo) {
        if (!photo?.id) {
            return;
        }
        let desc = photo?.description ?? "";

        let modalRef = this.modalService.open(ChangePhotoDescDialog);
        modalRef.componentInstance.desc = desc;

        modalRef.result.then((result) => {
            if (result?.result == 'ok') {
                this.changePhotoDesc(photo.id, result.desc);
            }
          });
    }

    changePhotoDesc(photoId, desc) {
        if (this.org?._id) {
            this.apiService.changeOrgPhotoDesc(this.org._id, photoId, desc).subscribe(
                (result) => {
                    this.sendUpdateProfileEvent();
                },
                (error) => this.handleApiError(error));
        } else {
            this.apiService.changeUserPhotoDesc(photoId, desc).subscribe(
                (result) => {
                    this.sendUpdateProfileEvent();
                },
                (error) => this.handleApiError(error));
        }
    }

    private sendUpdateProfileEvent(): void {
        this.sendEvent({ name: EventType.update, data: { update: 'profile' } });
    }
}
