import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../base/base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { EventService } from "../services/event.service";
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { User } from "../shared/types/profile";
import { ApiService } from "../services/api.service";
import { first } from "rxjs/operators";
import {RegisterUserComponent} from '../components/register-user/register-user.component';
import {EventType} from '../shared/types/base';

@Component({
  selector: 'app-register-referral-page',
  templateUrl: './register-referral-page.component.html',
  styleUrls: ['./register-referral-page.component.css'],
  providers: [NgbActiveModal]
})
export class RegisterReferralPageComponent extends BaseComponent implements OnInit {
  invitedByUser: User;
  referenceId: string;
  inviteUserId: string;

  constructor(
    protected eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
  ) {
    super(eventService);
  }

  async ngOnInit(): Promise<void> {
    if (this.apiService.getCurrentUser()) {
      await this.router.navigate(['home']);
      return ;
    }

    this.route.queryParams.subscribe(async (params): Promise<void> => {
      if (params.referenceId) {
        this.referenceId = params.referenceId;
        this.loadInvitedByUser(params.referenceId);
      } else {
        await this.router.navigate(['home']);
      }
    });
  }

  private loadInvitedByUser(userId: string): void {
    this.apiService.getUser(userId).pipe(first()).subscribe(user => {
      this.invitedByUser = user.body.user;
    });
  }

  onRegisterSuccess (): void {
    this.sendEvent({
      name: EventType.alert,
      data: {
        type: 'success',
        message: 'register_user.success',
        dismissible: true
      }
    });
    this.router.navigateByUrl('/home');
  }

}
