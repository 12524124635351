import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhotoPickerFromAlbumComponent } from '../photo-picker-from-album/photo-picker-from-album.component';

@Component({
    selector: 'app-choose-photo',
    templateUrl: './choose-photo.component.html',
    styleUrls: ['./choose-photo.component.css']
})
export class ChoosePhotoComponent implements OnInit {

    @Input() photos = null;
    @Input() headerText: string = "";
    @Input() croppingMode: "profile-picture" | "title-photo" | "none";
    @Output() photoSelected = new EventEmitter();
    showAlbumOption = true;

    constructor(
        private modalService: NgbModal,
        public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    photoFilePicked(event): void {
        this.photoSelected.emit(event);
        this.activeModal.dismiss();
    }

    openPhotoFromAlbum(): void {
        let modalRef = this.modalService.open(PhotoPickerFromAlbumComponent, { size: 'lg' });
        modalRef.componentInstance.photos = this.photos;
        modalRef.componentInstance.photoSelected.subscribe((event) => {
            this.photoSelected.emit(event);
            this.activeModal.dismiss();
        });
    }

    onUploadPreview(event: {files: File[]}): void {
      this.showAlbumOption = event.files?.length === 0;
    }
}
