<a [routerLink]="['/messages']" *ngIf="messages" class="mobile-version" ngbTooltip="{{ 'messages.header_title' | translate }}">
    <div class="control-icon no-gutters more has-items">
        <svg class="olymp-chat---messages-icon"><use xlink:href="#olymp-chat---messages-icon"></use></svg>
        <div class="label-avatar bg-purple" *ngIf="messages?.length > 0">{{messages?.length}}</div>
    </div>
</a>

<a [routerLink]="" *ngIf="messages" [matMenuTriggerFor]="messagesMenu" class="non-mobile-version" ngbTooltip="{{ 'messages.header_title' | translate }}">
    <div class="control-icon no-gutters more has-items">
        <svg class="olymp-chat---messages-icon"><use xlink:href="#olymp-chat---messages-icon"></use></svg>
        <div class="label-avatar bg-purple" *ngIf="messages?.length > 0">{{messages?.length}}</div>
    </div>
</a>

<mat-menu #messagesMenu="matMenu" xPosition="before" >

    <div class="notif-menu">
        <div class="ui-block-title ui-block-title-small">
            <h6 class="title" translate>messages.header_title</h6>
            <a [routerLink]="['/messages']" class="view-all view-all-button bg-purple" translate>messages.view_all</a>
        </div>

        <div *ngIf="!messages || messages.length == 0" class="notif-empty" translate>messages.empty</div>

        <div class=""  >
          <perfect-scrollbar [autoPropagation]="true" [config]="{ }">
            <ul class="notification-list chat-message">
              <ng-container *ngFor="let message of messages">
                <li *ngIf="message.valid" class="message-unread" [routerLink]="[message.url]" >
                  <div class="author-thumb">
                    <img loading="lazy" class="img-author" src="{{message.display.avatar}}" alt="author">
                  </div>
                  <div class="notification-event">
                    <a [routerLink]="[message.profileUrl]" (click)="$event.stopPropagation();" class="h6 notification-friend">{{message.display.name}}</a>
                    <span class="chat-message-item two-rows unselectable">{{message.display.message}}</span>
                    <span class="notification-date"><time class="entry-date updated unselectable">{{message.display.date}}</time></span>
                  </div>

                  <span class="notification-icon">
                    <svg class="olymp-chat---messages-icon"><use xlink:href="#olymp-chat---messages-icon"></use></svg>
                  </span>
                </li>
              </ng-container>
            </ul>
          </perfect-scrollbar>
        </div>
    </div>
</mat-menu>

