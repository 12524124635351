<div *ngIf="!userAlreadyMember" class="container">
	<div class="row">
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="align-right">
          <a *ngIf="userLoggedIn" [class.disabled]="working" [routerLink]="" (click)="requestMembership()" class="btn btn-primary btn-md-2">{{'profile.request_membership' | translate}}</a>
      </div>
		</div>
	</div>
</div>

<div class="container" *ngIf="showMemberRequestSentAlert">
  <div class="alert alert-info">
    <span>{{ 'profile.membership_requested_success' | translate }}</span>
  </div>
</div>

<div class="container">
  <h3 *ngIf="allowEdit">{{ 'profile.org_requests' | translate }}</h3>
  <div *ngIf="allowEdit" class="row">
    <ng-container *ngIf="!memberRequests || memberRequests.length == 0">
      <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
      <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
    </ng-container>
    <ng-container *ngFor="let memberRequest of memberRequests">
      <div *ngIf="allowEdit || memberRequest.show || (memberRequest.acceptedByOrg && memberRequest.acceptedByUser)" class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
        <app-user-widget-slim [user]="memberRequest.user"
                              [member]="memberRequest"
                              [isSuperAdmin]="memberRequest.userId === _org?.owner"
        >
          <div content class="d-flex justify-content-center" >
            <div *ngIf="!memberRequest.acceptedByUser" class="h5 mb-1 mt-2" translate>profile.member_pending_user</div>
            <div *ngIf="showRequestActions(memberRequest)" class="mt-2 d-flex justify-content-center gap-05 flex-grow-1">
              <button class="btn btn-success m-0 full-width" (click)="(acceptMembership(memberRequest.user._id))">{{ 'profile.accept_membership' | translate }}</button>
              <button class="btn btn-danger m-0 full-width" (click)="(removeMembership(memberRequest.user._id))">{{ 'profile.decline_membership' | translate }}</button>
            </div>
          </div>
        </app-user-widget-slim>
      </div>
    </ng-container>
  </div>

  <h3 *ngIf="allowEdit">{{ 'profile.members' | translate }}</h3>
	<div class="row">
      <ng-container *ngIf="!members || members.length == 0">
        <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
        <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
      </ng-container>
      <ng-container *ngFor="let member of members">
          <div *ngIf="allowEdit || member.show || (member.acceptedByOrg && member.acceptedByUser)" class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <app-user-widget-slim [user]="member.user"
                                  [member]="member"
                                  [showAdminActions]="showAdminActions(member)"
                                  [isSuperAdmin]="member.userId === _org?.owner"
            >
              <div content class="d-flex justify-content-center" >
                <div *ngIf="!member.acceptedByUser" class="h5 mb-1 mt-2" translate>profile.member_pending_user</div>
                <div *ngIf="showRequestActions(member)" class="mt-2 d-flex justify-content-center gap-05 flex-grow-1">
                  <button class="btn btn-success m-0 full-width" (click)="(acceptMembership(member.user._id))">{{ 'profile.accept_membership' | translate }}</button>
                  <button class="btn btn-danger m-0 full-width" (click)="(removeMembership(member.user._id))">{{ 'profile.decline_membership' | translate }}</button>
                </div>
              </div>
              <div actionsContent class="d-flex flex-column">
                <div class="d-flex flex-column gap-05">
                  <button *ngIf="showSetAdminAction(member)" class="btn btn-danger m-0 full-width" (click)="this.setAdmin(member)">
                    <i class="fa fa-hammer mr-2"></i>
                    <span>{{ 'profile.set_admin' | translate }}</span>
                  </button>

                  <button *ngIf="showUnsetAdminAction(member)" class="btn btn-danger m-0 full-width" (click)="this.unsetAdmin(member)">
                    <i class="fa fa-hammer mr-2"></i>
                    <span>{{ 'profile.unset_admin' | translate }}</span>
                  </button>

                  <button *ngIf="showRemoveUserAction(member)" class="btn btn-danger m-0 full-width" (click)="this.removeMembership(member.user._id)">
                    <i class="fa fa-user-slash mr-2"></i>
                    <span>{{ 'profile.remove_member' | translate }}</span>
                  </button>

                  <button *ngIf="showLeaveOrgAction(member)" class="btn btn-danger m-0 full-width" (click)="this.removeMembership(member.user._id)">
                    <i class="fa fa-exit mr-2"></i>
                    <span>{{ 'profile.leave_organization' | translate }}</span>
                  </button>

                  <div class="d-flex align-items-center" *ngIf="showChangePosition(member)">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'profile.position' | translate }}</mat-label>
                      <input matInput class="input-position" type="text" name="position.{{member.userId}}" [(ngModel)]="member.position" />
                      <div matSuffix class="position-buttons">
                        <button class="btn btn-primary btn-sm m-0" (click)="changeMemberPosition(member)">{{ 'save' | translate }}</button>
                      </div>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </app-user-widget-slim>
          </div>
      </ng-container>
  </div>
</div>

<app-load-page-button *ngIf="hasMorePages" (loadMore)="loadMore()" ></app-load-page-button>
