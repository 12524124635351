<div class="">
    <div class="modal-content" style="background-color: black; border: none;">
        <a [routerLink]="" (click)="activeModal.dismiss('cancel')" class="close icon-close" aria-label="Close">
            <svg class="olymp-close-icon">
                <use xlink:href="#olymp-close-icon"></use>
            </svg>
        </a>

        <div *ngIf="singleFeed" class="modal-body">

            <ngb-carousel
                [showNavigationArrows]="singleFeed.additionalPhotos && singleFeed.additionalPhotos.length > 1"
                [showNavigationIndicators]="singleFeed.additionalPhotos && singleFeed.additionalPhotos.length > 1" interval="0"
                activeId="{{openIndex}}">

                <ng-template ngbSlide id="0">
                    <div class="picsum-img-wrapper">
                        <img src="{{getPhotoUrl(singleFeed.photo)}}">

                        <div class="feed-author download-button">
                            <div *ngIf="isSuperAdmin">
                                <a class="btn btn-primary btn-sm" [routerLink]=""
                                    (click)="downloadImage(singleFeed.photo)">{{ 'feeds.download' | translate }}</a>
                            </div>
                        </div>

                    </div>
                </ng-template>

                <ng-template ngbSlide *ngFor="let photo of singleFeed.additionalPhotos; let i = index;" id="{{i + 1}}">
                    <div class="picsum-img-wrapper">
                        <img src="{{getPhotoUrl(photo)}}">

                        <div class="feed-author download-button">
                            <div *ngIf="isSuperAdmin">
                                <a class="btn btn-primary btn-sm" [routerLink]=""
                                    (click)="downloadImage(photo)">{{ 'feeds.download' | translate }}</a>
                            </div>
                        </div>

                    </div>
                </ng-template>

            </ngb-carousel>

            <div class="carousel-caption">
              <app-photo-feed-author-widget
                [feed]="singleFeed"
                (clicked)="activeModal.dismiss('cancel')">
              </app-photo-feed-author-widget>

              <div class="photo-text">
                <h4 *ngIf="singleFeed.blocked"
                    style="color: red; font-weight: 700; text-align: left; width: 100%"
                    ngbTooltip="{{'feeds.blocked_tooltip' | translate}}" translate>feeds.blocked</h4>
                <span class="image-textarea">{{ singleFeed.text }}</span>
              </div>

              <div class="reactions">
                <div>
                  <a [routerLink]=""
                     (click)="addFav()"
                     class="post-add-icon inline-items"
                     ngbTooltip="{{ (isMyFav() ? 'favs.remove' : 'favs.add') | translate }}">
                    <svg *ngIf="!isMyFav()" class="olymp-star-icon">
                      <use xlink:href="#olymp-star-icon"></use>
                    </svg>
                    <i *ngIf="isMyFav()" class="liked fas fa-star"></i>
                  </a>

                  <a [routerLink]=""
                     (click)="likeFeed(singleFeed)"
                     class="post-add-icon inline-items"
                     ngbTooltip="{{ 'favs.like' | translate }}" >
                    <svg *ngIf="!hasMyLike(singleFeed)" class="olymp-heart-icon">
                      <use xlink:href="#olymp-heart-icon"></use>
                    </svg>
                    <i *ngIf="hasMyLike(singleFeed)" class="liked fas fa-heart"></i>
                    <span >{{singleFeed.likes ? singleFeed.likes.length : 0}}</span>
                  </a>
                </div>
              </div>

              <div class="row comments-section">
                <div class="col col-12">
                  <span *ngIf="!allowComment && (!singleFeed?.comments || singleFeed.comments.length == 0)" translate>comments.empty</span>
                  <ul class="comments-list">
                    <li *ngFor="let comment of singleFeed.comments" class="comment-item">
                      <app-comment [comment]="comment" [allowComment]="allowComment" (reply)="replyComment($event)" [openReplies]="openReplyComment == comment.id" ></app-comment>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="add-comment">
                <ng-container *ngIf="allowComment">
                  <mat-form-field appearance="outline">
                    <mat-label translate>comments.feed_comment</mat-label>
                    <textarea matInput
                              name="newComment"
                              [(ngModel)]="newComment"
                              style="height:60px; min-height:60px">
                      </textarea>
                  </mat-form-field>

                  <div class="col" >
                    <div class="row " style="height: 40px; align-items: center;">
                      <a [routerLink]=""
                         class="emoji-control"
                         [matMenuTriggerFor]="emojisMenu"
                         #matMenuTrigger="matMenuTrigger"
                         style="margin-left: 1rem">
                        <svg class="olymp-happy-face-icon">
                          <use xlink:href="#olymp-happy-face-icon"
                               style="fill: var(--color-grey-light)"></use>
                        </svg>
                      </a>

                      <mat-menu #emojisMenu="matMenu" >
                        <emoji-mart (click)="$event.stopPropagation();"
                                    class="emoji-mart"
                                    [showSingleCategory]="true"
                                    (emojiSelect)="addEmoji($event);
                                         matMenuTrigger.closeMenu();"
                                    title="{{ 'choose_emoji' | translate }}">
                        </emoji-mart>
                      </mat-menu>


                      <div class="col" style="text-align: right;">
                        <button class="btn btn-primary btn-md-2" style="margin-bottom: 0;" (click)="addComment()" [disabled]="!newComment" translate>comments.add_comment</button>
                      </div>

                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
        </div>

        <div *ngIf="!singleFeed" class="modal-body">

            <ngb-carousel
                class="multi-feed"
                [showNavigationArrows]="feeds.length > 1"
                [showNavigationIndicators]="feeds.length > 1" interval="0"
                [animation]=false
                activeId="{{openIndex}}">

                <ng-template ngbSlide *ngFor="let feed of feeds; let i = index;" id="{{i}}">
                    <div class="picsum-img-wrapper">
                        <img src="{{getPhotoUrl(feed.photo)}}">

                        <div class="feed-author download-button">
                            <div *ngIf="isSuperAdmin">
                                <a class="btn btn-primary btn-sm" [routerLink]=""
                                    (click)="downloadImage(feed.photo)">{{ 'feeds.download' | translate }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-caption">
                      <app-photo-feed-author-widget
                        [feed]="feed"
                        (clicked)="activeModal.dismiss('cancel')">
                      </app-photo-feed-author-widget>

                      <div class="photo-text">
                        <h4 *ngIf="feed.blocked"
                            style="color: red; font-weight: 700; text-align: left; width: 100%"
                            ngbTooltip="{{'feeds.blocked_tooltip' | translate}}" translate>feeds.blocked</h4>
                        <span class="image-textarea">{{ feed.text }}</span>
                      </div>

                      <div class="reactions">
                        <a [routerLink]=""
                         (click)="likeFeed(feed)"
                         class="post-add-icon inline-items"
                         ngbTooltip="{{ 'favs.like' | translate }}">
                          <svg *ngIf="!hasMyLike(feed)" class="olymp-heart-icon">
                            <use xlink:href="#olymp-heart-icon"></use>
                          </svg>
                          <i *ngIf="hasMyLike(feed)" class="liked fas fa-heart"></i>
                          <span >{{feed.likes ? feed.likes.length : 0}}</span>
                        </a>
                      </div>

                     </div>

                </ng-template>

            </ngb-carousel>

        </div>
    </div>
</div>
