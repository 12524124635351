import { Component, Input, OnInit } from '@angular/core';
import {Legend, Mode} from './types/org-profile-legends.types';
import { ApiService } from "../../services/api.service";
import { first } from "rxjs/operators";
import { Page } from "../../shared/types/pageable";
import { LegendsService } from "./services/legends.service";
import { Utils } from "../../utils";
import { SearchConfig } from "../search-generic/types/search-generic.types";
import { EventService } from "../../services/event.service";
import { EventType } from "../../shared/types/base";
import { EnumService } from "../../services/enum.service";
import { MatSelectChange } from "@angular/material/select";
import { User } from "../../shared/types/profile";
import { GeocoderService } from "../../services/geocoder.service";

@Component({
  selector: 'app-org-profile-legends',
  templateUrl: './org-profile-legends.component.html',
  styleUrls: ['./org-profile-legends.component.css']
})
export class OrgProfileLegendsComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private legendsService: LegendsService,
    private eventService: EventService,
    private enumService: EnumService,
    private geoService: GeocoderService
  ) { }

  @Input() org: any;
  @Input() tab: string;
  legends: Array<Legend> = [];

  _currentUser?: User;
  _page = new Page();
  _selectedLegend: Legend;
  _pickedLegend: Legend;
  _countries: any;
  _filteredCountries: Array<any> = [];
  _sports: Array<any> = [];
  _filteredSports: Array<any> = [];
  _pendingRequest = false;
  filter = {
    country: '',
    sport: '',
  };
  loading = false;

  searchConfig: SearchConfig = {
    orgs: false,
    feeds: false,
    legends: true,
    users: false,
  };

  printName = (result: any) => {
    if (!result) return '';
    return [result.profile.firstname, result.profile.lastname].join(' ');
  };

  ngOnInit(): void {
    this._currentUser = this.apiService.getCurrentUser();
    this._pendingRequest = Boolean(this._currentUser?.legendRequest);
    this._page.sortDirection = 'desc';
    this._page.sortKey = 'renownCount';
    this._countries = this.enumService.getCountries();
    this._sports = this.enumService.getSports();

    this.geoService.countryCode.subscribe(countryCode => {
      this.filter.country = countryCode;
      this.loadLegends(true);
    });


    this.eventService.getObservable().subscribe(event => {
      if (event.name === EventType.data && event.data.legend) {
        const index = this.legends.findIndex(legend => legend._id === event.data.legend._id)
        if (index > -1) {
          this.legends[index] = event.data.legend;
        }
      }
    });

    if (this._currentUser?.legendRenown) {
      this.apiService.getLegend(this._currentUser.legendRenown).subscribe(legend => {
        this.eventService.publishData({name: EventType.data, data: {legend: legend}});
        this._selectedLegend = legend;
      });
    }
  }

  async openLegendFormDialog(): Promise<void> {
    const ref = this.legendsService.openCreateProfileDialog();
    ref.componentInstance.modal = ref;
    ref.closed.pipe(first()).subscribe(res => {
      if (res === 'success') {
        this._pendingRequest = true;
      }
    });
  }

  getAvatar(result: any): any {
    let defLogo = result?.type == 'org' ? Utils.getLogo() : Utils.getAvatarSmall();
    return ApiService.getPhotoUrl(result.avatar?.previewUrl ? result.avatar.previewUrl : result?.avatar, defLogo);
  }

  onLegendRenownSelect(event: any): void {
    this._pickedLegend = event;
  }

  onLegendRenownDelete() {
    this.apiService.legendsRemoveRenown(this._selectedLegend._id).subscribe(() => this.apiService.refreshCurrentUser());
    this.apiService.getLegend(this._selectedLegend._id).subscribe(legend => {
      this.eventService.publishData({name: EventType.data, data: {legend: legend}});
      this._selectedLegend = undefined;
    });
  }

  onShowProfile() {
    this.legendsService.showProfileDialog(this._selectedLegend, this._currentUser?.superAdmin ? Mode.ADMIN : Mode.READONLY);
  }

  onSaveRenownLegend(event: Event): void {
    event.stopPropagation();
    if (!this._pickedLegend) return;
    this.apiService.legendSetRenown(this._pickedLegend._id)
      .pipe(first())
      .subscribe(() => {
          this.eventService.publishData({name: EventType.alert, data: {message: 'legend.renown_success', type: 'success', dismissible: true}});
          this.apiService.getLegend(this._pickedLegend._id).subscribe(legend => {
            this.eventService.publishData({name: EventType.data, data: {legend: legend}});
            this._selectedLegend = legend;
          });
          this.apiService.refreshCurrentUser();
          this._pickedLegend = undefined;
        },
        () => this.eventService.publishData({name: EventType.alert, data: {message: 'legend.renown_fail', type: 'error', dismissible: true}})
      );
  }

  onCountrySelect(event: MatSelectChange): void {
    this.filter.country = event.value;
    this.loadLegends(true);
  }

  onSportSelect(event: MatSelectChange): void {
    this.filter.sport = event.value;
    this.loadLegends(true);
  }

  private loadLegends(resetPagination = false, page?: Page): void {
    this.loading = true;
    if (resetPagination) {
      this._page.setIndex(0);
    }
    this.apiService.getLegends(page || this._page, this.filter)
      .pipe(first())
      .subscribe(res => {
        if (resetPagination) {
          this.legends = res.result;
          this._page.setIndex(0);
        } else {
          this.legends = [...this.legends, ...res.result]
        }
        if (page) this._page = page;
        this._page.count = res.count;
      }, () => {}, () => this.loading = false);
  }

  onLoadMore(): void {
    this.loadLegends(false, this._page.next());
  }
}
