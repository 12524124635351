import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EnumService } from 'src/app/services/enum.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';
import { PhotoPickerComponent } from '../photo-picker/photo-picker.component';
import { Tag } from '../../shared/types/options';
import { BaseEvent, EventType } from '../../shared/types/base';
import { Feed, FeedType } from "../../shared/types/feed";
import { debounceTime, first, startWith } from "rxjs/operators";
import { Observable, of, scheduled } from "rxjs";

@Component({
    selector: 'app-new-feed',
    templateUrl: './new-feed.component.html',
    styleUrls: ['./new-feed.component.css']
})
export class NewFeedComponent extends BaseComponent implements OnInit {

    @Input() user: any = null;
    @Input() org: any = null;
    @Output() feedAdded = new EventEmitter();

    @ViewChild("photo_picker") private photoPicker: PhotoPickerComponent;
    @ViewChild('textFeedInput') _textFeedInput?: ElementRef<HTMLInputElement>;
    @ViewChild('photoFeedInput') _photoFeedInput?: ElementRef<HTMLInputElement>;

    youtubeLink: string = "";
    working: boolean = false;
    newFeedTypePhoto: boolean = false;
    newVideoTypePhoto: boolean = false;
    newFeedText: string = "";
    newFeedPhotos: any[] = [];
    feedTags: Array<Tag & {selected: boolean}> = [];
    selectedFeedTags: Record<string, any> = [];
    showTextFeedIcon = true;

    constructor(
        eventService: EventService,
        private enumService: EnumService,
        private apiService: ApiService,
        private translateService: TranslateService,
        private snackBar: MatSnackBar) {
        super(eventService);
    }

    ngOnInit(): void {
        this.loadEnums();
    }

    protected eventReceived(event: BaseEvent): void {
        super.eventReceived(event);
        if (event.name === EventType.langChange && event.data.languageChanged) this.loadEnums();
    }

    focusFeedInput(): void {
      new Observable().pipe(startWith(''), debounceTime(250), first()).subscribe(() => {
        this.newFeedTypePhoto ? this._photoFeedInput?.nativeElement?.focus() : this._textFeedInput?.nativeElement?.focus();
      })
    }

    blurFeedInput(): void {
      this._photoFeedInput?.nativeElement?.blur();
      this._textFeedInput?.nativeElement?.blur();
    }

    getAvatarUrl() {
        if (this.user) {
            return ApiService.getPhotoUrl(this.user?.avatar?.avatar?.medium, Utils.getAvatarMedium());
        }

        if (this.org) {
            return ApiService.getPhotoUrl(this.org?.avatar?.avatar?.medium, Utils.getLogoMedium());
        }
    }

    openYoutubeField() {
      if (this.newVideoTypePhoto) {
        this.youtubeLink = "";
        this.newVideoTypePhoto = false;
      } else {
        this.newVideoTypePhoto = true;
        this.clearPhotoFeeds()
      }
    }

    openPhotoFeeds() {
        this.newVideoTypePhoto = false;
        this.youtubeLink = "";
        this.newFeedTypePhoto = true;
        this.photoPicker?.openPicker();
    }

    clearPhotoFeeds() {
      this.newFeedTypePhoto = false;
      this.newFeedPhotos = [];
    }

    photoSelected($event) {
        if ($event?.files && $event?.files.length > 0) {
            this.newFeedPhotos = $event?.files;
        }
        else {
            this.newFeedPhotos = [];
            this.newFeedTypePhoto = false;
        }
    }

    addEmoji($event) {
        this.newFeedText = `${this.newFeedText}${$event.emoji.native}`;
    }

    postFeed() {
        if (!this.newFeedText) {
            return;
        }

        if (this.newFeedTypePhoto) {
            this.postPhotoFeed();
        }
        else if (this.newVideoTypePhoto) {
            this.postVideoFeed();
        }
        else {
            this.postTextFeed();
        }
    }

    postTextFeed() {
        let feed = {
            text: this.newFeedText,
            type: "text",
            tags: Utils.activeTagsMapToStringArray(this.selectedFeedTags),
        };

        if (this.user) {
            this.apiService.addUserFeed(feed).subscribe(
                () => this.feedHasPosted(feed),
                (error) => this.handleApiError(error) );
        }
        else if (this.org) {
            this.apiService.addOrgFeed(this.org._id, feed).subscribe(
                () => this.feedHasPosted(feed),
                (error) => this.handleApiError(error) );
        }
    }

    postVideoFeed() {
        if (Utils.getYoutubeUrl(this.youtubeLink) == "") {
          this.sendEvent({name: EventType.alert, data: {type: 'error', message: 'feeds.youtube_error', dismissible: true}});
          return;
        }
        let feed: Feed = {
            text: this.newFeedText,
            type: FeedType.video,
            youtubeLink: this.youtubeLink,
            tags: Utils.activeTagsMapToStringArray(this.selectedFeedTags),
        };

        if (this.user) {
            this.apiService.addUserFeed(feed).subscribe(
                () => this.feedHasPosted(feed),
                (error) => this.handleApiError(error) );
        }
        else if (this.org) {
            this.apiService.addOrgFeed(this.org._id, feed).subscribe(
                () => this.feedHasPosted(feed),
                (error) => this.handleApiError(error) );
        }
    }

    postPhotoFeed() {
        if (!this.newFeedPhotos || this.newFeedPhotos.length < 1) {
            return;
        }

        this.setWorking(true, "progress_uploading_photo");

        let tags: Array<string> = Utils.activeTagsMapToStringArray(this.selectedFeedTags);

        const formData = new FormData();
        this.newFeedPhotos.forEach(file => { formData.append(file.name, file, file.name); });
        formData.append("description", this.newFeedText);
        formData.append("feedTags", tags.join(','));
        formData.append("group", 'true');

        if (this.user) {
            this.apiService.uploadUserPhotos(formData).subscribe(
                () => {
                    this.setWorking(false);
                    this.feedHasPosted({ text: this.newFeedText, type: "photo", tags: tags }, true);
                    this.sendEvent({ name: EventType.update, data: { update: 'profile' }});
                    this.photoPicker?.cancel();
                    this.clearPhotoFeeds()
                },
                (error) => { this.setWorking(false); this.handleApiError(error); } );
        }
        else if (this.org) {
            this.apiService.uploadOrgPhotos(this.org._id, formData).subscribe(
                () => {
                    this.setWorking(false);
                    this.feedHasPosted({ text: this.newFeedText, type: "photo", tags: tags }, true);
                    this.sendEvent({ name: EventType.update, data: { update: 'profile' }});
                    this.photoPicker?.cancel();
                    this.clearPhotoFeeds()
                },
                (error) => { this.setWorking(false); this.handleApiError(error); } );
        }
    }

    feedHasPosted(feed: any, isPhoto: boolean = false) {
        this.newFeedText = "";
        this.youtubeLink = "";
        this.newVideoTypePhoto = false;

        if (isPhoto) {
            this.newFeedPhotos = [];
        }

        this.feedAdded.emit(feed);
    }

    setWorking(working: boolean, message: string = "") {
        this.working = working;

        if (working) {
            let msg = this.translateService.instant(message);
            this.snackBar?.open(msg, null, { duration: 0 });
        }
        else {
            this.snackBar?.dismiss();
        }
    }

    private loadEnums(): void {
        if (this.user) this.feedTags = this.enumService.getFeedTags().filter(tag => !tag.onlyForOrg);
        if (this.org) this.feedTags = this.enumService.getFeedTags();
    }
}
