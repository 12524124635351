import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
    selector: 'app-terms-condition',
    templateUrl: './terms-condition.component.html',
    styleUrls: ['./terms-condition.component.css']
})
export class TermsConditionComponent implements OnInit {

    pdfSrc: string = "";

    constructor(private storageService: LocalStorageService) { }

    ngOnInit(): void {

        // TODO: set for selected lang
        //let lang = this.storageService.getAppLang();
        this.pdfSrc = "assets/docs/terms-and-conditions-sk.pdf";
    }

}
