import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventService } from './event.service';
import { LocalStorageService } from './local-storage.service';
import { EventType } from '../shared/types/base';
import { User } from '../shared/types/profile';
import { Observable } from "rxjs";
import { Page, PageableResponse } from "../shared/types/pageable";
import { NotificationsConfig } from "../shared/types/notifications";
import { Legend } from "../components/org-profile-legends/types/org-profile-legends.types";

const PATH_AUTH = "/auth";
const PATH_AUTH_CONFIRM = "/auth/confirm/";
const PATH_AUTH_CONFIRM_RESEND = "/auth/confirm/resend";
const PATH_AUTH_FIRE_TOKEN = "/auth/fireToken";
const PATH_AUTH_CHANGE_PASSWORD = "/auth/password/change";
const PATH_USER = "/user";
const PATH_USER_ID = "/user/:userId";
const PATH_USER_DELETE = "/user/delete";
const PATH_USER_PHOTO = (id: string = 'me') => `/user/${id ?? 'me'}/photo`;
const PATH_USER_ME_PHOTO_DESC = "/user/me/photo/:photoId/description";
const PATH_USER_PROFILE = (id: string = 'me') => `/user/${id ?? 'me'}/profile`;
const PATH_USER_PROFILE_PHOTO = (id: string = 'me') => `${PATH_USER_PROFILE(id)}/photo`;
const PATH_USER_NOTIFICATIONS_CONFIG = (id: string = 'me') => `${PATH_USER_PROFILE(id)}/notifications`
const PATH_USER_SUGGESTEDBY = (id: string = 'me') => `${PATH_USER_PROFILE(id)}/suggestedBy`;
const PATH_USER_DEFORG = (id: string = 'me', orgId: string) => `/user/${id ?? 'me'}/organization/default/${orgId}`
const PATH_USER_FEEDS = "/user/:userId/feed";
const PATH_USER_ORGS = "/user/:userId/organization";
const PATH_USER_BLOCK = "/user/:userId/block";
const PATH_USER_UNBLOCK = "/user/:userId/unblock";
const PATH_USER_ALLOW_MM = "/user/:userId/allowmassmessages";
const PATH_USER_VERIFIED = (id: string): string => `/user/${id ?? 'me'}/verified`;
const PATH_ENUM = "/enum/";
const PATH_ORG = "/organization";
const PATH_ORG_ID = "/organization/:orgId";
const PATH_ORG_ACTIVATE = "/organization/:orgId/activate";
const PATH_ORG_PHOTO = "/organization/:orgId/photo";
const PATH_ORG_PHOTO_DESC = "/organization/:orgId/photo/:photoId/description";
const PATH_ORG_PROFILE = "/organization/:orgId/profile";
const PATH_ORG_PROFILE_PHOTO = "/organization/:orgId/profile/photo";
const PATH_ORG_FEEDS = "/organization/:orgId/feed";
const PATH_ORG_MEMBER = "/organization/:orgId/member";
const PATH_ORG_MEMBER_ID = "/organization/:orgId/member/:memberId";
const PATH_ORG_MEMBER_ACCEPT = "/organization/:orgId/member/:memberId/accept";
const PATH_ORG_MEMBER_POSITION = "/organization/:orgId/member/:memberId/position";
const PATH_ORG_ADMIN = "/organization/:orgId/admin/:userId";
const PATH_ORG_ALLOW_MM = "/organization/:orgId/allowmassmessages";
const PATH_ORG_VERIFIED = (orgId: string): string => `/organization/${orgId}/verified`;
const PATH_ORG_RECOMMEND = (orgId: string): string => `${PATH_ORG}/${orgId}/recommend`;
const PATH_ORG_RECOMMENDATIONS = (orgId: string): string => `${PATH_ORG}/${orgId}/recommendations`;

const PATH_LEGENDS = '/hall-of-fame/legends';
const PATH_LEGENDS_PENDING = '/hall-of-fame/legends-pending';
const PATH_LEGEND = (legendId?: string) => legendId ? `/hall-of-fame/legend/${legendId}` : '/hall-of-fame/legend';
const PATH_LEGEND_LIKE = (legendId: string) => `/hall-of-fame/legend/${legendId}/like`;
const PATH_LEGEND_RENOWN = (legendId: string) => `/hall-of-fame/legend/${legendId}/renown`;

const PATH_NOTIFICATIONS = "/user/me/notifications";
const PATH_NOTIFICATION_ID = "/user/me/notifications/:notifId";
const PATH_SEARCH = "/search";
const PATH_QUICKSEARCH = "/search/quicksearch";
const PATH_FEEDS = "/feed";
const PATH_FEEDS_ID = "/feed/:feedId";
const PATH_FEEDS_LIKE = "/feed/:feedId/like";
const PATH_FEEDS_COMMENT = "/feed/:feedId/comment";
const PATH_FEEDS_COMMENT_ID = "/feed/:feedId/comment/:commentId";
const PATH_FEED_BLOCK = "/feed/:feedId/block";
const PATH_FEED_UNBLOCK = "/feed/:feedId/unblock";

const PATH_MESSAGES = "/message";
const PATH_MESSAGES_THREAD = "/message/:threadId";
const PATH_MESSAGES_THREAD_READ = "/message/:threadId/read";

const PATH_MESSAGES_ORG = "/message/org/:orgId";
const PATH_MESSAGES_ORG_THREAD = "/message/org/:orgId/:threadId";
const PATH_MESSAGES_ORG_THREAD_READ = "/message/org/:orgId/:threadId/read";
const PATH_MASS_MESSAGES = "/message/massmessages";
const PATH_MASS_MESSAGES_ORG = "/message/massmessages/:orgId";

const PATH_USER_FAVORITES = "/user/:userId/favorites";
const PATH_ME_FAVORITES = "/user/me/favorites";

const PATH_REFERRALS_WEEKLY = "/referrals/weekly"


@Injectable({
  providedIn: 'root'
})
export class ApiService {
    private readonly baseUrl: string;
    private token: string = null;
    private firebaseToken: string = null;
    private currentUser: User | null = null;
    private userDetails?: User;
    private orgDetails?: any;

    constructor(
        private http: HttpClient,
        private eventService: EventService,
        private storageService: LocalStorageService,
    ) {
      this.baseUrl = environment.API_URL;
    }

    init() {
        this.token = this.storageService.getUserToken();
        this.currentUser = this.storageService.getUser();
        this.eventService.getObservable().subscribe(
          event => {
            if (event.name === EventType.update && event.data.update === 'profile') {
              this.getUser().subscribe(() => {});
            }
          }
        )
    }

    hasUserToken() {
      return this.token != null;
    }
    getCurrentUser(): User | null {
      return this.currentUser;
    }

    setCurrentUser(user: any): void {
      this.currentUser = user;
    }

    async getUserDetails(userId: string): Promise<User | undefined> {
        if (userId === this.userDetails?._id) {
            return this.userDetails;
        }

        const res = await this.getUser(userId).toPromise();

        if (res.status === 200) {
            this.userDetails = res.body.user as User;
            return res.body.user as User;
        }

        return undefined;
    }

    static getUserAdminRole(user, orgId) {
        return user?.adminOrgs?.find(o => o._id == orgId)?.role;
    }

    static isUserAdmin(user, orgId) {
        let role = this.getUserAdminRole(user, orgId);
        return (role == 'admin' || role == 'owner');
    }

    getCurrentUserAdminRole(orgId) {
        return ApiService.getUserAdminRole(this.currentUser, orgId);
    }

    isCurrentUserAdmin(orgId) {
        let role = this.getCurrentUserAdminRole(orgId);
        return (role == 'admin' || role == 'owner');
    }

    private getHttpHeader(useToken: Boolean = true): HttpHeaders {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Accept-Language', this.storageService.getAppLang());
        if (useToken && this.token && this.token !== 'undefined') {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }

        return headers;
    }

    private getHttpOptions(autoCred: boolean = true, withCred: boolean = true) : { "headers", "withCredentials" } {
        if ((autoCred && this.hasUserToken()) || withCred) {
            return { "headers": this.getHttpHeader(), withCredentials: true };
        }
        else
            return { "headers": this.getHttpHeader(false), withCredentials: false };
    }

    private getParamsQuery(params) {
        if (!params) {
            return "";
        }

        let paramsArray = [];
        for (var key of Object.keys(params)) {
            paramsArray.push(`${encodeURI(key)}=${encodeURI(params[key])}`);
        }

        if (paramsArray.length == 0) {
            return "";
        }
        return '?' + paramsArray.join("&");
    }

    getFirebaseToken() {
        return this.firebaseToken;
    }

    setFirebaseToken(token: string): void {
      this.firebaseToken = token;
    }

    loginUser(email: String, passowrd: String) {

        var login = { "email": email, "password": passowrd };

        return this.http.put(this.baseUrl + PATH_AUTH, login, { "headers": this.getHttpHeader(false), observe: 'response' }).pipe(
            tap(result => this.handleLogin(result))
        );
    }

    logoutUser() {
        const params = new HttpParams().set('firebaseToken', this.firebaseToken);
        return this.http.delete(this.baseUrl + PATH_AUTH, { headers: this.getHttpHeader(), params, observe: 'response', withCredentials: true }).pipe(
            tap(result => this.handleLogout())
        );
    }

    deleteUser() {
      return this.http.delete(this.baseUrl + PATH_USER_DELETE,
        { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true, params: {'userId': this.currentUser._id}});
    }

    private handleLogin(result: any) {
        if (result) {
            this.token = result.body;
            this.storageService.setUserToken(this.token);
            this.eventService.publishData({ name: EventType.data, data: { userLoggedIn: true } });
        }
    }

    private handleLogout() {
        this.clearLogin();
    }


    clearLogin() {
        this.token = null;
        this.firebaseToken = null;
        this.currentUser = null;
        this.storageService.setUserToken(undefined);
        this.storageService.setUser(undefined);
        this.eventService.publishData({ name: EventType.data, data: { userLoggedIn: false } });
        this.eventService.publishData({ name: EventType.data, data: { currentUser: null } });
        this.eventService.publishData({ name: EventType.data, data: { params: null } });
    }

    verifyUser() {
        return this.http.get(this.baseUrl + PATH_AUTH, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => this.handleVerifyUser(result.body["currentUser"]))
        );
    }

    private handleVerifyUser(user) {
        this.currentUser = user;
        this.storageService.setUser(this.currentUser);
        this.eventService.publishData({ name: EventType.data, data: { currentUser: this.currentUser } });
    }

    refreshCurrentUser() {
      this.verifyUser().subscribe();
    }

    updateFirebaseToken(token: string) {
        let data = { "firebaseToken": token };
        return this.http.put(this.baseUrl + PATH_AUTH_FIRE_TOKEN, data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => {
                if (result.body) {
                    this.handleLogin(result);
                }
            }));
    }

    registerUser(data) {
        return this.http.post(this.baseUrl + PATH_AUTH, data, { "headers": this.getHttpHeader(false), observe: 'response' });
    }

    activateUser(data) {
        return this.http.put(this.baseUrl + PATH_AUTH_CONFIRM, data, { "headers": this.getHttpHeader(false), observe: 'response' });
    }

    resendActivationLink() {
        return this.http.put(this.baseUrl + PATH_AUTH_CONFIRM_RESEND, {}, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    changePasswordRequest(email) {
        return this.http.post(this.baseUrl + PATH_AUTH_CHANGE_PASSWORD, { "email": email }, { "headers": this.getHttpHeader(false), observe: 'response' });
    }

    changePassword(data, withCredentials: boolean) {
        return this.http.put(this.baseUrl + PATH_AUTH_CHANGE_PASSWORD, data, { "headers": this.getHttpHeader(withCredentials), observe: 'response', withCredentials: withCredentials });
    }

    /**
     * Gets user by ID, reference ID or "me" for logged user
     * @param id - user ID | reference ID | "me"
     */
    getUser(id = "me") {
        const reqId = id.length < 11 ? id.replace('#', '') : id;
        return this.http.get<{user: User}>(this.baseUrl + PATH_USER_ID.replace(":userId", reqId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => {
                if (id == "me") {
                    this.handleVerifyUser(result.body["user"]);
                }
            })
        );
    }

    getContactUser() {
        return this.getUser("contact");
    }

    uploadUserPhotos(data: any, userId?: string) {
        let header = new HttpHeaders();
        header = header.append('Authorization', 'Bearer ' + this.token);

        return this.http.post(this.baseUrl + PATH_USER_PHOTO(userId), data, { "headers": header, observe: 'response', withCredentials: true });
    }

    changeUserPhotoDesc(photoId, description) {
        return this.http.put(this.baseUrl + PATH_USER_ME_PHOTO_DESC.replace(":photoId", photoId), { "description": description }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    updateUserProfile(data: any, userId?: string) {
        return this.http.put(this.baseUrl + PATH_USER_PROFILE(userId), data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    updateUserNotificationsConfig(notificationsConfig: NotificationsConfig, userId?: string): Observable<HttpResponse<any>> {
        return this.http.put(this.baseUrl + PATH_USER_NOTIFICATIONS_CONFIG(userId), notificationsConfig, { headers: this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    updateUserProfilePhoto(data, userId?: string) {
        return this.http.put(this.baseUrl + PATH_USER_PROFILE_PHOTO(userId), data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    updateUserSuggestedBy(suggestedById: string, userId?: string) {
        return this.http.put(this.baseUrl + PATH_USER_SUGGESTEDBY(userId), {}, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getReferralsWeeklyReport(page: Page): Observable<any> {
        let params = new HttpParams()
          .set('pageIndex', page.index.toString())
          .set('skip', page.skip.toString())
          .set('limit', page.limit.toString())
          .set('sortKey', page.sortKey);

        if (page.sortKey) {
          params = params.set('sortDirection', page.sortDirection.toString());
        }

        if (page.sortDirection) {
          params = params.set('sortDirection', page.sortDirection.toString());
        }

        return this.http.get(this.baseUrl + PATH_REFERRALS_WEEKLY, { headers: this.getHttpHeader(), params, observe: 'response', withCredentials: true });
    }

    getEnums() {
        return this.http.get(this.baseUrl + PATH_ENUM, { "headers": this.getHttpHeader(false), observe: 'response' });
    }

    getOrganization(orgId) {
        return this.http.get<any>(this.baseUrl + PATH_ORG_ID.replace(":orgId", orgId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true })
    }

    async getOrganizationDetails(orgId: string): Promise<User | undefined> {
      if (orgId === this.orgDetails?._id) {
        return this.orgDetails;
      }

      const res = await this.getOrganization(orgId).toPromise();

      if (res.status === 200) {
        this.orgDetails = res.body.organization;
        return res.body.organization;
      }

      return undefined;
    }

    getOrganizations(params) {
        let query = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_ORG + query, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true })
    }

    registerOrg(data) {
        return this.http.post(this.baseUrl + PATH_ORG, data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    activateOrg(orgId, data = {}) {
        return this.http.put(this.baseUrl + PATH_ORG_ACTIVATE.replace(":orgId", orgId), data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    uploadOrgPhotos(orgId, data) {
        let header = new HttpHeaders();
        header = header.append('Authorization', 'Bearer ' + this.token);

        return this.http.post(this.baseUrl + PATH_ORG_PHOTO.replace(":orgId", orgId), data, { "headers": header, observe: 'response', withCredentials: true });
    }

    changeOrgPhotoDesc(orgId, photoId, description) {
        return this.http.put(this.baseUrl + PATH_ORG_PHOTO_DESC.replace(":orgId", orgId).replace(":photoId", photoId), { "description": description }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    updateOrgProfile(orgId, data) {
        return this.http.put(this.baseUrl + PATH_ORG_PROFILE.replace(":orgId", orgId), data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    updateOrgProfilePhoto(orgId, data) {
        return this.http.put(this.baseUrl + PATH_ORG_PROFILE_PHOTO.replace(":orgId", orgId), data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    addUserFeed(feed) {
        return this.http.put(this.baseUrl + PATH_USER_FEEDS.replace(":userId", "me"), feed, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getUserFeeds(userId, params) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_USER_FEEDS.replace(":userId", userId) + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    removeUserFeed(feedId) {
        return this.http.delete(`${this.baseUrl}${PATH_USER_FEEDS.replace(":userId", "me")}/${feedId}`, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    addOrgFeed(orgId, feed) {
        return this.http.put(this.baseUrl + PATH_ORG_FEEDS.replace(":orgId", orgId), feed, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getOrgFeeds(orgId, params) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_ORG_FEEDS.replace(":orgId", orgId) + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    removeOrgFeed(orgId, feedId) {
        return this.http.delete(`${this.baseUrl}${PATH_ORG_FEEDS.replace(":orgId", orgId)}/${feedId}`, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    setDefaultOrg(orgId, userId?: string) {
        let path = this.baseUrl + PATH_USER_DEFORG(userId, orgId);
        return this.http.put(path, {}, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getUserOrgs(userId = "me") {
        let path = this.baseUrl + PATH_USER_ORGS.replace(":userId", userId);

        if (userId == "me") {
            return this.http.get(path, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
        }
        else {
            return this.http.get(path, { "headers": this.getHttpHeader(false), observe: 'response' });
        }
    }

    getOrgMembers(orgId, params) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_ORG_MEMBER.replace(":orgId", orgId) + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getLegends(page: Page, filter: Record<string, string>): Observable<PageableResponse<Legend>> {
        let params = {
          limit: page.limit.toString(),
          skip: page.skip.toString(),
          sortKey: page.sortKey || 'lastname',
          sortDirection: page.sortDirection || 'desc',
          sport: filter.sport || '',
          country: filter.country || '',
        }

        let urlParams = this.getParamsQuery(params);
        return this.http.get<PageableResponse<Legend>>(this.baseUrl + PATH_LEGENDS + urlParams, {headers: this.getHttpHeader(), observe: 'body'});
    }

    getLegendsPending(page?: Page): Observable<PageableResponse<Legend>> {
      let params = {
        limit: page.limit.toString(),
        skip: page.skip.toString(),
        sortKey: page.sortKey || 'lastname',
        sortDirection: page.sortDirection || 'desc',
      }

      let urlParams = this.getParamsQuery(params);
      return this.http.get<PageableResponse<Legend>>(this.baseUrl + PATH_LEGENDS_PENDING + urlParams, {headers: this.getHttpHeader(), observe: 'body'});
    }

    private updateAdminOrgMembers(orgId, member) {
        let org = this.currentUser?.adminOrgs?.find(o => o._id == orgId);
        if (org && member) {
            let idx = org?.members?.findIndex(m => m.userId == member?.userId);
            if (idx >= 0) {
                org.members[idx] = member;
            }
            else {
                org.members.push(member);
            }
        }
    }

    private removeAdminOrgMember(orgId, memberId) {
        if (memberId == "me") {
            memberId = this.currentUser._id;
        }
        let org = this.currentUser?.adminOrgs?.find(o => o._id == orgId);
        if (memberId && org) {
            let idx = org?.members?.findIndex(m => m.userId == memberId);
            if (org && idx >= 0) {
                org.members.splice(idx, 1);
            }
        }
    }

    requestOrgMembership(orgId, userId = "me") {
        return this.http.put(this.baseUrl + PATH_ORG_MEMBER_ID.replace(":orgId", orgId).replace(":memberId", userId), {}, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => {
                let member = result.body["member"];
                this.updateAdminOrgMembers(orgId, member);
            } )
        );
    }

    acceptOrgMembership(orgId, userId = "me") {
        return this.http.put(this.baseUrl + PATH_ORG_MEMBER_ACCEPT.replace(":orgId", orgId).replace(":memberId", userId), {}, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => {
                let member = result.body["member"];
                this.updateAdminOrgMembers(orgId, member);
            } ) );
    }

    changeOrgMemberPosition(orgId, userId, data) {
        return this.http.put(this.baseUrl + PATH_ORG_MEMBER_POSITION.replace(":orgId", orgId).replace(":memberId", userId), data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => {
                let member = result.body["member"];
                this.updateAdminOrgMembers(orgId, member);
            } ) );
    }

    removeOrgMembership(orgId, userId = "me") {
        return this.http.delete(this.baseUrl + PATH_ORG_MEMBER_ID.replace(":orgId", orgId).replace(":memberId", userId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => {
                this.removeAdminOrgMember(orgId, userId);
            } ) );
    }

    addOrgAdmin(orgId, userId) {
        return this.http.put(this.baseUrl + PATH_ORG_ADMIN.replace(":orgId", orgId).replace(":userId", userId), {}, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    removeOrgAdmin(orgId, userId) {
        return this.http.delete(this.baseUrl + PATH_ORG_ADMIN.replace(":orgId", orgId).replace(":userId", userId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    search(params) {
        let urlParams = new HttpParams({fromObject: params});
        return this.http.get(this.baseUrl + PATH_SEARCH, {...this.getHttpOptions(), params: urlParams, observe: 'response' });
    }

    quicksearch(params): Observable<any> {
        let urlParams = new HttpParams({fromObject: params});
        return this.http.get(this.baseUrl + PATH_QUICKSEARCH, {...this.getHttpOptions(), params: urlParams, observe: 'response' });
    }

    searchFeeds(params) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_FEEDS + urlParams, {...this.getHttpOptions(), observe: 'response' });
    }

    searchUsers(params) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_USER + urlParams, {...this.getHttpOptions(), observe: 'response' });
    }

    searchOrgs(params) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_ORG + urlParams, {...this.getHttpOptions(), observe: 'response' });
    }

    getNotifications() {
        return this.http.get(this.baseUrl + PATH_NOTIFICATIONS, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    removeNotification(notifId) {
        return this.http.delete(this.baseUrl + PATH_NOTIFICATION_ID.replace(":notifId", notifId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getMessages(params) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_MESSAGES + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getOrgMessages(orgId: string, params) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_MESSAGES_ORG.replace(":orgId", orgId) + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getMessage(threadId: string) {
        return this.http.get(this.baseUrl + PATH_MESSAGES_THREAD.replace(":threadId", threadId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getOrgMessage(orgId: string, threadId: string) {
        return this.http.get(this.baseUrl + PATH_MESSAGES_ORG_THREAD.replace(":orgId", orgId).replace(":threadId", threadId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    sendMessage(messageData: any) {
        return this.http.post(this.baseUrl + PATH_MESSAGES, messageData, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    sendOrgMessage(orgId: string, messageData: any) {
        return this.http.post(this.baseUrl + PATH_MESSAGES_ORG.replace(":orgId", orgId), messageData, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    responseMessage(threadId: string, message: string) {
        return this.http.post(this.baseUrl + PATH_MESSAGES_THREAD.replace(":threadId", threadId), { "message": message }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    responseOrgMessage(orgId: string, threadId: string, message: string) {
        return this.http.post(this.baseUrl + PATH_MESSAGES_ORG_THREAD.replace(":orgId", orgId).replace(":threadId", threadId), { "message": message }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    messageRead(threadId: string) {
        return this.http.put(this.baseUrl + PATH_MESSAGES_THREAD_READ.replace(":threadId", threadId), { }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    messageOrgRead(orgId: string, threadId: string) {
        return this.http.put(this.baseUrl + PATH_MESSAGES_ORG_THREAD_READ.replace(":orgId", orgId).replace(":threadId", threadId), { }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getFullFeed(feedId: string) {
        return this.http.get(this.baseUrl + PATH_FEEDS_ID.replace(":feedId", feedId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    likeFeed(feedId: string) {
        return this.http.put(this.baseUrl + PATH_FEEDS_LIKE.replace(":feedId", feedId), { }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    removeFeedLike(feedId: string) {
        return this.http.delete(this.baseUrl + PATH_FEEDS_LIKE.replace(":feedId", feedId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    commentFeed(feedId: string, text: string) {
        return this.http.put(this.baseUrl + PATH_FEEDS_COMMENT.replace(":feedId", feedId), { "text": text }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    commentFeedComment(feedId: string, commentId: string, text: string) {
        return this.http.put(this.baseUrl + PATH_FEEDS_COMMENT_ID.replace(":feedId", feedId).replace(":commentId", commentId), { "text": text }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    removeFeedComment(feedId: string, commentId: string) {
        return this.http.delete(this.baseUrl + PATH_FEEDS_COMMENT_ID.replace(":feedId", feedId).replace(":commentId", commentId), { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getFavorites(userId: string = "me", params: any = null) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_USER_FAVORITES.replace(":userId", userId) + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    addFavorite(fav) {
        return this.http.put(this.baseUrl + PATH_ME_FAVORITES, fav, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => {
                let favs = result.body["favs"];
                if (this.currentUser) {
                    this.currentUser.favs = favs;
                    this.storageService.setUser(this.currentUser);
                }
            } )
        );
    }

    removeFavorite(fav) {
        let urlParams = this.getParamsQuery(fav);
        return this.http.delete(this.baseUrl + PATH_ME_FAVORITES + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true }).pipe(
            tap(result => {
                let favs = result.body["favs"];
                if (this.currentUser) {
                    this.currentUser.favs = favs;
                    this.storageService.setUser(this.currentUser);
                }
            } )
        );
    }

    allowUserMassMessages(userId: string, allow: boolean) {
        return this.http.put(`${this.baseUrl}${PATH_USER_ALLOW_MM.replace(":userId", userId)}`, { "allow": allow }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    allowOrgMassMessages(orgId: string, allow: boolean) {
        return this.http.put(`${this.baseUrl}${PATH_ORG_ALLOW_MM.replace(":orgId", orgId)}`, { "allow": allow }, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    updateUserToVerified(userId: string): Observable<any> {
        return this.http.post<never>(this.baseUrl + PATH_USER_VERIFIED(userId), null, {headers: this.getHttpHeader(), withCredentials: true});
    }

    updateUserToNotVerified(userId: string): Observable<any> {
        return this.http.delete<never>(this.baseUrl + PATH_USER_VERIFIED(userId), {headers: this.getHttpHeader(), withCredentials: true});
    }

    updateOrgToVerified(orgId: string): Observable<any> {
        return this.http.post<never>(this.baseUrl + PATH_ORG_VERIFIED(orgId), null, {headers: this.getHttpHeader(), withCredentials: true});
    }

    updateOrgToNotVerified(orgId: string): Observable<any> {
        return this.http.delete<never>(this.baseUrl + PATH_ORG_VERIFIED(orgId), {headers: this.getHttpHeader(), withCredentials: true});
    }

    blockUser(userId: string, block: boolean) {
        let path = block ? PATH_USER_BLOCK : PATH_USER_UNBLOCK;
        return this.http.put(`${this.baseUrl}${path.replace(":userId", userId)}`, {}, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    blockFeed(feedId: string, block: boolean) {
        let path = block ? PATH_FEED_BLOCK : PATH_FEED_UNBLOCK;
        return this.http.put(`${this.baseUrl}${path.replace(":feedId", feedId)}`, {}, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getMassMessages(params: any) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_MASS_MESSAGES + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getOrgMassMessages(orgId: string, params: any) {
        let urlParams = this.getParamsQuery(params);
        return this.http.get(this.baseUrl + PATH_MASS_MESSAGES_ORG.replace(":orgId", orgId) + urlParams, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    sendMassMessage(data: any) {
        return this.http.post(`${this.baseUrl}${PATH_MASS_MESSAGES}`, data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    sendOrgMassMessage(orgId: string, data: any) {
        return this.http.post(`${this.baseUrl}${PATH_MASS_MESSAGES_ORG.replace(":orgId", orgId)}`, data, { "headers": this.getHttpHeader(), observe: 'response', withCredentials: true });
    }

    getOrgRecommendations(orgId: string): Observable<any> {
        return this.http.get(this.baseUrl + PATH_ORG_RECOMMENDATIONS(orgId), {headers: this.getHttpHeader(), observe: 'body'});
    }

    getLegend(legendId: string): Observable<Legend> {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.token);
      return this.http.get<Legend>(`${this.baseUrl}${PATH_LEGEND(legendId)}`, {headers: headers, observe: 'body'});
    }

    createLegend(formData: FormData): Observable<Legend> {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.token);
      return this.http.post<Legend>(`${this.baseUrl}${PATH_LEGEND()}`, formData, {headers, withCredentials: true});
    }

    updateLegend(legendId: string, formData?: FormData): Observable<Legend> {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.token);
      return this.http.put<never>(this.baseUrl + PATH_LEGEND(legendId), formData || null, {headers, withCredentials: true});
    }

    deleteLegend(legendId: string): any {
      return this.http.delete<never>(this.baseUrl + PATH_LEGEND(legendId), {headers: this.getHttpHeader(), withCredentials: true});
    }

    legendLike(legendId: string): Observable<never> {
      return this.http.post<never>(this.baseUrl + PATH_LEGEND_LIKE(legendId), null, {headers: this.getHttpHeader(), observe: 'body'});
    }

    legendRemoveLike(legendId: string): Observable<never> {
      return this.http.delete<never>(this.baseUrl + PATH_LEGEND_LIKE(legendId), {headers: this.getHttpHeader(), observe: 'body'});
    }

    legendsRemoveRenown(legendId: string): any {
      return this.http.delete<never>(this.baseUrl +  PATH_LEGEND_RENOWN(legendId), {headers: this.getHttpHeader()});
    }

    legendSetRenown(legendId: string): Observable<never> {
      return this.http.post<never>(this.baseUrl + PATH_LEGEND_RENOWN(legendId), null,{headers: this.getHttpHeader(), withCredentials: true,});
    }

    recommendOrg(orgId: any): any {
      return this.http.post<any>(this.baseUrl + PATH_ORG_RECOMMEND(orgId), null, {headers: this.getHttpHeader(), withCredentials: true});
    }

    cancelRecommendOrg(orgId: any): any {
      return this.http.delete<any>(this.baseUrl + PATH_ORG_RECOMMEND(orgId), {headers: this.getHttpHeader(), withCredentials: true});
    }

    static getPhotoUrl(url, defUrl = null) {
        if (!url) {
            return defUrl;
        }

        return `${environment.API_URL}${url}`;
    }

    static getErrorMessage(error: HttpErrorResponse) {
        if (!error)
            return "Something went wrong";

        if (error.error && error.error.desc) {
            return error.error.desc;
        }

        return error.message;
    }
}
