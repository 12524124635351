<section id="pending-legends-list">
  <ng-container *ngIf="!pendingLegends || pendingLegends.length == 0">
    <div *ngIf="!loading" class="h4 mx-0" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
    <div *ngIf="loading" class="h4 mx-0" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
  </ng-container>
  <div class="legends-grid">
    <app-legend-widget
      *ngFor="let legend of pendingLegends"
      [legend]="legend" [showButtons]="false"
      [admin]="true"
      (onAcceptSuccess)="removeFromList(legend)"
      (onDenySuccess)="removeFromList(legend)"
    ></app-legend-widget>
  </div>
</section>
