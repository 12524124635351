import {Component, InjectionToken, Input, OnInit, ViewChild} from '@angular/core';
import { ApiService } from "../../services/api.service";
import { ReferralsTableData } from "./types/referrals-table-data";
import { Page } from "../../shared/types/pageable";
import { PageEvent } from "@angular/material/paginator";
import { first } from "rxjs/operators";
import { Sort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import {User} from '../../shared/types/profile';

@Component({
  selector: 'app-referrals-table',
  templateUrl: './referrals-table.component.html',
  styleUrls: ['./referrals-table.component.css'],
})
export class ReferralsTableComponent implements OnInit {

  @Input() user: User;
  data: Array<ReferralsTableData> = [];
  page = new Page();
  loading: boolean = false;

  constructor(
    private apiService: ApiService,
  ) { }

  @ViewChild('table') table: MatTable<ReferralsTableData>;

  loadDataMethod: (page: Page) => any;

  ngOnInit(): void {
    this.page.sortDirection = 'desc';
    this.page.sortKey = 'weeklyScore'

    this.loadDataMethod = (page: Page) => {
      this.loading = true;
      this.apiService.getReferralsWeeklyReport(page).pipe(first()).subscribe(data => {
        this.data = data.body.result.map(item => ReferralsTableData.fromReferralsWeekly(item));
        this.page = page;
        this.page.count = data.body.count;
      }, () => {}, () => {
        this.loading = false;
      });
    }
    this.loadDataMethod(this.page);
  }

  onSortChange(event: Sort): void {
    this.page.sortKey = event.active;
    this.page.sortDirection = event.direction;
    this.loadDataMethod(this.page);
  }

  onPageChange(event: PageEvent): void {
    this.page.limit = event.pageSize;
    const isLast = this.page.getLastPageIndex() === event.pageIndex;

    if (isLast) {
      this.loadDataMethod(this.page.last());
      return;
    }

    if (event.pageIndex === 0) {
      this.loadDataMethod(this.page.first());
      return;
    }

    if (event.pageIndex !== this.page.index) {
      event.pageIndex > event.previousPageIndex ? this.loadDataMethod(this.page.next()) : this.loadDataMethod(this.page.previous());
      return;
    }
  }

  shouldHighlight = (row: any): boolean => this.user ? (row.userId === this.user._id) : false;
}
