import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

    pdfSrc: string = "";

    constructor(private storageService: LocalStorageService) { }

    ngOnInit(): void {

        // TODO: set for selected lang
        //let lang = this.storageService.getAppLang();
        this.pdfSrc = "assets/docs/gdpr-sk.pdf";
    }

}
