import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils';

@Pipe({
    name: 'flag'
})
export class FlagPipe implements PipeTransform {

    transform(value: string | null): string {
        if (!value) {
            return "";
        }
        
        return Utils.getFlag(value);
    }

}
