import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { EnumService } from 'src/app/services/enum.service';
import { Alert } from '../alert/alert.component';
import { passwordValidator } from "../../shared/validators";

@Component({
    selector: 'app-register-user',
    templateUrl: './register-user.component.html',
    styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {

    @Input() showCloseIcon = true;
    @Input() referenceId: string;
    @Output() registerSuccess = new EventEmitter();

    passwordHide: boolean = true;
    working: boolean = false;
    registerForm: FormGroup = null;
    alert: Alert = null;

    allSports: any[] = [];
    filteredSports: any[] = [];
    passwordFormField: AbstractControl;

    constructor(
      formBuilder: FormBuilder,
      private apiService: ApiService,
      private enumService: EnumService,
      public activeModal: NgbActiveModal) {
      this.allSports = this.enumService.getSports();

      this.registerForm = formBuilder.group({
          firstname: ['', Validators.required],
          lastname: ['', Validators.required],
          email: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,10}$")]),
          password: ['', [Validators.required, passwordValidator]],
          birthdate: ['', Validators.required],
          gender: 'male',
          defSport: new FormControl('', Validators.required),
          acceptTerms: [false, Validators.required],
          acceptGDPR: [false, Validators.required]
        });
    }

    ngOnInit(): void {
      this.passwordFormField = this.registerForm.get('password');
      this.registerForm.valueChanges.subscribe(change => console.log(change, this.registerForm.errors));
    }

    birthdateChanged(date: Date): void {
      this.registerForm.get('birthdate').setValue(date);
    }

    registerUser(formData): void {
        let email = formData.email.toLowerCase();
        let data: Record<string, any> = {
            "firstname": formData.firstname,
            "lastname": formData.lastname,
            "email": email,
            "password": formData.password,
            "gender": formData.gender,
            "birthdate": formData.birthdate,
            "defSport": formData.defSport
        };

        if (this.referenceId) {
          data.inviteReferenceId = this.referenceId;
        }

        this.working = true;
        this.apiService.registerUser(data).subscribe(
            (result) => {
                this.working = false;
                this.registerForm.reset();

                this.hideModal();
                this.registerSuccess.emit( { "email": email } );
            },
            (error) => {
                this.working = false;
                let errorMsg = ApiService.getErrorMessage(error);
                this.alert = { type: "error", "message": errorMsg, dismissible: true };
            });
    }

    hideModal() {
        this.activeModal.dismiss();
    }
}
