<div *ngIf="orgs && orgs.length > 0" class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>profile.badges</h6>
    </div>
    <div class="ui-block-content">
        
        <ul class="widget w-badges">
            <ng-container *ngFor="let org of orgs">
                <li >
                    <a [routerLink]="['/org-profile/' + org.orgId]">
                        <img loading="lazy" src="{{getLogoUrl(org)}}" alt="author" ngbTooltip="{{getOrgName(org)}}">
                        <!-- <div class="label-avatar bg-primary">2</div> -->
                    </a>
                </li>
            </ng-container>
        </ul>
        
    </div>
</div>