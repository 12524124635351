import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from '../../services/api.service';
import { EventService } from '../../services/event.service';
import { RestorePasswordComponent } from '../restore-password/restore-password.component';
import { EventType } from '../../shared/types/base';
import { DeleteUserComponent } from '../delete-user/delete-user.component';
import { first } from 'rxjs/operators';
import { NotificationOption, NotificationsConfig } from "../../shared/types/notifications";
import { User } from "../../shared/types/profile";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";


@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent extends BaseComponent implements OnInit, AfterViewInit {

    @ViewChild('passwordManagement') passwordManagement: ElementRef;

    @Input() scrollToPassword: boolean = false;
    @Input() user: User | null = null;
    oldPassword: string = "";
    newPassword: string = "";
    confirmPassword: string = "";

    working: boolean = false;
    notificationOptions: NotificationOption[] = [
      new NotificationOption('profile.notifications_favourite_user_post', 'favouriteUserPost', ['email']),
      new NotificationOption('profile.notifications_favourite_org_post', 'favouriteOrgPost', ['email']),
      new NotificationOption('profile.notifications_feed_blocked', 'feedBlocked', ['email']),
      new NotificationOption('profile.notifications_feed_blocked_org', 'feedBlockedOrg', ['email']),
      new NotificationOption('profile.notifications_memberRequest', 'memberRequest'),
      new NotificationOption ('profile.notifications_memberRequestOrg','memberRequestOrg'),
      new NotificationOption('profile.notifications_orgActivated', 'orgActivated'),
      new NotificationOption('profile.notifications_orgDeactivated', 'orgDeactivated'),
    ]

    constructor(
        protected eventService: EventService,
        private apiService: ApiService,
        private modalService: NgbModal,
    ) {
      super(eventService);
    }

    ngOnInit(): void {
      if (!this.user?.notificationsConfig) {
        this.user.notificationsConfig = new NotificationsConfig();
      } else {
        let notifConfig = new NotificationsConfig();
        notifConfig.set(this.user.notificationsConfig);
        this.user.notificationsConfig = notifConfig;
      }
    }

    ngAfterViewInit() {
      if (this.scrollToPassword) {
        setTimeout(() => {
          this.passwordManagement.nativeElement.scrollIntoView()
        }, 0);
      }
    }

  changePassword() {

        if (this.newPassword != this.confirmPassword) {
            this.sendEvent( { name: EventType.alert, data: { type: 'error', message: 'account_settings.confirm_error', dismissible: true } });
            return;
        }

        this.working = true;
        let data = { "oldPassword": this.oldPassword, "password": this.newPassword };
        this.apiService.changePassword(data, true).pipe(first()).subscribe(
            (result) => {
                this.working = false;
                this.newPassword = this.confirmPassword = this.oldPassword = "";
                this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'account_settings.success', dismissible: true } });
            },
            (error) => { this.working = false; this.handleApiError(error); } );
    }

    restorePassword() {
        let modalRef = this.modalService.open(RestorePasswordComponent);
        modalRef.componentInstance.restoreSuccess.pipe(first()).subscribe((event) => {
            this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'restore_password.success', dismissible: true } });
        });
    }

    openDeletionDialog() {
      let modalRef = this.modalService.open(DeleteUserComponent);
      modalRef.componentInstance.deletionSuccess.pipe(first()).subscribe(() => {
        this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'delete_account.success', dismissible: true } } );
        this.sendEvent( { name: EventType.command, data: { command: 'logout'} });
      });
    }

    onSaveNotifications(): void {
      this.saveNotificationsConfig();
    }

    onToggleNotifications(event: MatSlideToggleChange): void {
      this.user.notificationsConfig.enabled = event.checked;
      this.apiService.updateUserNotificationsConfig(this.user.notificationsConfig)
        .pipe(first())
        .subscribe(() => {
          this.eventService.publishData({name: EventType.alert, data: {type: 'success',header: 'success', message: 'profile.notifications_config_success', dismissible: true}});
        }, () => {
          this.eventService.publishData({name: EventType.alert, data: {type: 'error',header: 'error', message: 'profile.notifications_config_error', dismissible: true}});
        });
    }

    private saveNotificationsConfig(): void {
      this.apiService.updateUserNotificationsConfig(this.user.notificationsConfig)
        .pipe(first())
        .subscribe(res => {
          this.eventService.publishData({name: EventType.alert, data: {type: 'success',header: 'alert.header_success', message: 'profile.notifications_config_success', dismissible: true}});
        }, () => {
          this.eventService.publishData({name: EventType.alert, data: {type: 'error',header: 'alert.header_error', message: 'profile.notifications_config_error', dismissible: true}});
        });
    }
}
