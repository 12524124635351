import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ApiService } from 'src/app/services/api.service';
import { UiService } from 'src/app/services/ui.service';
import { Utils } from 'src/app/utils';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

    _user: any = null;
    get user(): any {
        return this._user;
    }
    @Input() set user(value: any) {
        this._user = value;
        this.userUpdated();
    }

    @Output() logoutUser = new EventEmitter();
    @Output() registerOrg = new EventEmitter();
    @Output() hideProfileEmitter = new EventEmitter();

    fullName: string = "";
    subtitle: string = "";
    avatarUrl: string = "";
    allowMassMessages: boolean = false;

    isMobile: boolean = false;

    @ViewChild(MatMenuTrigger) userMenu: MatMenuTrigger;

    constructor(
        public breakpointObserver: BreakpointObserver,
        private uiService: UiService) {
    }

    ngOnInit(): void {
        this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
          this.isMobile = state.matches ? true : false;
      });
    }

    userUpdated() {
        this.fullName = this.user ? `${this.user?.profile?.firstname} ${this.user?.profile?.lastname}` : "";
        this.subtitle = this.user ? `${this.user?.profile?.country}` : "";

        this.avatarUrl = ApiService.getPhotoUrl(this.user?.avatar?.avatar?.small, Utils.getAvatarSmall());

        this.allowMassMessages = this.user?.allowMassMessages === true ||
            this.user?.adminOrgs.some(o => o.allowMassMessages == true);
    }

    hideProfile() {
      this.hideProfileEmitter.emit();
    }

    logout() {
        this.logoutUser.emit();
    }

    toggleRightPanel() {
        this.uiService.toggleUserDrawer();
    }

    public openMenu() {
        if (this.isMobile) {
            this.toggleRightPanel();
        }
        else {
            this.userMenu?.openMenu();
        }
    }
}
