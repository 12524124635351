import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';
import { MapParams } from './types/types';
import { AgmMap } from '@agm/core';
import { Filter } from '../home-filter/types/types';
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { User } from "../../shared/types/profile";
import { GeocoderService } from "../../services/geocoder.service";

@Component({
    selector: 'app-home-map',
    templateUrl: './home-map.component.html',
    styleUrls: ['./home-map.component.css']
})
export class HomeMapComponent extends BaseComponent implements OnInit, OnDestroy {

    orgs = [];
    users = [];
    currentUser?: User;

    mapCenter = {
        latitude: 48.1486,
        longitude: 17.1077
    }

    mapBounds$ = new Subject<google.maps.LatLngBounds>();
    mapBounds: google.maps.LatLngBounds;

    mapFilter = {
        center: this.mapCenter,
        radius: 200000
    }
    newMapBound: boolean = false;

    _filter: Filter = {
        place: null,
        sports: [],
        userRadius: null,
    };

    @ViewChild('agmMap') agmMap: AgmMap;
    usersVisible: boolean = true;
    orgsVisible: boolean = true;
    zoomLevel: number;
    minZoomToLoad: number = 8;

    @Input() set filter(value) {
        const placeChanged = (this._filter.place != value.place);
        const radiusChanged = (this._filter.userRadius != value.userRadius);

        if (value.userRadius) {
            const radius = value.userRadius * 1000;
            this._filter.userRadius = radius;
            this.mapFilter.radius = radius;
        }

        if (placeChanged && value.place?.geometry?.location && value.place?.geometry?.viewport) {
            this.mapCenter = { longitude: value.place.geometry.location.lng(), latitude: value.place.geometry.location.lat() };
            this.mapBounds = new google.maps.LatLngBounds(value.place.geometry.viewport.getSouthWest(), value.place.geometry.viewport.getNorthEast());
            this.newMapBound = true;
        }

        let reloadData = (this._filter.sports != value.sports) || radiusChanged || this.newMapBound;

        this._filter = value;
        if (reloadData)
            this.search();
    }
    get filter() { return this._filter; }

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        private geocoderService: GeocoderService
    ) {
        super(eventService);
    }

    ngOnInit(): void {
        this.currentUser = this.apiService.getCurrentUser();
        if (this.currentUser?.profile?.geoPoint) {
          this.mapCenter = {
            latitude: this.currentUser.profile.geoPoint.coordinates[1],
            longitude: this.currentUser.profile.geoPoint.coordinates[0],
          }
        }
        this.subscriptions.add (
          this.mapBounds$
          .pipe(debounceTime(300))
          .subscribe(bounds => this.onMapBoundsChanged(bounds))
        );

        this.subscriptions.add(
          this.geocoderService.coordinates.subscribe(coords => {
            if (!coords) return;
            this.mapCenter.longitude = coords.longitude;
            this.mapCenter.latitude = coords.latitude;
            this.search();
          })
        )
    }

    search(): void {
        if (this.zoomLevel < this.minZoomToLoad) return;

        let params: MapParams = { start: 0, len: 100, feeds: false };

        if (this.filter?.sports) {
            params.sport = this.filter.sports.join(",");
        }

        if (this.mapFilter) {
            params.latLng = this._filter.place ? `${this.getRadiusLat()},${this.getRadiusLng()}` : `${this.mapFilter.center.latitude},${this.mapFilter.center.longitude}`;
            params.radius = Math.trunc(this.mapFilter.radius);
        }

        const tags: string[] = [];
        const excludeTags: string[] = ['goodshopEshop', 'accommodation', 'rescueService', 'otherOrg'];

        if (this._filter.clubTags) {
          tags.push(...Utils.activeTagsMapToStringArray(this._filter.clubTags));
        }

        if (this._filter.facilityTags) {
            tags.push(...Utils.activeTagsMapToStringArray(this._filter.facilityTags));
        }

        if (this._filter.firmTags) {
            tags.push(...Utils.activeTagsMapToStringArray(this._filter.firmTags));
        }

        if (this._filter.userTags) {
            tags.push(...Utils.activeTagsMapToStringArray(this._filter.userTags));
        }

        params.tags = tags.join(',');
        params.excludeTags = excludeTags.join(',');
        params.orgType = 'sport_club,sport_facility,fan_club,firm'

        this.newMapBound = false;
        this.apiService.search(params).subscribe(
            (result) => {

                let users = result.body["users"] ?? [];
                let orgs = result.body["organizations"] ?? [];
                users.forEach(u => {
                    u["mapIcon"] = this.getAvatarMapIcon(u);
                });

                orgs.forEach(o => {
                    o["mapIcon"] = this.getAvatarMapIcon(o);
                });

                this.users = users;
                this.orgs = orgs;
            },
            (error) => this.handleApiError(error) );
    }

    getAvatarMapIcon(org) {
        let url = ApiService.getPhotoUrl(org?.avatar?.avatar?.small, Utils.getLogoSmall());

        return {
            url: url,
            scaledSize: { width: 28, height: 28 }
        };
    }

    onMapBoundsChanged(bounds: google.maps.LatLngBounds): void {
      this._filter.place = null;
      this.mapFilter.center = { longitude: bounds.getCenter().lng(), latitude: bounds.getCenter().lat() };

      if (!this._filter.userRadius) {
        this.mapFilter.radius = google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), bounds.getSouthWest());
      }

      this.search();
    }

    onZoomChange(zoomLevel: number): void {
      this.zoomLevel = zoomLevel;
      if (zoomLevel < this.minZoomToLoad) {
        this.users = [];
        this.orgs = [];
      }
    }

    getRadius(): number {
        return this.mapFilter.radius ?? 0;
    }

    getRadiusLat(): number {
        return this._filter.place?.geometry?.location?.lat() ?? 0;
    }

    getRadiusLng(): number {
        return this._filter.place?.geometry?.location?.lng() ?? 0;
    }

    toggleUsers(): void {
        this.usersVisible = !this.usersVisible;
    }

    toggleOrganizations(): void {
        this.orgsVisible = !this.orgsVisible;
    }

    circleVisible(): boolean {
      return Boolean(this._filter.place && Boolean(this._filter.userRadius));
    }
}
