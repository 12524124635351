<section class="medium-padding120">
	<div class="container">
		<div class="row">
			<div class="col col-xl-6 m-auto col-lg-6 col-md-12 col-sm-12 col-12">
				<div class="page-404-content">
					<img loading="lazy" src="assets/img/404.png" alt="photo">
					<div class="crumina-module crumina-heading align-center">
						<h2 class="h1 heading-title" translate>404.header</h2>
            <p class="heading-text">
              {{ '404.text' | translate }}
              <a href='mailto:info@spaceofsports.com'>{{ 'info_email' | translate }}</a>
            </p>
					</div>

					<a [routerLink]="['/home']" class="btn btn-primary btn-lg" translate>404.home_btn</a>
				</div>
			</div>
		</div>
	</div>
</section>
