<footer>
    <!-- <div class="footer-alert">
        <app-alert [listenEvents]="true" ></app-alert>
    </div> -->
    <a [routerLink]="['/terms-and-conditions']">
        <span translate>terms_conditions</span>
    </a>
    <span> | </span>
    <a [routerLink]="['/privacy-policy']">
        <span translate>privacy_policy</span>
    </a>
    <p *ngIf="_showVersion">{{ _version }}</p>
</footer>
