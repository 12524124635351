<div class="container" *ngIf="user" >

	<div class="row">

      <div class="menu-settings-responsive col col-12">
          <app-tab-menu [items]="tabItems" (onChange)="onTabChange($event)"></app-tab-menu>
      </div>

      <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
          <app-profile-edit *ngIf="_setting == 'edit-profile'" [user]="user" ></app-profile-edit>
          <app-profile-edit-career *ngIf="_setting == 'edit-profile-career'" [user]="user" ></app-profile-edit-career>
          <app-account-settings *ngIf="_setting == 'edit-profile-settings' || _setting == 'edit-profile-password'" [scrollToPassword]="_setting === 'edit-profile-password'" [user]="user" ></app-account-settings>
      </div>

      <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
        <app-help-children-widget></app-help-children-widget>
        <div class="ui-block">
          <div class="your-profile">
            <div class="ui-block-title ui-block-title-small">
              <h6 class="title" translate>profile.settings</h6>
            </div>

            <div class="ui-block-title setting-link">
              <a [routerLink]="" (click)="setting = 'edit-profile'" class="h6 title" translate>profile.personal_info</a>
            </div>
            <div class="ui-block-title setting-link">
              <a [routerLink]="" (click)="setting = 'edit-profile-career'" class="h6 title" translate>profile.career</a>
            </div>
            <div class="ui-block-title setting-link" *ngIf="!_superAdminEdit">
              <a [routerLink]="" (click)="setting = 'edit-profile-settings'" class="h6 title" translate>profile.account_settings</a>
            </div>
          </div>
        </div>
      </div>
	</div>
</div>

<app-back-to-top></app-back-to-top>
