import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { Utils } from 'src/app/utils';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {

    @Input() comment;
    @Input() allowChildren: boolean = true;
    @Input() openReplies: boolean = false;
    @Input() allowComment: boolean = true;

    @Output() reply = new EventEmitter();

    newComment: string = "";

    constructor(
        private datePipe: DatePipe,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
    }

    getAvatarUrl() {
        return ApiService.getPhotoUrl(this.comment?.user?.avatar?.avatar?.medium, Utils.getAvatarMedium());
    }

    getName() {
        return `${this.comment?.user?.profile?.firstname} ${this.comment?.user?.profile?.lastname}`;
    }

    getDate() {
        return Utils.getHumanDate(this.comment?.added, this.datePipe, this.translateService);
    }

    addEmoji($event) {
        this.newComment = `${this.newComment}${$event.emoji.native}`;
    }

    addComment() {
        if (!this.newComment) {
            return;
        }

        this.reply.emit( { "responseTo": this.comment.id, "text": this.newComment } );
    }
}
