import { Component, OnDestroy, OnInit } from '@angular/core';
import { Legend } from "../../types/org-profile-legends.types";
import { Page } from "../../../../shared/types/pageable";
import { ApiService } from "../../../../services/api.service";
import { first } from "rxjs/operators";
import { EventService } from "../../../../services/event.service";
import { Subscription } from "rxjs";
import { EventType } from "../../../../shared/types/base";

@Component({
  selector: 'app-legends-manage',
  templateUrl: './legends-manage.component.html',
  styleUrls: ['./legends-manage.component.css']
})
export class LegendsManageComponent implements OnInit, OnDestroy {

  constructor(private apiService: ApiService, private eventService: EventService) { }

  _pendingLegendsEventSubscription: Subscription;
  _page = new Page();
  pendingLegends: Array<Legend> = [];
  loading = false;

  ngOnInit(): void {
    this._pendingLegendsEventSubscription = this.eventService.getObservable().subscribe(event => {
      if (event.name === EventType.update && event.data.update === 'legend-requests') {
        this.loadPendingLegends();
      }
    })
    this.loadPendingLegends();
  }

  ngOnDestroy(): void {
    this._pendingLegendsEventSubscription?.unsubscribe();
  }

  private loadPendingLegends(): void {
    this.loading = true;
    this.apiService.getLegendsPending(this._page)
      .pipe(first())
      .subscribe(res => {
        this.pendingLegends = res.result;
      }, () => {}, () => this.loading = false)}

  removeFromList(legend: Legend): void {
    this.pendingLegends = this.pendingLegends.filter(item => item._id !== legend._id);
  }
}
