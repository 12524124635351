<div *ngIf="user" class="container">
	<div class="row">
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="top-header">
          <div class="top-header-thumb img-cover-bgr" [ngStyle]="{'background-image': 'url(' + coverUrl + ')'}">
            <div *ngIf="editable || superAdminEdit" class="author-thumb-edit-photo-icon cover-edit-icon" ngbTooltip="{{'profile.update_cover_photo' | translate }}">
              <a role="button" (click)="updateCoverPhoto()">
                <i class="fa fa-camera"></i>
              </a>
            </div>
          </div>
					<div class="profile-section section-user">
						<div class="row">
							<div class="col col-lg-5 col-md-5 col-sm-12 col-12">
								<ul class="profile-menu">
                  <ng-container *ngIf="!_user?.profile?.hidden || _user?._id === loggedUser?._id || loggedUser?.superAdmin">
<!--                    <li>-->
<!--                      <a [routerLink]="[baseUrl + 'view-profile']" [routerLinkActive]="'active'" translate>profile.about</a>-->
<!--                    </li>-->
                    <li>
                      <a *ngIf="_user?._id === loggedUser?._id" [routerLink]="[baseUrl + '/referrals']" [routerLinkActive]="'active'" translate>profile.referrals</a>
                    </li>
                    <li *ngIf="editable && !superAdminEdit" >
                      <a [routerLink]="['/messages']" [routerLinkActive]="'active'" translate>profile.messages</a>
                    </li>
                  </ng-container>
                </ul>
							</div>
							<div class="col col-lg-5 ml-auto col-md-5 col-sm-12 col-12">
								<ul class="profile-menu">
                  <ng-container *ngIf="!_user?.profile?.hidden || _user?._id === loggedUser?._id || loggedUser?.superAdmin">
                    <li>
                      <a [routerLink]="[baseUrl + 'feeds']" [routerLinkActive]="'active'" translate>profile.timeline</a>
                    </li>
                    <li>
                      <a [routerLink]="[baseUrl + 'photos']" [routerLinkActive]="'active'" translate>profile.photos</a>
                    </li>
                  </ng-container>
								</ul>

							</div>
						</div>

            <div *ngIf="!editable" class="control-block-button" >
              <ng-container *ngIf="!_user?.profile?.hidden || _user?._id === loggedUser?._id || loggedUser?.superAdmin">
                <app-message-options [user]="user"></app-message-options>
                <app-user-invitation-options *ngIf="allowInvite" [user]="user" (userInvited)="profileUpdated()" (userInvitationAccepted)="profileUpdated()" ></app-user-invitation-options>
              </ng-container>
              <app-fav-button [user]="user"></app-fav-button>
            </div>

					</div>
					<div class="top-header-author">

            <div class="author-flag-user">
                <img *ngIf="flagUrl" alt="country flag" src="{{flagUrl}}"/>
            </div>

            <div class="position-relative d-inline-block">
              <a *ngIf="editable" [routerLink]="[baseUrl + 'edit-profile']" style="margin-left: 0;" class="author-thumb author-thumb-user">
                <img loading="lazy" class="img-profile" src="{{avatarUrl}}" alt="author">
                <app-verified-badge *ngIf="_user.verified" class="user-verified-badge"></app-verified-badge>
              </a>
              <div *ngIf="editable" class="author-thumb-edit-photo-icon avatar-edit-icon" ngbTooltip="{{'profile.update_profile_photo' | translate }}">
                <a role="button" (click)="updateProfilePhoto()">
                  <i class="fa fa-camera"></i>
                </a>
              </div>
            </div>

						<a *ngIf="!editable" [routerLink]="[baseUrl + 'view-profile']" style="margin-left: 0;" class="author-thumb author-thumb-user">
							<img loading="lazy" class="img-profile" src="{{avatarUrl}}" alt="author">
              <app-verified-badge *ngIf="_user.verified" class="user-verified-badge"></app-verified-badge>
						</a>

						<div class="author-content">
							<span class="h4 author-name">
                <a [routerLink]="editable ? [baseUrl + 'edit-profile'] : [baseUrl + 'view-profile']" class="d-block">{{ user.profile.firstname }} {{ user.profile.lastname }}</a>
              </span>
							<div class="country" *ngIf="user.profile?.country" translate>countries.{{ user.profile.country }}</div>
						</div>

            <div class="author-club-user">
              <a *ngIf="defUserOrg" [routerLink]="['/org-profile/' + defUserOrg?._id]" ngbTooltip="{{defUserOrg?.profile?.name}}">
                <img alt="country flag" src="{{defUserOrg.absoluteAvatarUrl}}"/>
              </a>

              <div *ngIf="editable && hasUserMoreOrgs" class="author-thumb-edit-photo-icon avatar-edit-icon club-edit-icon" ngbTooltip="{{'profile.set_default_user_org' | translate }}">
                <a role="button" (click)="setDefaultOrganization()">
                  <i class="fa fa-pen"></i>
                </a>
              </div>
            </div>

          </div>
				</div>
        <app-tab-menu *ngIf="!_user?.profile?.hidden || _user?._id === loggedUser?._id || loggedUser?.superAdmin"
                      class="profile-tab-menu" [position]="'bottom'" [items]="mobileMenuItems"
        ></app-tab-menu>
			</div>
		</div>
	</div>
</div>

<div *ngIf="org" class="container">
	<div class="row">
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="top-header top-header-favorit">
					<div class="top-header-thumb img-cover-bgr position-relative" [ngStyle]="{'background-image': 'url(' + coverUrl + ')'}">
            <app-verified-badge *ngIf="_org.verified" class="org-verified-mobile"></app-verified-badge>
            <div class="top-header-thumb img-cover-bgr" [ngStyle]="{'background-image': 'url(' + coverUrl + ')'}">
              <div *ngIf="editable || superAdminEdit" class="author-thumb-edit-photo-icon cover-edit-icon" ngbTooltip="{{'profile.update_cover_photo' | translate }}">
                <a role="button" (click)="updateCoverPhoto()">
                  <i class="fa fa-camera"></i>
                </a>
              </div>
            </div>
            <div class="top-header-author mt-2 mt-sm-0">
              <div class="author-flag d-none d-sm-block">
                  <img *ngIf="flagUrl" alt="country flag" src="{{flagUrl}}"/>
              </div>
							<div class="author-thumb">
                <div *ngIf="editable || superAdminEdit" class="author-thumb-edit-photo-icon avatar-org-edit-icon" ngbTooltip="{{'profile.update_profile_photo' | translate }}">
                  <a role="button" (click)="updateProfilePhoto()">
                    <i class="fa fa-camera"></i>
                  </a>
                </div>
                <div class="author-img">
                  <img loading="lazy" class="img-profile" src="{{avatarUrl}}" alt="author">
                </div>
							</div>
							<div class="author-content">
                <div class="d-inline-block">
                  <a [routerLink]="editable ? [baseUrl + 'edit-profile'] : [baseUrl + 'view-profile']" class="h3 author-name">
                    <span class="mr-2">{{org.profile?.name}}</span>
                    <app-verified-badge *ngIf="_org.verified" class="org-verified-badge"></app-verified-badge>
                  </a>
                  <div class="country">{{org.profile?.location?.formatted_address}}</div>
                </div>

                <div *ngIf="defSport" class="author-sports mx-0 d-xl-inline-block ml-xl-2 d-md-block ml-md-0 d-block ml-0">
                  <span style="text-transform: uppercase;">{{defSport}}</span>
                  <ng-container *ngFor="let sport of otherSports">, {{sport}}</ng-container>
                </div>
							</div>
						</div>
					</div>
					<div class="profile-section section-org">
						<div class="row">
							<div class="col col-xl-8 m-auto col-lg-8 col-md-12">
								<ul *ngIf="_org.type !== 'hall_of_fame'" class="profile-menu">
									<li>
										<a [routerLink]="[baseUrl + 'feeds']" [routerLinkActive]="'active'" translate>profile.timeline</a>
									</li>
									<li>
										<a [routerLink]="[baseUrl + 'view-profile']" [routerLinkActive]="'active'" translate>profile.about</a>
                  </li>
                  <li>
                    <a [routerLink]="[baseUrl + 'members']" [routerLinkActive]="'active'" translate>profile.members</a>
									</li>
									<li>
                    <a [routerLink]="[baseUrl + 'photos']" [routerLinkActive]="'active'" translate>profile.photos</a>
                  </li>
                  <li *ngIf="isUserOrgAdmin">
                    <a [routerLink]="['/org-messages/' + org._id]" [routerLinkActive]="'active'" translate>profile.messages</a>
									</li>
								</ul>

                <ul *ngIf="_org.type === 'hall_of_fame'" class="profile-menu">
                  <li>
                    <a [routerLink]="[baseUrl + 'legends']" [routerLinkActive]="'active'">{{ 'profile.legends' | translate }}</a>
                  </li>
                  <li>

                  </li>
                  <li *ngIf="loggedUser?.superAdmin">
                    <a [routerLink]="[baseUrl + 'legends-manage']" [routerLinkActive]="'active'">{{ 'profile.legends_requests' | translate }}</a>
                  </li>
                </ul>
							</div>
						</div>

            <div class="control-block-button">
              <app-message-options *ngIf="!editable" [org]="org"></app-message-options>
              <app-recommend-button [org]="org"></app-recommend-button>
              <app-fav-button [org]="org"></app-fav-button>
            </div>

                        <!-- <div *ngIf="!editable" class="control-block-button">
							<a href="#" class="btn btn-control bg-primary">
								<svg class="olymp-star-icon"><use xlink:href="#olymp-star-icon"></use></svg>
							</a>

							<a href="#" class="btn btn-control bg-purple">
								<svg class="olymp-chat---messages-icon"><use xlink:href="#olymp-chat---messages-icon"></use></svg>
							</a>

                        </div> -->

					</div>

          <app-tab-menu [items]="orgMobileMenuItems"
                        [position]="'bottom'"
                        [showBorder]="false"
                        class="profile-tab-menu"
          ></app-tab-menu>
        </div>
			</div>
		</div>
	</div>
</div>
