<!-- mobile version toggle right drawer -->
<a *ngIf="user" role="button" (click)="toggleRightPanel()" class="mobile-version author-page author vcard inline-items more">
    <div class="author-thumb">
        <img alt="author" src="{{avatarUrl}}" class="avatar">
    </div>
</a>

<a role="button" [matMenuTriggerFor]="userMenu" class="non-mobile-version author-page author vcard inline-items more align-items-center">
    <ng-container *ngIf="user">
        <div class="author-thumb mr-2">
            <img alt="author" src="{{avatarUrl}}" class="avatar">
        </div>
        <div class="author-name fn responsive-display-none gap-05">
            <div class="author-title">
                {{fullName}} <svg class="olymp-dropdown-arrow-icon"><use xlink:href="#olymp-dropdown-arrow-icon"></use></svg>
            </div>
            <span class="author-subtitle" *ngIf="user.profile?.country" translate>countries.{{subtitle}}</span>
        </div>
    </ng-container>
</a>

<mat-menu #userMenu="matMenu" xPosition="before">
    <div *ngIf="user" class="mCustomScrollbar" data-mcs-theme="dark">
        <div class="ui-block-title ui-block-title-small">
            <h6 class="title responsive-display-none" translate>user_menu.account</h6>
            <h6 class="title responsive-display-only" >{{fullName}}</h6>
        </div>

        <ul class="account-settings">
            <li>
                <a [routerLink]="['/profile/me/edit-profile']">
                    <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>
                    <span translate>user_menu.profile</span>
                </a>
            </li>
            <li>
                <a [routerLink]="['/profile/me/edit-profile-password']">
                    <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>
                    <span translate>profile.change_password</span>
                </a>
            </li>
            <li>
                <a *ngIf="allowMassMessages" [routerLink]="['/massmessages']" (click)="toggleRightPanel()">
                    <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>
                    <span translate>user_menu.mass_messages</span>
                </a>
            </li>
            <li>
                <a *ngIf="user.superAdmin" [routerLink]="['/admin']">
                    <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>
                    <span translate>user_menu.super_admin</span>
                </a>
            </li>
            <li>
                <a [routerLink]="" (click)="hideProfile()">
                  <i class="fa" [ngClass]="user.profile.hidden ? 'fa-eye' : 'fa-eye-slash'"></i>
                  <span>{{ (user.profile.hidden ? 'user_menu.make_profile_visible' : 'user_menu.hide_profile') | translate }}</span>
                </a>
            </li>
            <li>
                <a href="#" (click)="!!logout()">
                    <svg class="olymp-logout-icon"><use xlink:href="#olymp-logout-icon"></use></svg>
                    <span translate>user_menu.logout</span>
                </a>
            </li>
        </ul>

        <div class="ui-block-title ui-block-title-small">
            <h6 class="title" translate>user_menu.organizations</h6>
        </div>

        <ul class="account-settings">

            <li>
                <a class="create-org-item" [routerLink]="" (click)="registerOrg.emit()">
                    <svg class="olymp-star-icon left-menu-icon" ><use xlink:href="#olymp-register-icon"></use></svg>
                    <span translate>user_menu.create_org</span>
                </a>
            </li>

            <li *ngFor="let org of user.adminOrgs">
                <a [routerLink]="['/org-profile/'+ org._id + '/edit-profile']">

                    <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>

                    <span>{{org.profile?.name}}</span>
                </a>
            </li>

        </ul>

    </div>
</mat-menu>
