<app-profile-header [user]="user" [editable]="editable" #profileHeaderComponent ></app-profile-header>

<div *ngIf="contactUserId" class="container">
    <div class="alert alert-info" role="alert">
        <span translate>profile.support_contact_header</span>
        &nbsp;<a [routerLink]="['/messages/user/' + contactUserId]" translate>profile.support_contact_us</a>
        &nbsp;
        <button type="button" class="close" (click)="contactUserId = null" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<app-profile-referrals-overview *ngIf="currentView === 'referrals'"></app-profile-referrals-overview>
<app-profile-feeds *ngIf="!currentView || currentView == 'feeds'" [user]="user" [editable]="editable" ></app-profile-feeds>
<app-profile-view *ngIf="currentView == 'view-profile'" [user]="user" ></app-profile-view>
<app-profile-photos *ngIf="currentView == 'photos'" [user]="user" ></app-profile-photos>
<app-profile-favs *ngIf="currentView == 'favs' && user" [user]="user" ></app-profile-favs>
<app-profile-settings *ngIf="currentView?.startsWith('edit-profile')" [setting]="currentView" [user]="user" [superAdminEdit]="superAdminEdit"></app-profile-settings>

