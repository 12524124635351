import {DatePipe} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from 'src/app/services/api.service';
import {Utils} from 'src/app/utils';
import { NotificationType } from "../../shared/types/notifications";

@Component({
    selector: 'app-header-notifications',
    templateUrl: './header-notifications.component.html',
    styleUrls: ['./header-notifications.component.css']
})
export class HeaderNotificationsComponent implements OnInit {

    _notifications = [];
    notificationType = NotificationType;
    @Input() set notifications(value: Array<any>) {
        value?.forEach(v => {
            v["createdFormated"] = Utils.getHumanDate(v?.created, this.datePipe, this.translateService);
        } );

        this._notifications = value;
    }

    get notifications() {
        return this._notifications;
    }

    showNotifications: boolean = false;

    @Output() notifAction = new EventEmitter();

    constructor(
        private translateService: TranslateService,
        private datePipe: DatePipe,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    acceptMembership(orgId) {
        this.notifAction.emit({ "action": "acceptMembership", "orgId": orgId });
    }

    declineMembership(orgId) {
        this.notifAction.emit({ "action": "declineMembership", "orgId": orgId });
    }

    removeNotification(notif, event?: Event) {
        event?.stopPropagation();
        event?.preventDefault();
        if (notif?._id && !notif.permanent) {
            this.notifAction.emit({ "action": "removeNotification", "notifyId": notif._id });
        }
    }

    openNotifications(open: boolean = true, wait = 0) {
      if (wait > 0) {
            setTimeout(() => {
                if (this.showNotifications != open)
                    this.showNotifications = open;
            }, wait);
        }
        else {
            if (this.showNotifications != open)
                this.showNotifications = open;
        }
    }

    getAvatarUrl(notif) {
        let avatar = null;
        if (notif.type == "org-member-request" || notif.type == "member-request") {
            avatar = notif.org?.avatar?.avatar?.small;
        }
        if (notif.type == "user-blocked") {
            avatar = notif.user?.avatar?.avatar?.small;
        }
        if (notif.type === NotificationType.FAV_USER_NEW_POST) {
            avatar = notif.fav_data?.user?.avatar?.avatar?.small;
        }
        if (notif.type === NotificationType.FAV_ORG_NEW_POST) {
          avatar = notif.fav_data?.org?.avatar?.avatar?.small;
        }

        return ApiService.getPhotoUrl(avatar, Utils.getLogoSmall());
    }

    notClicked(notif) {
        if (notif?.org?._id) {
            this.router.navigateByUrl('/org-profile/' + notif.org._id);
        }
        else if (notif?.user?._id) {
            this.router.navigateByUrl('/profile/' + notif.user._id);
        }
    }
}
