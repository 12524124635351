import { UserAvatarAvatar, UserProfile } from "../../../shared/types/profile";

export type Legend = {
  _id: string,
  userId?: string,
  active: boolean,
  profile: Pick<UserProfile, 'firstname' | 'lastname' | 'defSport' | 'birthdate' | 'info' | 'country'> & {deathDate: string};
  createdBy?: string,
  likeCount?: number,
  renownCount?: number,
  avatar: LegendAvatar,
}

export type LegendAvatar = Pick<UserAvatarAvatar, 'name' | 'url' | 'previewUrl' | 'id'>

export enum Mode {
  READONLY = 'readonly',
  CREATE = 'create',
  ADMIN = 'admin'
}
