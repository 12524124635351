import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiService } from 'src/app/services/ui.service';
import { environment } from 'src/environments/environment';
import { GeocoderService } from "../../services/geocoder.service";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-header-side-menu',
    templateUrl: './header-side-menu.component.html',
    styleUrls: ['./header-side-menu.component.css']
})
export class HeaderSideMenuComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();
    instaUrl = environment.INSTA_PROFILE_URL;
    countryCode: string;

    constructor(private uiService: UiService, private geoService: GeocoderService) { }

    ngOnInit(): void {
      this.subscriptions.add(
        this.geoService.countryCode.subscribe(code => {
          this.countryCode = code
        })
      )
    }

    ngOnDestroy(): void {
      this.subscriptions?.unsubscribe();
    }

    toggleMenu(): void {
      this.uiService.toggleMenuDrawer();
    }
}
