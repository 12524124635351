import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from 'src/app/base/base.component';
import {ApiService} from 'src/app/services/api.service';
import {EventService} from 'src/app/services/event.service';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {UiService} from 'src/app/services/ui.service';
import {Utils} from 'src/app/utils';
import {environment} from 'src/environments/environment';
import {LoginComponent} from '../login/login.component';
import {RegisterOrgComponent} from '../register-org/register-org.component';
import {RegisterUserComponent} from '../register-user/register-user.component';
import {UserComponent} from '../user/user.component';
import {BaseEvent, EventType} from '../../shared/types/base';
import {first} from 'rxjs/operators';
import { NotificationService } from "../../services/notification.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnInit, AfterViewInit {

    appLang: string = "en";
    isUserLoggedIn: boolean = false;
    currentUser: any = null;
    notifications: any[] = [];
    messages: any[] = [];
    appLogo: string = "";
    build = environment.build;

    notifiActionWorking: boolean = false;

    @ViewChild(UserComponent) userComponent: UserComponent;
    @ViewChild('loginButton') loginButton: ElementRef<HTMLButtonElement>;

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        private translateService: TranslateService,
        private dateAdapter: DateAdapter<any>,
        private router: Router,
        private modalService: NgbModal,
        private uiService: UiService,
        private localStorageService: LocalStorageService,
        private notificationService: NotificationService,
    ) {
        super(eventService);

        this.appLogo = Utils.getLogo();
        this.appLang = localStorageService.getAppLang();
        translateService.use(this.appLang);
        dateAdapter.setLocale(this.appLang);
    }

    ngOnInit(): void {
      this.loadUser();
    }

    ngAfterViewInit(): void {
      this.uiService.setLoginButton(this.loginButton.nativeElement);
    }

  init(): void {
      this.loadUser();
    }

    protected eventReceived(event: BaseEvent): void {
        if (event.name === EventType.data && event.data?.currentUser) {
            this.getCurrentUser();
        }

        if (event.name === EventType.data && event.data.notification) {

            if (event.data.notification?.data?.body) { // all notifs are in event
                this.notifications = event.data.notification?.data?.body["notifications"];
                this.messages = event.data.notification?.data?.body["messages"];
            }
            else {
                // get all notifs from server
                this.getNotifications();
            }
        }

        if (event.name === EventType.update && event.data.update === 'notifications') {
            this.getNotifications();
        }

        if (event.name === EventType.data && event.data.userLoggedIn === false) {
            this.currentUser = null;
            this.router.navigateByUrl("/");
        }

        if (event.name === EventType.command) {
            if (event.data.command === "logout") {
                this.logoutUser();
            }
            if (event.data.command === "createOrg") {
                this.openRegisterOrgDialog();
            }
            // TODO LS - Check changeLang command usage in old version
            if (event.data.command === "changeLang") {
                this.appLang = event["lang"];
                this.onLangChange();
            }
            if (event.data.command === 'hideProfile') {
              this.hideProfile();
            }
        }
    }

    toggleLeftMenu() {
        this.uiService.toggleMenuDrawer();
    }

    toggleRightMenu() {
        this.uiService.toggleUserDrawer();
    }

    loadUser() {
        this.isUserLoggedIn = this.apiService.hasUserToken();

        if (this.isUserLoggedIn) {
            // verify user

            this.apiService.verifyUser().subscribe(
                (result) => {
                    this.getCurrentUser();
                },
                (error) => {
                    this.handleApiError(error);
                });

            this.getNotifications();
        }
        else {
            this.currentUser = null;
        }
    }

    getCurrentUser() {
        this.currentUser = this.apiService.getCurrentUser();
    }

    getNotifications() {
        if (!this.isUserLoggedIn) {
            return;
        }

        this.apiService.getNotifications().subscribe(
            (result) => {
                this.notifications = result.body["notifications"];
                this.messages = result.body["messages"];
            },
            (error) => {
                this.handleApiError(error);
            });
    }

    openLoginDialog() {
      this.modalService.dismissAll();
        let modalRef = this.modalService.open(LoginComponent);
        modalRef.componentInstance.loginSuccess.subscribe(() => {
            this.loadUser();
            this.notificationService.initFirebaseToken();
        });
    }

    openUserRegisterDialog() {
        let modalRef = this.modalService.open(RegisterUserComponent, { windowClass: "modal-login" });
        modalRef.componentInstance.registerSuccess.subscribe(() => {
            this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'register_user.success', dismissible: true } });
        });
    }

    openRegisterOrgDialog() {
        let modalRef = this.modalService.open(RegisterOrgComponent, { windowClass: "modal-login" });
        modalRef.componentInstance.registerSuccess.subscribe((event) => {
            if (event?.id) {
                // reload current user from server (to updated user orgs), then open profile page
                this.apiService.getUser().subscribe((result) => {
                    this.router.navigate(["org-profile", event?.id]);
                },
                (error) => { this.handleApiError(error); });
            }
        });
    }

  hideProfile(): void {
    const userProfile = {...this.currentUser.profile};
    userProfile.hidden = !userProfile.hidden;
    this.apiService.updateUserProfile(userProfile).pipe(first()).subscribe(
      () => {
        this.sendEvent({name: EventType.update, data: {update: 'profile'}});
        this.sendEvent({name: EventType.alert, data: {type: 'success', message: userProfile.hidden ? 'profile.hide_profile_success' : 'profile.visible_profile_success', dismissible: true}});
      },
      () => {
       this.getCurrentUser();
        this.sendEvent({name: EventType.alert, data: {type: 'error', message: 'profile.hide_profile_failed', dismissible: true}});
      });
  }

    logoutUser() {
        this.apiService.logoutUser().subscribe(
            (result) => {
                this.loadUser();
                this.notificationService.revokeFirebaseToken();
                this.router.navigateByUrl("/");
            },
            (error) => {
                this.handleApiError(error);
            });
    }

    resendUserActivation() {
        this.apiService.resendActivationLink().subscribe(
            (result) => {
                this.sendEvent( { name: EventType.alert, data: { type: "info", message: "login.activation_link_sent", dismissible: false } });
                this.currentUser.activationSent = true;
            },
            (error) => { this.handleApiError(error); });
    }

    notifAction($event) {
        if ($event.action == "removeNotification" && $event.notifyId) {
            this.notifiActionWorking = true;
            this.apiService.removeNotification($event.notifyId).subscribe(
                (result) => {
                    this.getNotifications();
                    this.notifiActionWorking = false;
                },
                (error) => {this.handleApiError(error); } );
        }
        else if ($event.action == "acceptMembership" && $event.orgId) {

            this.notifiActionWorking = true;
            this.apiService.acceptOrgMembership($event.orgId).subscribe(
                (result) => {
                    this.getNotifications();
                    this.notifiActionWorking = false;
                },
                (error) => {this.handleApiError(error); } );
        }
        else if ($event.action == "declineMembership" && $event.orgId) {

            this.notifiActionWorking = true;
            this.apiService.removeOrgMembership($event.orgId).subscribe(
                (result) => {
                    this.getNotifications();
                    this.notifiActionWorking = false;
                },
                (error) => this.handleApiError(error) );
        }
    }

    onLangChange(): void {
        this.translateService.use(this.appLang).subscribe(() => {
            this.dateAdapter.setLocale(this.appLang);
            this.localStorageService.setAppLang(this.appLang);
            this.sendEvent( { name: EventType.langChange, data: {languageChanged: true} });
        });
    }
}
