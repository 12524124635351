import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { EventType } from '../../shared/types/base';

@Component({
    selector: 'app-user-invitation-options',
    templateUrl: './user-invitation-options.component.html',
    styleUrls: ['./user-invitation-options.component.css']
})
export class UserInvitationOptionsComponent extends BaseComponent implements OnInit {

    _user: any = null;
    get user(): any {
        return this._user;
    }
    @Input() set user(value: any) {
        this._user = value;
        this.updateOptions();
    }

    @Output() userInvited = new EventEmitter();
    @Output() userInvitationAccepted = new EventEmitter();

    items: any[] = [];
    working: boolean = false;

    constructor(
        eventService: EventService,
        private apiService: ApiService) {
        super(eventService);
    }

    ngOnInit(): void {
    }

    updateOptions() {
        this.items = [];
        let adminUser = this.apiService.getCurrentUser();
        adminUser?.adminOrgs?.forEach(org => {
            if (org.active) {
                let member = org.members?.find(m=> m.userId == this.user?._id);
                let item = { "org": org, "member": member };

                if (!item.member || (item.member?.acceptedByUser && !item.member?.acceptedByOrg))
                    this.items.push( item );
            }
        });
    }

    inviteUser(org) {
        if (!org || !this.user || this.working)
            return;

        this.working = true;
        this.apiService.requestOrgMembership(org._id, this.user._id).subscribe(
            (result) => {
                this.sendEvent({ name: EventType.alert, data: { type: 'success', message: 'profile.membership_requested_success', dismissible: true } });
                this.working = false;
                this.userInvited.emit( { "userId": this.user._id } );
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    acceptMember(org) {
        if (!org || !this.user || this.working)
            return;

        this.working = true;
        this.apiService.acceptOrgMembership(org._id, this.user._id).subscribe(
            (result) => {
                this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'profile.membership_request_accepted', dismissible: true } });
                this.working = false;
                this.userInvitationAccepted.emit( { userId: this.user._id } );
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }
}
