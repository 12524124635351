<div class="tags-wrapper">
  <div class="app-tag-item" *ngFor="let tag of _allTags"
       (click)="onTagClick(tag)" role="button"
       [ngClass]="{active: isSelected(tag)}"
  >
    <span>{{ tag[labelKey] }}</span>
    <div class="tag-icon" [ngStyle]="{display: isSelected(tag) ? 'flex' : 'none'}">
      <i class="fa fa-lg fa-check"></i>
    </div>
  </div>
</div>
