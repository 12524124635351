import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';

@Component({
    selector: 'app-profile-members-widget',
    templateUrl: './profile-members-widget.component.html',
    styleUrls: ['./profile-members-widget.component.css']
})
export class ProfileMembersWidgetComponent extends BaseComponent implements OnInit {

    @Input() org: any = null;
    members: any[] = null;
    
    constructor(
        eventService: EventService,
        private apiService: ApiService) {
            super(eventService);
        }

    ngOnInit(): void {
        this.getMembers();
    }

    getMembers() {
        if (!this.org)
            return;

        let params = { start: 0, len: 14 };

        this.apiService.getOrgMembers(this.org._id, params).subscribe(
            (result) => {
                this.members = result?.body["members"] ?? [];
            },
            (error) => this.handleApiError(error) );
    }

    getFullName(member) {
        if (!member?.user?.profile?.firstname || !member?.user?.profile?.lastname) {
            return "";
        }

        return `${member?.user?.profile?.firstname} ${member?.user?.profile?.lastname}`;
    }

    getAvatarUrl(member) {
        return ApiService.getPhotoUrl(member?.user?.avatar?.avatar?.small, Utils.getAvatarSmall());
    }
}
