import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Utils } from 'src/app/utils';

@Component({
    selector: 'app-user-widget',
    templateUrl: './user-widget.component.html',
    styleUrls: ['./user-widget.component.css']
})
export class UserWidgetComponent implements OnInit {

    @Input() user = null;
    @Input() showInvitationOptions: boolean = false;

    isMe: boolean = false;
    _isVerified: boolean = false;
    constructor(private apiService: ApiService) { }

    ngOnInit(): void {
        let currentUser = this.apiService.getCurrentUser();
        this.isMe = this.user != null && this.user._id == currentUser?._id;
        this._isVerified = this.user?.verified || false;
    }

    getAvatarUrl() {
        return ApiService.getPhotoUrl(this.user?.avatar?.avatar?.medium, Utils.getAvatarMedium());
    }

    getCoverUrl() {
        return ApiService.getPhotoUrl(this.user?.avatar?.cover?.previewUrl, Utils.getCover());
    }
}
