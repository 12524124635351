import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/base/base.component';
import { ImageViewerComponent } from 'src/app/components/image-viewer/image-viewer.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { first } from "rxjs/operators";

@Component({
    selector: 'app-profile-photos-widget',
    templateUrl: './profile-photos-widget.component.html',
    styleUrls: ['./profile-photos-widget.component.css']
})
export class ProfilePhotosWidgetComponent extends BaseComponent implements OnInit {

    _user = null;
    @Input() set user(value) {
        this._user = value;

        this.getPhotoFeeds();
    }
    get user() {
        return this._user;
    }

    _org = null;
    @Input() set org(value) {
        this._org = value;
        this.getPhotoFeeds();
    }
    get org() {
        return this._org;
    }

    feeds = [];

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        public modalService: NgbModal) {
            super(eventService);
        }

    ngOnInit(): void {
    }

    getPhotoFeeds() {
        let params = { start: 0, len: 9, "type": "photo" };

        if (this.user) {
            this.apiService.getUserFeeds(this.user._id, params).pipe(first()).subscribe(
                (result) => { this.feeds = result.body["feeds"]; },
                (error) => this.handleApiError(error) );
        }
        else if (this.org) {
            this.apiService.getOrgFeeds(this.org._id, params).pipe(first()).subscribe(
                (result) => { this.feeds = result.body["feeds"]; },
                (error) => this.handleApiError(error) );
        }
    }

    getUrl(photo) {
        return ApiService.getPhotoUrl(photo?.url);
    }

    getPreviewUrl(feed) {
        return ApiService.getPhotoUrl(feed?.photo?.previewUrl, feed?.photo?.url);
    }

    openPhoto(photoIndex) {
        ImageViewerComponent.open(this.modalService, { "feeds": this.feeds, "openIndex": photoIndex, "user": this.user, "org": this.org });
    }
}
