import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { ApiService } from '../services/api.service';
import { EventService } from '../services/event.service';
import { BaseEvent, EventType } from '../shared/types/base';
import { User } from '../shared/types/profile';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends BaseComponent implements OnInit {

    userId = null;
    user: User | null = null;
    loggedUser?: User = null;
    editable: boolean = false;
    superAdminEdit = false;
    currentView: string = "feeds";

    contactUserId = null;
    hasShownContactAlert: boolean = false;

    constructor(
        activatedRoute: ActivatedRoute,
        eventService: EventService,
        private apiService: ApiService,
    ) {

        super(eventService);

        activatedRoute.params.subscribe(params => {

            this.currentView = params["view"];
            this.userId = params["userId"];
            if (!this.userId) {
                this.userId = 'me';
            }

            let loggedInUser = this.apiService.getCurrentUser();
            this.editable = (loggedInUser && (loggedInUser?.superAdmin || loggedInUser._id == this.userId || this.userId == "me"));
            if (!this.hasShownContactAlert && this.editable) {
                this.hasShownContactAlert = true;
                this.getContactUser();
            }

            this.getUser();
        });
    }

    ngOnInit(): void {
      this.loggedUser = this.apiService.getCurrentUser();
    }

    eventReceived(event: BaseEvent): void {
        if (event.name === EventType.update && event.data.update === 'profile') {
            this.getUser();
        }
    }

    getUser(): void {

        this.apiService.getUser(this.userId ?? "me").toPromise().then(
            (res) => {
                this.user = res.body.user;
                this.superAdminEdit = this.loggedUser?.superAdmin && this.loggedUser?._id !== this.user._id;
            },
            (error) => this.handleApiError(error) );
    }

    getContactUser() {
        this.apiService.getContactUser().subscribe(
            (result) => {
                let contactUser = result.body["user"];
                if (contactUser?._id) {
                    this.contactUserId = contactUser?._id;
                }
            },
            (error) => this.handleApiError(error) );
    }
}
