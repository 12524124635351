import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableActionEvent } from "./types/super-admin-mobile-table.types";

@Component({
  selector: 'app-super-admin-mobile-table',
  templateUrl: './super-admin-mobile-table.component.html',
  styleUrls: ['./super-admin-mobile-table.component.css']
})
export class SuperAdminMobileTableComponent {

  @Input() data: any[];
  @Input() type: 'org' | 'user';
  @Output('onActivateProfileChange') activateProfileEmitter = new EventEmitter<TableActionEvent>();
  @Output('onBlockUserChange') blockUserEmitter = new EventEmitter<TableActionEvent>();
  @Output('onMassMessagesChange') massMessagesEmitter = new EventEmitter<TableActionEvent>();

  working = true;

  constructor(
  ) {}

  ngOnInit(): void {
  }

  onAllowMessages(row: Record<string, any>, allow: boolean): void {
    this.massMessagesEmitter.emit({data: row, allowMassMessages: allow});
  }

  onActivateProfile(row: Record<string, any>, activate: boolean): void {
    this.activateProfileEmitter.emit({data: row, activate});
  }

  onBlockUser(data: any, block: boolean): void {
    this.blockUserEmitter.emit({data, block});
  }
}
