import { Injectable } from '@angular/core';
import { first } from "rxjs/operators";
import { BaseComponent } from "../base/base.component";
import { EventService } from "./event.service";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService extends BaseComponent {

  constructor(protected eventService: EventService, private apiService: ApiService) {
    super(eventService);
  }

  updateUserToVerified(userId: string): void {
    this.apiService.updateUserToVerified(userId)
      .pipe(first())
      .subscribe(() => {},
        (e) => this.handleApiError(e)
      );
  }

  updateUserToNotVerified(userId: string): void {
    this.apiService.updateUserToNotVerified(userId)
      .pipe(first())
      .subscribe(() => {},
        (e) => this.handleApiError(e)
      );
  }

  updateOrgToVerified(userId: string): void {
    this.apiService.updateOrgToVerified(userId)
      .pipe(first())
      .subscribe(() => {},
        (e) => this.handleApiError(e)
      );
  }

  updateOrgToNotVerified(userId: string): void {
    this.apiService.updateOrgToNotVerified(userId)
      .pipe(first())
      .subscribe(() => {},
        (e) => this.handleApiError(e)
      );
  }
}
