<mat-drawer-container [hasBackdrop]="true">

    <mat-drawer #menuDrawer mode="over" position="start">
        <app-header-side-menu></app-header-side-menu>
    </mat-drawer>

    <mat-drawer #userDrawer mode="over" position="end">
        <app-user-side-menu></app-user-side-menu>
    </mat-drawer>

    <mat-drawer-content>

        <app-header #headerComponent class="app-header"></app-header>
        <app-alert [listenEvents]="true" ></app-alert>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </mat-drawer-content>

</mat-drawer-container>
