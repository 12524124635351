<!-- global alerts -->
<div class="global-alerts">
    <ngb-toast *ngFor="let a of globalAlerts; let i = index;" header="{{a.header}}" aria-live="polite" aria-atomic="true"
        [autohide]="a.delay > 0" [delay]="a.delay" [class]="a.class"
        (hide)="clearAlert(i)" (hidden)="clearAlert(i)">
        {{a.message}}
    </ngb-toast>
</div>

<!-- local alert -->
<div *ngIf="alert && alert.type == 'info'" class="alert alert-secondary" role="alert">
    <span [innerHTML]="alert?.message" ></span>
    <button type="button" class="close" (click)="clearAlert()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="alert && alert.type == 'success'" class="alert alert-success" role="alert">
    <span [innerHTML]="alert?.message" ></span>
    <button type="button" class="close" (click)="clearAlert()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="alert && alert.type == 'error'" class="alert alert-danger" role="alert">
    <span [innerHTML]="alert?.message" ></span>
    <button type="button" class="close" (click)="clearAlert()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>