<div class="container">

    <div class="row mobile-version" style="width: 100%">
      <form class="content" [formGroup]='searchForm'>
        <mat-form-field appearance="outline" class="mobile-search">
          <mat-label translate>search.placeholder</mat-label>
          <input formControlName='searchInput' matInput type="text" name="searchTerm">
          <mat-icon matSuffix style="cursor: pointer;" (click)="mobileSearch()">search</mat-icon>
        </mat-form-field>
      </form>
    </div>

    <div class="row" style="justify-content: center">
      <mat-spinner diameter="50" *ngIf="working"></mat-spinner>
    </div>

    <div class="row" *ngIf="!working">
      <div class="col col-12 row">

        <ng-container *ngIf="(!users || users.length == 0) && (!orgs || orgs.length == 0) && (!feeds || feeds.length == 0)">
            <div *ngIf="!working" class="h4 no-result" translate>no_result</div>
        </ng-container>

        <div *ngFor="let user of users" class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" >
            <app-user-widget [user]="user" [showInvitationOptions]="hasOrgs"></app-user-widget>
        </div>

        <div *ngFor="let org of orgs" class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" >
            <app-org-widget [org]="org"></app-org-widget>
        </div>

        <div *ngFor="let feed of feeds" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <app-feed [feed]="feed" [user]="feed.user" [org]="feed.org" [allowEdit]="false"></app-feed>
        </div>

      </div>
    </div>
</div>

<app-back-to-top></app-back-to-top>
