<a mat-icon-button *ngIf="items && items.length > 0" [matMenuTriggerFor]="menu" class="btn btn-control bg-blue" ngbTooltip="{{ 'profile.tooltip_options' | translate }}" >
    <svg class="olymp-three-dots-icon"><use xlink:href="#olymp-three-dots-icon"></use></svg>
</a>

<mat-menu #menu="matMenu">

    <ng-container *ngFor="let item of items">

        <a *ngIf="!item.member" mat-menu-item (click)="inviteUser(item.org);"
            translate [translateParams]="{ 'org': item.org.profile?.name }" >
            profile.invite_user_into
        </a>

        <a *ngIf="item.member?.acceptedByUser && !item.member?.acceptedByOrg"
            mat-menu-item (click)="acceptMember(item.org);"
            translate [translateParams]="{ 'org': item.org.profile?.name }" >
            profile.accept_member_of
        </a>

    </ng-container>

</mat-menu>