import { Pipe, PipeTransform } from '@angular/core';
import { EnumService } from "../services/enum.service";

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {
  constructor(private enumService: EnumService) {
  }

  transform(countryCode?: string): string {
    if (!countryCode) return '';
    const countries = this.enumService.getCountries();
    return countries.find(country => country.code === countryCode).name || '';
  }
}
