import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Injectable } from '@angular/core';
import { OrganizationType } from "../types/profile";

@Injectable({
	providedIn: 'root'
})
export class ProfileEditGuard implements CanActivate {
	constructor(private apiService: ApiService, private router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		const view = route.params.view;
    if (!['edit-profile', 'referrals', 'legends', 'legends-manage'].includes(view)) return true;

    const loggedUser = this.apiService.getCurrentUser();
    const rootPath = route.url[0].path;
    const entityPath = this.router.createUrlTree([route.url[0].path, route.url[1].path]);

    if (loggedUser?.superAdmin) return true;

    if (rootPath === 'profile') {
      const userId = route.params.userId;
      const user = await this.apiService.getUserDetails(userId);
      if (['referrals', 'edit-profile'].includes(view)) {
        return loggedUser._id === user._id ? true : entityPath;
      }
    }

    if (rootPath === 'org-profile') {
      const orgId = route.params.orgId;

      if (view === 'legends-manage' && !loggedUser?.superAdmin) {
        return this.router.createUrlTree([entityPath.toString(), 'legends']);
      }

      const org = await this.apiService.getOrganizationDetails(orgId);
      if (view === 'legends' && org.type === OrganizationType.hall_of_fame) {
        return true;
      }

      return loggedUser?.adminOrgs.find(org => org._id === orgId) ? true : entityPath;
    }
	}
}
