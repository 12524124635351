import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

const STORAGE_USER_TOKEN = "storage_user_token";
const STORAGE_USER = "storage_user";
const STORAGE_APP_LANG = "storage_app_lang";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
    }

    getUserToken() {
        return this.storage.get(STORAGE_USER_TOKEN);
    }

    setUserToken(token: string | undefined) {
        if (!token) return window.localStorage.removeItem(STORAGE_USER_TOKEN);
        this.storage.set(STORAGE_USER_TOKEN, token);
    }

    getUser() {
        return this.storage.get(STORAGE_USER);
    }

    setUser(user) {
        this.storage.set(STORAGE_USER, user);
    }

    getAppLang(defaultValue = 'en') {
        let value = this.storage.get(STORAGE_APP_LANG);
        if (!value) {
            value = defaultValue;
        }
        return value;
    }

    setAppLang(lang: string) {
        this.storage.set(STORAGE_APP_LANG, lang);
    }
}
