export class PhonePrefix {
	constructor(
		public prefixNumber: number,
		public countryName: string,
		public countryCode: string,
		public pattern: RegExp,
	) {}
}

export const PhonePrefixes: PhonePrefix[] = [
	new PhonePrefix(null, 'Other', '', null),
	new PhonePrefix(420, 'Czech Republic', 'CZ', /^(60[2-9]\d{6})/),
	new PhonePrefix(49, 'Germany','DE', /^(0(15|1[6-9]|[2-9]))/),
	new PhonePrefix(44, 'United Kingdom','GB', /^(07\d{3})/),
	new PhonePrefix(36, 'Hungary', 'HU', /^(20)/),
	new PhonePrefix(39, 'Italy', 'IT', /^(0\d{9})/),
	new PhonePrefix(48, 'Poland', 'PL', /^(5[0-9])/),
	new PhonePrefix(381, 'Serbia', 'RS', /^(6[0-9])/),
	new PhonePrefix(7, 'Russia', 'RU', /^(9\d{2})\d{7}$/),
	new PhonePrefix(421, 'Slovakia','SK', /^(9\d{2})\d{6}$/),
	new PhonePrefix(380, 'Ukraine', 'UA', /^(67)/),
];

export interface InputPhoneChangeEvent {
  number: string,
  prefix?: PhonePrefix,
}
