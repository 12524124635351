import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { EventType } from '../../shared/types/base';

@Component({
    selector: 'app-message-options',
    templateUrl: './message-options.component.html',
    styleUrls: ['./message-options.component.css']
})
export class MessageOptionsComponent extends BaseComponent implements OnInit {

    @Input() user: any = null;
    @Input() org: any = null;

    adminOrgs: any[] = [];

    constructor(
        eventService: EventService,
        private router: Router,
        private apiService: ApiService) {
            super(eventService);
    }

    ngOnInit(): void {
        let currentUser = this.apiService.getCurrentUser();
        this.updateOptions(currentUser);
    }

    openMsgs() {
        let currentUser = this.apiService.getCurrentUser();
        if (!currentUser) {
            this.sendEvent( { name: EventType.alert, data: { type: 'warning', message: 'messages.login_to_message', dismissible: true } });
            return;
        }

        let route = "";
        if (this.user) {
            route = `/messages/user/${this.user._id}`;
        }
        else if (this.org) {
            route = `/messages/org/${this.org._id}`;
        }

        this.router.navigateByUrl(route);
    }

    updateOptions(currentUser) {
        this.adminOrgs = [];
        currentUser?.adminOrgs?.forEach(adminOrg => {
            if (adminOrg.active && adminOrg.profile?.name) {
                let route = null;
                if (this.user) {
                    route = `/org-messages/${adminOrg._id}/user/${this.user._id}`;
                }
                else if (this.org) {
                    route = `/org-messages/${adminOrg._id}/org/${this.org._id}`;
                }

                if (route) {
                    this.adminOrgs.push( { "name": adminOrg.profile.name, "route": route } );
                }
            }
        });
    }
}
