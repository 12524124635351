<div class="input-phone-wrapper" [ngClass]="{'ng-invalid': phoneControl.invalid, 'ng-dirty': phoneControl.dirty }">
  <mat-select #matSelect [(ngModel)]="selectedPhonePrefix" (selectionChange)="onSelect($event)">
    <mat-select-filter [matSelect]="matSelect"
                       [items]="phonePrefixItems"
                       [filterKey]="'countryName'"
                       (onFiltered)="filteredPhonePrefixItems = $event"
    ></mat-select-filter>
    <mat-select-trigger>
      <div class="custom-trigger" *ngIf="selectedPhonePrefix?.countryName !== 'Other'; else other">
        <img [src]="'assets/svg-flags/' + selectedPhonePrefix.countryCode + '.svg'" [alt]="selectedPhonePrefix.countryCode + '-flag-icon'">
        <span>+{{ selectedPhonePrefix.prefixNumber }}</span>
      </div>
      <ng-template #other>
        <span>{{ selectedPhonePrefix.countryName | translate }}</span>
      </ng-template>
    </mat-select-trigger>
    <mat-option *ngFor="let option of filteredPhonePrefixItems" [value]="option">
      <ng-container>
        <div class="custom-trigger phone-mat-option">
          <img *ngIf="option.countryCode" [src]="'assets/svg-flags/' + option.countryCode + '.svg'" [alt]="option.countryCode + '-flag-icon'">
          <span *ngIf="option.prefixNumber" class="text">+{{ option.prefixNumber }}</span>
          <span class="text">{{ option.countryName | translate }}</span>
        </div>
      </ng-container>
    </mat-option>
  </mat-select>
  <input matInput [formControl]="phoneControl" type="tel" [pattern]="selectedPhonePrefix.pattern" [placeholder]="'profile.phone' | translate">
</div>
