import {Component, OnInit} from '@angular/core';
import {BaseComponent} from 'src/app/base/base.component';
import {ApiService} from 'src/app/services/api.service';
import {EventService} from 'src/app/services/event.service';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {UiService} from 'src/app/services/ui.service';
import {Utils} from 'src/app/utils';
import {BaseEvent, EventType} from '../../shared/types/base';

@Component({
    selector: 'app-user-side-menu',
    templateUrl: './user-side-menu.component.html',
    styleUrls: ['./user-side-menu.component.css']
})
export class UserSideMenuComponent extends BaseComponent implements OnInit {

    user: any = {};

    fullName: string = "";
    subtitle: string = "";
    avatarUrl: string = "";
    allowMassMessages: boolean = false;

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        private uiService: UiService,
        private storageService: LocalStorageService) {
            super(eventService);
        }

    ngOnInit(): void {
        this.updateUser();
    }

    eventReceived(event: BaseEvent) {
        if (event.name === EventType.data && event.data.currentUser) {
            this.updateUser();
        }
        if (event.name === EventType.update && event.data.update === 'profile') {
          this.updateUser();
        }
    }

    updateUser() {
        const currentUser = this.apiService.getCurrentUser();
        currentUser && (this.user = Object.assign({}, currentUser));
        this.fullName = this.user ? `${this.user?.profile?.firstname} ${this.user?.profile?.lastname}` : "";
        this.subtitle = this.user ? `${this.user?.profile?.country}` : "";

        this.avatarUrl = ApiService.getPhotoUrl(this.user?.avatar?.avatar?.small, Utils.getAvatarSmall());

        this.allowMassMessages = this.user?.allowMassMessages === true ||
            this.user?.adminOrgs?.some(o => o.allowMassMessages == true);
    }

    hideProfile(): void {
      this.togglePanel();
      this.sendEvent( { name: EventType.command, data: { command: 'hideProfile' }});
    }

    logout() {
        // send event
        this.sendEvent( { name: EventType.command, data: { command: 'logout' }});
        this.togglePanel();
    }

    registerOrg() {
        // send event
        this.sendEvent( { name: EventType.command, data: { command: 'createOrg' }});
        this.togglePanel();
    }

    togglePanel() {
        this.uiService.toggleUserDrawer();
    }
}
