import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Alert } from '../alert/alert.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestorePasswordComponent } from '../restore-password/restore-password.component';
import { RegisterUserComponent } from '../register-user/register-user.component';
import { BaseComponent } from 'src/app/base/base.component';
import { EventService } from 'src/app/services/event.service';
import { EventType } from '../../shared/types/base';
import { UiService } from "../../services/ui.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

    @Output() loginSuccess = new EventEmitter();

    working: boolean = false;
    loginForm: FormGroup = null;
    alert: Alert = null;
    passwordHide: boolean = true;

    constructor(
        formBuilder: FormBuilder,
        eventService: EventService,
        private apiService: ApiService,
        private modalService: NgbModal,
        private uiService: UiService,
        public activeModal: NgbActiveModal) {
            super(eventService);

        this.loginForm = formBuilder.group({
            email: new FormControl('',[Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,10}$")]),
            password: ['', Validators.required]
          });
    }

    ngOnInit(): void {
    }

    loginUser(formData) {

        this.working = true;
        this.apiService.loginUser(formData.email.toLowerCase(), formData.password).subscribe(
            (result) => {
                this.working = false;
                this.loginForm.reset();

                this.activeModal?.dismiss();
                this.loginSuccess.emit( { } );
                this.uiService.toggleUserDrawer();
            },
            (error) => {
                this.working = false;

                let errorMsg = ApiService.getErrorMessage(error);
                this.alert = { type: EventType.error, message: errorMsg, dismissible: true };
                //this.handleApiError(error);
            });
    }

    openRestoreDialog() {
        let modalRef = this.modalService.open(RestorePasswordComponent);
        modalRef.componentInstance.restoreSuccess.subscribe((event) => {
            this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'restore_password.success', dismissible: true } } );
            if (this.loginForm && event?.email) {
                this.loginForm.patchValue({
                    email: event.email
                  });
            }
        });
    }

    openRegisterDialog() {
        let modalRef = this.modalService.open(RegisterUserComponent, { windowClass: "modal-login" });
        modalRef.componentInstance.registerSuccess.subscribe(() => {
            this.sendEvent( { name: EventType.alert, data: { type: 'success', message: 'register_user.success', dismissible: true } } );
            this.activeModal?.dismiss();
        });
    }
}
