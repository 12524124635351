import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { UserNamePipe } from "../../../pipes/user-name.pipe";

@Pipe({
  name: 'warningText'
})
export class WarningTextPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private userNamePipe: UserNamePipe,
  ) {}

  transform(entity: Record<string, any>, type: 'user' | 'org'): string {
    if (type === 'user') return this.translateService.instant('admin.profile_confirm_edit', {userName: this.userNamePipe.transform(entity.profile)});
    else return this.translateService.instant('admin.profile_confirm_edit_org', {orgName: entity.profile.name});
  }
}
