import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { EventType } from '../../shared/types/base';
import { User } from "../../shared/types/profile";
import { SearchConfig } from "../search-generic/types/search-generic.types";
import { Utils } from "../../utils";

@Component({
    selector: 'app-profile-referral',
    templateUrl: './profile-referral.component.html',
    styleUrls: ['./profile-referral.component.css']
})
export class ProfileReferralComponent extends BaseComponent implements OnInit  {

  @Input() user: User;
  hide: boolean = false;
  selectedUser: any = null;
  searchConfig: SearchConfig = {
    orgs: false,
    feeds: false,
    legends: false,
    users: true,
  };

  constructor(
    eventService: EventService,
    private apiService: ApiService,
  ) {
    super(eventService);
  }

  ngOnInit(): void {}

  async onCopyReferralLink(): Promise<void> {
    await navigator.clipboard.writeText(this.user.referralLink)
  }

  getSuggestedByName() : string {
    if (!this.user?.suggestedBy) {
        return "";
    }
    return `${this.user.suggestedBy.profile.firstname} ${this.user.suggestedBy.profile.lastname}`;
  }

  displayFn(result: any): any {
    if (result) {
      return result.name;
    }
  }

  getAvatar(result: any): any {
    let defLogo = result?.type == 'org' ? Utils.getLogo() : Utils.getAvatarSmall();
    return ApiService.getPhotoUrl(result?.avatar, defLogo);
  }

  setSuggestedBy() {
    if (!this.selectedUser) {
        return;
    }

    this.apiService.updateUserSuggestedBy(this.selectedUser._id).subscribe(
      (result) => {
        this.sendEvent({
            name: EventType.update,
            data: { update: "profile", }
        } );
        this.sendEvent({
            name: EventType.alert,
            data: { type: 'success', message: "profile.update_success", dismissible: true }
        })
      },
      (error) => { this.handleApiError(error); } );
  }

  onUserSelect(user: any): void {
    this.selectedUser = user;
  }
}
