import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';

@Component({
    selector: 'app-mass-messages-history',
    templateUrl: './mass-messages-history.component.html',
    styleUrls: ['./mass-messages-history.component.css']
})
export class MassMessagesHistoryComponent extends BaseComponent implements OnInit {

    displayedColumns: string[] = ['date', 'count', 'message'];

    working: boolean = false;
    resultsLength: number = 0;
    history: any[] = [];

    @Input() fromSubjects: any[];
    _from: string = "";

    set from(value: string) {
        this._from = value;
        this.fromChanged.emit(this._from);
    }

    get from(): string { return this._from; }

    @Output() fromChanged = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    
    constructor(
        eventService: EventService,
        private apiService: ApiService) {
            super(eventService);
        }

    ngOnInit(): void {
        if (this.fromSubjects && this.fromSubjects.length > 0) {
            this.from = this.fromSubjects[0].id;
        }
    }

    ngAfterViewInit() {
        
        this.fromChanged.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.paginator.page, this.fromChanged)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.working = true;
                    return this.getHistory(this.paginator.pageIndex)
                        .pipe(catchError(() => observableOf(null)));
                }),
                map(data => {
                    this.working = false;

                    let messages = data?.body["messages"] ?? [];
                    if (messages === null) {
                        return [];
                    }

                    this.resultsLength = data?.body["count"] ?? 0;
                    return messages;
                })
            ).subscribe(data => this.history = data);
    }

    getHistory(page: number) {
        
        let params = {
            start: (page) * this.paginator.pageSize,
            len: this.paginator.pageSize
        };

        if (this.from == "me") {
            return this.apiService.getMassMessages(params);
        }
        else {
            return this.apiService.getOrgMassMessages(this.from, params);
        }
    }
}
