import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { EnumService } from 'src/app/services/enum.service';
import { EventService } from 'src/app/services/event.service';
import { of } from 'rxjs';
import { delay, first } from 'rxjs/operators';
import { Tag } from '../../shared/types/options';
import { TranslateService } from '@ngx-translate/core';
import { BaseEvent, EventType } from '../../shared/types/base';
import { GeocoderService } from '../../services/geocoder.service';
import { ApiService } from '../../services/api.service';
import { UserProfile } from '../../shared/types/profile';
import { Filter } from './types/types';
import { Utils } from "../../utils";

export enum FilterType {
    Home = 'home',
    Feeds = 'feeds',
    Users = 'users',
    Clubs = 'clubs',
    Firms = 'firms',
    Facilities = 'facalities',
    Map = 'map',
    Events = 'events'
}

@Component({
    selector: 'app-home-filter',
    templateUrl: './home-filter.component.html',
    styleUrls: ['./home-filter.component.css']
})
export class HomeFilterComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
      eventService: EventService,
      private enumService: EnumService,
      private ngZone: NgZone,
      private renderer: Renderer2,
      private translateService: TranslateService,
      private geocoderService: GeocoderService,
      private apiService: ApiService,
    ) {
        super(eventService);
    }

    private geoLoaded = false;
    private placeAutocomplete: google.maps.places.Autocomplete;
    filterTypes = FilterType;

    userPlaces: boolean = false;
    itemsLoaded: boolean = false;
    allCountries = [];
    filteredCountries: Array<any> = [];
    allSports = [];
    filteredSports: Array<any> = [];
    tags = {
        feedTags: [],
        firmTags: [],
        clubTags: [],
        userTags: [],
        facilityTags: [],
        currentTags: []
    }

    filter: Filter = {
        place: null,
        countries: [],
        sports: [],
        tags: [],
        user: null,
        feedTypes: null,
        superAdminFilter: null,
        userRadius: null,
        firmTags: {},
        clubTags: {},
        userTags: {},
        facilityTags: {},
    }

    advancedFilter = {
        tags: {},
        user: null,
        feedTypes: null
    }

    superAdminFilter = {
        active: "",
        name: ""
    }

    _type: FilterType = FilterType.Feeds;
    sportFilterLoaded = false;
    countryLoaded = false;
    _filterCount = 0;
    _showFilterIcon: boolean = false;

    @Input() set type(value: FilterType) {
        this._type = value;

        this.showAdvanced = value != FilterType.Home && value != FilterType.Map && value != FilterType.Events;
        this.advancedIsCollapsed = !this.showAdvanced || value == FilterType.Users;

        if (this.advancedFiltersWrapper) {
            this.renderer.addClass(this.advancedFiltersWrapper.nativeElement, 'transition-duration-none');
            of(null).pipe(delay(500),first()).subscribe(() => this.renderer.removeClass(this.advancedFiltersWrapper.nativeElement, 'transition-duration-none'));
        }

        this.userPlaces = (value == FilterType.Map);
        this.registerAutocomplete();
        this.updateTags();
        this.updateFilterCount();
    }

    get type(): FilterType { return this._type; }

    @Input() caption: string = "";
    @Input() superAdmin: boolean = false;
    @Input() advancedIsCollapsed: boolean = false;
    @Input() initDefaultFilter = true;
    @Output() filterChanged = new EventEmitter();
    @Output() filterLoaded = new EventEmitter();

    showAdvanced: boolean = true;

    @ViewChild('place') public placeElement: ElementRef;
    @ViewChild('advancedFiltersWrapper') advancedFiltersWrapper: ElementRef<HTMLDivElement>;
    @ViewChild('filterWrapper') filterWrapper: ElementRef<HTMLDivElement>;
    @HostListener('window:scroll') onScroll() {
      this._showFilterIcon = document.documentElement.scrollTop >= 200;
    }

    superAdminTags: Array<Tag> = [];

    ngOnInit(): void {
        this.getEnums();
        this.superAdmin && (this.initSuperAdminTags());
        this.subscriptions.add(this.geocoderService.country.subscribe(countryName => this.initCountryFilter(countryName)));
        this.initSportFilter();
        this.updateFilterCount();
    }

    ngAfterViewInit(): void {
      this.subscriptions.add(
        this.geocoderService.onLoad.subscribe(loaded => {
          this.geoLoaded = loaded;
          this.registerAutocomplete();
          this.updateFilterCount();
        })
      )
    }

    ngOnDestroy(): void {
      super.ngOnDestroy();
      this.subscriptions.unsubscribe();
    }

    registerAutocomplete(): void {
      if (!this.placeElement || this.placeAutocomplete || !this.geoLoaded || !this.userPlaces) return;
      this.placeAutocomplete = new google.maps.places.Autocomplete(this.placeElement.nativeElement);
      this.placeAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.filter.place = this.placeAutocomplete.getPlace();
          this.filterChanged.emit({ "filter": this.filter });
        });
      });
    }

    protected eventReceived(event: BaseEvent): void {
        super.eventReceived(event);
        if (event.name === EventType.langChange && event.data.languageChanged) {
            this.getEnums();
            this.superAdmin && (this.initSuperAdminTags());
        }
        if (event.name === EventType.data && event.data.userLoggedIn === false) {
          this.resetFilters();
        }
        if (event.name === EventType.data && event.data.currentUser) {
          this.resetFilters();
          this.initSportFilter();
        }
    }

    clearCountries(): void {
      this.filter.countries = [];
      this.filterChanged.emit({ "filter": this.filter });
      this.updateFilterCount();
    }

    clearSports(): void {
      this.filter.sports = [];
      this.filterChanged.emit({ "filter": this.filter });
      this.updateFilterCount();
    }

    clearFilters() {
        this.filter = {
            place: null,
            countries: [],
            sports: [],
            tags: [],
            user: null,
            feedTypes: null,
            superAdminFilter: null,
            firmTags: {},
            clubTags: {},
            userTags: {},
            facilityTags: {},
        }

        this.advancedFilter = { tags: {}, user: null, feedTypes: null };
        this.updateTags();

        this.superAdminFilter = { active: "", name: "" }

        this.filterChanged.emit({ "filter": this.filter });
        this.updateFilterCount();
    }

    private resetFilters() {
      this.filter = {
        place: null,
        countries: [],
        sports: [],
        tags: [],
        user: null,
        feedTypes: null,
        superAdminFilter: null,
        firmTags: {},
        clubTags: {},
        userTags: {},
        facilityTags: {},
      }
      this.onChange();
    }

    getEnums() {

        this.allSports = this.enumService.getSports();
        this.allCountries = this.enumService.getCountries();
        this.tags.feedTags = this.enumService.getFeedTags();
        this.tags.clubTags = this.enumService.getClubTags();
        this.tags.firmTags = this.enumService.getFirmTags();
        this.tags.facilityTags = this.enumService.getFacilityTags();
        this.tags.userTags = this.enumService.getUserTags();

        this.updateTags();
        this.itemsLoaded = true;
    }

    updateTags() {
        let user = null;
        let feedTypes = null;
        if (this.type == FilterType.Feeds) {
            this.tags.currentTags = this.tags.feedTags;
            feedTypes = { "text": false, "photo": false, "video": false };
            this.superAdminFilter.name = null;
        }
        else if (this.type == FilterType.Users) {
            this.tags.currentTags = this.tags.userTags;
            user = { gender: null, minAge: 0, maxAge: 99, height: NaN, weight: NaN, isMember: "" };
        }
        else if (this.type == FilterType.Clubs) {
            this.tags.currentTags = this.tags.clubTags;
        }
        else if (this.type == FilterType.Firms) {
            this.tags.currentTags = this.tags.firmTags;
        }
        else if (this.type == FilterType.Facilities) {
            this.tags.currentTags = this.tags.facilityTags;
        }
        else {
            this.tags.currentTags = [];
        }

        this.advancedFilter.tags = {};

        if (this.type === FilterType.Events) {
            this.advancedFilter.tags['event'] = true;
            this.searchAdvancedFilter();
        }

        this.advancedFilter.user = user;
        this.advancedFilter.feedTypes = feedTypes;
        this.updateFilterCount();
    }

    onCountrySelect(countries: string[]): void {
      if (countries.includes('any')) {
        this.filter.countries = ['any'];
      }
      this.updateFilterCount();
    }

    onSportSelect(sports: string[]): void {
      if (sports.includes('allSports')) {
        this.filter.sports = ['all'];
      }
      this.updateFilterCount();
    }

    onChange(): void {
        this.filterChanged.emit({ "filter": this.filter });
        this.updateFilterCount();
    }

    searchAdvancedFilter() {
        let tags = [];
        if (this.advancedFilter?.tags) {
            Object.keys(this.advancedFilter.tags).forEach(t => { if (this.advancedFilter?.tags[t]) tags.push(t); });
        }

        if (this.advancedFilter?.user) {
            this.filter.user = this.advancedFilter.user;
        }

        let feedTypes = [];
        if (this.advancedFilter?.feedTypes) {
            Object.keys(this.advancedFilter.feedTypes).forEach(t => { if (this.advancedFilter?.feedTypes[t]) feedTypes.push(t); });
            this.filter.feedTypes = feedTypes;
        }

        this.filter.tags = tags;

        if (this.superAdmin) {
            this.filter.superAdminFilter = this.superAdminFilter;
        }

        this.filterChanged.emit({ filter: this.filter });
        this.updateFilterCount();
    }

    updateFilterCount(): void {
      const filterCount = Object.entries(this.filter).reduce((count, [key, value]) => {
        const baseFilterKeys = ['countries', 'sports'];

        if ([FilterType.Home, FilterType.Events].includes(this._type) && baseFilterKeys.includes(key)) {
          return Utils.isValueValidForFilter(value) ? ++count : count;
        }

        if ([FilterType.Clubs, FilterType.Facilities, FilterType.Feeds].includes(this._type) && [...baseFilterKeys, 'tags'].includes(key)) {
          return Utils.isValueValidForFilter(value) ? ++count : count;
        }

        if (this._type === FilterType.Users && [...baseFilterKeys, 'user', 'tags'].includes(key)) {
          let userFilterCount = 0;
          if (key === 'user') {
            if (!this.filter?.user) return count;
            userFilterCount = Object.values(this.filter.user).reduce((count, value) => {
              return Utils.isValueValidForFilter(value) ? ++count : count;
            }, 0);
          } else {
            if (Utils.isValueValidForFilter(value)) ++count;
          }
          return userFilterCount + count;
        }

        return count;
      }, 0);

      this._filterCount = filterCount;
    }

    private initSuperAdminTags(): void {
        this.superAdminTags = [
            new Tag('text', this.translateService.instant('admin.text')),
            new Tag('photo', this.translateService.instant('admin.photo')),
            new Tag('video', this.translateService.instant('admin.video'))
        ];
    }

  private initSportFilter(): void {
    if (!this.initDefaultFilter) return;
    const userProfile: UserProfile = this.apiService.getCurrentUser()?.profile;
    if (userProfile?.defSport) {
      this.filter.sports = [...this.filter.sports, userProfile.defSport];
    }
    this.sportFilterLoaded = true;
    if (this.countryLoaded) {
      this.onChange();
      this.filterLoaded.emit();
    }
  }

  private initCountryFilter(countryName?: string): void {
    if (!this.initDefaultFilter || countryName === '') return;

    if (countryName) {
      const country = this.allCountries.find(country => country.name === countryName);
      country && (this.filter.countries = [...this.filter.countries, country.code]);
    }

    if (countryName === null) {
      const userProfile: UserProfile = this.apiService.getCurrentUser()?.profile;
      if (userProfile?.country) {
        this.filter.countries = [...this.filter.countries, userProfile.country]
      }
    }
    this.countryLoaded = true;
    if (this.sportFilterLoaded) {
      this.onChange();
      this.filterLoaded.emit();
    }
  }

  onFilterIconClick(event: Event): void {
      event.stopPropagation();
      this.filterWrapper.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end'});
  }
}
