<a [routerLink]="" (click)="openNotifications()" class="mobile-version" ngbTooltip="{{ 'notifications.title' | translate }}">
    <div class="control-icon no-gutters more has-items">
        <svg class="olymp-thunder-icon"><use xlink:href="#olymp-thunder-icon"></use></svg>
        <div class="label-avatar bg-primary" *ngIf="notifications?.length > 0">{{notifications?.length}}</div>
    </div>
</a>

<a [routerLink]="" [matMenuTriggerFor]="notificationsMenu" class="non-mobile-version" ngbTooltip="{{ 'notifications.title' | translate }}">
    <div class="control-icon no-gutters more has-items">
        <svg class="olymp-thunder-icon"><use xlink:href="#olymp-thunder-icon"></use></svg>
        <div class="label-avatar bg-primary" *ngIf="notifications?.length > 0">{{notifications?.length}}</div>
    </div>
</a>

<mat-menu #notificationsMenu="matMenu" xPosition="before" >

    <div class="notif-menu">
        <div class="ui-block-title ui-block-title-small">
            <h6 class="title" translate>notifications.title</h6>
        </div>

        <div *ngIf="!notifications || notifications.length == 0" class="notif-empty" translate>notifications.empty</div>

        <div class="" >
            <perfect-scrollbar [autoPropagation]="true" [config]="{ }">
                <ul class="notification-list">

                    <li *ngFor="let notif of notifications">
                        <a [routerLink]="notif | notificationLink" (click)="removeNotification(notif)">
                          <div class="author-thumb">
                            <a (click)="notClicked(notif)">
                              <img *ngIf="notif.type !== notificationType.FAV_USER_NEW_POST && notif.type !== notificationType.FAV_ORG_NEW_POST; else favAvatar" loading="lazy" class="img-author" src="{{getAvatarUrl(notif)}}" alt="author">
                              <ng-template #favAvatar>
                                <img loading="lazy" class="img-author" src="{{getAvatarUrl(notif)}}" alt="author">
                              </ng-template>
                            </a>
                          </div>
                          <div class="notification-event" style="width: 85%">

                            <div *ngIf="notif.type == notificationType.ORG_ACTIVATED || notif.type == notificationType.ORG_DEACTIVATED">
                              <a [routerLink]="['/org-profile/' + notif.org.id]" class="h6 notification-friend">{{notif.org.profile.name}}</a>
                              &nbsp;
                              <span *ngIf="notif.type == notificationType.ORG_ACTIVATED" translate>notifications.org_activated</span>
                              <span *ngIf="notif.type == notificationType.ORG_DEACTIVATED" translate>notifications.org_deactivated</span>
                            </div>

                            <div *ngIf="notif.type == notificationType.MEMBER_REQUEST_ORG">
                              <a [routerLink]="['/org-profile/' + notif.org._id + '/members']" class="h6 notification-friend">{{notif.org.profile.name}}</a>&nbsp;<span translate [translateParams]="{ 'num': notif.num }">notifications.org_member_request</span>
                            </div>

                            <div *ngIf="notif.type == notificationType.MEMBER_REQUEST">
                              <a [routerLink]="['/org-profile/' + notif.org.id]" class="h6 notification-friend">{{notif.org.profile.name}}</a>
                              &nbsp;
                              <span translate>notifications.member_request</span>
                              <br/>
                              <a [routerLink]="" (click)="acceptMembership(notif.org._id)" class="notification-link" translate>accept</a>
                              &nbsp;
                              <a [routerLink]="" (click)="declineMembership(notif.org._id)" class="notification-link" translate>decline</a>
                            </div>

                            <div *ngIf="notif.type == notificationType.USER_BLOCKED">
                              <span translate>notifications.account_blocked</span>
                            </div>

                            <div *ngIf="notif.type == notificationType.FEED_BLOCKED">
                              <span translate>notifications.feed_blocked</span>
                              <br/>
                              <span *ngIf="notif.feed?.text">"{{notif.feed.text}}"</span>
                            </div>

                            <div *ngIf="notif.type === notificationType.FAV_USER_NEW_POST || notif.type === notificationType.FAV_ORG_NEW_POST">
                              <span *ngIf="notif.type === notificationType.FAV_USER_NEW_POST" class="h6">{{ notif.fav_data.user.profile.firstname }} {{ notif.fav_data.user.profile.lastname }} </span>
                              <span *ngIf="notif.type === notificationType.FAV_ORG_NEW_POST" class="h6">{{ notif.fav_data.org.profile.name }} </span>
                              <span>{{ 'has published a new post' | translate }}</span>
                            </div>

                            <span class="notification-date"><time class="entry-date updated" >{{notif.createdFormated}}</time></span>

                          </div>

                          <div *ngIf="!notif.permanent" class="more remove-button-x">
                            <a role="button" class="p-1" (click)="removeNotification(notif, $event)">
                              <svg class="olymp-little-delete"><use xlink:href="#olymp-little-delete"></use></svg>
                            </a>
                          </div>
                        </a>
                    </li>

                </ul>
            </perfect-scrollbar>
        </div>
    </div>
</mat-menu>

<div class="mobile-notification-screen" #notifScreen *ngIf="showNotifications && notifications" tabindex="-1" (focusout)="openNotifications(false, 150)" >

    {{ notifScreen.focus() }}


    <div class="ui-block-title ui-block-title-small">
        <h6 class="title" translate>notifications.title</h6>
    </div>

    <div *ngIf="!notifications || notifications.length == 0" class="notif-empty" translate>notifications.empty</div>

    <div class="" style="height: 80vh;" >
      <ul class="notification-list">

        <li *ngFor="let notif of notifications">
          <div class="author-thumb">
            <a [routerLink]="" (click)="notClicked(notif); openNotifications(false);"><img loading="lazy" class="img-author" src="{{getAvatarUrl(notif)}}" alt="author"></a>
          </div>

          <div class="notification-event" style="width: 85%">

            <div *ngIf="notif.type == 'org-activated' || notif.type == 'org-deactivated'">
              <a [routerLink]="['/org-profile/' + notif.org.id]" (click)="openNotifications(false)" class="h6 notification-friend">{{notif.org.profile.name}}</a>
              &nbsp;
              <span *ngIf="notif.type == 'org-activated'" translate>notifications.org_activated</span>
              <span *ngIf="notif.type == 'org-deactivated'" translate>notifications.org_deactivated</span>
            </div>

            <div *ngIf="notif.type == 'org-member-request'">
              <a [routerLink]="['/org-profile/' + notif.org._id + '/members']" (click)="openNotifications(false)" class="h6 notification-friend">{{notif.org.profile.name}}</a>&nbsp;<span translate [translateParams]="{ 'num': notif.num }">notifications.org_member_request</span>
              <a [routerLink]="['/org-profile/' + notif.org._id + '/members']" (click)="openNotifications(false)" class="notification-link" translate>notifications.org_member_request_visit</a>.
            </div>

            <div *ngIf="notif.type == 'member-request'">
              <a [routerLink]="['/org-profile/' + notif.org.id]" (click)="openNotifications(false)" class="h6 notification-friend">{{notif.org.profile.name}}</a>
              &nbsp;
              <span translate>notifications.member_request</span>
              <br/>
              <a [routerLink]="" (click)="acceptMembership(notif.org._id); openNotifications(false);" class="notification-link" translate>accept</a>
              &nbsp;
              <a [routerLink]="" (click)="declineMembership(notif.org._id); openNotifications(false);" class="notification-link" translate>decline</a>
            </div>

            <div *ngIf="notif.type == 'user-blocked'">
              <span translate>notifications.account_blocked</span>
            </div>

            <div *ngIf="notif.type == 'feed-blocked'">
              <span translate>notifications.feed_blocked</span>
              <br/>
              <span *ngIf="notif.feed?.text">"{{notif.feed.text}}"</span>
            </div>

            <span class="notification-date"><time class="entry-date updated" >{{notif.createdFormated}}</time></span>

          </div>

          <div *ngIf="!notif.permanent" class="more remove-button-x" style="opacity: 100;">
            <a [routerLink]="" (click)="removeNotification(notif); openNotifications(false);"><svg class="olymp-little-delete"><use xlink:href="#olymp-little-delete"></use></svg></a>
          </div>

        </li>
      </ul>
    </div>
</div>
