import { Directive, HostListener, Inject, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appStretchToBottom]'
})
export class StretchToBottomDirective implements OnInit {

    @Input('appStretchToBottom') elem: any = null;
    @Input() stretchToBottomOffset: number = 0;

    constructor(
        private renderer: Renderer2) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.onResize(null);
    }

    @HostListener('window:resize', ['$event']) onResize($event) {

        if (this.elem) {
            let r = this.elem.getBoundingClientRect();
            if (!r) {
                return;
            }

            let height = (document.body.clientHeight - r.top) - this.stretchToBottomOffset;
            if (height > 0)
                this.renderer.setStyle(this.elem, 'height', `${height}px`);
            else
                this.renderer.setStyle(this.elem, 'height', `50vh`);
        }
    }
}
