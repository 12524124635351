import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumService } from "../../../../services/enum.service";
import { EventType } from "../../../../shared/types/base";
import { BaseComponent } from "../../../../base/base.component";
import { EventService } from "../../../../services/event.service";
import { ApiService } from "../../../../services/api.service";
import { Utils } from "../../../../utils";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-home-photo-item',
  templateUrl: './home-photo-item.component.html',
  styleUrls: ['./home-photo-item.component.css']
})
export class HomePhotoItemComponent extends BaseComponent implements OnInit {

  @Input() feed: any;
  @Input() editable = false;
  @Output() onOpenFeed = new EventEmitter();
  @Output() onRemoveFeed = new EventEmitter();
  loadState: 'pending' | 'loaded' | 'failed' = 'pending';
  _previewUrl = '';
  _addedText = '';
  _orgType = '';
  _hasDefaultOrg = false;
  _isLikedByMe = false;
  _userDefOrg = null;
  _userDefOrgType = null;

  constructor(
    protected eventService: EventService,
    private enumService: EnumService,
    private apiService: ApiService,
    private translateService: TranslateService
  ) {
    super(eventService);
  }

  ngOnInit(): void {
    this._previewUrl = ApiService.getPhotoUrl(this.feed?.photo?.previewUrl, this.feed?.photo?.url);
    this._addedText = Utils.getAddedText(this.feed?.updated, this.translateService);
    this._orgType = this.getOrgType(this.feed?.org);
    this._hasDefaultOrg = this.userHasOrg(this.feed?.user);
    this._isLikedByMe = this.hasMyLike(this.feed);
    this._userDefOrg = this.getUserDefOrg(this.feed?.user);
    this._userDefOrgType = this.getOrgType(this._userDefOrg);
  }

  userHasOrg(user) {
    return user?.organizations?.some(o => o.active);
  }

  getUserDefOrg(user) {
    return user?.organizations?.find(o => o._id == user?.defOrg) ?? user?.organizations?.find(o => o.active);
  }

  hasMyLike(feed) {
    let currentUser = this.apiService.getCurrentUser();
    return feed?.likes?.some(l => l == currentUser?._id) ?? false;
  }

  likeFeed(feed) {
    if (!feed?._id) {
      return;
    }

    let currentUser = this.apiService.getCurrentUser();
    if (!currentUser) {
      this.sendEvent( {
        name: EventType.alert,
        data: { type: 'warning', message: 'favs.login_to_like', dismissible: true }
      });
      return;
    }

    if (!this.hasMyLike(feed)) {
      this.apiService.likeFeed(feed._id).subscribe(
        (result) => {
          if (!feed.likes) {
            feed.likes = [];
          }
          feed.likes.push(currentUser._id);
        },
        (error) => this.handleApiError(error) );
    }
    else {
      this.apiService.removeFeedLike(feed._id).subscribe(
        (result) => {
          let idx = feed.likes?.findIndex(l => l == currentUser?._id) ?? -1;
          if (idx > -1) {
            feed.likes.splice(idx, 1);
          }
        },
        (error) => this.handleApiError(error) );
    }
  }

  openPhoto(): void {
    this.onOpenFeed.emit(this.feed);
  }

  removePhoto(): void {
    this.onRemoveFeed.emit(this.feed);
  }

  getOrgType(org: any) {
    if (!org || !['sport_club', 'sport_facility', 'firm'].includes(org.type)) return '';
    return this.enumService.getOrgTypes()?.find(orgType => orgType.id === org.type)?.name || '';
  }

  onImgLoad(): void {
    this.loadState = 'loaded';
  }

  onImgError(): void {
    this.loadState = 'failed';
  }

}
