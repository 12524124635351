import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'age'
})
export class AgePipe implements PipeTransform {
  transform(value: Date | {startDate: Date, endDate: Date}): string {
    if (!value) return '';

    if (value instanceof Date) return this.getYearsDiff(value).toString();
    if (value.startDate && !value.endDate) return this.getYearsDiff(value.startDate).toString();
    if (!value.startDate) return '';

    return moment(value.startDate).diff(moment(value.endDate)).toString();
  }

  private getYearsDiff(date: Date): number {
    const today = moment();
    const mDate = moment(date);
    return today.diff(mDate, 'year');
  }
}
