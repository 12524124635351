import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { Alert } from '../alert/alert.component';

@Component({
    selector: 'app-restore-password',
    templateUrl: './restore-password.component.html',
    styleUrls: ['./restore-password.component.css']
})
export class RestorePasswordComponent implements OnInit {

    @Output() restoreSuccess = new EventEmitter();

    workingCode: boolean = false;
    alertCode: Alert = null;
    working: boolean = false;
    alert: Alert = null;

    email: string = "";
    code: string = "";
    password: string = "";

    constructor(
        private translateService: TranslateService,
        private apiService: ApiService,
        public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    requestPasswordChangeCode() {
        if (!this.email)
            return;

        this.workingCode = true;
        this.apiService.changePasswordRequest(this.email).subscribe(
            (result) => {
                this.workingCode = false;
                this.alertCode = {
                    type: "success", dismissible: false,
                    message: this.translateService.instant("restore_password.code_sent_info") };
            },
            (error) => {
                this.workingCode = false;
                let errorMsg = ApiService.getErrorMessage(error);
            this.alertCode = { type: "error", "message": errorMsg, dismissible: true };
            });
    }

    requestPasswordChange() {
        if (!this.code || !this.password)
            return;

        this.working = true;
        this.apiService.changePassword({ "code": this.code, "password": this.password }, false).subscribe(
            (result) => {
                this.working = false;

                this.activeModal.dismiss();
                this.restoreSuccess.emit( { "email": this.email } );
            },
            (error) => {
                this.working = false;
                let errorMsg = ApiService.getErrorMessage(error);
                this.alert = { type: "error", "message": errorMsg, dismissible: true };
            });
    }
}
