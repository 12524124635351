import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import { BaseEvent } from '../shared/types/base';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor() { }

  private subject = new Subject<any>();

    publishData(data: BaseEvent) {
        this.subject.next(data);
    }

    getObservable(): Subject<BaseEvent> {
        return this.subject;
    }
}
