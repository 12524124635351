<div *ngIf="feed" class="ui-block">

    <article class="hentry post">

      <h4 *ngIf="feed.blocked"
         style="color: red; font-weight: 700; text-align: right; width: 100%"
         ngbTooltip="{{'feeds.blocked_tooltip' | translate}}" translate>feeds.blocked</h4>

        <div class="post__author author vcard inline-items">
            <a [routerLink]="[getProfileUrl()] "><img loading="lazy" src="{{getAvatarUrl()}}" alt="author"></a>

            <div class="author-date">
                <a [routerLink]="[getProfileUrl()] "><span class="h6 post__author-name fn">{{getName()}}</span></a>
                <div class="post__date">
                    <time class="published">
                        {{getDate()}}
                    </time>
                </div>
            </div>

            <div *ngIf="allowEdit" class="more"><svg class="olymp-three-dots-icon"><use xlink:href="#olymp-three-dots-icon"></use></svg>
                <ul class="more-dropdown">
                    <li>
                        <a [routerLink]="" (click)="removeIt()" translate>feeds.remove_feed</a>
                    </li>
                </ul>
            </div>

        </div>

        <ng-container>
          <div class="feed-body">
            <div [ngClass]="hasAttachment() ? 'attachment-feed' : 'no-attachment'">

              <div *ngIf="feed.type == 'photo'" class="photo-grid" [ngClass]="{
               'photos-1': _photosLength == 1,
               'photos-2': _photosLength == 2,
               'photos-3': _photosLength == 3,
               'photos-4': _photosLength == 4,
               'photos-5': _photosLength > 4 }">
                <div class="post-thumb">
                  <a [routerLink]="" (click)="openPhoto(feed.photo)">
                    <img loading="lazy" src="{{getFeedPhotoUrl(feed.photo)}}" alt="photo">
                  </a>
                </div>
                <ng-container *ngFor="let photo of feed.additionalPhotos; let i = index;">
                  <div *ngIf="i<4" class="post-thumb">
                    <a [routerLink]="" (click)="openPhoto(i + 1)">
                      <img [ngClass]="{'more-mask': i==3 && _photosLength >5}" loading="lazy" src="{{getFeedPhotoUrl(photo)}}" alt="photo">
                    </a>
                    <div *ngIf="i==3 && _photosLength >5" class="plus-mask" (click)="openPhoto(i + 1)">
                      <svg class="olymp-plus-icon"><use xlink:href="#olymp-plus-icon"></use></svg>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div *ngIf="feed.type == 'video'" class="video-container">
                <iframe class="video" [src]="youtubeUri" style="border: none" allowfullscreen></iframe>
              </div>

            </div>

            <div *ngIf="feed.text" class="feed-textarea">
              <p>{{ getFeedText() }}</p>
            </div>
          </div>
          <div class="comments-shared" style="width: 100%; text-align: right;">

            <ng-content></ng-content>

            <a [routerLink]="" (click)="addFav()" class="post-add-icon inline-items" ngbTooltip="{{ (isMyFav ? 'favs.remove' : 'favs.add') | translate }}">
              <svg *ngIf="!isMyFav" class="olymp-star-icon"><use xlink:href="#olymp-star-icon"></use></svg>
              <i *ngIf="isMyFav" class="liked fas fa-star"></i>
            </a>

            <a [routerLink]="" (click)="likeFeed()" class="post-add-icon inline-items" ngbTooltip="{{ 'favs.like' | translate }}" >
              <svg *ngIf="!hasMyLike" class="olymp-heart-icon"><use xlink:href="#olymp-heart-icon"></use></svg>
              <i *ngIf="hasMyLike" class="liked fas fa-heart"></i>
              <span >{{feed.likes ? feed.likes.length : 0}}</span>
            </a>

            <a [routerLink]="" (click)="collapseComments.toggle()" class="post-add-icon inline-items">
              <svg class="olymp-speech-balloon-icon"><use xlink:href="#olymp-speech-balloon-icon"></use></svg>
              <span>{{ commentsLen }} <span translate>comments.comments</span></span>
            </a>
          </div>
        </ng-container>


        <!-- comments -->
        <div class="" #collapseComments="ngbCollapse" [(ngbCollapse)]="commentsCollapsed">

            <div class="row" style="margin-top: 12px;">

                <div class="col col-12" *ngIf="feed.hasFullComments">

                    <span *ngIf="!allowComment && (!feed?.comments || feed.comments.length == 0)" translate>comments.empty</span>

                    <ul class="comments-list">
						            <li *ngFor="let comment of feed.comments" class="comment-item">
                            <app-comment [comment]="comment" [allowComment]="allowComment" (reply)="replyComment($event)" [openReplies]="openReplyComment == comment.id" ></app-comment>
						            </li>
                    </ul>
                </div>

                <ng-container *ngIf="allowComment">
                    <div class="col col-12">

                        <mat-form-field appearance="outline">
                            <mat-label translate>comments.feed_comment</mat-label>
                            <textarea matInput name="newComment" [(ngModel)]="newComment" ></textarea>
                        </mat-form-field>

                    </div>

                    <div class="col col-12" >

                        <div class="row " style="height: 40px; vertical-align: middle;">

                            <div class="col" style="text-align: left; margin-top: 10px">

                                <a [routerLink]="" class="emoji-control" [matMenuTriggerFor]="emojisMenu" #matMenuTrigger="matMenuTrigger">
                                    <svg class="olymp-happy-face-icon"><use xlink:href="#olymp-happy-face-icon"></use></svg>
                                </a>

                                <mat-menu #emojisMenu="matMenu" >
                                    <emoji-mart (click)="$event.stopPropagation();" class="emoji-mart" [showSingleCategory]="true" (emojiSelect)="addEmoji($event); matMenuTrigger.closeMenu();" title="{{ 'choose_emoji' | translate }}"></emoji-mart>
                                </mat-menu>

                            </div>

                            <div class="col" style="text-align: right;">
                                <button class="btn btn-primary btn-md-2" style="margin-bottom: 0px;" (click)="addComment()" [disabled]="!newComment" translate>comments.add_comment</button>
                            </div>

                        </div>
                    </div>
                </ng-container>
            </div>

        </div>

    </article>
</div>
