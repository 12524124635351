import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectOption } from '../../shared/types/options';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Utils } from "../../utils";

@Component({
  selector: 'app-date-picker-simple',
  templateUrl: './date-picker-simple.component.html',
  styleUrls: ['./date-picker-simple.component.css'],
})
export class DatePickerSimpleComponent implements OnInit {
  constructor(private formBuilder: FormBuilder) { }

  @Input() set date(date: Date | string | undefined) {
    this.initForm();
    if (!date || !this._dateForm) return;
    if (typeof date === 'string' ) this._date = new Date(date);
    else this._date = date;

    this._dateForm.get('day').setValue(this._date?.getDate() ?? null);
    this._dateForm.get('month').setValue(this._date?.getMonth() ?? null);
    this._dateForm.get('year').setValue(this._date?.getFullYear() ?? null);
    this._date?.setHours(23, 59, 59, 0);
  }

  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Output('onChange') onChangeEmitter = new EventEmitter<Date>();

  _date: Date;
  _dateForm: FormGroup;
  _emptyOption = new SelectOption('-', null);


  dayOptions: Array<SelectOption<string, number>> = [];
  monthOptions: Array<SelectOption<string, number>> = [];
  yearOptions: Array<SelectOption<string, number>> = [];

  selectedDay: number;
  selectedMonth: number;
  selectedYear: number;

  ngOnInit(): void {
    this.initOptions();
    this.initForm();
    if (this.disabled) {
      this._dateForm.disable();
    }
  }

  onDayChange(day: number): void {
    this.selectedDay = day;
    this._dateForm.get('day').setValue(day);
    this.updateDate();
  }

  onMonthChange(month?: number): void {
    this.dayOptions = [this._emptyOption];
    if (!Utils.isNullish(month)) {
      this.selectedMonth = month;
      this._dateForm.get('month').setValue(month);
    }
    const monthDays = this.getMonthEndDate();

    for (let i = 1; i <= monthDays; i++) {
      this.dayOptions = [...this.dayOptions, new SelectOption(i.toString(), i)];
    }

    if (this.selectedDay > monthDays) {
      this.selectedDay = monthDays;
      this._dateForm.get('month').setValue(monthDays);
    }

    this.updateDate();
  }
  onYearChange(year: number): void {
    this.selectedYear = year;
    this._dateForm.get('year').setValue(year);
    this.updateDate();
    this.onMonthChange();
  }

  private initForm(): void {
    if (this._dateForm) return;
    this._dateForm = this.formBuilder.group({
      day: [null, this.required ? Validators.required : undefined],
      month: [null, this.required ? Validators.required : undefined],
      year: [null, this.required ? Validators.required : undefined],
    }, {
      validators: Validators.required
    });
  }

  private initOptions() {
    this.dayOptions.push(this._emptyOption);
    this.monthOptions.push(this._emptyOption);
    this.yearOptions.push(this._emptyOption);
    this.initDayOptions();
    this.initMonthOptions();
    this.initYearOptions();
  }

  private initDayOptions(): void {
    for (let i = 1; i <= 31; i++) {
      this.dayOptions.push(new SelectOption(i.toString(), i));
    }
  }

  private initMonthOptions(): void {
    for (let i = 0; i < 12; i++) {
      this.monthOptions.push(new SelectOption(`months.${ i }`, i));
    }
  }

  private initYearOptions(): void {
    let startYear = 1900;
    const endYear = new Date().getFullYear();

    for (let i = startYear; startYear <= endYear; i++) {
      this.yearOptions.push(new SelectOption(startYear.toString(), startYear));
      startYear = startYear + 1;
    }

    this.yearOptions.sort((a, b) => {
      if (typeof a.value === 'object') return -1;
      if (typeof b.value === 'object') return 1;
      return b.value - a.value;
    });
  }

  private getMonthEndDate(): number {
    if (this.selectedMonth === 1 && !this.selectedYear) return 28;

    if (this._date) {
      return new Date(this._date.getFullYear(), this.selectedMonth + 1, 0).getDate();
    }

    if (!this.selectedYear || !this.selectedMonth) return 31;
    return new Date(this.selectedYear, this.selectedMonth + 1, 0).getDate();
  }

  private updateDate(): void {
    if (Utils.isNullish(this.selectedDay) || Utils.isNullish(this.selectedMonth) || Utils.isNullish(this.selectedYear)) {
      this.onChangeEmitter.emit(null);
      return;
    }
    this._date = new Date(this.selectedYear, this.selectedMonth, this.selectedDay);
    this.onChangeEmitter.emit(this._date);
  }
}
