<header class="header header-new">
  <div class="header-wrapper">
    <div class="header-nav-menu">
      <a role="button" class="logo logo-static" (click)="toggleLeftMenu()">
        <svg class="olymp-menu-icon hamburger">
          <use xlink:href="#olymp-menu-icon"></use>
        </svg>
      </a>
    </div>

    <a class="header-logo" [routerLink]="['/']">
      <img src="assets/img/logo.png" alt="header-logo"/>
      <h6 class="text-white m-0"> {{ 'app_name' | translate }}</h6>
    </a>

    <div class="header-center">
      <app-quick-search class="d-none d-md-flex full-width"></app-quick-search>

      <a class="d-md-none d-flex" id="search-icon"  [routerLink]="['/search']" ngbTooltip="{{ 'home.search' | translate }}">
        <svg class="olymp-magnifying-glass-icon left-menu-icon" style="fill: white;">
          <use xlink:href="#olymp-magnifying-glass-icon"></use>
        </svg>
      </a>
    </div>

    <div class="header-right">

        <app-header-messages id="messages-icon" *ngIf="isUserLoggedIn" [messages]="messages"></app-header-messages>
        <app-header-notifications
          *ngIf="isUserLoggedIn"
          [notifications]="notifications"
          (notifAction)="notifAction($event)"
          id="notifications-icon"
        ></app-header-notifications>

        <a class="align-items-center d-flex" id="map-icon" [routerLink]="['home/map']" [ngbTooltip]="'home.map' | translate" >
          <svg class="olymp-add-a-place-icon left-menu-icon" style="fill: white;" >
            <use xlink:href="#olymp-add-a-place-icon"></use>
          </svg>
        </a>

        <div class="lang-control">
          <mat-form-field class="lang-select-field infix-none" appearance="legacy">
            <mat-select name="appLang" [(ngModel)]="appLang" (ngModelChange)="onLangChange()">
              <mat-option value="en" >EN</mat-option>
              <mat-option value="sk" >SK</mat-option>
              <mat-option value="cz" >CZ</mat-option>
              <mat-option value="de" >DE</mat-option>
              <mat-option value="it" >IT</mat-option>
              <mat-option value="pl" >PL</mat-option>
              <mat-option value="ua" >UA</mat-option>
              <mat-option value="ru" >RU</mat-option>
              <mat-option value="hu" >HU</mat-option>
              <mat-option value="rs" >RS</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <app-user
          *ngIf="currentUser"
          [user]="currentUser"
          (logoutUser)="logoutUser()"
          (registerOrg)="openRegisterOrgDialog()"
          (hideProfileEmitter)="hideProfile()"
        ></app-user>

        <div *ngIf="!isUserLoggedIn" class="form-inline">
          <button #loginButton id="login-button" class="btn btn-primary btn-md-2" (click)="openLoginDialog()" translate>btn_login</button>
        </div>
    </div>
  </div>

  <div class="header-content-wrapper lower-header">

    <a [routerLink]="" class="item-middle" (click)="toggleLeftMenu()">
      <div class="control-icon more has-items">
        <svg class="olymp-menu-icon">
          <use xlink:href="#olymp-menu-icon"></use>
        </svg>
      </div>
    </a>

    <div class="item-middle">
      <a [routerLink]="['home/map']" ngbTooltip="{{ 'home.map' | translate }}" >
        <svg class="olymp-add-a-place-icon left-menu-icon" style="fill: white;" [routerLinkActive]="['active-icon']" [routerLink]="['home/map']">
          <use xlink:href="#olymp-add-a-place-icon"></use>
        </svg>
      </a>
    </div>

    <app-header-messages [routerLinkActive]="['active-icon']"
                         [routerLink]="['/messages']" class="item-middle"
                         *ngIf="isUserLoggedIn" [messages]="messages">
    </app-header-messages>

  </div>

</header>

<div class="header-spacer"></div>

<div *ngIf="currentUser && !currentUser.active && !currentUser.activationSent" class="alert alert-secondary"
    role="alert">
    <span translate>
        login.user_inactive_msg
        <a href="#" (click)="!!resendUserActivation()" translate>login.user_inactive_msg_link</a>.
    </span>
</div>
