<app-profile-header [org]="org" [editable]="editable" ></app-profile-header>

<div *ngIf="!org?.active && (isCurrentUserAdmin || isCurrentUserOwner())" class="container">
  <div class="alert alert-warning d-flex">
    <span class="h6">
      {{ 'profile.org_backlink' | translate }}
      <a class="inline flex-shrink-0" href="assets/logos.zip" download="assets/logos.zip">{{ 'profile.download_logos' | translate }}</a>
    </span>
  </div>
</div>

<div *ngIf="editable && showMemberAnnouncement && currentView !== 'legends' && currentView !== 'legends-manage'" class="container">
    <div class="alert alert-info" role="alert">
        <span translate>profile.org_ask_members_announcement</span>
        &nbsp;
        <button type="button" class="close" (click)="showMemberAnnouncement = false" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<app-profile-feeds *ngIf="currentView == 'feeds'" [org]="org" [editable]="editable" ></app-profile-feeds>
<app-profile-view *ngIf="!currentView || currentView == 'view-profile'" [org]="org" ></app-profile-view>
<app-org-profile-members *ngIf="currentView == 'members'" [org]="org" ></app-org-profile-members>
<app-profile-photos *ngIf="currentView == 'photos'" [org]="org" ></app-profile-photos>
<app-org-profile-settings *ngIf="currentView == 'edit-profile'" [setting]="currentView" [org]="org" ></app-org-profile-settings>
<app-org-profile-legends [org]="org" [tab]="currentView" *ngIf="currentView === 'legends' || currentView === 'legends-manage'"></app-org-profile-legends>
