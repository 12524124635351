import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Legend, Mode } from "../../types/org-profile-legends.types";
import { BaseComponent } from "../../../../base/base.component";
import { EventService } from "../../../../services/event.service";
import { EventType } from "../../../../shared/types/base";

@Component({
  selector: 'app-legend-form-dialog',
  templateUrl: './legend-form-dialog.component.html',
  styleUrls: ['./legend-form-dialog.component.css']
})
export class LegendFormDialogComponent extends BaseComponent implements OnInit {

  constructor(protected eventService: EventService) {
    super(eventService);
  }

  modal: NgbModalRef;
  legend: Legend;
  mode: Mode;
  onClose: (data: any) => void;

  ngOnInit(): void {
  }

  close(): void {
    this.onClose?.('close');
    this.modal?.dismiss('');
  }

  onSuccess(): void {
    this.modal?.close('success');
  }
}
