
<div class="row justify-content-center">

    <ng-container *ngIf="!feeds || feeds.length == 0">
        <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
        <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
    </ng-container>

    <!-- <div *ngIf="!superAdmin" class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"> -->
    <div *ngIf="!superAdmin" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

        <ng-container *ngFor="let feed of feeds">
            <app-feed [feed]="feed" [user]="feed.user" [org]="feed.org" [allowEdit]="false"></app-feed>
        </ng-container>

    </div>

    <div *ngIf="superAdmin" class="col col-sm-12">

        <ng-container *ngFor="let feed of feeds">
          <app-feed [feed]="feed" [user]="feed.user" [org]="feed.org" [allowEdit]="false">
            <div class="admin-options">
              <ng-container *ngIf="!feed.blocked">
                <span style="color: green;" translate>admin.nonblocked</span>
                (<a [routerLink]="" (click)="blockFeed(feed)" translate>admin.block</a>)
              </ng-container>

              <ng-container *ngIf="feed.blocked">
                <span style="color: red;" translate>admin.blocked</span>
                (<a [routerLink]="" (click)="blockFeed(feed, false)" translate>admin.unblock</a>)
              </ng-container>
            </div>
          </app-feed>
        </ng-container>

    </div>

</div>

<app-load-page-button *ngIf="hasMorePages" (loadMore)="loadMore()" ></app-load-page-button>
