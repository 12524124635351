<div *ngIf="tab === 'legends'" class="container">
  <div class="alert alert-info fade show" role="alert">
    <span [innerHtml]="'header_info.hall_of_fame' | translate"></span>
  </div>
  <div class="ui-block" *ngIf="_currentUser">
    <div class="ui-block-content">
      <div class="row align-items-center justify-content-between gap-1">
        <div class="col col-12 col-md-6">
          <app-search-generic
            *ngIf="!_selectedLegend"
            [label]="'legend.renown_label'"
            [matSuffix]="matSuffix"
            [itemTemplate]="item"
            [searchConfig]="searchConfig"
            [inputDisplayFn]="printName"
            (onSelect)="onLegendRenownSelect($event)"
          >
            <ng-template #matSuffix>
              <div class="p-2">
                <button [disabled]="!_pickedLegend" class="btn btn-primary m-0" (click)="onSaveRenownLegend($event)">{{ 'save' | translate }}</button>
              </div>
            </ng-template>
            <ng-template #item let-result>
              <div class="author-thumb">
                <img loading="lazy" class="img-author" src="{{getAvatar(result)}}" alt="result">
              </div>
              <span class="result-text">
                <span>{{ result.profile.firstname }} {{ result.profile.lastname }}</span>
                <div *ngIf="result.profile.country" class="result-text-small">{{ 'countries.' + result.profile.country | translate }}</div>
              </span>
            </ng-template>
          </app-search-generic>
          <div *ngIf="_selectedLegend" class="row flex-nowrap m-0">
            <span (click)="onShowProfile()" style="align-self: center; white-space: nowrap; cursor: pointer;">{{ _selectedLegend.profile?.firstname }} {{ _selectedLegend.profile?.lastname }}</span>
            <button class="btn btn-primary m-0 ml-3 w-100" (click)="onLegendRenownDelete()">{{ 'legend.remove_selection' | translate }}</button>
          </div>
        </div>
        <div class="col col-12 col-md-4">
          <div *ngIf="_pendingRequest" class="alert alert-info m-0">
            <span>{{ 'legend.request-pending' | translate }}</span>
          </div>
          <button *ngIf="!_pendingRequest" class="btn btn-primary m-0 full-width" (click)="openLegendFormDialog()">{{ 'legend.create_profile_athlete' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <section id="legends-list">
    <div class="ui-block">
      <div class="ui-block-content">
        <div class="row">
          <div class="col col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'home.filter_country' | translate }}</mat-label>
              <mat-select #countrySelect [(ngModel)]="filter.country" (selectionChange)="onCountrySelect($event)">
                <mat-select-filter
                  [matSelect]="countrySelect"
                  [autoFocus]="true"
                  [filterKey]="'name'"
                  [placeholder]="'home.search' | translate"
                  [items]="_countries"
                  (onFiltered)="_filteredCountries = $event"
                ></mat-select-filter>
                <mat-option *ngFor="let country of _filteredCountries" [value]="country.code">{{ country.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'home.filter_sport' | translate }}</mat-label>
              <mat-select #sportSelect [(ngModel)]="filter.sport" (selectionChange)="onSportSelect($event)">
                <mat-select-filter
                  [matSelect]="sportSelect"
                  [autoFocus]="true"
                  [filterKey]="'name'"
                  [placeholder]="'home.search' | translate"
                  [items]="_sports"
                  (onFiltered)="_filteredSports = $event"
                ></mat-select-filter>
                <mat-option *ngFor="let sport of _filteredSports" [value]="sport.id">{{ sport.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!legends || legends.length == 0">
      <div *ngIf="!loading" class="h4 mx-0" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
      <div *ngIf="loading" class="h4 mx-0" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
    </ng-container>

    <div class="legends-grid">
      <app-legend-widget
        *ngFor="let legend of legends"
        [showButtons]="true"
        [legend]="legend"
      ></app-legend-widget>
    </div>

    <app-load-page-button (loadMore)="onLoadMore()" *ngIf="_page.index !== _page.getLastPageIndex()"></app-load-page-button>
  </section>
</div>

<div *ngIf="tab === 'legends-manage'" class="container">
  <app-legends-manage></app-legends-manage>
</div>
