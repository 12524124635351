import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AdminEditUserWarningComponent } from "../components/admin-edit-user-warning/admin-edit-user-warning.component";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(
    private ngbModalService: NgbModal
  ) {}

  showSuperAdminProfileEditWarning(entity: Record<string, any>, type: 'user' | 'org'): NgbModalRef {
    const ref = this.createDialog(AdminEditUserWarningComponent);
    if (type === 'user') ref.componentInstance._user = entity;
    else ref.componentInstance._org = entity;
    return ref;
  }

  private createDialog(content: any, config?: NgbModalConfig ): NgbModalRef {
    const ref = this.ngbModalService.open(content, config);
    ref.componentInstance._ngbRef = ref;
    return ref;
  }
}
