<div *ngIf="user" class="container">

    <div *ngIf="user.suggestedBy && !hide" class="alert alert-info" role="alert">

            <span>
                {{ 'suggestion.suggestedByUser' | translate: { name: getSuggestedByName() } }}
            </span>
            &nbsp;
            <a [routerLink]="['/profile', user.suggestedBy._id, 'view-profile']" *ngIf="user.suggestedBy.active && !user.suggestedBy.blocked">{{ 'admin.view_profile' | translate }}</a>
        &nbsp;
        <button type="button" class="close" (click)="hide = true" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="ui-block">
        <div class="ui-block-title">
          <h4>{{ 'profile.referrals' | translate }}</h4>
        </div>
        <div class="ui-block-content">
            <form *ngIf="!user.suggestedBy" class="mb-2">
                <fieldset>
                    <div class="row align-items-center">
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                            <span>{{'suggestion.addSuggestedByInfo' | translate }}</span>
                        </div>
                        <div class="col col-lg-4 col-md-4 col-sm-12 col-12">
                          <app-search-generic
                            [inputDisplayFn]="displayFn"
                            [searchConfig]="searchConfig"
                            (onSelect)="onUserSelect($event)"
                            [itemTemplate]="item"
                          >
                            <ng-template #item let-result>
                              <div class="author-thumb">
                                <img loading="lazy" class="img-author" src="{{getAvatar(result)}}" alt="result">
                              </div>
                              <span class="result-text">
                                <span>{{ result.name }}</span>
                                <div *ngIf="result.country" class="result-text-small">{{ 'countries.' + result.country | translate }}</div>
                              </span>
                            </ng-template>
                          </app-search-generic>
                        </div>

                        <div class="col col-lg-2 col-md-2 col-sm-12 col-12">
                            <button class="btn btn-primary m-0" style="width: 100%" [disabled]="!selectedUser" (click)="setSuggestedBy()">{{'save' | translate}}</button>
                        </div>
                    </div>
                </fieldset>
            </form>

            <hr *ngIf="!user.suggestedBy">

            <ng-container *ngIf="user">
              <div class="row">
                <div class="col col-12">
                  <p>{{ 'profile.referrals_description' | translate }}</p>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" class="mat-field-align-center">
                    <mat-label>Link</mat-label>
                    <input matInput disabled [ngModel]="user.referralLink">
                    <button matSuffix [ngbTooltip]="'copy' | translate" class="btn btn-primary btn-sm m-0 ml-2" (click)="onCopyReferralLink()">
                      <i class="fa fa-link"></i>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col col-12 mb-2">
                  <span class="h6">{{ 'profile.referral_registered_users' | translate }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col col-6 col-md-6">
                  <div class="d-flex flex-column text-center">
                    <span class="h4">{{ 'profile.referrals_this_week' | translate }}</span>
                    <span class="h4">{{ user.referralsWeeklyCount }}</span>
                  </div>
                </div>
                <div class="col col-6 col-md-6">
                  <div class="d-flex flex-column text-center">
                    <span class="h4">{{ 'profile.referrals_total' | translate }}</span>
                    <span class="h4">{{ user.referralsCount }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
        </div>
    </div>
</div>
