import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Injectable } from '@angular/core';
import { User } from "../types/profile";

@Injectable({
	providedIn: 'root'
})
export class ProfileHiddenGuard implements CanActivate {
	constructor(private apiService: ApiService, private router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		const userId = route.params.userId;
		const user: User = await this.apiService.getUserDetails(userId);
    const isMe = user?._id === userId || userId === 'me';
    if (isMe || user?.superAdmin) return true;
		return user.profile?.hidden ? this.router.createUrlTree(['/home']) : true;
	}
}
