<div *ngIf="user" class="ui-block">

    <div class="friend-item fav-page">
        <div [routerLink]="['/profile', user._id, 'view-profile']" class="friend-header-thumb img-cover-bgr cursor-pointer" [ngStyle]="{'background-image': 'url(' + getCoverUrl() + ')' }" ></div>

        <div class="friend-item-content">

            <div [routerLink]="['/profile', user._id, 'view-profile']" class="friend-avatar cursor-pointer">
                <div class="author-thumb">
                    <a>
                        <img class="img-logo" loading="lazy" src="{{getAvatarUrl()}}" alt="author">
                        <app-verified-badge *ngIf="_isVerified"></app-verified-badge>
                    </a>
                </div>
                <div class="author-content">
                    <a class="h5 author-name">{{user.profile?.firstname + ' ' + user.profile?.lastname}}</a>
                    <div *ngIf="user.profile?.country" class="country" translate>countries.{{user.profile.country}}</div>
                    <div *ngIf="!user.profile?.country" class="country">&nbsp;</div>
                </div>

                <!-- <ng-content></ng-content> -->

            </div>

            <div class="friend-count">
                <ng-content></ng-content>
            </div>

            <!-- <div class="friend-count" data-swiper-parallax="-500">
                <a class="friend-count-item">
                    <div class="h6">{{!user.photos ? 0 : user.photos.length}}</div>
                    <div class="title" translate>admin.photos</div>
                </a>
                <a class="friend-count-item">
                    <div class="h6">{{!user.members ? 0 : user.members.length}}</div>
                    <div class="title" translate>admin.members</div>
                </a>

            </div> -->

            <div class="control-block-button" data-swiper-parallax="-100">

                <app-message-options *ngIf="!isMe" [user]="user"></app-message-options>
                <app-user-invitation-options *ngIf="!isMe && showInvitationOptions" [user]="user"></app-user-invitation-options>

                <app-fav-button *ngIf="!isMe" [user]="user" ></app-fav-button>

                <a *ngIf="isMe" [routerLink]="['/profile/me/edit-profile']" class="btn btn-control bg-primary-opacity" ngbTooltip="{{ 'profile.tooltip_options' | translate }}">
                    <svg class="olymp-settings-icon"><use xlink:href="#olymp-settings-icon"></use></svg>
                </a>

            </div>

        </div>
    </div>
</div>
