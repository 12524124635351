export class SelectOption<Label, Value, Meta = Record<string, any>> {
	constructor(
		public label: Label,
		public value?: Value,
		public meta?: Meta,
	) {}
}

export class Tag {
	constructor(
		public id: string,
		public name: string,
		public filterName?: string
	) {}
}
