import { AfterViewInit, Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { SuperAdminService } from "../../services/super-admin.service";

@Component({
    selector: 'app-super-admin-orgs',
    templateUrl: './super-admin-orgs.component.html',
    styleUrls: ['./super-admin-orgs.component.css']
})
export class SuperAdminOrgsComponent extends BaseComponent implements OnInit, AfterViewInit {

    displayedColumns: string[] = [
      'profile.name',
      'profile.country',
      'created',
      'active',
      'verified',
      'allowMassMessages',
      'star',
    ];

    @Input() type = [];

    _filter = { countries: [], sports: [], tags: [], superAdminFilter: null };
    @Input() set filter(value) {

        let reloadData =
            this._filter.countries != value.countries
            || this._filter.sports != value.sports
            || this._filter.tags != value.tags
            || this._filter.superAdminFilter != value.superAdminFilter;

        this._filter = value;
        if (reloadData && this.initialized)
            this.filterChanged.emit();
    }
    get filter() { return this._filter; }

    filterChanged = new EventEmitter();
    working: boolean = false;
    orgs: any[] = [];
    currentPage = 0;
    hasMorePages: boolean = false;
    resultsLength: number = 0;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        private superAdminService: SuperAdminService
    ) {
        super(eventService);
    }

    initialized: boolean = false;
    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.initialized = true;

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.filterChanged.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page, this.filterChanged)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.working = true;
                    return this.getOrgs(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex)
                        .pipe(catchError(() => observableOf(null)));
                }),
                map(data => {
                    this.working = false;

                    let orgs = data?.body["organizations"] ?? [];
                    if (orgs === null) {
                        return [];
                    }

                    this.resultsLength = data?.body["orgsCount"] ?? 0;
                    return orgs;
                })
            ).subscribe(data => this.orgs = data);
    }

    getOrgs(sort: string, order: SortDirection, page: number) {

        let params = {
            start: (page) * this.paginator.pageSize,
            len: this.paginator.pageSize,
            users: false,
            feeds: false
        };

        params["sort"] = sort;
        params["sortDir"] = order.toString();

        params["sa_all"] = true;
        if (this.filter.superAdminFilter) {
            if (this.filter.superAdminFilter["name"]) {
                params["search"] = this.filter.superAdminFilter["name"];
            }
            let active = this.filter.superAdminFilter["active"];
            if (active) {
                params["active"] = active;
            }
        }

        if (this.type && this.type.length > 0) {
            params["orgType"] = this.type.join(',');
        }
        if (this.filter?.countries) {
            params["country"] = this.filter.countries.join(",");
        }
        if (this.filter?.sports) {
            params["sport"] = this.filter.sports.join(",");
        }
        if (this.filter?.tags && this.filter.tags.length > 0) {
            params["tags"] = this.filter.tags.join(",");
        }

        return this.apiService.search(params);
    }

    activateProfile(org, activate: boolean = true) {
        if (!this.apiService.getCurrentUser()?.superAdmin) {
            return;
        }

        let data = { "active": activate };
        this.working = true;
        this.apiService.activateOrg(org._id, data).subscribe(
            (result) => {
                this.working = false;
                org.active = activate;
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

    allowMassMessages(user: any, allow: boolean) {
        this.working = true;
        this.apiService.allowOrgMassMessages(user._id, allow).subscribe(
            (result) => {
                this.working = false;
                user.allowMassMessages = allow;
            },
            (error) => {this.handleApiError(error); this.working = false;} );
    }

  onVerifiedChange(event: MatCheckboxChange, org: any): void {
      if (!event) return;
      event.checked
        ? this.superAdminService.updateOrgToVerified(org._id)
        : this.superAdminService.updateOrgToNotVerified(org._id);
  }
}
