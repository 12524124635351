<div *ngIf="members && members.length > 0" class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>profile.members</h6>
        <a [routerLink]="['/org-profile/' + org._id + '/members']" class="more" style="width: auto;" >View all</a>
    </div>
    <div class="ui-block-content">
        
        <ul class="widget w-faved-page js-zoom-gallery">
            <li *ngFor="let member of members">
                <a [routerLink]="['/profile/' + member.userId]" ngbTooltip="{{getFullName(member)}}" >
                    <img loading="lazy" src="{{getAvatarUrl(member)}}" alt="author">
                </a>
            </li>

            <!-- <li class="all-users">
                <a href="#">+74</a>
            </li> -->

        </ul>
        
    </div>
</div>