<div>
    <div class="progress-spinner" *ngIf="working">
        <mat-spinner></mat-spinner>
    </div>

    <div>

        <table mat-table [dataSource]="users" class="example-table d-none d-md-table" matSort matSortActive="created" matSortDisableClear
            matSortDirection="desc">

            <ng-container matColumnDef="email" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Email</th>
                <td mat-cell *matCellDef="let row">{{row.email}}</td>
            </ng-container>

            <ng-container matColumnDef="profile.name" sticky>
              <th class="name-col" mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{ 'name' | translate }}</th>
              <td class="name-col" mat-cell *matCellDef="let row">
                <div class="d-flex justify-content-between align-items-center">
                  <a href="/profile/{{row._id}}" target="_blank">{{ row?.profile | userName }}</a>
                  <a [href]="'/profile/' + row._id + '/edit-profile'" target="_blank" class="btn btn-primary btn-sm m-0" role="button">
                    <i class="fa fa-pencil"></i>
                  </a>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="profile.country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Country</th>
                <td mat-cell *matCellDef="let row">
                    <img *ngIf="row?.profile?.country" style="width: 32px" src="{{row.profile.country | flag}}" />
                </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Registered</th>
                <td mat-cell *matCellDef="let row">{{row.created | date}}</td>
            </ng-container>

            <ng-container matColumnDef="age">
                <th mat-header-cell *matHeaderCellDef>{{'profile.age' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.profile?.birthdate | age}}</td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Active</th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.active" style="color: green;" >{{'admin.active' | translate}}</span>
                    <span *ngIf="!row.active" style="color: red;" >{{'admin.inactive' | translate}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="verified">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{'admin.verified' | translate }}</th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox [(ngModel)]="row.verified" [color]="'primary'" (change)="onVerifiedChange($event, row)">{{ (row.verified ? 'yes' : 'no') | translate }}</mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="blocked">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Blocked</th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="!row.blocked" style="color: green">{{'admin.nonblocked' | translate}}</span>
                    <span *ngIf="row.blocked" style="color: red">{{'admin.blocked' | translate}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="allowMassMessages">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{'admin.massMessages' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                  <a *ngIf="!row.allowMassMessages" role="button" (click)="allowMassMessages(row, true)">{{'admin.allow' | translate}}</a>
                  <a *ngIf="row.allowMassMessages" role="button" (click)="allowMassMessages(row, false)">{{'admin.disallow' | translate}}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="star" stickyEnd >
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" style="text-align: right;">
                    <span *ngIf="!row.superAdmin">
                        <a *ngIf="!row.blocked" [routerLink]="" (click)="blockUser(row)" translate>admin.block</a>
                        <a *ngIf="row.blocked" [routerLink]="" (click)="blockUser(row, false)" translate>admin.unblock</a>
                    </span>
                    <span style="color: green" *ngIf="row.superAdmin">
                        Superadmin
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <app-super-admin-mobile-table
          [data]="users"
          [type]="'user'"
          (onBlockUserChange)="blockUser($event.data, $event.block)"
          (onMassMessagesChange)="allowMassMessages($event.data, $event.allowMassMessages)"
          class="d-block d-md-none"
        ></app-super-admin-mobile-table>
    </div>

    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="10" aria-label="Select page">
    </mat-paginator>
</div>
