import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (!phoneNumber) return '';
    return `+${phoneNumber.replace(' ','')}`;
  }
}
