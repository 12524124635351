import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {buildDate, commitId, version } from 'src/environments/version';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  _showVersion = false;
  _version: string = "";

  constructor() {
    let build = environment.build;
    this._showVersion = build !== 'spaceofsports';
    this._version = build === 'spaceofsports-dev'
      ? `Version ${version}, commit ${commitId}, (${buildDate})`
      : `Version: ${version}`;
  }

  ngOnInit(): void {
  }

}
