import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Utils } from 'src/app/utils';

@Component({
    selector: 'app-photo-feed-author-widget',
    templateUrl: './photo-feed-author-widget.component.html',
    styleUrls: ['./photo-feed-author-widget.component.css']
})
export class PhotoFeedAuthorWidgetComponent implements OnInit {

    @Input() feed: any = null;
    @Input() openProfileOnClick: boolean = true;
    @Input() showInfo: boolean = true;
    @Input() nameFormat: 'firstName' | 'lastName' | 'full' = 'full';
    @Input() flow: 'row' | 'row-reverse' = 'row';

    @Output() clicked = new EventEmitter();
    tooltip: string = "";
    _isVerified = false;

    constructor(private router: Router) { }

    ngOnInit(): void {
        this.tooltip = this.feed.org?.profile?.name
            ?? `${this.feed.user?.profile?.firstname} ${this.feed.user?.profile?.lastname}`;
        this._isVerified = this.feed?.user?.verified || this.feed?.org?.verified;
    }

    getAvatar() {
        if (this.feed?.org) {
            return ApiService.getPhotoUrl(this.feed?.org?.avatar?.avatar?.small, Utils.getLogo());
        }
        else {
            return ApiService.getPhotoUrl(this.feed?.user?.avatar?.avatar?.small, Utils.getAvatarSmall());
        }
    }
    onClick() {
        if (this.openProfileOnClick) {
            let url = (this.feed?.org) ? `/org-profile/${this.feed.org._id}` : `/profile/${this.feed.user._id}`;
            this.router.navigateByUrl(url);
        }

        this.clicked.emit("");
    }
}
