import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';

@Component({
    selector: 'app-profile-favs',
    templateUrl: './profile-favs.component.html',
    styleUrls: ['./profile-favs.component.css']
})
export class ProfileFavsComponent extends BaseComponent implements OnInit {

    @Input() user: any = null;

    favs = {
        feeds: [],
        users: [],
        clubs: [],
        firms: []
    }

    currentTab: string = "clubs";
    hasOrgs: boolean = false;
    working: boolean = false;

    constructor(
        eventService: EventService,
        private apiService: ApiService
    ) {
        super(eventService);
    }

    ngOnInit(): void {
        this.hasOrgs = this.apiService.getCurrentUser()?.adminOrgs?.some(o => o.active);
        this.getFavs();
    }

    getFavs() {
        if (!this.user?._id) {
            return;
        }

        this.working = true;
        let clubs = ['sport_club','fan_club'];
        this.apiService.getFavorites(this.user._id).subscribe(
            (result) => {
                this.working = false;
                let res = result.body["favorites"];
                this.favs = {
                    "feeds": res.filter(f => f.feed),
                    "users": res.filter(f => f.user),
                    "clubs": res.filter(f => f.org && clubs.includes(f.org.type)),
                    "firms": res.filter(f => f.org && !clubs.includes(f.org.type))
                };

                if (this.favs.feeds.length > 0) {
                    this.currentTab = "feeds";
                }
                else if (this.favs.users.length > 0) {
                    this.currentTab = "users";
                }
                else if (this.favs.clubs.length > 0) {
                    this.currentTab = "clubs";
                }
                else if (this.favs.firms.length > 0) {
                    this.currentTab = "firms";
                }
             },
            (error) => {this.handleApiError(error); this.working = false; } );
    }
}
