<div class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>profile.career_header</h6>
    </div>
    <div class="ui-block-content">

        <form>
            <fieldset [disabled]="working">
                <div class="row">
            
                    <ng-container *ngFor="let career of data; let i = index">

                        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            
                            <a [routerLink]="" (click)="removeField(career)" class="add-field">
                                <svg class="olymp-little-delete"><use xlink:href="#olymp-little-delete"></use></svg>
                                <span translate>profile.career_remove_field</span>
                            </a>
                        </div>

                        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                            <mat-form-field appearance="outline" >
                                <mat-label translate>profile.career_title</mat-label>
                                <input matInput type="text" name="title_{{i}}" [(ngModel)]="career.title" required>
                            </mat-form-field>
                        </div>
                
                        <div class="col col-lg-3 col-md-3 col-sm-6 col-6">
                            <mat-form-field appearance="outline" >
                                <mat-label translate>profile.career_from</mat-label>
                                <input matInput type="text" name="from_{{i}}" [(ngModel)]="career.from" required>
                            </mat-form-field>
                        </div>

                        <div class="col col-lg-3 col-md-3 col-sm-6 col-6">
                            <mat-form-field appearance="outline" >
                                <mat-label translate>profile.career_to</mat-label>
                                <input matInput type="text" name="to_{{i}}" [(ngModel)]="career.to" required>
                            </mat-form-field>
                        </div>
                
                        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <mat-form-field appearance="outline">
                                <mat-label translate>profile.career_description</mat-label>
                                <textarea matInput name="description_{{i}}" [(ngModel)]="career.description" required></textarea>
                            </mat-form-field>
                        </div>

                        <div style="margin:12px;"></div>

                    </ng-container>
            
                    <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            
                        <a [routerLink]="" (click)="addNewFiled()" class="add-field">
                            <svg class="olymp-plus-icon"><use xlink:href="#olymp-plus-icon"></use></svg>
                            <span translate>profile.career_add_field</span>
                        </a>
                    </div>

                    <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                        <button class="btn btn-secondary btn-lg full-width"  (click)="restoreValues()" >
                            <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ 'profile.btn_restore_values' | translate }}
                        </button>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                        <button class="btn btn-primary btn-lg full-width"  (click)="saveCareer()" >
                            <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ 'btn_save_all' | translate }}
                        </button>
                    </div>
                </div>
            </fieldset>
        </form>
        
    </div>
</div>