<div class="container">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">

                <div class="header-menu-red-dots">
                    <h2>Superadmin</h2>
                    <ul class="cat-list-bg-style align-center sorting-menu display-navigation">
                        <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'clubs' }"><a [routerLink]="['/admin/clubs']" translate>home.clubs</a></li>
                        <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'firms' }"><a [routerLink]="['/admin/firms']" translate>home.firms</a></li>
                        <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'users' }"><a [routerLink]="['/admin/users']" translate>home.users</a></li>
                        <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'facilities' }"><a [routerLink]="['/admin/facilities']" translate>home.facilities</a></li>
                        <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'feeds' }"><a [routerLink]="['/admin/feeds']" translate>admin.feeds</a></li>
                        <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'referrals' }"><a [routerLink]="['/admin/referrals']" translate>profile.referrals</a></li>
                    </ul>
                </div>

                <app-home-filter
                    *ngIf="currentTab != 'referrals'"
                    [caption]="getHeaderText()"
                    [type]="filterType"
                    [superAdmin]="true"
                    (filterChanged)="filterChanged($event)"
                    [initDefaultFilter]="false"
                ></app-home-filter>

                <app-home-feeds [filter]="filter" [superAdmin]="true" *ngIf="currentTab == 'feeds'"></app-home-feeds>
                <app-super-admin-users [filter]="filter" *ngIf="currentTab == 'users'"></app-super-admin-users>
                <app-super-admin-orgs [filter]="filter" [type]="['sport_club','fan_club']" *ngIf="currentTab == 'clubs'"></app-super-admin-orgs>
                <app-super-admin-orgs [filter]="filter" [type]="['firm']" *ngIf="currentTab == 'firms'"></app-super-admin-orgs>
                <app-super-admin-orgs [filter]="filter" [type]="['sport_facility']" *ngIf="currentTab == 'facilities'"></app-super-admin-orgs>
                <div class="ui-block" *ngIf="currentTab == 'referrals'">
                  <div class="ui-block-title">
                    <h4>{{ 'profile.referrals_leaderboard' | translate }}</h4>
                  </div>
                  <div class="ui-block-content">
                    <div class="row">
                      <div class="col col-12">
                        <app-referrals-table></app-referrals-table>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-back-to-top></app-back-to-top>
