<form>
  <fieldset #fieldSet [formGroup]="_dateForm">
    <div class="d-flex gap-05">
      <mat-form-field appearance="outline" class="day-picker">
        <mat-label>{{ 'date.day' | translate }}</mat-label>
        <mat-select (selectionChange)="onDayChange($event.value)"
                    [formControlName]="'day'">
          <mat-option *ngFor="let dayOption of dayOptions" [value]="dayOption.value">
            <span>{{ dayOption.label }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="month-picker">
        <mat-label>{{ 'date.month' | translate }}</mat-label>
        <mat-select [formControlName]="'month'" (selectionChange)="onMonthChange($event.value)">
          <mat-option *ngFor="let monthOption of monthOptions" [value]="monthOption.value">
            <span>{{ monthOption.label | translate }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="year-picker">
        <mat-label>{{ 'date.year' | translate }}</mat-label>
        <mat-select [formControlName]="'year'" (selectionChange)="onYearChange($event.value)">
          <mat-option *ngFor="let yearOption of yearOptions" [value]="yearOption.value">
            <span>{{ yearOption.label }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </fieldset>
</form>

