import { Pipe, PipeTransform } from '@angular/core';
import { EnumService } from "../services/enum.service";

@Pipe({
  name: 'sportName'
})
export class SportNamePipe implements PipeTransform {
  constructor(private enumService: EnumService) {
  }

  transform(sportId: string): string {
    const sports = this.enumService.getSports();
    return sports.find(sport => sport.id === sportId).name;
  }
}
