<form class="search-bar w-search friend-requests" [formGroup]='searchForm'>
    <div class="form-group with-button">

        <input formControlName='searchInput' class="form-control js-user-search" placeholder="{{'search.placeholder' | translate}}" [matAutocomplete]="auto" type="text">
        <button (click)="search()" >
            <svg class="olymp-magnifying-glass-icon">
                <use xlink:href="#olymp-magnifying-glass-icon"></use>
            </svg>
        </button>

        <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayFn" class="notifications-list">
            <mat-option *ngIf="isLoading" class="is-loading">
                <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isLoading">
                <mat-option class="notification-tile" *ngFor="let result of filteredResults" [value]="result" (click)="selectResult(result)">
                        <div class="author-thumb">
                            <img loading="lazy" class="img-author" src="{{getAvatar(result)}}" alt="result">
                        </div>
                        <span class="result-text">
                            <div class="notification-label"> {{ result.type | searchType }} </div>
                            <span>
                              {{ result.name }}
                              <span class="small c-primary">{{ result.referenceId }}</span>
                            </span>
                            <div *ngIf="result.country" class="result-text-small"> {{ 'countries.' + result.country | translate }}</div>
                        </span>
                </mat-option>
            </ng-container>
        </mat-autocomplete>

    </div>
</form>
