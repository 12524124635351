export enum EventType {
	alert = 'alert',
	error = 'error',
	langChange = 'langChanged',
	update = 'update',
	command = 'command',
	data = 'data'
}

export type BaseEvent = LanguageChangeEvent | AlertEvent | ErrorEvent | UpdateEvent | CommandEvent | DataEvent;

export type LanguageChangeEvent = {
	name: EventType.langChange,
	data: LanguageChangeEventData,
};

export type LanguageChangeEventData = {
	languageChanged: boolean,
};

export type AlertEvent = {
	name: EventType.alert,
	data: AlertEventData,
};

export type AlertType = 'info' | 'success' | 'warning' | 'error';

export type AlertEventData = {
	type: AlertType,
	message: string,
	dismissible: boolean,
	class?: string,
	header?: string,
};

export type ErrorEvent = {
	name: EventType.error,
	data: ErrorEventData,
};

export type ErrorEventData = {
	error: any,
	alert: AlertEventData,
	unauthorized: boolean,
};

export type UpdateEvent = {
	name: EventType.update,
	data: UpdateEventData,
};

export type UpdateEventData = {
	update: 'notifications' | 'profile' | 'legend-requests'
};

export type CommandEvent = {
	name: EventType.command,
	data: CommandEventData,
};

export type CommandEventData = {
	command: 'logout' | 'createOrg' | 'changeLang' | 'hideProfile',
};

export type DataEvent = {
	name: EventType.data,
	data: DataEventData
};

export type DataEventData = {
	userLoggedIn?: boolean,
	currentUser?: any,
	params?: any,
	notification?: any,
	data?: any,
	fromBackground?: boolean,
  legend?: any,
};


