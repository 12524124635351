import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { EventType } from '../../shared/types/base';

@Component({
    selector: 'app-fav-button',
    templateUrl: './fav-button.component.html',
    styleUrls: ['./fav-button.component.css']
})
export class FavButtonComponent extends BaseComponent implements OnInit {

    @Input() user: any = null;
    @Input() org: any = null;

    constructor(
        eventService: EventService,
        private apiService: ApiService
    ) {
        super(eventService);
    }

    ngOnInit(): void {
    }

    isMyFav() {
        let currentUser = this.apiService.getCurrentUser();

        if (this.user)
            return currentUser?.favs?.some(f => f.userId == this.user._id) ?? false;
        if (this.org)
        return currentUser?.favs?.some(f => f.orgId == this.org._id) ?? false;

        return false;
    }

    clicked() {
        if (this.user) {
            this.addFav(this.user, { "userId": this.user._id });
        }
        else if (this.org) {
            this.addFav(this.org, { "orgId": this.org._id });
        }
    }

    addFav(subject, fav) {

        let currentUser = this.apiService.getCurrentUser();
        if (!currentUser) {
            this.sendEvent( {
                name: EventType.alert,
                data: { type: 'warning', message: 'favs.login_to_fav', dismissible: true }
            });
            return;
        }

        if (!this.isMyFav()) {
            this.apiService.addFavorite(fav).subscribe(
                (result) => {
                    if (!subject.likes) {
                        subject.likes = [];
                    }
                    subject.likes.push(currentUser._id);
                 },
                (error) => this.handleApiError(error) );
        }
        else {
            this.apiService.removeFavorite(fav).subscribe(
                (result) => {
                    let idx = subject.likes?.findIndex(l => l == currentUser?._id) ?? -1;
                    if (idx > -1) {
                        subject.likes.splice(idx, 1);
                    }
                },
                (error) => this.handleApiError(error) );
        }
    }
}
