import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { EnumService } from 'src/app/services/enum.service';
import { User } from "../../shared/types/profile";

@Component({
    selector: 'app-profile-info-widget',
    templateUrl: './profile-info-widget.component.html',
    styleUrls: ['./profile-info-widget.component.css']
})
export class ProfileInfoWidgetComponent implements OnInit {

    @Input() user: User = null;
    @Input() org: any = null;
    @Input() showFullInfo: boolean = true;

    occupations: any = null;
    isSuperAdmin: boolean = false;

    constructor(
        private enumService: EnumService,
        private translateService: TranslateService,
        apiService: ApiService) {
            this.isSuperAdmin = apiService.getCurrentUser()?.superAdmin ?? false;
        }

    ngOnInit(): void {
        this.occupations = this.enumService.getUserOccupations();
    }

    formatDate(date) {
        let d = new Date(date);

        let month = this.translateService.instant(`months.${d.getMonth()}`);
        return `${d.getDate()}. ${month}, ${d.getFullYear()}`;
    }

    getAge(date) {
        let ageDifMs = Date.now() - new Date(date).getTime();
        let ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    getOccupation() {
        if (!this.occupations || !this.user?.profile?.occupation) {
            return "";
        }

        let occup = this.occupations.find(o => o.id == this.user.profile.occupation);
        return occup?.name ?? "";
    }
}
