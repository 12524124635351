<div class="container">
    <div class="row">
        <div class="col-12 col-xl-10 m-auto" style="padding: 12px;">
            <div class="ui-block">

                <article class="hentry blog-post single-post single-post-v2">

                    <img src="assets/img/logo-label-300x60.png" width="200" height="40" style="margin-bottom: 35px;" alt="logo" />

                    <h3 translate>about_us.header</h3>

                    <div class="post-content-wrap">

                        <div class="post-content">
                            <p translate>about_us.text</p>
                            <p translate>about_us.text2</p>
                            <p translate>about_us.text3</p>

                            <ul style="list-style-type: disc;">
                                <li translate>about_us.list.item_0</li>
                                <li translate>about_us.list.item_1</li>
                                <li translate>about_us.list.item_2</li>
                                <li translate>about_us.list.item_3</li>
                                <li translate>about_us.list.item_4</li>
                                <li translate>about_us.list.item_5</li>
                                <li translate>about_us.list.item_6</li>
                            </ul>
                            <p translate>about_us.signature</p>
                            <p>
                                <app-insta-follow></app-insta-follow>
                            </p>
                        </div>

                    </div>

                </article>
            </div>
        </div>
    </div>
</div>



<!-- spaceofsports.com

je komunikačná a prezentačná platforma - meetingpoint športových klubov, športovcov a organizácií na podporu spolupráce, priatelstva, športu, pohybu a zdravého životného štýlu.

Všetci športovci sveta sú priatelia - nezáleží na veku, postavení, bohatstve, farbe pleti alebo počte medailí

   KLUBY - ŠPORTOVCI - ORGANIZÁCIE - ŠPORTOVISKÁ

- pomáhame si navzájom a podporujeme jeden druhého.
- prezentujeme svoj šport, svoj klub, svoju krajinu v tom         najlepšom svetle. Tvoj profil a prezentáciu uvidia     priaznivci športu z celého sveta
- budeš mať možnosť využívať mnohé výhody a zľavy doma aj v   iných krajinách pri cestovaní
- chceš vyskúšať iný nový šport? nájdeš tu priateľov ktorí Ti    ochotne poradia a poskytnú svoje skúsenosti a       znalosti. Rovnako aj Ty môžeš ukázať svoj šport v ktorom si dobrý a Tvoje oblúbené miesta či techniky niekomu inému
- hľadáš parťáka, spoluhráča alebo sparingpartnera na šport  doma alebo v zahraničí kam sa chystáš ?   U nás nejakého určite nájdeš
- nájdeš tu aj rady a referencie od reálnych overených      športovcov a klubov ktorí Ti odporučia zaujímavé miesta     (športoviská, prírodné krásy, oblasti a trasy) na Zemi,        spoľahlivé obchody a služby či kvalitné overené športové     potreby a značky

- ak pôjdeš na opačnú stranu sveta, na spaceofsports.com nájdeš v Databáze aj na Sportmape priatelov športovcov ktorí ti pomôžu v núdzi a na ktorých sa môžeš obrátiť so žiadosťou o pomoc alebo radu.

       SPACEOFSPORTS Vám praje zdravie, silu a úspech

 -->

