<div>
    <div class="progress-spinner" *ngIf="working">
        <mat-spinner></mat-spinner>
    </div>

    <div class="table-wrapper">

        <table mat-table [dataSource]="orgs" class="example-table d-none d-md-table" matSort matSortActive="created" matSortDisableClear
            matSortDirection="desc">

            <ng-container matColumnDef="profile.name" sticky>
                <th class="name-col" mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{ 'name' | translate }}</th>
                <td class="name-col" mat-cell *matCellDef="let row">
                  <div class="d-flex justify-content-between align-items-center pr-2">
                    <a href="/org-profile/{{row._id}}" target="_blank">{{ row?.profile?.name }}</a>
                    <a class="btn btn-sm btn-primary m-0" role="button" href="/org-profile/{{row._id}}/edit-profile" target="_blank">
                      <i class="fa fa-pencil"></i>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="profile.country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{ 'country' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                    <img *ngIf="row?.profile?.country" style="width: 32px" src="{{row.profile.country | flag}}" />
                </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{ 'registered' | translate }}</th>
                <td mat-cell *matCellDef="let row">{{row.created | date}}</td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{ 'status' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.active" style="color: green;" >{{'admin.active' | translate}}</span>
                    <span *ngIf="!row.active" style="color: red;" >{{'admin.inactive' | translate}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="allowMassMessages">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{'admin.massMessages' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="!row.allowMassMessages">
                        <button class="btn btn-primary m-0" (click)="allowMassMessages(row, true)">{{'admin.allow' | translate}}</button>
                    </span>
                    <span *ngIf="row.allowMassMessages">
                        <button class="btn btn-primary m-0" (click)="allowMassMessages(row, false)">{{'admin.disallow' | translate}}</button>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="verified">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{'admin.verified' | translate }}</th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox [(ngModel)]="row.verified" [color]="'primary'" (change)="onVerifiedChange($event, row)">{{ (row.verified ? 'yes' : 'no') | translate }}</mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="star" stickyEnd >
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" style="text-align: right;">
                    <span>
                        <a *ngIf="row.active" [routerLink]="" (click)="activateProfile(row, false)" translate>admin.deactivate_profile</a>
                        <a *ngIf="!row.active" [routerLink]="" (click)="activateProfile(row)" translate>admin.activate_profile</a>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <app-super-admin-mobile-table
          [data]="orgs"
          [type]="'org'"
          (onActivateProfileChange)="activateProfile($event.data, $event.activate)"
          (onMassMessagesChange)="allowMassMessages($event.data, $event.allowMassMessages)"
          class="d-block d-md-none"
        ></app-super-admin-mobile-table>
    </div>

    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="10" aria-label="Select page">
    </mat-paginator>
</div>
