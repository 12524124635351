<app-profile-referral [user]="user"></app-profile-referral>

<div class="container">
  <div class="ui-block">
    <div class="ui-block-title">
      <h4>{{ 'profile.referrals_leaderboard' | translate }}</h4>
    </div>
    <div class="ui-block-content">
      <div class="row">
        <div class="col col-12">
          <app-referrals-table [user]="user"></app-referrals-table>
        </div>
      </div>
    </div>
  </div>
</div>
