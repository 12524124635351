import { Component, OnInit } from '@angular/core';
import { User } from "../../shared/types/profile";
import { ApiService } from "../../services/api.service";

@Component({
  selector: 'app-profile-referrals-overview',
  templateUrl: './profile-referrals-overview.component.html',
  styleUrls: ['./profile-referrals-overview.component.css']
})
export class ProfileReferralsOverviewComponent implements OnInit {
  user: User;
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.user = this.apiService.getCurrentUser()
  }
}
