<div class="container">
  <div class="col-12 col-xl-10 m-auto p-3">
    <div class="ui-block">
      <article class="text-left blog-post single-post-v2">
        <h1 class="mb-3 text-center">{{ 'help_children.title' | translate }}</h1>
        <p>{{ 'help_children.p1' | translate }}</p>
        <p>{{ 'help_children.p2' | translate }}</p>
        <p>{{ 'help_children.p3' | translate }}</p>
        <p>{{ 'help_children.p4' | translate }}</p>
        <p>{{ 'help_children.register_text' | translate }}</p>

        <div class="m-4 text-center">
          <button class="btn btn-primary text-uppercase m-0" (click)="onRegisterLogin()">
            {{ 'help_children.register_button' | translate }}
          </button>
        </div>

        <p class="text-center text-uppercase fs-lg">{{ 'help_children.p5' | translate }}</p>

        <div class="row align-items-center text-center no-gutters">
          <div class="col-md-4 col-12 mb-3">
            <a href="https://www.svetielkopomoci.sk" target="_blank">
              <img src="assets/img/svetielko-pomoci.png" alt="svetielko-pomoci-logo">
            </a>
          </div>
          <div class="col-md-4 col-12 mb-3">
            <a href="https://www.svetielkonadeje.sk" target="_blank">
              <img src="assets/img/svetielko-nadeje.png" alt="svetielko-nadeje-logo">
            </a>
          </div>
          <div class="col-md-4 col-12 mb-3">
            <a href="https://www.detomsrakovinou.sk" target="_blank">
              <img src="assets/img/detom-s-rakovinou.png" alt="detom-s-rakovinou-logo">
            </a>
          </div>
        </div>
        <div class="row align-items-center text-center no-gutters">
          <div class="col-md-4 col-12 mb-3">
            <a href="https://nadaciadkc.sk/" target="_blank">
              <img src="assets/img/ndkc-logo.png" alt="nadacia-detskeho-kardiocentra-logo">
            </a>
          </div>
        </div>
      </article>
    </div>
  </div>
</div>
