
<ng-container *ngIf="(!adminOrgs || adminOrgs.length == 0)">
    <a [routerLink]="" (click)="openMsgs()" class="btn btn-control bg-purple" ngbTooltip="{{ 'messages.button_tooltip' | translate }}" >
        <svg class="olymp-chat---messages-icon"><use xlink:href="#olymp-chat---messages-icon"></use></svg>
    </a>
</ng-container>

<ng-container *ngIf="adminOrgs && adminOrgs.length > 0">

    <a mat-icon-button [matMenuTriggerFor]="menu" class="btn btn-control bg-purple" ngbTooltip="{{ 'messages.button_tooltip' | translate }}">
        <svg class="olymp-chat---messages-icon"><use xlink:href="#olymp-chat---messages-icon"></use></svg>
    </a>

    <mat-menu #menu="matMenu">

        <a mat-menu-item [routerLink]="" (click)="openMsgs()" translate>
            messages.send_message
        </a>

        <a mat-menu-item *ngFor="let adminOrg of adminOrgs" [routerLink]="[adminOrg.route]"
            translate [translateParams]="{ 'org': adminOrg.name }">
            messages.send_message_from
        </a>

    </mat-menu>
    
</ng-container>