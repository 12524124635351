import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { Alert } from '../alert/alert.component';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-delete-user',
    templateUrl: './delete-user.component.html',
    styleUrls: ['./delete-user.component.css']
})
export class DeleteUserComponent implements OnInit {

    @Output() deletionSuccess = new EventEmitter();

    working: boolean = false;
    alert: Alert = null;

    constructor(
        private apiService: ApiService,
        public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    deleteUser() {
        this.working = true;
        this.apiService.deleteUser().pipe(first()).subscribe(
            () => {
                this.deletionSuccess.emit();
            },
            (error) => {
                let errorMsg = ApiService.getErrorMessage(error);
                this.alert = { type: "error", "message": errorMsg, dismissible: true };
            },
            () => {
              this.working = false;
              this.activeModal.dismiss();
            });
    }

}
