<div class="container" *ngIf="user" >

	<div class="row">
        
        <div class="menu-settings-responsive col-12">
            <div class="ui-block">

                <div class="row">
                    <div class="col" >
                        <div class="ui-block-title setting-link" style="text-align: center;">
                            <a [routerLink]="" (click)="currentTab = 'feeds'" class="h6 title" translate>favs.feeds</a>
                        </div>
                    </div>
                    <div class="col">
                        <div class="ui-block-title setting-link" style="text-align: center;">
                            <a [routerLink]="" (click)="currentTab = 'clubs'" class="h6 title" translate>favs.clubs</a>
                        </div>
                    </div>
                    <div class="col">
                        <div class="ui-block-title setting-link" style="text-align: center;">
                            <a [routerLink]="" (click)="currentTab = 'users'" class="h6 title" translate>favs.users</a>
                        </div>
                    </div>
                    <div class="col">
                        <div class="ui-block-title setting-link" style="text-align: center;">
                            <a [routerLink]="" (click)="currentTab = 'firms'" class="h6 title" translate>favs.firms</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

		<div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">

            <div *ngIf="currentTab == 'feeds'" class="row justify-content-center">

                <ng-container *ngIf="!favs.feeds || favs.feeds.length == 0">
                    <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
                    <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
                </ng-container>

                <div *ngFor="let fav of favs.feeds" class="col col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12" >
                    <app-feed *ngIf="!fav.feed.blocked" [feed]="fav.feed" [user]="fav.feed.user" [org]="fav.feed.org" [allowEdit]="false"></app-feed>
                </div>
            </div>

            <div *ngIf="currentTab == 'clubs'" class="row">

                <ng-container *ngIf="!favs.clubs || favs.clubs.length == 0">
                    <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
                    <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
                </ng-container>
    
                <div *ngFor="let fav of favs.clubs" class="col col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" >
                    <app-org-widget [org]="fav.org"></app-org-widget>
                </div>
    
            </div>

            <div *ngIf="currentTab == 'firms'" class="row">

                <ng-container *ngIf="!favs.firms || favs.firms.length == 0">
                    <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
                    <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
                </ng-container>
    
                <div *ngFor="let fav of favs.firms" class="col col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" >
                    <app-org-widget [org]="fav.org"></app-org-widget>
                </div>
    
            </div>

            <div *ngIf="currentTab == 'users'" class="row">

                <ng-container *ngIf="!favs.users || favs.users.length == 0">
                    <div *ngIf="!working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>no_result</div>
                    <div *ngIf="working" class="h4" style="text-align: center; margin:24px; width:100%;" translate>loading</div>
                </ng-container>
    
                <div *ngFor="let fav of favs.users" class="col col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" >
                    <app-user-widget [user]="fav.user" [showInvitationOptions]="hasOrgs"></app-user-widget>
                </div>
    
            </div>

        </div>
        <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
			<div class="ui-block">
				
				<div class="your-profile">
					<div class="ui-block-title ui-block-title-small">
						<h6 class="title" translate>favs.favourites</h6>
					</div>
				
					<div class="ui-block-title setting-link">
						<a [routerLink]="" (click)="currentTab = 'feeds'" class="h6 title" translate>favs.feeds</a>
                    </div>
                    <div class="ui-block-title setting-link">
						<a [routerLink]="" (click)="currentTab = 'clubs'" class="h6 title" translate>favs.clubs</a>
					</div>
					<div class="ui-block-title setting-link">
						<a [routerLink]="" (click)="currentTab = 'users'" class="h6 title" translate>favs.users</a>
					</div>
                    <div class="ui-block-title setting-link">
						<a [routerLink]="" (click)="currentTab = 'firms'" class="h6 title" translate>favs.firms</a>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<app-back-to-top></app-back-to-top>