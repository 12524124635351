<div class="ui-block">
  <div class="ui-block-title">
    <h5>{{ 'help_children.widget_title' | translate }}</h5>
    <i class="fa fa-heart fa-2x ml-2 c-primary"></i>
  </div>
  <div class="ui-block-content">
    <a class="btn btn-primary full-width m-0" role="button" [routerLink]="'/help-children'">
      {{ 'btn_donate' | translate }}
    </a>
  </div>
</div>
