import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EnumService } from '../../../../services/enum.service';
import { Legend, LegendAvatar, Mode } from '../../types/org-profile-legends.types';
import { ApiService } from '../../../../services/api.service';
import { first } from 'rxjs/operators';
import { LocaleDatePipe } from '../../../../pipes/locale-date.pipe';
import { EventService } from '../../../../services/event.service';
import { EventType } from '../../../../shared/types/base';
import { User } from '../../../../shared/types/profile';
import { SearchConfig } from '../../../search-generic/types/search-generic.types';
import { Utils } from '../../../../utils';

@Component({
  selector: 'app-legend-form',
  templateUrl: './legend-form.component.html',
  styleUrls: ['./legend-form.component.css'],
  providers: [LocaleDatePipe]
})
export class LegendFormComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private enumService: EnumService,
    private apiService: ApiService,
    private localeDatePipe: LocaleDatePipe,
    private eventService: EventService
  ) { }

  @Input() set legend(legend: Legend) {
    if (!legend) { return; }
    this.initForm();
    this._avatar = legend.avatar;
    this._legend = legend;
    this._form.get('userId').setValue(legend.userId);
    Object.keys(legend.profile).forEach(key => {
      if (['birthdate', 'deathDate'].includes(key)) {
        const value = legend.profile[key];
        if (!value || value === 'null') { return; }
        const date = new Date(value);
        if (key === 'birthdate') { this._birthdate = date; }
        if (key === 'deathDate') { this._deathDate = date; }
        this._form.get(key)?.setValue(date);
      } else {
        this._form.get(key)?.setValue(legend.profile[key]);
      }
    });
  }
  @Input() mode: Mode = Mode.READONLY;
  @Output() onCreateSuccess = new EventEmitter<Legend>();
  showErrorMessage = false;
  errorMessage = '';

  _form: FormGroup;
  _sports: Array<any> = [];
  _countries: Array<any> = [];
  _filteredSports: Array<any> = [];
  _filteredCountries: Array<any> = [];
  _birthdate: Date;
  _deathDate: Date;
  _avatar: LegendAvatar;
  _legend?: Legend;
  _currentUser: User;
  _linkedUser?: User;
  _searchConfig: SearchConfig = {
    users: true,
    legends: false,
    feeds: false,
    orgs: false,
  };

  private profilePicture: File;
  private linkUserId: string;
  printName = (result: any) => result?.name || '';

  ngOnInit(): void {
    this._currentUser = this.apiService.getCurrentUser();
    if (this._legend?.userId) {
      this.apiService.getUser(this._legend.userId)
        .pipe(first())
        .subscribe(res => {
          console.log(res.body);
          this._linkedUser = res.body.user;
        });
    }
    this.initForm();
    this.loadSports();
    this.loadCountries();
  }

  onPhotoPicked(event: {files: Array<File>}): void {
    if (event.files.length > 0) {
      this.profilePicture = event.files[0];
    }
  }

  onBirthdateChange(event: Date): void {
    this._form.get('birthdate').setValue(event);
    this._form.updateValueAndValidity();
  }

  onDeathDateChange(event: Date): void {
    this._form.get('deathDate').setValue(event);
    this._form.updateValueAndValidity();
  }

  onSubmit(): void {
    this.createLegend();
  }

  printDates(): string {
    if (this._birthdate && this._deathDate) { return `${this.localeDatePipe.transform(this._birthdate)} - ${this.localeDatePipe.transform(this._deathDate)}`; }
    if (this._birthdate && !this._deathDate) { return `${this.localeDatePipe.transform(this._birthdate)}`; }
    return '';
  }

  private initForm(): void {
    if (this._form) { return; }
    this._form = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      birthdate: [null, Validators.required],
      deathDate: [null],
      info: ['', Validators.required],
      country: ['', Validators.required],
      defSport: ['', Validators.required],
      userId: ['', Validators.required]
    });

    this._form.get('deathDate').setValidators(this.isValidDeathDate.bind(this)(this._form));
  }

  private loadSports(): void {
    this._sports = this.enumService.getSports();
  }

  private loadCountries(): void {
    this._countries = this.enumService.getCountries();
  }

  private createLegend(): void {
    this.showErrorMessage = false;

    const formData = this.getFormData();
    formData.set('createdBy', this._currentUser._id);
    formData.set('userId', this.linkUserId);
    this.apiService.createLegend(formData).pipe(first()).subscribe(res => {
      this.onCreateSuccess.emit(res);
      this.eventService.publishData({
        name: EventType.alert,
        data: {header: 'success', message: 'legend.create_profile_success', type: 'success', dismissible: true}
      });
    }, (e) => {
      this.showErrorMessage = true;
      this.errorMessage = e.error.desc;
    });
  }

  onSaveAccept(): void {
    const formData = this.getFormData();
    formData.set('_id', this._legend._id);
    formData.set('createdBy', this._legend.createdBy);
    formData.set('userId', this.linkUserId);
    this.apiService.updateLegend(this._legend._id, formData).pipe(first()).subscribe(updatedLegend => {
      this.eventService.publishData(
        {name: EventType.alert, data: {type: 'success', message: 'legend.save_and_accept_success', dismissible: true}}
      );
      this.eventService.publishData({name: EventType.update, data: {update: 'legend-requests'}});
      this.eventService.publishData({name: EventType.data, data: {legend: updatedLegend}});
      this.onCreateSuccess.emit();
    }, () => {
      this.eventService.publishData(
        {name: EventType.alert, data: {type: 'error', message: 'legend.save_and_accept_fail', dismissible: true}}
      );
    });
  }

  private getFormData(): FormData {
    const formData = new FormData();
    formData.set('firstname', this._form.value.firstname);
    formData.set('lastname', this._form.value.lastname);
    formData.set('country', this._form.value.country);
    formData.set('defSport', this._form.value.defSport);
    formData.set('birthdate', this._form.value.birthdate.toISOString());
    formData.set('info', this._form.value.info);
    formData.set('deathDate', this._form.value.deathDate?.toISOString() || null);

    if (this.profilePicture) {
      formData.set('profilePicture', this.profilePicture, this.profilePicture.name);
    }

    return formData;
  }

  isValidDeathDate(form: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !form) { return null; }
      const birthdate = form.get('birthdate').value as Date;

      if (!birthdate) { return null; }

      const deathDate = control.value as Date;
      return deathDate.getTime() < birthdate.getTime() ? {deathDate: 'form_error.deathDate'} : null;
    };
  }

  hasError(controlName: string, key: string): boolean {
    return this._form?.get(controlName)?.hasError(key) || false;
  }

  onUserSelect(event: any): void {
    this.linkUserId = event?._id || undefined;
    this._form.get('userId').setValue(event?._id);
  }

  getAvatar(result: any): string {
    if (!result) { return ''; }
    return Utils.getUserAvatarUrl(result);
  }

  onChangeLinkedUser(): void {
    this._linkedUser = undefined;
    this.linkUserId = undefined;
  }
}
