<div class="modal-header d-flex justify-content-between">
  <h5>{{ 'legend.dialog_header' | translate }}</h5>
  <div>
    <i role="button" (click)="close()">
      <svg class="olymp-close-icon" style="fill: var(--color-primary)">
        <use xlink:href="#olymp-close-icon"></use>
      </svg>
    </i>
  </div>
</div>
<div class="legend-modal-body">
  <app-legend-form [legend]="legend" (onCreateSuccess)="onSuccess()" [mode]="mode"></app-legend-form>
</div>
