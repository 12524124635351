import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '../../../shared/types/profile';
import { CONSTANTS } from '../../../shared/constants';

@Pipe({
  name: 'profileSocialLink'
})
export class ProfileSocialLinkPipe implements PipeTransform {

  transform(profile: UserProfile, type: 'facebook' | 'twitter' | 'instagram' | 'tiktok' | 'youtube' | 'linkedin' | 'web'): string {
    switch (type) {
      case 'twitter': return profile.twitter
      case 'facebook': return profile.facebook
      case 'linkedin': return profile.linkedin
      case 'instagram': {
        if (!profile.instagram) return '';
        if (profile.instagram.match(CONSTANTS.REGEX.INSTA_URL)) return profile.instagram;
        if (profile.instagram.match(CONSTANTS.REGEX.SOCIAL_AT_USERNAME)) {
          if (profile.instagram.charAt(0) === '@') profile.instagram = profile.instagram.replace('@', '');
          return CONSTANTS.URLS.INSTA_BASE_URL + profile.instagram;
        }
        return '';
      }
      case 'tiktok': {
        if (profile.tiktok.match(CONSTANTS.REGEX.SOCIAL_AT_USERNAME)) return CONSTANTS.URLS.TIKTOK_BASE_URL + profile.tiktok;
        return profile.tiktok;
      }
      case 'youtube': {
        if (profile.youtube.match(CONSTANTS.REGEX.SOCIAL_AT_USERNAME)) return CONSTANTS.URLS.YOUTUBE_BASE_URL + profile.youtube;
        return profile.youtube;
      }
      case 'web': {
        if (profile.web) return profile.web.trim();
        return '';
      }
    }
  }
}
