<div class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>profile.last_photos</h6>
        <a *ngIf="user" style="text-align: right;" [routerLink]="['/profile/' + user._id + '/photos']" translate>view_all</a>
    </div>
    <div class="ui-block-content">

        <ul class="widget w-last-photo js-zoom-gallery">
            <li *ngFor="let feed of feeds; let i = index;" >
                <a [routerLink]="" (click)="openPhoto(i)">
                    <img class="img-preview" loading="lazy" src="{{getPreviewUrl(feed)}}" alt="photo">
                </a>
            </li>
        </ul>

    </div>
</div>