import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class MassMessagesGuard implements CanActivate {
  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {}
  async canActivate(): Promise<boolean | UrlTree> {
    const user = this.apiService.getCurrentUser();
    return user.allowMassMessages ? true : this.router.createUrlTree(['home']);
  }
}
