<div style="padding:0px;" class="col col-lg-12 col-md-12 col-sm-12 col-12 col-centered">
    <div class="ui-block align-center">
        <div class="ui-block-content">
            
            <div style="width: 100%; text-align: left;">
                <mat-form-field style="width: unset; margin-right: 16px;" appearance="outline">
                    <mat-label translate>mass_messages.from</mat-label>
                    <mat-select name="from" [(ngModel)]="from">
                        <mat-option *ngFor="let subject of fromSubjects" value="{{subject.id}}">{{subject.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div class="progress-spinner" *ngIf="working">
                <mat-spinner></mat-spinner>
            </div>
        
            <div>
                <table mat-table [dataSource]="history" >
        
                    <ng-container matColumnDef="date" sticky>
                        <th mat-header-cell *matHeaderCellDef >{{'mass_messages.date' | translate}}</th>
                        <td mat-cell *matCellDef="let row">{{row?.date | date }}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="count">
                        <th mat-header-cell *matHeaderCellDef>{{'mass_messages.users_count' | translate}}</th>
                        <td mat-cell *matCellDef="let row">{{row.count}}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef>{{'mass_messages.message' | translate}}</th>
                        <td mat-cell *matCellDef="let row">{{row.message}}</td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        
            <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="10" aria-label="Select page">
            </mat-paginator>

        </div>
    </div>
</div>