
<div class="header-wrapper">
  <a [routerLink]="['/']"  (click)="toggleMenu()" >
    <img class="logo" src="assets/img/logo-label-300x60.png" alt="logo" />
  </a>
  <svg class="olymp-close-icon left-menu-icon" (click)="toggleMenu()"><use xlink:href="#olymp-close-icon"></use></svg>
</div>


<ul class="account-settings" id="nav-list">
    <li class="search-item">
        <a [routerLink]="['/search']" (click)="toggleMenu()">
          <svg class="olymp-magnifying-icon left-menu-icon"><use xlink:href="#olymp-magnifying-glass-icon"></use></svg>
          <span translate>home.search</span>
        </a>
    </li>
    <li>
      <a [routerLink]="['/help-children']" (click)="toggleMenu()">
        <i class="mr-3 fa fa-heart c-primary fa-2x"></i>
        <span translate>help_children.widget_title</span>
      </a>
    </li>
    <li class="btn-pretty">
        <a [routerLink]="['/org-profile/hall-of-fame/legends']" (click)="toggleMenu()">
          <img *ngIf="countryCode | flag" [src]="countryCode | flag" alt="flag-icon">
          <span translate>home.hall_of_fame_title</span>
        </a>
    </li>
    <li class="btn-pretty">
      <a [routerLink]="['/home/firms']" (click)="toggleMenu()">
        <img *ngIf="countryCode | flag" [src]="countryCode | flag" alt="flag-icon">
        <span translate>home.nav_benefits</span>
      </a>
    </li>
    <li class="events-item">
      <a [routerLink]="['/home/events']" (click)="toggleMenu()">
        <svg class="olymp-trophy-icon left-menu-icon"><use xlink:href="#olymp-trophy-icon"></use></svg>
        <span translate>home.events</span>
      </a>
    </li>
    <li>
        <a [routerLink]="['/home/clubs']" (click)="toggleMenu()">
            <svg class="olymp-star-icon left-menu-icon"><use xlink:href="#olymp-star-icon"></use></svg>
            <span translate>home.clubs</span>
        </a>
    </li>
    <li>
        <a [routerLink]="['/home/users']" (click)="toggleMenu()">
            <svg class="olymp-happy-faces-icon left-menu-icon"><use xlink:href="#olymp-happy-faces-icon"></use></svg>
            <span translate>home.users</span>
        </a>
    </li>
    <li>
        <a [routerLink]="['/home/facilities']" (click)="toggleMenu()">
            <svg class="olymp-add-a-place-icon left-menu-icon" ><use xlink:href="#olymp-add-a-place-icon"></use></svg>
            <span translate>home.facilities</span>
        </a>
    </li>
    <li>
        <a [routerLink]="['/home/feeds']" (click)="toggleMenu()">
            <svg class="olymp-newsfeed-icon left-menu-icon"><use xlink:href="#olymp-newsfeed-icon"></use></svg>
            <span translate>home.feeds</span>
        </a>
    </li>
    <li>
        <a [routerLink]="['/about-us']" (click)="toggleMenu()">
          <svg class="olymp-info-icon left-menu-icon"><use xlink:href="#olymp-info-icon"></use></svg>
          <span translate>about_us.title</span>
        </a>
    </li>
    <div style="width: 100%; height: 50px;"></div>
</ul>

<ul class="account-settings external-links">
    <li>
        <a target="_blank" href="{{instaUrl}}" >
            <img width="20" style="margin-right: 15px;" alt="insta" src="assets/svg-icons/instagram-logo.svg"/>
            <span translate>instagram_follow_us</span>
        </a>
    </li>

    <!-- <li>
        <a [routerLink]="['/terms-and-conditions']" (click)="toggleMenu()">
            <svg class="olymp-info-icon left-menu-icon"><use xlink:href="#olymp-info-icon"></use></svg>
            <span translate>terms_conditions</span>
        </a>
    </li> -->
    <!-- <li>
        <a [routerLink]="['/privacy-policy']" (click)="toggleMenu()">
            <svg class="olymp-blog-icon left-menu-icon"><use xlink:href="#olymp-blog-icon"></use></svg>
            <span translate>privacy_policy</span>
        </a>
    </li> -->
</ul>
