export type Feed = TextFeed | VideoFeed;

type BaseFeed = {
  _id?: string,
  text: string,
  tags?: Array<string>,
  comments?: Array<any>,
  likes?: Array<any>,
  updated?: string,
  photo?: Record<string, any>,
  country?: string,
  sports?: Array<string>
}

export type TextFeed = BaseFeed & {
  type: FeedType.text,
}

export type VideoFeed = BaseFeed & {
  type: FeedType.video,
  youtubeLink: string,
}

export enum FeedType {
  text = 'text',
  video = 'video'
}
