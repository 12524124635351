<mat-form-field appearance="outline" class="mat-field-align-center">
  <mat-label>{{ label | translate }}</mat-label>
  <input matInput [matAutocomplete]="auto" type="text" name="searchTerm" [(ngModel)]="searchText" (input)="onInput()">
  <ng-container matSuffix>
    <ng-template [ngTemplateOutlet]="matSuffix || defaultMatSuffix"></ng-template>
    <ng-template #defaultMatSuffix></ng-template>
  </ng-container>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="inputDisplayFn">
  <mat-option *ngIf="loading" class="is-loading">
    <mat-spinner diameter="50"></mat-spinner>
  </mat-option>
  <ng-container *ngIf="!loading">
    <mat-option style="line-height: unset;" *ngFor="let result of filteredResults" [value]="result"
                (click)="selectResult(result)">
      <ng-template
        [ngTemplateOutlet]="itemTemplate || defaultItem"
        [ngTemplateOutletContext]="{$implicit: result}"
      ></ng-template>
    </mat-option>
  </ng-container>
</mat-autocomplete>

<ng-template #defaultItem let-item>
  <span>{{ this.inputDisplayFn(item) || item[displayKey] }}</span>
</ng-template>
