<div *ngIf="user?._id" class="mCustomScrollbar" data-mcs-theme="dark">

    <div class="header-wrapper">
      <svg class="olymp-close-icon left-menu-icon" (click)="togglePanel()"><use xlink:href="#olymp-close-icon"></use></svg>
    </div>

    <div class="ui-block-title ui-block-title-small">
        <h6 class="title responsive-display-none" translate>user_menu.account</h6>
        <h6 class="title responsive-display-only" >{{fullName}}</h6>
    </div>

    <ul class="account-settings">
        <li>
            <a [routerLink]="['/profile/me/edit-profile']" (click)="togglePanel()">
                <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>
                <span translate>user_menu.profile</span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/profile/me/edit-profile-password']" (click)="togglePanel()">
                <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>
                <span translate>profile.change_password</span>
            </a>
        </li>
        <li>
            <a *ngIf="allowMassMessages" [routerLink]="['/massmessages']" (click)="togglePanel()">
                <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>
                <span translate>user_menu.mass_messages</span>
            </a>
        </li>
        <li>
            <a *ngIf="user.superAdmin" [routerLink]="['/admin']" (click)="togglePanel()">
                <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>
                <span translate>user_menu.super_admin</span>
            </a>
        </li>
        <li>
          <a [routerLink]="" (click)="hideProfile()">
            <i class="fa" [ngClass]="user.profile.hidden ? 'fa-eye' : 'fa-eye-slash'"></i>
            <span>{{ (user.profile.hidden ? 'user_menu.make_profile_visible' : 'user_menu.hide_profile') | translate }}</span>

          </a>
        </li>
        <li>
            <a [routerLink]="" (click)="logout()">
                <svg class="olymp-logout-icon"><use xlink:href="#olymp-logout-icon"></use></svg>
                <span translate>user_menu.logout</span>
            </a>
        </li>
    </ul>

    <div class="ui-block-title ui-block-title-small">
        <h6 class="title" translate>user_menu.organizations</h6>
    </div>

    <ul class="account-settings">

        <li>
            <a class="create-org-item" [routerLink]="" (click)="registerOrg()">
                <svg class="olymp-star-icon left-menu-icon" ><use xlink:href="#olymp-register-icon"></use></svg>
                <span translate>user_menu.create_org</span>
            </a>
        </li>

        <li *ngFor="let org of user.adminOrgs">
            <a [routerLink]="['/org-profile/'+ org._id + '/edit-profile']" (click)="togglePanel()">

                <svg class="olymp-menu-icon"><use xlink:href="#olymp-menu-icon"></use></svg>

                <span>{{org.profile?.name}}</span>
            </a>
        </li>

    </ul>

</div>
