export const CONSTANTS = {
	REGEX: {
		INSTA_URL: "http://www\.instagram\.com\/(.+)|https://www\.instagram\.com\/(.+)",
		FACEBOOK_URL: "http://www\.facebook\.com\/(.+)|https://www\.facebook\.com\/(.+)",
		TWITTER_URL: "http://twitter\.com\/(.+)|https://twitter\.com\/(.+)",
		TIKTOK_URL: "^(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9_.-]{3,24})|^(?:@)?([a-zA-Z0-9_-]+)",
		LINKEDIN_URL: "^(?:https?:\/\/)?(?:www.)?linkedin.com\/(?:in\/|public-profile\/in\/|pub\/)([a-zA-Z0-9-]+)",
		YOUTUBE_URL: "^(?:https?:\/\/)?(?:www\.)?youtube\.com(?:\/(?:channel|user)\/)?([^/?#]*)|^(?:@)?([a-zA-Z0-9_-]+)",
		SOCIAL_AT_USERNAME: "^@?[a-zA-Z0-9._]+$",
    EMAIL: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
	},
	URLS: {
		INSTA_BASE_URL: "https://www.instagram.com/",
		YOUTUBE_BASE_URL: "https://www.youtube.com/",
		TIKTOK_BASE_URL: "https://www.tiktok.com/",
	},
	PROFILE: {
		TAGS: {
			OFFERS: 'offers',
			ROOM_SHARING: "roomSharing",
			KNOWLEDGE_SHARING: "knowledgeSharing",
			SUPPORT: "support",
			TRAINER: "trainer",
			REFEREE: "referee",
			MASSEUR: "masseur",
			FRIENDSHIP: "friendship",
			PHOTOGRAPH: "photograph",
			SPORT_MODEL: "sportModel",
			MOUNTAIN_GUIDE: "mountainGuide",
			REGISTERED_ATHLETE: "registeredAthlete",
			PROFESSIONAL_INDEPENDENT_ATHLETE: "professionalIndependentAthlete",
			AMATEUR_ATHLETE: "amateurAthlete",
			INSTRUCTOR: "instructor",
			SPORTS_DOCTOR: "sportsDoctor",
			NUTRITIONAL_CONSULTANT: "nutritionalConsultant",
			MENTAL_COUCH: "mentalCouch",
			MANAGER: "manager",
			SPORTS_PHOTOGRAPH: "sportsPhotograph",
			COMMENTATOR_INFLUENCER: "commentatorInfluencer",
			FAN: "fan",
			EQUIPMENT_SERVICE: "equipmentService",
		},
	},
} as const;

export const getAllProfileTags = (): string[] => {
	return Object.values(CONSTANTS.PROFILE.TAGS);
}

export const getProfileSearchTags = (): string[] => {
	return [
		CONSTANTS.PROFILE.TAGS.TRAINER,
		CONSTANTS.PROFILE.TAGS.REFEREE,
		CONSTANTS.PROFILE.TAGS.MASSEUR,
		CONSTANTS.PROFILE.TAGS.PHOTOGRAPH,
		CONSTANTS.PROFILE.TAGS.SPORT_MODEL,
		CONSTANTS.PROFILE.TAGS.MOUNTAIN_GUIDE,
		CONSTANTS.PROFILE.TAGS.REGISTERED_ATHLETE,
		CONSTANTS.PROFILE.TAGS.PROFESSIONAL_INDEPENDENT_ATHLETE,
		CONSTANTS.PROFILE.TAGS.AMATEUR_ATHLETE,
		CONSTANTS.PROFILE.TAGS.INSTRUCTOR,
		CONSTANTS.PROFILE.TAGS.SPORTS_DOCTOR,
		CONSTANTS.PROFILE.TAGS.NUTRITIONAL_CONSULTANT,
		CONSTANTS.PROFILE.TAGS.MENTAL_COUCH,
		CONSTANTS.PROFILE.TAGS.MANAGER,
		CONSTANTS.PROFILE.TAGS.SPORTS_PHOTOGRAPH,
		CONSTANTS.PROFILE.TAGS.COMMENTATOR_INFLUENCER,
		CONSTANTS.PROFILE.TAGS.FAN,
		CONSTANTS.PROFILE.TAGS.EQUIPMENT_SERVICE,
	];
}
