import { Component, Input, OnInit } from '@angular/core';
import { User } from "../../shared/types/profile";
import { ApiService } from "../../services/api.service";
import { Utils } from "../../utils";
import { BaseComponent } from "../../base/base.component";
import { EventService } from "../../services/event.service";
import { BaseEvent, EventType } from "../../shared/types/base";

@Component({
  selector: 'app-profile-donation-widget',
  templateUrl: './profile-donation-widget.component.html',
  styleUrls: ['./profile-donation-widget.component.css']
})
export class ProfileDonationWidgetComponent extends BaseComponent {

  @Input() set user(val: User) {
    if (!val) return;
    this._user = val;
    this.isMe = Utils.isMe(this._user, this.loggedUser);
    this.updateVisible();
  };

  @Input() set org(val: any) {
    if (!val) return;
    this._org = val;
    this.updateVisible();
  }

  @Input() visible = true;

  _user?: User;
  _org?: any;

  isMe = false;
  loggedUser?: User;

  constructor(
    protected apiService: ApiService,
    eventService: EventService,
  ) {
    super(eventService);
    this.loggedUser = this.apiService.getCurrentUser();
  }

  protected eventReceived(event: BaseEvent): void {
    if (event.name === EventType.data && event.data.currentUser) {
      this.loggedUser = event.data.currentUser;
      this.isMe = Utils.isMe(this._user, event.data.currentUser);
      this.updateVisible();
    }
  }

  private updateVisible(): void {
    if (this._user && !this._user.profile.donationsUrl || this._org && !this._org.profile.donationsUrl) {
      this.visible = false;
      return;
    }
    this.visible = this._user ? this.isMe === false : true;
  }
}
