<div class="">
    <div class="modal-content">
        <a [routerLink]="[]" (click)="activeModal.dismiss()" class="close icon-close" aria-label="Close">
            <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
        </a>
        <div class="modal-body">
            <div class="registration-login-form form-register">

                <div class="title h6" translate>register_org.register_org</div>
                    <form class="content">
                        <fieldset [disabled]="working">
                        <div class="row">
                            <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">

                                <mat-form-field appearance="outline" >
                                    <mat-label translate>register_org.name</mat-label>
                                    <input matInput type="text" [(ngModel)]="data.name" name="name" required>
                                </mat-form-field>

                                <mat-form-field appearance="outline" >
                                    <mat-label translate>register_org.place</mat-label>
                                    <input #org_place matInput type="text" name="org_address" value="{{data.location?.formatted_address}}" required>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label translate>register_org.type</mat-label>
                                    <mat-select name="type" [(ngModel)]="data.type" required>
                                        <mat-option *ngFor="let t of orgTypes; trackBy: trackTypesItem" value="{{t.id}}" >{{ t.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>


                                <div style="margin-bottom: 8px;" class="row">

                                    <ng-container *ngIf="data.type == 'firm'">
                                        <div class="col col-6" *ngFor="let tag of tags.firmTags">
                                            <mat-checkbox [(ngModel)]="tag.selected" name="{{tag.id}}" >
                                                {{tag.filterName}}
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="data.type == 'sport_facility'">
                                        <div class="col col-6" *ngFor="let tag of tags.facilityTags">
                                            <mat-checkbox [(ngModel)]="tag.selected" name="{{tag.id}}" >
                                                {{tag.filterName}}
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>

                                </div>

                                <!-- <div style="margin-bottom: 8px;"></div> -->

                                <mat-checkbox name="acceptTerms" [(ngModel)]="acceptTerms">
                                    <span>
                                        {{ 'register_org.accept_terms' | translate }}
                                        <a target='_blank' href='/terms-and-conditions'>{{ 'terms_conditions' | translate }}</a>
                                    </span>
                                </mat-checkbox>

                                <br>

                                <mat-checkbox name="optionsCheckboxes" [(ngModel)]="acceptGDPR">
                                    <span>
                                      {{ 'register_org.accept_gdpr' | translate }}
                                      <a target='_blank' href='/privacy-policy'>{{ 'privacy_policy' | translate }}</a>
                                    </span>
                                </mat-checkbox>

                                <app-alert [alert]="alert"></app-alert>
                                <button class="btn btn-purple btn-lg full-width" (click)="registerOrg()" [disabled]="!data.name || !data.location || !acceptTerms || !acceptGDPR" >
                                    <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {{ 'register_org.btn_register' | translate }}
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </form>

            </div>
        </div>
	</div>
</div>
