<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>profile.change_photo_description</h4>
</div>
<div class="modal-body" style="padding-left: 12px; padding-right: 12px;">
    <mat-form-field appearance="outline">
        <mat-label translate>profile.photo_description</mat-label>
        <textarea matInput name="newDesc" [(ngModel)]="desc"></textarea>
    </mat-form-field>
</div>
<div class="modal-footer" style="margin-top: 0px;">
    <button class="btn btn-secondary" style="min-width: 100px;" (click)="cancel()" translate>btn_cancel</button>
    <button class="btn btn-primary" style="min-width: 100px" (click)="save()" cdkFocusInitial translate>btn_ok</button>
</div>