import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '../../shared/types/menu';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.css']
})
export class TabMenuComponent implements OnInit {
  @Input() items: MenuItem[] = [];
  @Input() position: 'top' | 'bottom' | 'float' = 'top';
  @Input() showBorder = true;
  @Output('onChange') onChangeEmitter = new EventEmitter<MenuItem>();

  constructor() { }

  ngOnInit(): void {
  }

  async onClick(item: MenuItem): Promise<void> {
    this.onChangeEmitter.emit(item);
  }

  isActive(item: MenuItem): boolean {
    if (typeof item.active === 'boolean') return item.active;
    else return item.active();
  }

  navClass(): string {
    const classList: string[] = [this.position];
    if (!this.showBorder) classList.push('no-border');
    return classList.join(' ');
  }
}
