import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '../../shared/types/menu';

@Component({
    selector: 'app-org-profile-settings',
    templateUrl: './org-profile-settings.component.html',
    styleUrls: ['./org-profile-settings.component.css']
})
export class OrgProfileSettingsComponent implements OnInit {

    @Input() org: any = null;
    @Input() setting: string = "edit-profile";
    orgTabItems: MenuItem<{navName: 'edit-profile' | 'edit-profile-career'}>[] = [
        {label: 'profile.org_base_profile', visible: true, active: () => this.setting === 'edit-profile', props: {navName: 'edit-profile'}},
        {label: 'profile.career', visible: true, active: () => this.setting === 'edit-profile-career', props: {navName: 'edit-profile-career'}},
    ];
    
    constructor() { }

    ngOnInit(): void {
    }

    changeSetting(editProfile: string): void {
        this.setting = editProfile;
    }

    onTabChange(item: MenuItem): void {
        if (!item.props?.navName) return;
        this.changeSetting(item.props.navName);
    }
}
