import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PhonePrefix, PhonePrefixes } from './types/input-phone.types';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Component({
  selector: 'input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.css']
})
export class InputPhoneComponent implements OnInit, OnDestroy, OnChanges {
  @Input() input: string = '';
  @Output() inputChange = new EventEmitter<string>();

  phonePrefixItems: Array<PhonePrefix> = PhonePrefixes;
  filteredPhonePrefixItems: Array<PhonePrefix>;
  selectedPhonePrefix: PhonePrefix;
  phoneControl = new FormControl('', );

  private valueChanges: Subscription;

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    let locale = this.translateService.currentLang;
    if (locale === 'en') {
      locale = 'gb';
    }
    if (!this.input) {
      this.selectedPhonePrefix = this.phonePrefixItems.find(prefix => prefix.countryCode.toLowerCase() === locale);
    }
    this.valueChanges = this.phoneControl.valueChanges.subscribe(() => this.emitInput());
  }

  ngOnDestroy(): void {
    this.valueChanges?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.input.currentValue) {
      this.setPhoneNumber(changes.input.currentValue);
    }
  }

  onSelect(event: MatSelectChange): void {
    this.phoneControl.setValidators(this.phonePatternValidator.bind(this)(event.value));
    this.phoneControl.updateValueAndValidity();
    this.emitInput();
  }

  setPhoneNumber(phoneNumber: string): void {
    if (!phoneNumber) return;

    const phoneParts = phoneNumber.split(' ');
    if (phoneParts.length > 1) {
      const prefixString = phoneParts[0];
      this.selectedPhonePrefix = this.phonePrefixItems.find(item => item.prefixNumber && item.prefixNumber.toString() === prefixString) ?? this.phonePrefixItems[0];
      this.phoneControl.setValue(phoneParts[1]);
    } else {
      this.selectedPhonePrefix = this.phonePrefixItems[0];
      this.phoneControl.setValue(phoneNumber);
    }

    this.phoneControl.setValidators(this.phonePatternValidator.bind(this)(this.selectedPhonePrefix));
    this.phoneControl.updateValueAndValidity();
  }

  private emitInput(): void {
    this.selectedPhonePrefix.countryName !== 'Other' ? this.inputChange.emit(`${this.selectedPhonePrefix.prefixNumber.toString()} ${this.phoneControl.value}`) : this.inputChange.emit(this.phoneControl.value);
  }

  private phonePatternValidator(prefix: PhonePrefix): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valid = prefix.pattern === null ? true : prefix.pattern.exec(control.value);
      return Boolean(valid) ? null : {invalidPattern: 'Pattern does not match'};
    };
  }
}
