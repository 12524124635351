import { Pipe, PipeTransform } from '@angular/core';
import { NotificationType } from "../../../shared/types/notifications";

@Pipe({
  name: 'notificationLink'
})
export class NotificationLinkPipe implements PipeTransform {

    transform(notif: any): string | null {
        switch (true) {
          case notif.type === NotificationType.MEMBER_REQUEST: return '/org-profile/' + notif.org.id;
          case notif.type === NotificationType.MEMBER_REQUEST_ORG: return '/org-profile/' + notif.org._id + '/members';
          case notif.type === NotificationType.ORG_ACTIVATED:
          case notif.type === NotificationType.ORG_DEACTIVATED: return '/org-profile/' + notif.org.id;
          case notif.type === NotificationType.FAV_USER_NEW_POST: return '/profile/' + notif.fav_data.user._id + '/feeds';
          case notif.type === NotificationType.FAV_ORG_NEW_POST: return '/org-profile/' + notif.fav_data.org._id + '/feeds';
          default: return null;
        }
    }
}
