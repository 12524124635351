<div class="ui-block">
  <div class="ui-block-title">
    <h6 class="title">{{ 'recommend.recommended_by' | translate }}</h6>
  </div>
  <div class="ui-block-content">
    <div class="d-flex flex-wrap">
      <a *ngFor="let user of recommendedBy" [href]="'/profile/' + user._id">
        <img class="avatar-icon"
             [src]="user.avatar | avatarUrl: 'small'" alt="user-icon"
             [ngbTooltip]="user.profile | userName: 'full'"
        >
      </a>
    </div>
    <p *ngIf="othersCount > 0" class="m-0 text-center c-primary">{{ 'recommend.and_others' | translate:{totalCount: othersCount} }}</p>
  </div>
</div>
