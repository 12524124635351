import { AfterViewInit, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { EnumService } from 'src/app/services/enum.service';
import { Alert } from '../alert/alert.component';
import { GeocoderService } from '../../services/geocoder.service';

@Component({
    selector: 'app-register-org',
    templateUrl: './register-org.component.html',
    styleUrls: ['./register-org.component.css']
})
export class RegisterOrgComponent implements OnInit, AfterViewInit {

    @ViewChild("org_place") public placeElement: ElementRef;

    @Output() registerSuccess = new EventEmitter();

    working: boolean = false;
    orgTypes: any = null;
    data: any = {};
    tags = {
        clubTags: [],
        firmTags: [],
        facilityTags: []
    }
    acceptTerms: boolean = false;
    acceptGDPR: boolean = false;
    alert: Alert = null;

    constructor(
        private apiService: ApiService,
        private enumService: EnumService,
        private ngZone: NgZone,
        public activeModal: NgbActiveModal,
        private geocoderService: GeocoderService,
    ) {
        this.orgTypes = this.enumService.getOrgTypes();
        this.resetData();
    }

    resetData() {
        this.data = {
            name: "",
            type: this.orgTypes ? this.orgTypes[0] : "sport_club",
            location: null
        }

        this.tags.clubTags = this.enumService.getClubTags();
        this.tags.firmTags = this.enumService.getFirmTags();
        this.tags.facilityTags = this.enumService.getFacilityTags();

        this.tags.clubTags.forEach(t=> t.selected = false);
        this.tags.firmTags.forEach(t=> t.selected = false);
        this.tags.facilityTags.forEach(t=> t.selected = false);
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
      this.geocoderService.onLoad.subscribe(loaded => {
        if (!loaded) return;
        const autocomplete = new google.maps.places.Autocomplete(this.placeElement.nativeElement);
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            this.data.location = autocomplete.getPlace();
          });
        });
      })
    }

  trackTypesItem(index: number, item: any) {
        return item;
    }

    registerOrg() {

        if (this.data.location) {
            if (typeof this.data.location.geometry.location.lat == 'function')
                this.data.location.latitude = this.data.location.geometry.location.lat();

            if (typeof this.data.location.geometry.location.lng == 'function')
                this.data.location.longitude = this.data.location.geometry.location.lng();
        }

        if (this.data.type == 'firm') {
            this.data.tags = this.tags.firmTags.filter(t=>t.selected).map(t=> t.id);
        }
        else if (this.data.type == 'sport_facility') {
            this.data.tags = this.tags.facilityTags.filter(t=>t.selected).map(t=> t.id);
        }

        this.working = true;
        this.apiService.registerOrg(this.data).subscribe(
            (result) => {
                this.working = false;
                this.resetData();
                this.hideModal();

                let resData = {};
                if (result?.body?.hasOwnProperty("id"))
                    resData = { "id": result.body["id"] };

                this.registerSuccess.emit(resData);
            },
            (error) => {
                this.working = false;
                let errorMsg = ApiService.getErrorMessage(error);
                this.alert = { type: "error", "message": errorMsg, dismissible: true };
            });
    }

    hideModal() {
        this.activeModal.dismiss();
    }
}
