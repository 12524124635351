<div class="" >
    <div class="modal-content ">
        <a [routerLink]="" (click)="activeModal.dismiss('cancel')" class="close icon-close"  aria-label="Close">
            <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
        </a>

        <div class="modal-header">
            <h6 class="title" translate>{{headerText}}</h6>
        </div>

        <div class="modal-body">
            <div class="upload-photo-item">
                <app-photo-picker [croppingMode]="croppingMode" (photoPicked)="photoFilePicked($event)" (photoPreviewChanged)="onUploadPreview($event)"></app-photo-picker>
            </div>

            <a [hidden]="!showAlbumOption" [routerLink]="" (click)="openPhotoFromAlbum()" class="upload-photo-item" >
              <div>
                <svg class="olymp-photos-icon"><use xlink:href="#olymp-photos-icon"></use></svg>

                <h6 translate>choose_photo.from_photos</h6>
                <span translate>choose_photo.from_photos_desc</span>
              </div>
            </a>
        </div>
	</div>
</div>
