<div class="container">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">

                <div class="header-menu-red-dots">
                    <h2>{{'mass_messages.header_title' | translate}}</h2>
                    <ul class="cat-list-bg-style align-center sorting-menu display-navigation">
                        <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'new' }"><a [routerLink]=""  (click)="currentTab = 'new'" translate>mass_messages.new</a></li>
                        <li [ngClass]="{'cat-list__item' : true, 'active' : currentTab == 'history' }"><a [routerLink]="" (click)="currentTab = 'history'" translate>mass_messages.history</a></li>
                    </ul>
                </div>

                <ng-container *ngIf="currentTab == 'new'">

                    <app-home-filter
                        [initDefaultFilter]="false"
                        [caption]="'home.users' | translate"
                        [type]="FilterType.Users" [advancedIsCollapsed]="false"
                        (filterChanged)="filterChanged($event)"></app-home-filter>

                        <div style="padding:0px;" class="col col-lg-12 col-md-12 col-sm-12 col-12 col-centered">
                            <div class="ui-block align-center">
                                <div class="ui-block-content">
                                    <p *ngIf="usersCount < 0">{{'mass_messages.instruction' | translate}}</p>
                                    <p *ngIf="usersCount >= 0">{{ 'mass_messages.users_found' | translate:{'count': usersCount} }}</p>

                                    <mat-form-field appearance="outline">
                                        <!-- <mat-label translate>Reply</mat-label> -->
                                        <textarea matInput name="newMessage" rows="3" [(ngModel)]="messageText"></textarea>
                                    </mat-form-field>

                                    <div class="send-msg">
                                        
                                        <span class="emoji-selector">
                                            <a [routerLink]="" class="emoji-control" [matMenuTriggerFor]="emojisMenu" #matMenuTrigger="matMenuTrigger">
                                                <svg class="olymp-happy-face-icon"><use xlink:href="#olymp-happy-face-icon"></use></svg>
                                            </a>
            
                                            <mat-menu #emojisMenu="matMenu" >
                                                <emoji-mart (click)="$event.stopPropagation();" class="emoji-mart" [showSingleCategory]="true" (emojiSelect)="addEmoji($event); matMenuTrigger.closeMenu();" title="{{ 'choose_emoji' | translate }}"></emoji-mart>
                                            </mat-menu>
                                        </span>

                                        <mat-form-field style="width: unset; margin-right: 16px;" appearance="outline">
                                            <mat-label translate>mass_messages.from</mat-label>
                                            <mat-select name="from" [(ngModel)]="from">
                                                <mat-option *ngFor="let subject of fromSubjects" value="{{subject.id}}">{{subject.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <button style="margin-top: 6px;" (click)="sendMessage()" [disabled]="working || usersCount < 1 || !messageText" class="btn btn-primary">
                                                <span *ngIf="!working">{{'mass_messages.send_message' | translate:{'count': usersCount >= 0 ? usersCount : 0 } }}</span>
                                                <span *ngIf="working">{{'mass_messages.sending_in_progress' | translate }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                </ng-container>
                <ng-container *ngIf="currentTab == 'history'">
                    <app-mass-messages-history [fromSubjects]="fromSubjects"></app-mass-messages-history>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<app-back-to-top></app-back-to-top>
