import { Component } from '@angular/core';

@Component({
  selector: 'app-help-children-widget',
  templateUrl: './help-children-widget.component.html',
  styleUrls: ['./help-children-widget.component.css']
})
export class HelpChildrenWidgetComponent {
  constructor() { }
}
