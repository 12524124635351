import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Subject, Subscription } from "rxjs";
import { ApiService } from "../../services/api.service";
import { debounceTime, finalize, switchMap, tap } from "rxjs/operators";
import { SearchConfig } from "./types/search-generic.types";

@Component({
  selector: 'app-search-generic',
  templateUrl: './search-generic.component.html',
  styleUrls: ['./search-generic.component.css']
})
export class SearchGenericComponent implements OnInit, OnDestroy {

  constructor(private apiService: ApiService) { }

  @Input() searchConfig: SearchConfig;
  @Input() label: string = 'suggestion.searchPlaceholder';
  @Input() matSuffix: TemplateRef<any>;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() displayKey: string;
  @Input() inputDisplayFn: (result: any) => string;

  @Output() onSelect = new EventEmitter<any>();
  private searchSub: Subscription;
  _searchSubject = new Subject<string>();
  loading = false;
  filteredResults: Array<any> = [];
  searchText: string;

  ngOnInit(): void {
    this.searchSub = this._searchSubject.pipe(debounceTime(300),
      tap(() => {
        this.loading = true;
        this.searchConfig.search = this.searchText;
      }),
      switchMap(value => this.apiService.quicksearch(this.searchConfig)
        .pipe(
          finalize(() => this.loading = false)
        )
      )
    ).subscribe(result => {
      if (result.body.result.length === 0) this.onSelect.emit(undefined);
      this.filteredResults = result.body?.result
    });
  }

  selectResult(result: any): any {
    this.onSelect.emit(result);
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
  }

  onInput(): void {
    this._searchSubject.next(this.searchText);
  }
}
