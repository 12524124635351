<div class="" >
    <div class="modal-body registration-login-form " style="padding: 0px; margin: 0px;">
        <div class="title d-flex align-items-center justify-content-between">
          <h6 class="m-0">{{ 'register_user.register_user' | translate }}</h6>
          <a *ngIf="showCloseIcon" [routerLink]="[]" (click)="activeModal.dismiss();" class="close icon-close" aria-label="Close">
            <svg class="olymp-close-icon"><use xlink:href="#olymp-close-icon"></use></svg>
          </a>
        </div>
        <div class="content">
          <ng-content select="[startContent]"></ng-content>
          <form [formGroup]="registerForm" (ngSubmit)="registerUser(registerForm.value)">
            <fieldset [disabled]="working">
              <div class="row">
                <div class="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">

                  <mat-form-field appearance="outline" >
                    <mat-label translate>register_user.first_name</mat-label>
                    <input matInput type="text" formControlName="firstname" >
                  </mat-form-field>

                </div>
                <div class="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">

                  <mat-form-field appearance="outline" >
                    <mat-label translate>register_user.last_name</mat-label>
                    <input matInput type="text" formControlName="lastname" >
                  </mat-form-field>

                </div>
                <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">

                  <mat-form-field appearance="outline" >
                    <mat-label translate>register_user.your_email</mat-label>
                    <input matInput type="email" formControlName="email" >
                  </mat-form-field>

                  <mat-form-field appearance="outline" >
                    <mat-label translate>register_user.your_password</mat-label>
                    <input matInput [type]="passwordHide ? 'password' : 'text'" formControlName="password">
                    <mat-icon matSuffix style="cursor: pointer;" (click)="passwordHide = !passwordHide">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="passwordFormField.dirty && passwordFormField.invalid && passwordFormField.errors.passwordLength">
                      <span>{{ passwordFormField.errors.passwordLength | translate }}</span>
                    </mat-error>
                  </mat-form-field>

                  <mat-label>{{ 'profile.birthday' | translate }}</mat-label>
                  <app-date-picker-simple (onChange)="birthdateChanged($event)"></app-date-picker-simple>

                  <mat-form-field appearance="outline">
                    <mat-label translate>register_user.your_gender</mat-label>
                    <mat-select formControlName="gender" >
                      <mat-option value="male">{{ 'register_user.male' | translate }}</mat-option>
                      <mat-option value="female">{{ 'register_user.female' | translate }}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label translate>register_user.defSport</mat-label>
                    <mat-select formControlName="defSport">
                      <mat-select-filter  [items]="allSports"
                                          [filterKey]="'name'"
                                          [placeholder]="'profile.search_sport' | translate"
                                          (onFiltered)="filteredSports = $event"
                      ></mat-select-filter>
                      <mat-option *ngFor="let sport of filteredSports" value="{{sport.id}}">{{sport.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div class="d-flex flex-column mb-2">
                    <mat-checkbox name="optionsCheckboxes" formControlName="acceptTerms">
                        <span>
                          {{ 'register_user.accept_terms' | translate }}
                          <a target='_blank' href='/terms-and-conditions'>{{ 'terms_conditions' | translate }}</a>
                        </span>
                    </mat-checkbox>

                    <mat-checkbox name="optionsCheckboxes" formControlName="acceptGDPR">
                        <span>
                          {{ 'register_user.accept_gdpr' | translate }}
                          <a target='_blank' href='/privacy-policy'>{{ 'privacy_policy' | translate }}</a>
                        </span>
                    </mat-checkbox>
                  </div>

                  <app-alert [alert]="alert"></app-alert>
                  <button type="submit" class="btn btn-purple btn-lg full-width" [disabled]="!registerForm.valid || !registerForm.value.acceptTerms || !registerForm.value.acceptGDPR" >
                    <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{ 'register_user.btn_register' | translate }}
                  </button>
                  <ng-content select="[endContent]"></ng-content>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
    </div>
</div>
