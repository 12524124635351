import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';

const MAX_ELEMS = 5;

@Component({
    selector: 'app-favs-widget',
    templateUrl: './favs-widget.component.html',
    styleUrls: ['./favs-widget.component.css']
})
export class FavsWidgetComponent extends BaseComponent implements OnInit {

    @Input() user: any = null;
    favs: any[] = [];

    constructor(
        eventService: EventService,
        private apiService: ApiService
    ) {
        super(eventService);
    }

    ngOnInit(): void {
        this.getFavs();
    }

    getFavs() {
        if (!this.user?._id) {
            return;
        }

        this.apiService.getFavorites(this.user._id, { "filter": "users,orgs" }).subscribe(
            (result) => { 
                let res = result.body["favorites"];
                this.handleFavs(res?.slice(0, MAX_ELEMS) ?? []);
             },
            (error) => this.handleApiError(error) );
    }

    handleFavs(result) {
        let favs = [];
        result.forEach(f => {
            if (f.user) {
                f["profileUrl"] = `/profile/${f.user._id}`;
                f["avatar"] = ApiService.getPhotoUrl(f.user.avatar?.avatar?.small, Utils.getAvatarSmall());
                f["name"] = `${f.user.profile?.firstname} ${f.user.profile?.lastname}`;
                f["country"] = f.user.profile?.country;
            }
            else if (f.org) {
                f["profileUrl"] = `/org-profile/${f.org._id}`;
                f["avatar"] = ApiService.getPhotoUrl(f.org.avatar?.avatar?.small, Utils.getLogoSmall());
                f["name"] = `${f.org.profile?.name}`;
                f["country"] = f.org.profile?.country;
            }
            else {
                return;
            }

            favs.push(f);
        });

        this.favs = favs;
    }
}
