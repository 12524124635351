import { Component, Input, OnInit } from '@angular/core';
import { OrganizationType, User } from "../../shared/types/profile";
import { ApiService } from "../../services/api.service";
import { first } from "rxjs/operators";
import { BaseComponent } from "../../base/base.component";
import { EventService } from "../../services/event.service";

@Component({
  selector: 'app-recommend-button',
  templateUrl: './recommend-button.component.html',
  styleUrls: ['./recommend-button.component.css']
})
export class RecommendButtonComponent extends BaseComponent implements OnInit {

  @Input() org: any;
  @Input() visible = false;
  _isRecommended = false;
  _currentUser: User = null;

  constructor(protected eventService: EventService, private apiService: ApiService) {
    super(eventService);
  }

  ngOnInit(): void {
    this._currentUser = this.apiService.getCurrentUser();
    if (this._currentUser) {
      this.visible = this.org.owner !== this._currentUser._id &&
        this._currentUser.verified &&
        ![OrganizationType.hall_of_fame, OrganizationType.fan_club, OrganizationType.rescue_service].includes(this.org.type);
      this._isRecommended = this._currentUser?.recommendedOrgs?.some(orgId => this.org._id === orgId) || false;
    }
  }

  clicked(): void {
    if (this.org) {
      if (!this._isRecommended) {
        this.apiService.recommendOrg(this.org._id)
          .pipe(first())
          .subscribe(() => {
            this._isRecommended = true;
          }, (e) => this.handleApiError(e));
      } else {
        this.apiService.cancelRecommendOrg(this.org._id)
          .pipe(first())
          .subscribe(() => {
            this._isRecommended = false;
          }, (e) => this.handleApiError(e));
      }
    }
  }
}
