import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { EventType } from '../../shared/types/base';
import { first } from 'rxjs/operators';
import { User } from "../../shared/types/profile";
import { ModalService } from "../../services/modal.service";

@Component({
    selector: 'app-profile-edit-career',
    templateUrl: './profile-edit-career.component.html',
    styleUrls: ['./profile-edit-career.component.css']
})
export class ProfileEditCareerComponent extends BaseComponent implements OnInit {

    _user: User = null;
    _loggedUser?: User;
    _superAdminEdit = false;

    get user(): any {
        return this._user;
    }

    @Input() set user(value: any) {
        this._user = value;
        this._superAdminEdit = this._loggedUser?.superAdmin && this._user._id !== this._loggedUser?._id;
        this.restoreValues();
    }

    _org: any = null;
    get org(): any {
        return this._org;
    }

    @Input() set org(value: any) {
        this._org = value;
        this._superAdminEdit = this._loggedUser?.superAdmin && this._org.owner !== this._loggedUser._id;
        this.restoreValues();
    }

    working: boolean = false;

    data: any[] = [];

    constructor(
        eventService: EventService,
        private apiService: ApiService,
        private modalService: ModalService,
    ) {
        super(eventService);
        this._loggedUser = this.apiService.getCurrentUser();
    }

    ngOnInit(): void {}

    addNewFiled() {
        this.data.push( { "title": "", "from": "", "to": "", "description": "" } );
    }

    removeField(field) {
        if (this.data)
            this.data = this.data.filter(f => f != field);
    }

    restoreValues() {
        if (this._user?.profile?.career) {
            this.data = [];
            this.data = [].concat(this._user.profile.career);
        }

        if (this._org?.profile?.career) {
            this.data = [];
            this.data = [].concat(this._org.profile.career);
        }

        if (this.data.length == 0) {
            this.addNewFiled();
        }
    }

    async saveCareer(): Promise<void> {
        if (this._superAdminEdit) {
          const confirmed = await this.modalService.showSuperAdminProfileEditWarning(this._user || this._org, this._user ? 'user' : 'org').closed.toPromise()
          if (!confirmed) return;
        }

        let incomplete = this.data.find(c => (c.title == "" || c.from == "" || c.to == "" || c.description == "") );
        if (incomplete) {
            this.sendEvent({
                name: EventType.alert, data: { type: 'error', message: 'profile.update_all_field_required', dismissible: true }
            });
            return;
        }

        let career = this.data.filter(c => {
            return c.title != "" ;
        });

        if (this.org?._id) {
            this.working = true;
            this.apiService.updateOrgProfile(this.org._id, {"career": career} )
              .pipe(first())
              .subscribe(
                (result) => {
                    this.working = false;
                    this.sendUpdateProfileEvent()
                    this.sendUpdateProfileSuccessEvent();
                },
                (error) => { this.working = false; this.handleApiError(error); } );
        } else {
            this.working = true;
            this.apiService.updateUserProfile( {"career": career}, this._superAdminEdit ? this._user._id : 'me' )
              .pipe(first())
              .subscribe(
                (result) => {
                    this.working = false;
                    this.sendUpdateProfileEvent();
                    this.sendUpdateProfileSuccessEvent();
                },
                (error) => { this.working = false; this.handleApiError(error); } );
        }
    }

    private sendUpdateProfileEvent(): void {
        this.sendEvent({ name: EventType.update, data: { update: 'profile' } });
    }

    private sendUpdateProfileSuccessEvent(): void {
        this.sendEvent({
            name: EventType.alert, data: { type: 'success', message: 'profile.update_success', dismissible: true }
        });
    }
}
