import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { ApiService } from '../services/api.service';
import { EventService } from '../services/event.service';
import { BaseEvent, EventType } from '../shared/types/base';
import { User } from "../shared/types/profile";

@Component({
    selector: 'app-org-profile',
    templateUrl: './org-profile.component.html',
    styleUrls: ['./org-profile.component.css']
})
export class OrgProfileComponent extends BaseComponent implements OnInit {

    orgId: string = null;
    org: any = null;
    editable: boolean = false;

    currentView: string = "feeds";
    showMemberAnnouncement: boolean = true;

    isCurrentUserAdmin = false;
    currentUser?: User;

    constructor(
        protected eventService: EventService,
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService
    ) {
        super(eventService);

        this.editable = true;

        this.activatedRoute.params.subscribe(params => {

            this.currentView = params["view"];
            this.orgId = params["orgId"];
            this.getOrg();
        });
    }

    ngOnInit(): void {
      this.currentUser = this.apiService.getCurrentUser();
      this.isCurrentUserAdmin = this.apiService.isCurrentUserAdmin(this.orgId);
    }

    protected eventReceived(event: BaseEvent): void {
        if (event.name === EventType.update && event.data.update === 'profile') {
            this.getOrg();
        }
    }

    getOrg() {
        if (!this.orgId) {
            return;
        }

        this.apiService.getOrganization(this.orgId).subscribe(
            (result) => {
                this.org = result.body["organization"];
                this.editable = this.apiService.isCurrentUserAdmin(this.org._id);
            },
            (error) => this.handleApiError(error) );
    }

    isCurrentUserOwner(): boolean {
      return (this.currentUser?._id === this.org?.owner) || false;
    }
}
