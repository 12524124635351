<div class="container">
	<div class="row">

        <div class="col col-xl-4 order-xl-1 col-lg-4 order-lg-1 col-md-12 order-md-1 col-sm-12 col-12">
            <app-profile-donation-widget [user]="user" [org]="org"></app-profile-donation-widget>
			      <app-profile-info-widget [user]="user" [org]="org"></app-profile-info-widget>
            <app-favs-widget *ngIf="user && (user?._id === curentUser?._id)" [user]="user"></app-favs-widget>
        </div>

        <div class="col col-xl-8 order-xl-2 col-lg-8 order-lg-2 col-md-12 order-md-2 col-sm-12 col-12">
            <div *ngIf="profile?.career?.length" class="ui-block">
				<div class="ui-block-title">
            <h6 *ngIf="user" class="title" translate>profile.career_header_me</h6>
            <h6 *ngIf="org" class="title" translate>profile.career_org_header_me</h6>
				</div>
				<div class="ui-block-content">
					<div class="row">

						<div *ngFor="let career of profile.career" class="col col-lg-6 col-md-6 col-sm-12 col-12">
							<ul class="widget w-personal-info item-block">
								<li>
									<span class="title">{{career.title}}</span>
									<span class="date">{{career.from}} - {{career.to}}</span>
									<span class="text">{{career.description}}</span>
                </li>
            </ul>
        </div>

					</div>
				</div>
            </div>

            <div *ngIf="profile?.location" class="ui-block">
				<div class="ui-block-title">
                    <h6 *ngIf="user" class="title" translate>profile.place</h6>
                    <h6 *ngIf="org" class="title" translate>profile.place_org</h6>
				</div>
				<div class="ui-block-content" style="padding: 4px;">
                    <agm-map
                        [latitude]="profile.location.latitude"
                        [longitude]="profile.location.longitude"
                        [disableDefaultUI]="true"
                        [clickableIcons]="false"
                        [scrollwheel]="null" >
                        <agm-marker
                            [latitude]="profile.location.latitude"
                            [longitude]="profile.location.longitude">
                        </agm-marker>
                    </agm-map>
				</div>
            </div>

        </div>
	</div>
</div>

<app-back-to-top></app-back-to-top>
