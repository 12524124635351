import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'searchType'
})
export class SearchTypePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
  ) {
  }
  transform(type: 'user' | 'org' | 'feed'): string {
    if (type=='user') return this.translateService.instant('search.user');
    if (type == 'org') return this.translateService.instant('search.org');
    if (type == 'feed') return this.translateService.instant('search.feed');

    return '';
  }
}
