<div class="modal-header">
  <h4 class="modal-title">{{ 'alert.header_warning' | translate }}</h4>
</div>
<div class="p-3">
  <span class="text"
        [innerHtml]="(_user || _org) | warningText: _user ? 'user' : 'org'"
  ></span>
</div>
<div class="modal-footer">
  <div class="d-flex flex-grow-1 justify-content-end gap-05">
    <button class="btn btn-primary m-0" (click)="onCancel()">{{ 'btn_cancel' | translate }}</button>
    <button class="btn btn-secondary m-0" (click)="onConfirm()">{{ 'btn_ok' | translate }}</button>
  </div>
</div>
