<ng-container *ngFor="let row of data">
  <div class="mobile-item ui-block">
    <div class="content ui-block-content d-flex">
      <div class="info">
        <div class="caption d-flex flex-column gap-05">
          <ng-container *ngIf="type === 'org'">
            <span class="title">
              <a href="/org-profile/{{row._id}}" target="_blank">{{ row.profile.name }}</a>
              <img *ngIf="row?.profile?.country" style="width: 32px" src="{{row.profile.country | flag}}" />
            </span>
          </ng-container>
          <ng-container *ngIf="type === 'user'">
            <span class="title">
              <a href="/profile/{{row._id}}" target="_blank">{{ row.profile | userName }}</a>
              <img *ngIf="row?.profile?.country" style="width: 32px" src="{{row.profile.country | flag}}" />
            </span>
          </ng-container>
          <span *ngIf="type === 'user'">{{ row.email }}</span>
          <span>{{row.created | date}}</span>
        </div>
      </div>
      <div class="actions">
        <div class="d-flex gap-05">
          <button *ngIf="!row.allowMassMessages" class="btn btn-sm btn-success m-0" (click)="onAllowMessages(row, true)">{{'admin.allow_messages' | translate}}</button>
          <button *ngIf="row.allowMassMessages" class="btn btn-sm btn-danger m-0" (click)="onAllowMessages(row, false)">{{'admin.disallow_messages' | translate}}</button>

          <button *ngIf="!row.active && type === 'org'" class="btn btn-sm btn-success m-0" (click)="onActivateProfile(row, true)">{{'admin.activate_profile' | translate}}</button>
          <button *ngIf="row.active && type === 'org'" class="btn btn-sm btn-danger m-0" (click)="onActivateProfile(row, false)">{{'admin.deactivate_profile' | translate}}</button>

          <button *ngIf="!row.blocked && type === 'user'" class="btn btn-sm btn-danger m-0" (click)="onBlockUser(row, true)">{{'admin.block' | translate}}</button>
          <button *ngIf="row.blocked && type === 'user'" class="btn btn-sm btn-success m-0" (click)="onBlockUser(row, false)">{{'admin.unblock' | translate}}</button>
        </div>
        <div class="d-flex gap-05">
          <button class="btn m-0 btn-sm" [ngClass]="row.verified ? 'btn-danger' : 'btn-success'">{{ (row.verified ? 'admin.remove_verified' : 'admin.add_verified') | translate }}</button>
          <a class="btn m-0 btn-sm btn-primary full-width d-flex align-items-center justify-content-center" role="button"
             [href]="row.type ? '/org-profile/' + row._id + '/edit-profile' : '/profile/' + row._id + '/edit-profile'"
             target="_blank"
          >
            {{ 'admin.edit_profile' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
