import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { FilterType, HomeFilterComponent } from '../components/home-filter/home-filter.component';
import { ApiService } from '../services/api.service';
import { EventService } from '../services/event.service';
import { BaseEvent, EventType } from '../shared/types/base';
import { HomeFeedsComponent } from '../components/home-feeds/home-feeds.component';
import { Filter } from '../components/home-filter/types/types';
import { HomeMapComponent } from '../components/home-map/home-map.component';
import { GeocoderService } from "../services/geocoder.service";
import { NewFeedComponent } from "../components/new-feed/new-feed.component";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {

    filter: Filter = { countries: [], sports: [], tags: [] };

    currentTab: string = "";
    filterType: FilterType = FilterType.Feeds;

    currentUser: any = null;
    collapseNewFeed: boolean = true;

    showMap: boolean = false;

    _feedsComponent?: HomeFeedsComponent = null;
    _geoCountryCode?: string;
    filtersLoaded = false;

    @ViewChild('feedsComponent') set feedsComponent(component: HomeFeedsComponent) {
        this._feedsComponent = component;
    }

    @ViewChild('newFeedComponent') newFeedComponent: NewFeedComponent;
    @ViewChild('filterComponent') filtersComponent: HomeFilterComponent;
    @ViewChild('homeMapComponent') homeMap: HomeMapComponent;

    get feedsComponent() { return this._feedsComponent; }

    constructor(
      protected activatedRoute: ActivatedRoute,
      eventService: EventService,
      private apiService: ApiService,
      private geoService: GeocoderService
    ) {
        super(eventService);
    }

    ngOnInit(): void {
        this.geoService.countryCode.subscribe(code => this._geoCountryCode = code);
        this.currentUser = this.apiService.getCurrentUser();
        this.activatedRoute.params.subscribe(params => {
            this.currentTab = params["tab"];
            this.showMap = params["map"] == "map";

            if (!this.currentTab)
                this.filterType = FilterType.Home;
            else if (this.currentTab == 'feeds')
                this.filterType = FilterType.Feeds;
            else if (this.currentTab == 'users')
                this.filterType = FilterType.Users;
            else if (this.currentTab == 'clubs')
                this.filterType = FilterType.Clubs;
            else if (this.currentTab == 'firms')
                this.filterType = FilterType.Firms;
            else if (this.currentTab == 'facilities')
                this.filterType = FilterType.Facilities;
            else if (this.currentTab == 'map')
                this.filterType = FilterType.Map;
            else if (this.currentTab == 'map')
                this.filterType = FilterType.Events;
            else if (this.currentTab == 'events')
                this.filterType = FilterType.Events;
        });
    }

    protected eventReceived(event: BaseEvent): void {
        if (event.name === EventType.data && event.data.currentUser) {
            this.currentUser = this.apiService.getCurrentUser();
        }
    }

    getClasses(tab) {
        let classes = ['cat-list__item'];
        //if (tab == this.currentTab || (!this.currentTab && tab == 'feeds')) {
        if (tab == this.currentTab) {
            classes.push('active');
        }

        return classes;
    }

    getHeaderText() {
        if (!this.currentTab)
            return "home.home";

        return `home.${this.currentTab}`;
    }

    filterChanged(event: {filter: Filter}): void {
        if (this.filterType === 'map') {
            this.homeMap?.search();
        }
        this.filter = Object.assign({}, event?.filter);
    }

    onFiltersLoaded(): void {
        this.filtersLoaded = true;
    }

    onNewFeedCollapse(collapsed: boolean): void {
      if (this.newFeedComponent) {
        collapsed ? this.newFeedComponent.blurFeedInput() : this.newFeedComponent.focusFeedInput();
      }
    }
}
